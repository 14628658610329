import { createContext, useContext }        from "react";
import { InscriptionExpeditionContextType } from "./InscriptionExpeditionContextType";

export const InscriptionExpeditionContext = createContext<InscriptionExpeditionContextType>({
    expeditions                          : [],
    setExpeditions                       : () => {
    },
    ouvriers                             : [],
    setOuvriers                          : () => {
    },
    recuperationCitoyensPreinscrit       : (): any => {
    },
    recuperationCitoyensPreinscritOuvrier: (): any => {
    },
    jourActuel                           : null,
    faoDirection                         : null,
    optionsSoif                          : [],
});

export function useIEContext() {
    return useContext(InscriptionExpeditionContext);
}