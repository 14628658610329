import React, { useEffect, useState }        from "react";
import chargement                            from "../../../img/chargement.svg";
import { RetourRuineGameMain, RuineGameApi } from "../../services/api/RuineGameApi";
import { RuineGame }                         from "../../containers/RuineGame/RuineGame";
import { useGeneralContext }                 from "../../types/Context/GeneralContext";
import { useGHContext }                      from "../../types/Context/GHContext";
import { Status_error, usePopUp }            from "../../types/Context/PopUpContext";
import { useNavigate }                       from "react-router-dom";
import { useTranslation }                    from "react-i18next";

export function RuineGameGeneral() {
    const { general, setGeneral } = useGeneralContext();
    const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const navigate = useNavigate();
    const [ruineGameData, setRuineGameData] = useState(null);
    const { t } = useTranslation();
    
    // Fonction pour recharger les données
    const reloadContactData = async () => {
        const ruineGameApi = new RuineGameApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
        
        ruineGameApi.main().then((response: RetourRuineGameMain) => {
            setIsOnRefresh(false);
            setIsOnUpdate(false);
            if (response.data.general !== undefined) {
                setGeneral(response.data.general);
                sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
            }
            if (response.data.ruine_game === undefined) {
                console.error("Erreur de chargement des données ruine_game", response);
            } else {
                setRuineGameData(response.data.ruine_game);
            }
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error?.data?.error);
            navigate("/news");
        });
    };
    
    useEffect(() => {
        if (checkHabilitation("ruineGame")) {
            reloadContactData().then(r => r);
        } else {
            navigate("/news");
        }
    }, [refreshKey]);
    
    return (
        <>
            {ruineGameData === null ? (
                <div className={"chargement_page"}>
                    <img src={chargement} alt="Drapeau" />
                    <span>{t("Chargement...", { ns: "app" })}</span>
                </div>
            ) : (<RuineGame ruineGame={ruineGameData} />)}
        </>
    );
}