import { Fetch }            from "../../components/generality/Fetch";
import { StatistiquesType } from "../../types/components/Hotel/StatistiquesType";
import { GeneralType }      from "../../types/components/Generality/GeneralType";


export class StatistiqueApi {
    
    private fetch: Fetch;
    
    constructor(mapId?: number) {
        this.fetch = new Fetch("stats", mapId ?? 0);
    }
    
    public main(mapId?: string): Promise<AffichageStats> {
        return this.fetch.from("/" + ((mapId) ? mapId : "")).request().get();
    }
    
}

export type AffichageStats = {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        stats: StatistiquesType
        general: GeneralType,
    },
}