import { Fetch }             from "../../components/generality/Fetch";
import { GeneralType }       from "../../types/components/Generality/GeneralType";
import { FetchGH }           from "../../components/generality/FetchGH";
import { OutilsChantierDTO } from "../../types/models/outilsChantier.dto";

export class OutilsChantiersApi {
    
    private fetch: Fetch;
    private fetchGH: FetchGH;
    
    constructor(mapId: number) {
        this.fetch = new Fetch("outils/chantiers", mapId ?? 0);
        this.fetchGH = new FetchGH("outils/chantiers", mapId ?? 0);
    }
    
    public main(): Promise<AffichageChantiers> {
        return this.fetchGH.get("/");
    }
    
}

export interface AffichageChantiers {
    data: {
        outilsChantier?: OutilsChantierDTO,
        general: GeneralType,
        error?: string,
    }
    status?: number,
    message?: string,
}