import React, { useEffect, useState }             from "react";
import { ListPlansObtenu, PlansChantierType }     from "../../../types/components/Hotel/PlansChantierType";
import SvgIcone                                   from "../../../components/generality/SvgIcone";
import { PlansChantierApi }                       from "../../../services/api/PlansChantierApi";
import Cookies                                    from "js-cookie";
import ListChantiers                              from "../../Encyclopedie/Chantiers/ListChantiers";
import Tab                                        from "react-bootstrap/Tab";
import Tabs                                       from "react-bootstrap/Tabs";
import LignePlanChantier                          from "./LignePlanChantier";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import HTMLParser                                 from "html-react-parser";
import { ChantierPrototypeDTO }                   from "../../../types/models/chantierPrototype.dto";
import { useTranslation }                         from "react-i18next";
import { formatInTimeZone }                       from "date-fns-tz";
import { useGeneralContext }                      from "../../../types/Context/GeneralContext";


interface PlansChantiersProps {
    plansChantier: PlansChantierType;
}

export default function PlansChantiers({ plansChantier }: PlansChantiersProps) {
    const { t } = useTranslation();
    const selectOngletCookie = Cookies.get("selectedOngletPlans");
    const { general } = useGeneralContext();
    const [listPlansObtenu, setListPlansObtenu] = useState(plansChantier.listPlansVille);
    const [panneauRuineOuvert, setPanneauRuineOuvert] = useState(false);
    const [ville, setVille] = useState(plansChantier.ville);
    const [idxSelected, setIdxSelected] = useState(selectOngletCookie ?? "plans");
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const [modified, setModified] = useState(false);
    
    useEffect(() => {
        setListPlansObtenu(plansChantier.listPlansVille);
        setVille(plansChantier.ville);
    }, [plansChantier]);
    
    
    const handleChangePlansObtenu = (listPlansObtenu) => {
        setListPlansObtenu(listPlansObtenu);
        setModified(true);
    };
    
    const handleBoutonAnnuler = (event) => {
        event.preventDefault();
        setListPlansObtenu(plansChantier.listPlansVille);
        setModified(false);
    };
    
    const handleBoutonSauver = (event) => {
        event.preventDefault();
        const planApi = new PlansChantierApi();
        
        planApi.sauvegarde({
            mapId    : plansChantier.ville.map_id,
            userId   : plansChantier.user.id,
            listPlans: listPlansObtenu,
        }).then((response) => {
            if (response.codeRetour === 0) {
                setVille(response.zoneRetour.ville);
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
                setTimeout(() => {
                    setShowPop(false);
                    setMessagePopUp("");
                    setModified(false);
                }, 1500);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
            }
        });
    };
    
    const handleTabChange = (eventKey) => {
        setIdxSelected(eventKey);
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        Cookies.set("selectedOngletPlans", eventKey, {
            expires: expirationDate,
            path   : "/",
            secure : true,
        });
    };
    
    let nbrPlansCom: number = 0;
    let nbrPlansInhab: number = 0;
    let nbrPlansRare: number = 0;
    let nbrPlansTresRare: number = 0;
    
    Object.values(listPlansObtenu).map((plans: ListPlansObtenu) => {
        switch (plans.chantier.plan) {
            case 1:
                nbrPlansCom++;
                break;
            case 2:
                nbrPlansInhab++;
                break;
            case 3:
                nbrPlansRare++;
                break;
            case 4:
                nbrPlansTresRare++;
                break;
        }
    });
    
    const plansTrier = Object.values(plansChantier.listPlans).sort((chantier_a, chantier_b) => {
        const aName = t(chantier_a.nom, { ns: "chantiers" });
        const bName = t(chantier_b.nom, { ns: "chantiers" });
        return aName.localeCompare(bName);
    });
    
    const plansCommun = [];
    const plansInhab = [];
    const plansRare = [];
    const plansTresRare = [];
    const plansInhab_bu = [];
    const plansRare_bu = [];
    const plansTresRare_bu = [];
    const plansInhab_hs = [];
    const plansRare_hs = [];
    const plansTresRare_hs = [];
    const plansInhab_ho = [];
    const plansRare_ho = [];
    const plansTresRare_ho = [];
    
    plansTrier.map((chantier: ChantierPrototypeDTO) => {
        switch (chantier.plan) {
            case 1:
                plansCommun.push(chantier);
                break;
            case 2:
                plansInhab.push(chantier);
                if (chantier.ruine_hs) {
                    plansInhab_hs.push(chantier);
                }
                if (chantier.ruine_bu) {
                    plansInhab_bu.push(chantier);
                }
                if (chantier.ruine_ho) {
                    plansInhab_ho.push(chantier);
                }
                break;
            case 3:
                plansRare.push(chantier);
                if (chantier.ruine_hs) {
                    plansRare_hs.push(chantier);
                }
                if (chantier.ruine_bu) {
                    plansRare_bu.push(chantier);
                }
                if (chantier.ruine_ho) {
                    plansRare_ho.push(chantier);
                }
                break;
            case 4:
                plansTresRare.push(chantier);
                if (chantier.ruine_hs) {
                    plansTresRare_hs.push(chantier);
                }
                if (chantier.ruine_bu) {
                    plansTresRare_bu.push(chantier);
                }
                if (chantier.ruine_ho) {
                    plansTresRare_ho.push(chantier);
                }
                break;
        }
    });
    
    const plansCommunTab = Object.values(plansCommun);
    const plansInhabTab = Object.values(plansInhab);
    const plansRareTab = Object.values(plansRare);
    const plansTresRareTab = Object.values(plansTresRare);
    
    return <div id="corpsPlanChantier">
        <div id={"zonePlansChantierConteneur"}>
            <Tabs
                activeKey={idxSelected}
                onSelect={handleTabChange}
            >
                <Tab eventKey={"plans"} title={t("Plans de chantier", { ns: "hotel" })}>
                    <div id="zonePlansChantiersPlans">
                        {modified && <h2 style={{ color: "red" }}>{t("Une modification a été effectué, pensez à sauvegarder", { ns: "hotel" })}</h2>}
                        <div className="formPlansChantier fondWhite02">
                            <div id="zonePlansChantier">
                                <div className="listPlansCommun">
                                    {plansCommunTab.map((chantier: ChantierPrototypeDTO) =>
                                        <LignePlanChantier chantier={chantier}
                                                           type={"c"}
                                                           chantierObtenu={listPlansObtenu}
                                                           onClickChantier={handleChangePlansObtenu}
                                                           key={"chantier_" + chantier.id_chantier} />,
                                    )}
                                </div>
                                <hr className="hrBlack40" />
                                <div className="listPlansInhab">
                                    {Object.values(plansInhab).map((chantier: ChantierPrototypeDTO) =>
                                        <LignePlanChantier chantier={chantier}
                                                           type={"u"}
                                                           chantierObtenu={listPlansObtenu}
                                                           onClickChantier={handleChangePlansObtenu}
                                                           key={"chantier_" + chantier.id_chantier} />,
                                    )}
                                </div>
                                <hr className="hrBlack40" />
                                <div className="listPlansRare">
                                    {Object.values(plansRare).map((chantier: ChantierPrototypeDTO) =>
                                        <LignePlanChantier chantier={chantier}
                                                           type={"r"}
                                                           chantierObtenu={listPlansObtenu}
                                                           onClickChantier={handleChangePlansObtenu}
                                                           key={"chantier_" + chantier.id_chantier} />,
                                    )}
                                </div>
                                <hr className="hrBlack40" />
                                <div className="listPlansTresRare">
                                    {Object.values(plansTresRare).map((chantier: ChantierPrototypeDTO) =>
                                        <LignePlanChantier chantier={chantier}
                                                           type={"e"}
                                                           chantierObtenu={listPlansObtenu}
                                                           onClickChantier={handleChangePlansObtenu}
                                                           key={"chantier_" + chantier.id_chantier} />,
                                    )}
                                </div>
                                <div id="formPlansFooter">
                                    <div id="boutonFormChantier">
                                        {modified && plansChantier.myVille && <>
                                            <input type={"button"} className={"btn btn-success btn-xs"} value={t("Sauvegarder", { ns: "hotel" })} onClick={handleBoutonSauver} />
                                            <input type={"button"} className={"btn btn-warning btn-xs"} value={t("Annuler modification", { ns: "hotel" })} onClick={handleBoutonAnnuler} />
                                        </>}
                                    </div>
                                    <div id="compteurPlans" className="fondWhite06">
                                        {ville.plans_chantier_date_maj !== null &&
                                            <span>{HTMLParser(t("Mis à jour par <strong>{userMaj}</strong> le <strong>{dateMaj}</strong>.", { ns: "hotel" }).replace("{userMaj}", ville.plans_chantier_update_by.pseudo).replace("{dateMaj}", formatInTimeZone(new Date(Date.parse(ville.plans_chantier_date_maj)), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))))}</span>}
                                        <React.Fragment>
                                            <span>
                                                <SvgIcone icone={"item_bplan_c"} />
                                                <span id="nrbLvl_1">{nbrPlansCom}</span>/{plansCommunTab.length}
                                            </span>
                                            <span>
                                                <SvgIcone icone={"item_bplan_u"} />
                                                <span id="nrbLvl_1">{nbrPlansInhab}</span>/{plansInhabTab.length}
                                            </span>
                                            <span>
                                                <SvgIcone icone={"item_bplan_r"} />
                                                <span id="nrbLvl_1">{nbrPlansRare}</span>/{plansRareTab.length}
                                            </span>
                                            <span>
                                                <SvgIcone icone={"item_bplan_e"} />
                                                <span id="nrbLvl_1">{nbrPlansTresRare}</span>/{plansTresRareTab.length}
                                            </span>
                                        </React.Fragment>
                                    </div>
                                </div>
                            </div>
                            <div id="zoneBoutonPlansRuine" onClick={() => setPanneauRuineOuvert(prevPanneau => !prevPanneau)}>
                                <div>{t("Plans de chantier dans les ruines", { ns: "hotel" })}</div>
                                <div id="declenchRuine">{panneauRuineOuvert ? HTMLParser(t("Cliquer pour <span>fermer</span>", { ns: "hotel" })) : HTMLParser(t("Cliquer pour <span>ouvrir</span>", { ns: "hotel" }))}</div>
                            </div>
                            <div id="zoneRuinePlan">
                                <div id="zoneInviPlan"></div>
                                {panneauRuineOuvert && <div id="listRuinePlans">
                                    <div id="ruineFlex">
                                        <div id="ruineBunker">
                                            <div className="titreRuine">{t("Bunker abandonné", { ns: "bats" })}</div>
                                            <div className="listPlansInhabRuine">
                                                {Object.values(plansInhab_bu).map((chantier: ChantierPrototypeDTO) =>
                                                    <LignePlanChantier chantier={chantier}
                                                                       chantierObtenu={listPlansObtenu}
                                                                       type={"u"} sous_type={"b"}
                                                                       key={"chantier_bu_" + chantier.id_chantier} />,
                                                )}
                                            </div>
                                            <div className="listPlansRareRuine">
                                                {Object.values(plansRare_bu).map((chantier: ChantierPrototypeDTO) =>
                                                    <LignePlanChantier chantier={chantier}
                                                                       chantierObtenu={listPlansObtenu}
                                                                       type={"r"} sous_type={"b"}
                                                                       key={"chantier_bu_" + chantier.id_chantier} />,
                                                )}
                                            </div>
                                            <div className="listPlansTresRareRuine">
                                                {Object.values(plansTresRare_bu).map((chantier: ChantierPrototypeDTO) =>
                                                    <LignePlanChantier chantier={chantier}
                                                                       chantierObtenu={listPlansObtenu}
                                                                       type={"e"} sous_type={"b"}
                                                                       key={"chantier_bu_" + chantier.id_chantier} />,
                                                )}
                                            </div>
                                        </div>
                                        <div id="ruineHopital">
                                            <div className="titreRuine">{t("Hôpital abandonné", { ns: "bats" })}</div>
                                            <div className="listPlansInhabRuine">
                                                {Object.values(plansInhab_hs).map((chantier: ChantierPrototypeDTO) =>
                                                    <LignePlanChantier chantier={chantier}
                                                                       chantierObtenu={listPlansObtenu}
                                                                       type={"u"} sous_type={"h"}
                                                                       key={"chantier_hs_" + chantier.id_chantier} />,
                                                )}
                                            </div>
                                            <div className="listPlansRareRuine">
                                                {Object.values(plansRare_hs).map((chantier: ChantierPrototypeDTO) =>
                                                    <LignePlanChantier chantier={chantier}
                                                                       chantierObtenu={listPlansObtenu}
                                                                       type={"r"} sous_type={"h"}
                                                                       key={"chantier_hs_" + chantier.id_chantier} />,
                                                )}
                                            </div>
                                            <div className="listPlansTresRareRuine">
                                                {Object.values(plansTresRare_hs).map((chantier: ChantierPrototypeDTO) =>
                                                    <LignePlanChantier chantier={chantier}
                                                                       chantierObtenu={listPlansObtenu}
                                                                       type={"e"} sous_type={"h"}
                                                                       key={"chantier_hs_" + chantier.id_chantier} />,
                                                )}
                                            </div>
                                        </div>
                                        <div id="ruineHotel">
                                            <div className="titreRuine">{t("Hôtel abandonné", { ns: "hotel" })}</div>
                                            <div className="listPlansInhabRuine">
                                                {Object.values(plansInhab_ho).map((chantier: ChantierPrototypeDTO) =>
                                                    <LignePlanChantier chantier={chantier}
                                                                       chantierObtenu={listPlansObtenu}
                                                                       type={"u"} sous_type={"m"}
                                                                       key={"chantier_ho_" + chantier.id_chantier} />,
                                                )}

                                            </div>
                                            <div className="listPlansRareRuine">
                                                {Object.values(plansRare_ho).map((chantier: ChantierPrototypeDTO) =>
                                                    <LignePlanChantier chantier={chantier}
                                                                       chantierObtenu={listPlansObtenu}
                                                                       type={"r"} sous_type={"m"}
                                                                       key={"chantier_ho_" + chantier.id_chantier} />,
                                                )}

                                            </div>
                                            <div className="listPlansTresRareRuine">
                                                {Object.values(plansTresRare_ho).map((chantier: ChantierPrototypeDTO) =>
                                                    <LignePlanChantier chantier={chantier}
                                                                       chantierObtenu={listPlansObtenu}
                                                                       type={"e"} sous_type={"m"}
                                                                       key={"chantier_ho_" + chantier.id_chantier} />,
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey={"chantiers"} title={t("Chantiers", { ns: "hotel" })}>
                    <div id="zonePlansChantiersPlans">
                        <div className="listChantierPlans fondWhite02">
                            <ListChantiers listChantier={plansChantier.listChantier}
                                           banque={plansChantier.banque}
                                           listChantiersVille={plansChantier.listChantiersConstruit}
                                           listPlansVille={listPlansObtenu}
                                           listAvancement={plansChantier.listAvancement}
                                           reductionAtelier={plansChantier.reductionPA}
                            />
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    </div>;
    
}

