import React, { useCallback }       from "react";
import { OutilsChantiersTypeProps } from "../../../../types/components/Outils/OutilsChantiers";
import Select, { components }       from "react-select";
import { v4 as uuid }               from "uuid";
import SvgIcone                     from "../../../../components/generality/SvgIcone";
import { isChantierPlannedOrBuilt } from "./EnteteOutilsChantiers";
import { useOutilsChantiers }       from "../TabsOutilsChantiers";
import { Status_error, usePopUp }   from "../../../../types/Context/PopUpContext";
import { HomePrototypeDTO }         from "../../../../types/models/homePrototype.dto";
import { CitoyensDTO }              from "../../../../types/models/citoyens.dto";
import { RessourceHomeDTO }         from "../../../../types/models/ressourceHome.dto";
import { useTranslation }           from "react-i18next";

const { SingleValue, Option, GroupHeading } = components;

export default function UpHabitation(props: { outils: OutilsChantiersTypeProps }) {
    const { t } = useTranslation();
    const { outilsChantiers, setOutilsChantiers } = useOutilsChantiers();
    const outils: OutilsChantiersTypeProps = props.outils;
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    
    const strategie_construit = isChantierPlannedOrBuilt(outils.listChantiersConstruit, outilsChantiers.chantiers_programmes, outils.option.id_strategie);
    
    const listCitoyens = Object.values(outils.listCitoyens).filter((citoyen: CitoyensDTO) => !citoyen.mort &&
        (citoyen.lvl_maison ? (citoyen.lvl_maison.id < (strategie_construit ? 9 : 5)) : true))
        .sort((a: CitoyensDTO, b: CitoyensDTO) => a.citoyen.pseudo.localeCompare(b.citoyen.pseudo));
    const listHabitation = Object.values(outils.option.list_home).filter((habitation: HomePrototypeDTO) => (strategie_construit) ? true : habitation.id < 6);
    
    // Définissez les composants personnalisés ici en utilisant `useCallback` si nécessaire.
    const CustomOptionCitoyen = useCallback((props) => (
        <components.Option {...props}>
            <SvgIcone icone={"h_" + props.data.job.icon} />
            <span className={(props.data.ban) ? "color-red" : null}>{props.data.citoyen.pseudo}</span>
        </components.Option>
    ), []);
    
    const CustomSingleValueCitoyen = useCallback((props) => (
        <components.SingleValue {...props}>
            <SvgIcone icone={"h_" + props.data.job.icon} />
            <span className={(props.data.ban) ? "color-red" : null}>{props.data.citoyen.pseudo}</span>
        </components.SingleValue>
    ), []);
    
    const customStylesCitoyen = {
        option           : (provided, state) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: state.isDisabled ? "lightgrey" : "white",
            color          : state.isDisabled ? "grey" : "black",
            ":active"      : {
                ...provided[":active"],
                backgroundColor: !state.isDisabled && (state.isSelected ? "data.color" : "white"),
            },
        }),
        singleValue      : (provided) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        control          : (provided) => ({
            ...provided,
            minHeight: "24px",
            height   : "24px",
            width    : "155px",
        }),
        input            : (provided) => ({
            ...provided,
            gridTemplateColumns: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "0",
        }),
        valueContainer   : (provided) => ({
            ...provided,
            padding: "0px 8px",
        }),
        menu             : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            width          : "200px",
        }),
        menuList         : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuPortal       : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
    };
    
    // Définissez les composants personnalisés ici en utilisant `useCallback` si nécessaire.
    const CustomOptionHabitation = useCallback((props) => (
        <components.Option {...props}>
            <SvgIcone icone={"home_" + props.data.icon} />
            <span>{t(props.data.nom, { ns: "game" })}</span>
        </components.Option>
    ), []);
    
    const CustomSingleValueHabitation = useCallback((props) => (
        <components.SingleValue {...props}>
            <SvgIcone icone={"home_" + props.data.icon} />
            <span>{t(props.data.nom, { ns: "game" })}</span>
        </components.SingleValue>
    ), []);
    
    const customStylesHabitation = {
        option           : (provided, state) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: state.isDisabled ? "lightgrey" : "white",
            color          : state.isDisabled ? "grey" : "black",
            ":active"      : {
                ...provided[":active"],
                backgroundColor: !state.isDisabled && (state.isSelected ? "data.color" : "white"),
            },
        }),
        singleValue      : (provided) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        control          : (provided) => ({
            ...provided,
            minHeight: "24px",
            height   : "24px",
            width    : "155px",
        }),
        input            : (provided) => ({
            ...provided,
            gridTemplateColumns: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "0",
        }),
        valueContainer   : (provided) => ({
            ...provided,
            padding: "0px 8px",
        }),
        menu             : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            width          : "200px",
        }),
        menuList         : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuPortal       : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
    };
    
    // Fonction pour ajouter un nouveau up Habitation
    const ajouterHabitation = () => {
        
        // Vous devez définir la structure de l'objet chantier par défaut qui sera ajouté
        const nouvelHabitation = {
            uuid          : uuid(),
            citoyen       : null,
            lvl_habitation: null,
        };
        
        // Mettre à jour l'état avec le nouveau chantier
        setOutilsChantiers({
            ...outilsChantiers,
            up_habitations: [...outilsChantiers.up_habitations, nouvelHabitation],
        });
    };
    
    // Fonction pour trouver la valeur actuelle du Select basée sur id joueur
    const findCitoyenValue = (citoyenId) => {
        const foundOption = listCitoyens.find(citoyen => citoyen.citoyen.id === citoyenId);
        if (foundOption) {
            return foundOption;
        }
        return null;
    };
    
    const handleCitoyenChange = (uuid: string, citoyen: CitoyensDTO) => {
        const homeUpdated = outilsChantiers.up_habitations.map(habitation => {
            if (habitation.uuid === uuid) {
                return {
                    ...habitation,
                    ...{ citoyen: citoyen.citoyen },
                    ...{ lvl_habitation: citoyen.lvl_maison },
                };
            }
            return habitation;
        });
        // Mettre à jour l'état d'outilsChantiers avec les nouvelles informations des armes à réparer
        setOutilsChantiers({ ...outilsChantiers, up_habitations: homeUpdated });
    };
    
    const handleHabitationeChange = (uuid: string, habitationSelected: RessourceHomeDTO) => {
        const homeUpdated = outilsChantiers.up_habitations.map(habitation => {
            if (habitation.uuid === uuid) {
                // Trouver le citoyen associé à cette habitation
                const citoyenAssocie = listCitoyens.find(citoyen => citoyen.citoyen.id === habitation.citoyen.id);
                if (citoyenAssocie && (habitationSelected.id === citoyenAssocie.lvl_maison.id + 1 || habitationSelected.id === citoyenAssocie.lvl_maison.id)) {
                    return {
                        ...habitation,
                        ...{ lvl_habitation: habitationSelected },
                    };
                } else {
                    setStatus(Status_error);
                    setShowPop(true);
                    setMessagePopUp((habitationSelected.id > citoyenAssocie.lvl_maison.id + 1) ? t("Vous ne pouvez pas faire construire plus d'un level habitation par jour.", { ns: "outils" }) : t("Vous ne pouvez pas démonter une maison.", { ns: "outils" }));
                    return habitation;
                }
            }
            return habitation;
        });
        // Mettre à jour l'état d'outilsChantiers avec les nouvelles informations des armes à réparer
        setOutilsChantiers({ ...outilsChantiers, up_habitations: homeUpdated });
    };
    
    // Fonction pour trouver la valeur actuelle du Select basée sur le level de l'habitation
    const findHabitationValue = (home_id) => {
        const foundOption = listHabitation.find(home => home.id === home_id);
        if (foundOption) {
            return foundOption;
        }
        return null;
    };
    
    const deleteUpHabitation = (uuid: string) => {
        const upHabitations = outilsChantiers.up_habitations.filter(hab => hab.uuid !== uuid);
        setOutilsChantiers({ ...outilsChantiers, up_habitations: upHabitations });
    };
    
    const isOptionDisabled = (option, selectedOptions) => {
        return selectedOptions.some(selectedOption => selectedOption?.id === option.citoyen.id);
    };
    
    return <div id={"bloc_up_habitations"}>
        {listCitoyens.length === 0 ? (
            <p style={{ width: "100%", margin: "0", color: "red", fontSize: "12px" }}>{t("Tous les citoyens ont actuellement monté leur habitation au maximum de ce qu'il est possible.", { ns: "outils" })}</p>
        ) : (
            <>
                <table className={"tab_upHabitation"}>
                    <thead>
                    <tr className={"fondBlack02"}>
                        <th className={"pseudo"}>{t("Citoyen", { ns: "outils" })}</th>
                        <th className={"choix"}>{t("Habitation", { ns: "outils" })}</th>
                        <th className={"action"}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.values(outilsChantiers.up_habitations).filter((habitation) => {
                        if (habitation.citoyen === null || habitation.lvl_habitation === null) {
                            return true;
                        }
                        return findCitoyenValue(habitation.citoyen.id).lvl_maison.id !== habitation.id;
                    }).map((habitation) => {
                        return <tr key={habitation.uuid}>
                            <td className={"nom"}>
                                <Select
                                    onChange={(selectedOption: CitoyensDTO) => handleCitoyenChange(habitation.uuid, selectedOption)}
                                    options={listCitoyens}
                                    components={{
                                        Option     : CustomOptionCitoyen,
                                        SingleValue: CustomSingleValueCitoyen,
                                    }}
                                    styles={customStylesCitoyen}
                                    isOptionDisabled={(option) => isOptionDisabled(option, outilsChantiers.up_habitations.map(habitation => habitation.citoyen))}
                                    value={findCitoyenValue(habitation.citoyen?.id)}
                                />
                            </td>
                            <td className={"choix"}>
                                <Select
                                    onChange={(selectedOption: RessourceHomeDTO) => handleHabitationeChange(habitation.uuid, selectedOption)}
                                    options={listHabitation}
                                    components={{
                                        Option     : CustomOptionHabitation,
                                        SingleValue: CustomSingleValueHabitation,
                                    }}
                                    styles={customStylesHabitation}
                                    value={findHabitationValue(habitation.lvl_habitation?.id)}
                                    isDisabled={!habitation.citoyen}
                                />
                            </td>
                            <td className={"action"}>
                                <button onClick={() => deleteUpHabitation(habitation.uuid)}><i className="fa-solid fa-trash color-red"></i></button>
                            </td>
                        </tr>;
                    })}
                    </tbody>
                </table>
                <button type={"button"} className={"btn btn-primary btn-sm"} onClick={ajouterHabitation}>{t("Ajouter un up habitation", { ns: "outils" })}</button>
            </>
        )}
    
    </div>;
}

