import React, { useEffect }       from "react";
import { useNavigate }            from "react-router-dom";
import AdminPanel                 from "../../containers/Admin/AdminPanel";
import { GeneralApi }             from "../../services/api/GeneralApi";
import { useGHContext }           from "../../types/Context/GHContext";
import { Status_error, usePopUp } from "../../types/Context/PopUpContext";
import { useGeneralContext }      from "../../types/Context/GeneralContext";
import { useTranslation }         from "react-i18next";

export function AdminGeneral() {
    const { setGeneral } = useGeneralContext();
    const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    // Fonction pour recharger les données
    const reloadGeneral = async () => {
        const generalApi = new GeneralApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
        
        generalApi.general().then((response) => {
            setIsOnRefresh(false);
            setIsOnUpdate(false);
            if (response.data.general !== undefined) {
                setGeneral(response.data.general);
                sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
            }
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error?.data?.error);
            navigate("/news");
        });
    };
    
    useEffect(() => {
        if (checkHabilitation("admin")) {
            reloadGeneral().then(r => r);
        } else {
            navigate("/news");
        }
    }, [refreshKey]);
    
    return (
        <AdminPanel />
    );
}