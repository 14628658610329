import React, { useEffect, useState } from "react";
import { CreationJumpType }           from "../../../types/components/Jump/CreationJumpType";
import { GestionJumpApi }             from "../../../services/api/GestionJumpApi";
import FormJump                       from "../FormJump";
import { useNavigate }                from "react-router-dom";
import { useTranslation }             from "react-i18next";

export default function CreationJump(props: {
    creation: CreationJumpType, onRetour: (isError: boolean, showMod: boolean, messagePop: string) => void
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [jump, setJump] = useState(props.creation.jump);
    const [activeJump, setActiveJump] = useState(false);
    
    useEffect(() => {
        setJump(props.creation.jump);
    }, [props.creation.jump]);
    
    const handleSubmitJump = () => {
        // On fait des contrôles pour vérifier les dates si elles sont cohérentes entre elle → date de fin > date de début, date de jump > date de fin
        const dateDebut = new Date(jump.date_deb_inscription);
        const dateFin = new Date(jump.date_fin_inscription);
        const dateJump = new Date(jump.date_approx_jump);
        
        if (dateDebut > dateFin) {
            props.onRetour(true, true, t("La date de début d'inscription doit être inférieur à la date de fin d'inscription . ", { ns: "jumpEvent" }));
            return;
        }
        
        if (dateFin > dateJump) {
            props.onRetour(true, true, t("La date de fin d'inscription doit être inférieur à la date approximative de jump . ", { ns: "jumpEvent" }));
            return;
        }
        
        const gestionApi = new GestionJumpApi();
        gestionApi.creation_jump({ jump: jump }).then((response) => {
            if (response.codeRetour === 0) {
                props.onRetour(false, true, response.libRetour);
                setTimeout(() => {
                    props.onRetour(false, false, "");
                    navigate("/jump/inscription/" + response.zoneRetour.jump);
                }, 2000);
            } else {
                props.onRetour(true, true, response.libRetour);
            }
        });
    };
    
    useEffect(() => {
        setActiveJump(
            jump.nom !== null && jump.community !== null && jump.type_ville !== null && jump.objectif.length > 0 && jump.date_deb_inscription !==
            jump.date_fin_inscription && jump.date_deb_inscription !== jump.date_approx_jump && jump.date_fin_inscription !== jump.date_approx_jump);
    }, [jump]);
    
    
    return <div id="zone_jump">
        <div id="formJump" className="fondWhite02">
            <FormJump formJump={{
                jump   : jump,
                options: props.creation.options,
                user   : props.creation.user,
            }} onRetour={props.onRetour} onChange={(jumpMod) => setJump(jumpMod)} />
            <div className="simpleFormButton">
                <button type={"button"} className={"btn btn-primary"} disabled={!activeJump} onClick={handleSubmitJump}>{t("Créer jump", { ns: "jumpEvent" })}</button>
            </div>
        </div>
    
    </div>;
    
    
}