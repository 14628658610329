import React                        from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { ChangelogType }            from "../../types/components/General/ChangelogType";
import ChangelogList                from "../General/ChangelogList";
import { formatInTimeZone }         from "date-fns-tz";
import { GeneralType }              from "../../types/components/Generality/GeneralType";
import { ContentsVersionDTO }       from "../../types/models/contentsVersion.dto";
import { useTranslation }           from "react-i18next";

interface ChangelogComponentProps {
    changelog: ChangelogType;
    general: GeneralType;
}

const ChangelogComponent = (props: ChangelogComponentProps) => {
    const { t } = useTranslation();
    const renderChangelogEntries = () => {
        const { changelogData } = props.changelog;
        const general = props.general;
        
        return Object.values(changelogData).map((entry, index) => {
            const contents = Object.values(entry.contents).sort((a: ContentsVersionDTO, b: ContentsVersionDTO) => {
                if (a.type_content === b.type_content) {
                    return a.id_relatif > b.id_relatif ? 1 : -1;
                }
                
                return a.type_content > b.type_content ? 1 : -1;
            });
            
            return <ListGroupItem key={"group_" + index}>
                <div className="blocNews fondWhite02" key={"zone-changelog_" + index}>
                    <h2>V.{entry.version_majeur}.{entry.version_mineur}.{entry.version_corrective}{entry.tag_name ? `-${entry.tag_name}.${entry.num_tag}` : ""} - {t(entry.titre, { ns: "version" })}</h2>
                    <div className="contenuNews">
                        <p>{t("Contenus :", { ns: "version" })}</p>
                        <ChangelogList contents={contents} />
                    </div>
                    <div className="lignAutheurDateNews">
                        <p className="authorNews">Le {formatInTimeZone(new Date(Date.parse(entry.date_version)), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</p>
                    </div>
                </div>
            </ListGroupItem>;
        });
    };
    
    return (
        <div>
            <ListGroup>{renderChangelogEntries()}</ListGroup>
        </div>
    );
};

export default ChangelogComponent;