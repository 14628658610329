import React                        from "react";
import ListChantiers                from "../../Encyclopedie/Chantiers/ListChantiers";
import { OutilsChantiersTypeProps } from "../../../types/components/Outils/OutilsChantiers";
import TabsOutilsChantiers          from "./TabsOutilsChantiers";
import Tab                          from "react-bootstrap/Tab";
import Tabs                         from "react-bootstrap/Tabs";
import { useTranslation }           from "react-i18next";

export default function OutilsChantier(props: { outilsChantier: OutilsChantiersTypeProps }) {
    const { t } = useTranslation();
    const outilsChantier = props.outilsChantier;
    
    return <div id="corpsOutilsChantier">
        <div id={"zoneOutilsChantierConteneur"}>
            <Tabs
                defaultActiveKey="outilsChantiers"
                id="uncontrolled-tab-example"
            >
                <Tab eventKey="outilsChantiers" title={t("Outils chantiers", { ns: "outils" })}>
                    <div className="zoneOutilsChantiersPlans">
                        <div className="listChantierPlans fondWhite02">
                            <TabsOutilsChantiers outils={outilsChantier} />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="chantiers" title={t("Liste chantiers", { ns: "outils" })}>
                    <div className="zoneOutilsChantiersPlans">
                        <div className="listChantierPlans fondWhite02">
                            <h2> Liste des chantiers de la ville</h2>
                            <ListChantiers listChantier={outilsChantier.listChantier} banque={outilsChantier.banque}
                                           listChantiersVille={outilsChantier.listChantiersConstruit}
                                           listPlansVille={outilsChantier.listPlansVille}
                                           listAvancement={outilsChantier.listAvancement}
                                           reductionAtelier={outilsChantier.option.reductionPA}
                            />
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>
    </div>;
    
}

