import { CustomPaginationProps }              from "../../types/components/Generality/ComposantGeneralType";
import React, { useEffect, useRef, useState } from "react";
import Pagination                             from "react-bootstrap/Pagination";

export function CustomPagination(props: CustomPaginationProps) {
    const { jour, jour_max, onChoixJour } = props;
    const [currentPage, setCurrentPage] = useState(jour);
    const [startEllipsisMenuVisible, setStartEllipsisMenuVisible] = useState(false);
    const [endEllipsisMenuVisible, setEndEllipsisMenuVisible] = useState(false);
    const timerRef = useRef<number | null>(null);
    
    const handlePaginationSelect = (selectedPage: number) => {
        setCurrentPage(selectedPage);
        onChoixJour(selectedPage);
    };
    
    const handleStartEllipsisClick = () => {
        setStartEllipsisMenuVisible(!startEllipsisMenuVisible);
    };
    
    const handleEndEllipsisClick = () => {
        setEndEllipsisMenuVisible(!endEllipsisMenuVisible);
    };
    
    const handleStartEllipsisInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timerRef.current);
        let selectedPage = parseInt(e.target.value, 10);
        if (!isNaN(selectedPage)) {
            selectedPage = (selectedPage < 1) ? 1 : ((selectedPage >= totalPages) ? totalPages - 1 : selectedPage);
            timerRef.current = window.setTimeout(() => {
                handlePaginationSelect(selectedPage);
                setStartEllipsisMenuVisible(false);
            }, 1000); // Délai de 1 seconde avant validation automatique
        }
    };
    
    const handleEndEllipsisInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timerRef.current);
        let selectedPage = parseInt(e.target.value, 10);
        if (!isNaN(selectedPage)) {
            selectedPage = (selectedPage < 1) ? 1 : ((selectedPage >= totalPages) ? totalPages - 1 : selectedPage);
            timerRef.current = window.setTimeout(() => {
                handlePaginationSelect(selectedPage);
                setEndEllipsisMenuVisible(false);
            }, 1000); // Délai de 1 seconde avant validation automatique
        }
    };
    
    
    const totalPages = jour_max;
    
    useEffect(() => {
        return () => {
            if (timerRef.current !== null) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);
    
    useEffect(() => {
        setCurrentPage(jour);
    }, [jour]);
    
    return (
        <Pagination>
            <Pagination.First onClick={() => handlePaginationSelect(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePaginationSelect(currentPage - 1)} disabled={currentPage === 1} />
            
            {currentPage > 3 && (<>
                {startEllipsisMenuVisible ? (
                    <input
                        type="number"
                        min={1}
                        max={currentPage - 2}
                        onChange={handleStartEllipsisInputChange}
                        onBlur={() => setStartEllipsisMenuVisible(false)}
                        autoFocus
                    />
                ) : (
                    <Pagination.Ellipsis onClick={handleStartEllipsisClick} />
                )}
            </>)}
            
            {Array.from({ length: totalPages - 1 }, (_, index) => (
                ((index + 1 === currentPage) ||
                    (index + 1 >= currentPage - 2 && index + 1 <= currentPage + 2))
                    ? (
                        <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => handlePaginationSelect(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    )
                    : null
            ))}
            
            {(totalPages - currentPage) > 3 && (<>
                {endEllipsisMenuVisible ? (
                    <input
                        type="number"
                        min={currentPage + 2}
                        max={totalPages - 1}
                        onChange={handleEndEllipsisInputChange}
                        onBlur={() => setEndEllipsisMenuVisible(false)}
                        autoFocus
                    />
                ) : (
                    <Pagination.Ellipsis onClick={handleEndEllipsisClick} />
                )}
            </>)}
            <Pagination.Item key={totalPages} active={totalPages === currentPage}
                             onClick={() => handlePaginationSelect(totalPages)}>{totalPages}</Pagination.Item>
            <Pagination.Next onClick={() => handlePaginationSelect(currentPage + 1)} disabled={currentPage === totalPages} />
            <Pagination.Last onClick={() => handlePaginationSelect(totalPages)} disabled={currentPage === totalPages} />
        </Pagination>
    );
}