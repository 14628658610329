export const itemEncombrant = 1;
export const itemDefense = 2;
export const itemVeille = 3;
export const itemAmenagementt = 4;
export const itemRessources = 5;
export const itemArmes = 6;
export const itemExtensionSac = 7;
export const itemExtensionCoffre = 8;
export const itemSourcePa = 9;
export const itemEau = 10;
export const itemNourriture = 11;
export const itemDrogue = 12;
export const itemAlcool = 13;
export const itemAnimaux = 14;
export const itemSoin = 15;
export const itemDeco = 16;
export const itemRP = 17;
export const itemCamping = 18;
export const itemBanni = 19;
export const itemRuines = 20;
export const itemEvent = 21;
export const itemAssembler = 22;
export const itemTransfo = 23;
export const itemOuvrable = 24;
export const itemCuisinable = 25;

export const idItemSac = 21;
export const idItemSacSuper = 47;
export const idItemCaddie = 19;
export const idItemCeinture = 69;
