import * as React              from "react";
import { useEffect, useState } from "react";
import axios                   from "axios";

export const LogList = () => {
    const [logs, setLogs] = useState();
    
    const fetchLogs = async () => {
        try {
            const response = await axios.get("/rest/v1/admin/logs"); // L'URL de votre API
            setLogs(response.data.logs);
        } catch (error) {
            console.error("Erreur lors de la récupération des logs :", error);
        }
    };
    
    useEffect(() => {
        // Fonction pour récupérer les logs depuis l'API
        fetchLogs().then(r => r);
    }, []);
    
    
    return <>{logs !== undefined && <div dangerouslySetInnerHTML={{ __html: logs }} />}</>;
};