import * as React                                                                                                                                   from "react";
import * as RA                                                                                                                                      from "react-admin";
import {ArrayInput, CheckboxGroupInput, FunctionField, RadioButtonGroupInput, ReferenceArrayInput, ReferenceInput, SelectInput, SimpleFormIterator} from "react-admin";
import {FontAwesomeIcon}                                                                                                                            from "@fortawesome/react-fontawesome";
import {faCheck, faTimes}                                                                                                                           from "@fortawesome/free-solid-svg-icons";
import {useWatch}                                                                                                                                   from "react-hook-form";
import SvgDrapeau                                                                                                                                   from "../../components/generality/SvgDrapeau";
import {useParams}                                                                                                                                  from "react-router-dom";


// Ensuite, vous pouvez utiliser les composants de react-admin en utilisant l'alias RA
const {
          BooleanInput,
          Datagrid,
          DateField,
          DateTimeInput,
          Edit,
          EditButton,
          List,
          ReferenceInput,
          SimpleForm,
          TextField,
          TextInput,
          useRecordContext,
      } = RA;

export const JumpList = (props) => {
    const postFilters = [
        <TextInput source="q" label="Search" alwaysOn key={"search_jump"}/>,
        <ReferenceInput source="id" label="Jump Id" reference="jump" key={"search_jump_id"}/>,
        <TextInput source="nom" label="Nom du jump" reference="jump" key={"search_jump_nom"}/>,
    ];
    
    return <List {...props} filters={postFilters}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="nom"/>
            <DateField source={"date_deb_inscription"} label={"Date de début d'inscription"}/>
            <DateField source={"date_fin_inscription"} label={"Date de fin d'inscription"}/>
            <FunctionField render={record => record && record.event !== null ? <FontAwesomeIcon icon={faCheck} style={{color: "green"}}/> : <FontAwesomeIcon icon={faTimes} style={{color: "red"}}/>} source={"event"} label={"in Event ?"}/>
            <EditButton/>
        </Datagrid>
    </List>;
};

const ImagePreview = ({source}) => {
    const imageUrl = useWatch({name: source}); // Watch the input value
    
    return (
        <div style={{marginBottom: "20px"}}>
            {imageUrl ? (
                <img src={"/uploads/banniere/" + imageUrl} alt="Bannière" style={{maxHeight: "200px", maxWidth: "100%"}}/>
            ) : (
                <p>Aucune bannière à afficher</p>
            )}
        </div>
    );
};

export const JumpEdit = (props) => {
    
    const { id } = useParams();
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition du jump {record ? `"${record.nom}"` : ""}</span>;
    };
    
    const communityChoices = [
        {id: "fr", name: "France"},
        {id: "en", name: "United States"},
        {id: "es", name: "Spain"},
        {id: "de", name: "Spain"},
        {id: "int", name: "Spain"},
    ];
    
    return (<Edit {...props} title={<PostTitle/>}>
            <SimpleForm>
                <div id={"admin_zone_ville_info"}>
                    <h2>Info Jump</h2>
                    <div id={"admin_ville_info_disable"}>
                        <TextInput name={"id"} source={"id"} label={"Id"} disabled={true}/>
                        <TextInput name={"nom"} source={"nom"} label={"Nom"}/>
                        <DateTimeInput name={"date_deb_inscription"} source={"date_deb_inscription"} label={"Date de début d'inscription"}/>
                        <DateTimeInput name={"date_fin_inscription"} source={"date_fin_inscription"} label={"Date de fin d'inscription"}/>
                        <DateTimeInput name={"date_approx_jump"} source={"date_approx_jump"} label={"Date approximative du jump"}/>
                        <DateTimeInput name={"date_jump_effectif"} source={"date_jump_effectif"} label={"Date effective du jump"}/>
                        <DateTimeInput name={"date_creation"} source={"date_creation"} label={"Date création du jump"} disabled={true}/>
                        <DateTimeInput name={"date_modif"} source={"date_modif"} label={"Date modification du jump"} disabled={true}/>
                        <BooleanInput name={"ville_prive"} source={"ville_prive"} label={"Ville privée"}/>
                        <ReferenceInput reference="type_ville" source="type_ville">
                            <SelectInput
                                label="Type de ville"
                                source="type_ville"
                                optionText="nom"
                                optionValue="id"
                                parse={(value) => ({id: value})}
                                format={(value) => value?.id}
                            />
                        </ReferenceInput>
                        <ReferenceArrayInput reference="objectif_ville_prototype"
                                             source="objectif"
                        >
                            <CheckboxGroupInput optionText="nom"
                                                optionValue="id"
                                                label="Objectif de ville"
                                                source="objectif"
                                                parse={(value) => value && value.map((v) => ({id: v}))}
                                                format={(value) => value && value.map((v) => v.id)}
                            />
                        </ReferenceArrayInput>
                        <BooleanInput name={"job_fige"} source={"job_fige"} label={"Job figé"}/>
                        <RadioButtonGroupInput
                            source="community"
                            choices={communityChoices}
                            optionText={(choice) => (
                                <span><SvgDrapeau drapeau={choice.id}/></span>
                            )}
                            optionValue="id"
                            label="Select Community"
                        />
                    </div>
                    <div>
                        <TextInput name={"description"} source={"description"} label={"Description"} multiline={true}/>
                    </div>
                    <div>
                        <TextInput name={"banniere"} source={"banniere"} label={"Banniere"}/>
                        {/* Image preview based on the banner value */}
                        <ImagePreview source="banniere"/>
                    </div>
                    <div>
                        <ArrayInput name={"lead"} source={"lead"}>
                            <SimpleFormIterator inline>
                                <ReferenceInput
                                    source="type_lead"
                                    label="Type Lead"
                                    reference="type_lead"
                                >
                                    <SelectInput
                                        optionText="nom"
                                        optionValue="id"
                                        label="Type Lead"
                                        format={(value) => value && value.id} // Prend l'id pour pré-remplir le champ
                                        parse={(value) => ({id: value})} // Parse l'ID sélectionné pour reformer l'objet
                                    />
                                </ReferenceInput>
                                {/*<ReferenceInput
                                    source="user"
                                    label="User"
                                    reference="inscription_jump"
                                    filter={{jump_id: id}} // Filter users based on jump ID
                                >
                                    <SelectInput
                                        optionText="pseudo"
                                        optionValue="id"
                                        label="Pseudo"
                                        format={(value) => value && value?.user?.id} // Prend l'id pour pré-remplir le champ
                                        parse={(value) => ({id: value})} // Parse l'ID sélectionné pour reformer l'objet
                                    />
                                </ReferenceInput>*/}
                                <BooleanInput source={"apprenti"} label={"Apprenti"}/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </div>
                </div>
            
            </SimpleForm>
        </Edit>
    )
        ;
};