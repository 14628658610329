import * as React                                                                                              from "react";
import { BooleanInput, Datagrid, Edit, List, NumberField, SimpleForm, TextField, TextInput, useRecordContext } from "react-admin";
import { Link, useParams }                                                                                     from "react-router-dom";
import { Button }                                                                                              from "@mui/material";
import { BiEditAlt }                                                                                           from "react-icons/bi";
import EditToolbar                                                                                             from "../General/EditToolBar";
import BulkActionWithNoDelete                                                                                  from "../General/BulkActionWithNoDelete";


export const BatimentList = (props) => {
    
    const { id } = useParams();
    
    /*const postFilters = [
     <TextInput source="q" label="Search" alwaysOn/>,
     <ReferenceInput source="id" label="Map Id" reference="ville"/>,
     ];*/
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition des bâtiments de la ville {record ? `"${record.ville.nom}" - ${record.ville.id}` : ""}</span>;
    };
    
    
    //return <List {...props} filters={postFilters}>
    return <List {...props} title={<PostTitle />} resource="ville_batiment" filter={{ villeId: id }}>
        <Datagrid bulkActionButtons={<BulkActionWithNoDelete />}>
            <TextField source="id" />
            <TextField source={"bat.nom"} />
            <TextField source={"coords"} />
            <EditBatimentButton />
        </Datagrid>
    </List>;
};


const EditBatimentButton = () => {
    const batiment = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/admin/ville/${batiment?.ville.id}/batiment/${batiment?.id}`}
            color="primary"
        >
            <BiEditAlt />
            Editer
        </Button>
    );
};

export const BatimentEdit = (props) => {
    
    const { id, bat_id } = useParams();
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition du batiment {record ? `"${record.bat.nom}" - ${record.id}` : ""}</span>;
    };
    
    
    return (<Edit {...props} title={<PostTitle />} resource={"ville_batiment"} id={bat_id} redirect={`admin/ville/${id}/batiment`}>
        <SimpleForm toolbar={<EditToolbar />}>
            <div id={"admin_zone_ville_info"}>
                <h2>Info Batiment</h2>
                <div id={"admin_ville_info_disable"}>
                    <TextInput source={"bat"} format={(value) => (value ? value.nom : "")} label={"Bâtiment"} disabled={true} />
                    <NumberField source={"x"} label={"X"} />
                    <NumberField source={"y"} label={"Y"} />
                    <TextInput source={"coords"} label={"Coordonnée"} disabled={true} />
                </div>
                <div id={"admin_ville_info_edit"}>
                    <BooleanInput source={"camped"} label={"Campé"} />
                    <BooleanInput source={"empty"} label={"Vide"} />
                </div>
            </div>
        
        </SimpleForm>
    </Edit>);
};