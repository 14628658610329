import * as React                                                                                    from "react";
import { Datagrid, Edit, List, NumberField, NumberInput, SelectInput, SimpleForm, useRecordContext } from "react-admin";
import { Link, useParams }                                                                           from "react-router-dom";
import ShiftNumberField                                                                              from "../InputFieds/Fields/ShiftNumberField";
import { Button }                                                                                    from "@mui/material";
import { BiEditAlt }                                                                                 from "react-icons/bi";
import { TypeCaseField }                                                                             from "./Component/Ruine/TypeCaseField";
import { TypeEscalierField }                                                                         from "./Component/Ruine/TypeEscalierField";
import { TypePortesField }                                                                           from "./Component/Ruine/TypePortesField";


export const RuineCasesList = (props) => {
    
    const { ruine_id } = useParams();
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Liste des cases de la Ruines de la ville </span>;
    };
    
    const postFilters = [
        <NumberInput source="x" label="X" alwaysOn />,
        <NumberInput source="y" label="Y" alwaysOn />,
        <NumberInput source="z" label="Z" alwaysOn />,
        <NumberInput source="typeCase" label="Case" alwaysOn />,
    ];
    
    
    //return <List {...props} filters={postFilters}>
    return <List {...props} title={<PostTitle />} resource={"ville_ruine_case"} filters={postFilters} filter={{ ruinesId: ruine_id }}>
        <Datagrid>
            <ShiftNumberField source="x" adjustment={7} />
            <NumberField source="y" />
            <NumberField source="z" />
            <NumberField source="typeCase" />
            <EditCaseRuineButton />
        </Datagrid>
    </List>;
};

const EditCaseRuineButton = () => {
    const caseRuine = useRecordContext();
    const { id, ruine_id } = useParams();
    return (
        <Button
            component={Link}
            to={`/admin/ville/${id}/ruine/${ruine_id}/cases/${caseRuine?.id}`}
            color="primary"
        >
            <BiEditAlt />
            Editer
        </Button>
    );
};

export const RuineCasesEdit = (props) => {
    
    const { id, ruine_id, case_id } = useParams();
    const record = useRecordContext();
    
    const PostTitle = () => {
        const record = useRecordContext();
        return (record ? (<span>Edition de la case ({record.x - 7},{record.y}, {record.z}) de la ruine {ruine_id} de la ville {id}</span>) : null);
    };
    
    const dropdownValues = [
        { id: 0, name: "CASE_VIDE" },
        { id: 1, name: "Ouest" },
        { id: 2, name: "Nord" },
        { id: 4, name: "Est" },
        { id: 8, name: "Sud" },
        { id: 3, name: "Nord-Ouest" },
        { id: 5, name: "Est-Ouest" },
        { id: 9, name: "Sud-Ouest" },
        { id: 6, name: "Nord-Est" },
        { id: 10, name: "Nord-Sud" },
        { id: 12, name: "Sud-Est" },
        { id: 7, name: "Nord-Est-Ouest" },
        { id: 11, name: "Nord-Est-Sud" },
        { id: 13, name: "Sud-Est-Ouest" },
        { id: 14, name: "Nord-Est-Sud" },
        { id: 15, name: "Nord-Est-Sud-Ouest" },
        { id: 16, name: "Entrée" },
        { id: 17, name: "Couloir vide" },
        { id: 18, name: "Ouest ouvert" },
        { id: 19, name: "Nord ouvert" },
        { id: 21, name: "Est ouvert" },
        { id: 25, name: "Sud ouvert" },
    ];
    
    const dropdownValuesPortes = [
        { id: "p", name: "Porte ouverte", icon: "small_enter" },
        { id: "pC", name: "Porte fermée", icon: "item_lock" },
        { id: "pP", name: "Percussion", icon: "item_classicKey" },
        { id: "pD", name: "Decapsuleur", icon: "item_bumpKey" },
        { id: "pM", name: "Magnétique", icon: "item_magneticKey" },
    ];
    
    const dropdownValuesEscalier = [
        { id: "up", name: "Escalier montant" },
        { id: "down", name: "Escalier descendant" },
    ];
    
    const dropdownValuesZombie = [
        { id: 0, name: "0 zombie" },
        { id: 1, name: "1 zombie" },
        { id: 2, name: "2 zombie" },
        { id: 3, name: "3 zombie" },
        { id: 4, name: "4 zombie" },
    ];
    
    
    return (<Edit {...props} title={<PostTitle />} resource={"ville_ruine_case"} id={case_id} redirect={`admin/ville/${id}/ruine/${ruine_id}`}>
        <SimpleForm>
            <div id={"admin_zone_ville_info"}>
                <h2>Info Ruine</h2>
                <div id={"admin_ville_info_edit"}>
                    <SelectInput source="type_case" label="Type de case" choices={dropdownValues} optionText={<TypeCaseField />} optionValue={"id"} />
                    <SelectInput source="nbr_zombie" label="Nombre de zombie" choices={dropdownValuesZombie} optionText={"name"} optionValue={"id"} />
                    <SelectInput source="type_escalier" label="Escalier" choices={dropdownValuesEscalier} optionText={<TypeEscalierField />} optionValue={"id"} />
                    <SelectInput source="type_porte" label="Porte" choices={dropdownValuesPortes} optionText={<TypePortesField />} optionValue={"id"} />
                </div>
            </div>
        </SimpleForm>
    </Edit>);
};