import React, { useEffect, useRef, useState }     from "react";
import { TourDeGuetType }                         from "../../../types/components/Hotel/TourDeGuetType";
import EstimationsAttaque                         from "./EstimationsAttaque";
import { CustomPagination }                       from "../../../components/generality/Pagination";
import CustomSwitch                               from "../../../components/generality/CustomSwitch";
import { TourDeGuetApi }                          from "../../../services/api/TourDeGuetApi";
import CalculAttaque                              from "./CalculAttaque";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { EstimationTdgDTO }                       from "../../../types/models/estimationTdg.dto";
import { useTranslation }                         from "react-i18next";

interface TourDeGuetProps {
    tourDeGuet: TourDeGuetType;
}

export default function TourDeGuet(props: TourDeGuetProps) {
    const { t } = useTranslation();
    const estimationsTdg = useRef<EstimationsAttaque>(); // Référence pour le premier enfant
    const estimationsPlanif = useRef<EstimationsAttaque>(); // Référence pour le premier enfant
    const tourDeGuet = props.tourDeGuet as TourDeGuetType;
    
    const [jourCours, setJourCours] = useState(tourDeGuet.jour);
    const [vue, setVue] = useState(false);
    const [tdg, setTdg] = useState(tourDeGuet.tdg);
    const [planif, setPlanif] = useState(tourDeGuet.planif);
    const [planifConst, setPlanifConst] = useState(tourDeGuet.planif_cons);
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    
    useEffect(() => {
        setJourCours(tourDeGuet.jour);
        setTdg(tourDeGuet.tdg);
        setPlanif(tourDeGuet.planif);
        setPlanifConst(tourDeGuet.planif_cons);
    }, [tourDeGuet]);
    
    const handleChoixJour = (value) => {
        const tdgApi = new TourDeGuetApi();
        
        tdgApi.recup_estims({ jour: value, mapId: tourDeGuet.ville.map_id, userId: tourDeGuet.user.id })
            .then((response) => {
                // Utilise la valeur de response ici
                if (response.codeRetour === 0) {
                    setTdg(response.zoneRetour.tdg);
                    setPlanif(response.zoneRetour.planif);
                    setPlanifConst(response.zoneRetour.planif_cons);
                    setJourCours(value);
                    if (estimationsTdg.current) {
                        estimationsTdg.current.resetEstimDay();
                    }
                    if (estimationsPlanif.current) {
                        estimationsPlanif.current.resetEstimDay();
                    }
                } else {
                    setShowPop(true);
                    setStatus(Status_error);
                    setMessagePopUp(response.libRetour);
                }
            }).catch((error) => {
            // Gère les erreurs ici si nécessaire
            console.error(error);
        });
        
    };
    
    const handleAddEstimation = (estimation: EstimationTdgDTO, type_estim: number) => {
        if (type_estim === 1) {
            setTdg([...tdg, estimation]);
        } else {
            setPlanif([...planif, estimation]);
        }
    };
    
    const handleCancel = () => {
        if (estimationsTdg.current) {
            estimationsTdg.current.resetFormFields();
        }
        
        if (estimationsPlanif.current) {
            estimationsPlanif.current.resetFormFields();
        }
        
        setPlanif(tourDeGuet.planif);
        setTdg(tourDeGuet.tdg);
        
    };
    
    const handleChangeEstimation = (estimations: EstimationTdgDTO[], type_estim: number) => {
        if (type_estim === 1) {
            setTdg(estimations);
        } else {
            setPlanif(estimations);
        }
    };
    
    const handleSave = () => {
        const tdgApi = new TourDeGuetApi();
        
        const tdgSorted = sortEstimationsIfNeeded(tdg);
        
        if (tdgSorted.length === 0) {
            setShowPop(true);
            setStatus(Status_error);
            setMessagePopUp(t("Il n'est pas possible de sauvegarder sans avoir mis d'estimation . ", { ns: "hotel" }));
            return;
        }
        
        const retour = validateEstimations(tdgSorted);
        if (tdgSorted.length > 1) {
            if (retour === 1) {
                setShowPop(true);
                setStatus(Status_error);
                setMessagePopUp(t("Il y a une erreur avec vos estimations mini, elles ne sont pas correctement croissantes ou égales.", { ns: "hotel" }));
                return;
            } else if (retour === 2) {
                setShowPop(true);
                setStatus(Status_error);
                setMessagePopUp(t("Il y a une erreur avec vos estimations maxi, elles ne sont pas correctement décroissantes ou égales.", { ns: "hotel" }));
                return;
            }
        }
        
        if (retour === 3) {
            setShowPop(true);
            setStatus(Status_error);
            setMessagePopUp(t("L'estimation mini ne peut pas être supérieur au maximum.", { ns: "hotel" }));
            return;
        }
        
        const planifSorted = sortEstimationsIfNeeded(planif);
        if (planifSorted.length > 1) {
            const retour = validateEstimations(planifSorted);
            if (retour === 1) {
                setShowPop(true);
                setStatus(Status_error);
                setMessagePopUp(t("Il y a une erreur avec vos estimations du planificateur mini, elles ne sont pas correctement croissantes ou égales . ", { ns: "hotel" }));
                return;
            } else if (retour === 2) {
                setShowPop(true);
                setStatus(Status_error);
                setMessagePopUp(t("Il y a une erreur avec vos estimations du planificateur maxi, elles ne sont pas correctement décroissantes ou égales . ", { ns: "hotel" }));
                return;
            }
        }
        
        tdgApi.sauvegarde_estims({ mapId: tourDeGuet.ville.map_id, userId: tourDeGuet.user.id, jour: jourCours, tdg: tdgSorted, planif: planifSorted })
            .then((response) => {
                // Utilise la valeur de response ici
                if (response.codeRetour === 0) {
                    setShowPop(true);
                    setStatus(Status_success);
                    setMessagePopUp(response.libRetour);
                    
                    // On referme les pop-up au bout de 1sec
                    setTimeout(() => {
                        setShowPop(false);
                        setStatus(Status_success);
                        setMessagePopUp("");
                    }, 1000);
                } else {
                    setShowPop(true);
                    setStatus(Status_error);
                    setMessagePopUp(response.libRetour);
                }
            })
            .catch((error) => {
                // Gère les erreurs ici si nécessaire
                console.error(error);
            });
    };
    
    const validateEstimations = (estimations: EstimationTdgDTO[]): number => {
        for (let i = 0; i < estimations.length - 1; i++) {
            const currentEstimation = estimations[i];
            const nextEstimation = estimations[i + 1];
            
            if (currentEstimation.min_estim > nextEstimation.min_estim) {
                return 1;
            }
            if (currentEstimation.max_estim < nextEstimation.max_estim) {
                return 2;
            }
        }
        for (let i = 0; i < estimations.length; i++) {
            const currentEstimation = estimations[i];
            
            if (currentEstimation.min_estim > currentEstimation.max_estim) {
                return 3;
            }
            
        }
        
        return 0;
    };
    
    const sortEstimationsIfNeeded = (estimations) => {
        const estimationsToSort = estimations.filter(
            (estimation) =>
                typeof estimation.val_pourcentage === "number" &&
                typeof estimation.min_estim === "number" &&
                typeof estimation.max_estim === "number",
        );
        
        if (estimationsToSort.length === estimations.length) {
            return [...estimationsToSort].sort((a, b) => a.val_pourcentage - b.val_pourcentage);
        }
        
        return estimations;
    };
    
    const { jour } = tourDeGuet;
    
    const sortedTdgEstimations = sortEstimationsIfNeeded(tdg);
    const sortedPlanifEstimations = sortEstimationsIfNeeded(planif);
    
    return (<div id="tourDeGuetForm" className={"fondWhite02"}>
            <div id={"group-jour-tdg"}><span id={"jour"}>{t("Jour  :", { ns: "hotel" })}</span><CustomPagination jour={jourCours} jour_max={jour}
                                                                                                                 onChoixJour={handleChoixJour} /></div>
            <div id={"group-estim-atk"}>
                <div className="formTourDeGuet fondWhite02">
                    <div id="zoneBlocEstimTDG">
                        <div id="zoneEstimTDG">
                            <p>{t("Estimations du jour :", { ns: "hotel" })}</p>
                            <EstimationsAttaque jour={jourCours}
                                                type_estim={1}
                                                estimations={sortedTdgEstimations}
                                                vueDetail={vue ? "textarea" : "inputGroup"}
                                                onAddEstimation={(estimation) => {
                                                    handleAddEstimation(estimation, 1);
                                                }}
                                                onChangeEstimation={(estimations) => handleChangeEstimation(estimations, 1)}
                                                ref={estimationsTdg}
                            />
                        </div>
                        <div id="zoneEstimPlanif">
                            {planifConst ? (<>
                                <p>{t("Estimations du planificateur :", { ns: "hotel" })}</p>
                                <EstimationsAttaque jour={jourCours}
                                                    type_estim={2}
                                                    estimations={sortedPlanifEstimations}
                                                    vueDetail={vue ? "textarea" : "inputGroup"}
                                                    onAddEstimation={(estimation) => {
                                                        handleAddEstimation(estimation, 2);
                                                    }}
                                                    onChangeEstimation={(estimations) => handleChangeEstimation(estimations, 2)}
                                                    ref={estimationsPlanif}
                                />
                            </>) : (
                                <p>{t("Le planificateur doit être construit pour avoir accés à cette zone . ", { ns: "hotel" })}</p>
                            )}
                        </div>
                    </div>
                    <div className="options-container-tdg">
                        <div className="switches">
                            <CustomSwitch
                                leftLabel={t("Mode formulaire", { ns: "hotel" })}
                                rightLabel={t("Mode liste", { ns: "hotel" })}
                                initialChecked={vue}
                                onToggle={(value) => setVue(value)}
                            />
                        </div>
                        <div className="buttons-tdg">
                            <button onClick={handleCancel}>{t("Annuler", { ns: "hotel" })}</button>
                            <button onClick={handleSave}>{t("Enregistrer les estimations", { ns: "hotel" })}</button>
                        </div>
                    </div>
                </div>
                <div className="formCalculAtk fondWhite02">
                    <CalculAttaque jour={jourCours} tdg={sortedTdgEstimations} planif={sortedPlanifEstimations} />
                    <p className="warning warnTDG">{t("Calcul de l'attaque à venir", { ns: "hotel" })}</p>
                </div>
            </div>
        </div>
    );
}
