import React, {useEffect, useState}                        from "react";
import {CitoyensType, FiltreCitoyens, PopUpMajCoffreProps} from "../../../types/components/Hotel/Citoyens";
import {Avatar, BoutonCopy}                                from "../../../components/generality/ComposantGeneral";
import SvgIcone                                            from "../../../components/generality/SvgIcone";
import PopUpMajCoffre                                      from "./PopUpMajCoffre";
import {CitoyensApi, RetourSaveCitoyen}                    from "../../../services/api/CitoyensApi";
import CompteurCitoyens                                    from "./CompteurCitoyens";
import CitoyensQueryBuilder                                from "./CitoyensQueryBuilder";
import HTMLParser                                          from "html-react-parser";
import {LigneCitoyenVivantCompact}                         from "./LigneCitoyenVivantCompact";
import {LigneCitoyenVivantExpend}                          from "./LigneCitoyenVivantExpend";
import {LibCoordonneCitoyen}                               from "./CitoyensUtils";
import {RuleGroupType}                                     from "react-querybuilder";
import {ParameterizedNamedSQL}                             from "react-querybuilder/dist/cjs/react-querybuilder.cjs.development";
import {Status_error, usePopUp}                            from "../../../types/Context/PopUpContext";
import Modal                                               from "react-bootstrap/Modal";
import {CitoyensDTO}                                       from "../../../types/models/citoyens.dto";
import {VilleDTO}                                          from "../../../types/models/ville.dto";
import {useTranslation}                                    from "react-i18next";

interface CitoyensProps {
    citoyens: CitoyensType;
    popUpMaj: PopUpMajCoffreProps;
}

export default function Citoyens({citoyens, popUpMaj}: CitoyensProps) {
    const {t} = useTranslation();
    const [citoyensVie, setCitoyensVie] = useState(Object.values(citoyens.citoyensVie));
    const [citoyen_coffre, setCitoyen_coffre] = useState(null);
    const [retourMajPopUp, setRetourMajPopUp] = useState(null);
    const [typeRetourMajPopup, setTypeRetourMajPopup] = useState(null);
    const [nameFormIsUpdate, setNameFormIsUpdate] = useState(null);
    const [citIdUpdate, setCitIdUpdate] = useState(null);
    const [updateCompteur, setUpdateCompteur] = useState(false);
    const [afficher_filtre, setAfficher_filtre] = useState(false);
    const [listingCitoyens, setListingCitoyens] = useState(null);
    const [filtre_query, setFiltre_query] = useState(null);
    const [querySqlState, setQuerySqlState] = useState(null);
    const {setStatus, setMessagePopUp, setShowPop} = usePopUp();
    
    const compteurs = citoyens.compteurs;
    const moyenContact = citoyens.moyenContact;
    
    
    const compareCitoyensNames = (a: CitoyensDTO, b: CitoyensDTO) => {
        // Si ce qui est fournis ce sont des morts
        if (a.death_day !== null && b.death_day !== null) {
            // tri selon le jour de mort, si c'est le même jour, cela trira comme lorsque le citoyen est vivant
            if ((a.death_day ?? 0) > (b.death_day ?? 0)) {
                return -1;
            } else if ((a.death_day ?? 0) < (b.death_day ?? 0)) {
                return 1;
            }
        }
        
        return a.citoyen.pseudo.localeCompare(b.citoyen.pseudo);
    };
    
    const updateCitoyen = async (citoyen: CitoyensDTO, nameForm: string): Promise<RetourSaveCitoyen> => {
        const citoyenApi = new CitoyensApi();
        try {
            const response = await citoyenApi.save_citoyen({
                mapId   : citoyens.ville.map_id,
                userId  : citoyens.user.id,
                citoyens: citoyen,
                nameForm: nameForm,
            });
            setUpdateCompteur(true);
            return response;
        } catch (error) {
            console.error(error);
        }
    };
    
    const majCitoyen = (citoyenToUpdate: CitoyensDTO, index: number, nameForm: string) => {
        updateCitoyen(citoyenToUpdate, nameForm)
            .then((response) => {
                // Utilise la valeur de response ici
                if (response.codeRetour === 0) {
                    citoyensVie[index] = response.zoneRetour.citoyen;
                    setCitIdUpdate(citoyenToUpdate.citoyen.id);
                    setCitoyensVie(citoyensVie);
                    setNameFormIsUpdate(nameForm);
                    setTimeout(() => {
                        setNameFormIsUpdate(null);
                        setCitIdUpdate(null);
                    }, 2000);
                } else {
                    setStatus(Status_error);
                    setMessagePopUp(response.libRetour);
                    setShowPop(true);
                }
            })
            .catch((error) => {
                // Gère les erreurs ici si nécessaire
                console.error(error);
            });
    };
    
    const updateCoffreCitoyen = (citoyen: CitoyensDTO) => {
        updateCitoyen(citoyen, "coffres")
            .then((response) => {
                // Utilise la valeur de response ici
                if (response.codeRetour === 0) {
                    const citoyenChange = response.zoneRetour.citoyen;
                    citoyenChange.coffres = Object.values(citoyenChange.coffres);
                    citoyensVie[citIdUpdate] = citoyenChange;
                    setCitoyensVie(citoyensVie);
                    setCitIdUpdate(null);
                    setRetourMajPopUp(response.libRetour);
                    setTypeRetourMajPopup(response.codeRetour);
                    setTimeout(() => {
                        setRetourMajPopUp(null);
                        setTypeRetourMajPopup(null);
                        setCitoyen_coffre(null);
                    }, 2000);
                } else {
                    setRetourMajPopUp(response.libRetour);
                    setTypeRetourMajPopup(response.codeRetour);
                }
                /**/
            })
            .catch((error) => {
                // Gère les erreurs ici si nécessaire
                console.error(error);
            });
    };
    
    const filtreCitoyens = (querySql: ParameterizedNamedSQL, query: RuleGroupType) => {
        const citoyenApi = new CitoyensApi();
        citoyenApi
            .filtre_citoyen({
                mapId : citoyens.ville.map_id,
                userId: citoyens.user.id,
                filtre: querySql,
            })
            .then((response) => {
                // Utilise la valeur de response ici
                if (response.codeRetour === 0) {
                    setFiltre_query(query);
                    setQuerySqlState(querySql);
                    setUpdateCompteur(true);
                    setCitoyensVie(response.zoneRetour.citoyen);
                } else {
                    setStatus(Status_error);
                    setShowPop(true);
                    setMessagePopUp(response.libRetour);
                }
                /**/
            })
            .catch((error) => {
                // Gère les erreurs ici si nécessaire
                console.error(error);
            });
    };
    
    const listeCitoyensPuce = () => {
        const formattedCitizenList: string[] = citoyensVie.map(
            (citizen: CitoyensDTO) =>
                `• @${citizen.citoyen.pseudo.replace(/\s/g, "")}:${
                    citizen.citoyen.id_my_hordes
                }`,
        );
        setListingCitoyens(formattedCitizenList.join("<br>"));
    };
    
    const listeCitoyensColonne = () => {
        const formattedCitizenList: string[] = citoyensVie.map(
            (citizen: CitoyensDTO) =>
                `@${citizen.citoyen.pseudo.replace(/\s/g, "")}:${
                    citizen.citoyen.id_my_hordes
                }`,
        );
        setListingCitoyens(formattedCitizenList.join("<br>"));
    };
    
    const listeCitoyens = () => {
        const formattedCitizenList: string[] = citoyensVie.map(
            (citizen: CitoyensDTO) =>
                `@${citizen.citoyen.pseudo.replace(/\s/g, "")}:${
                    citizen.citoyen.id_my_hordes
                }`,
        );
        setListingCitoyens(formattedCitizenList.join(", "));
    };
    
    const citoyenVie: CitoyensDTO[] = citoyensVie.sort(compareCitoyensNames);
    
    const citoyensMort: CitoyensDTO[] = Object.values(citoyens.citoyensMort).sort(
        compareCitoyensNames,
    );
    
    useEffect(() => {
        if (filtre_query === null) {
            setCitoyensVie(Object.values(citoyens.citoyensVie));
        } else {
            filtreCitoyens(querySqlState, filtre_query);
        }
    }, [citoyens]);
    
    const filtre: FiltreCitoyens = {
        listJob    : citoyens.listJob,
        listPouvoir: citoyens.listPouvoir,
        listMaison : citoyens.listHabitation,
        listAmelio : citoyens.listAmelio,
    };
    
    return (
        <React.Fragment>
            <div className="listCitoyensHV">
                {citoyens.myVille && (
                    <React.Fragment>
                        <CompteurCitoyens
                            citoyens={citoyensVie}
                            compteurs={compteurs}
                            updateCpt={updateCompteur}
                            onAfterUpdate={(update: boolean) => setUpdateCompteur(!update)}
                        />
                        <input
                            type="button"
                            name="affFiltre"
                            value={afficher_filtre ? t("Masquer les filtres", {ns: "hotel"}) : t("Afficher les filtres", {ns: "hotel"})}
                            className="affFiltre"
                            onClick={() => setAfficher_filtre(!afficher_filtre)}
                        />
                        {afficher_filtre && (
                            <div id="zoneFiltreCitoyen" className={"elementVisible"}>
                                <div className="titreZoneCitoyen">
                                    {t("Filtres", {ns: "hotel"})}
                                </div>
                                <div className="fondWhite02" id="filtreCitoyen">
                                    <div id="filtreJson">
                                        <CitoyensQueryBuilder
                                            filtre={filtre}
                                            listIdPouvoir={citoyens.listIdPouvoir}
                                            onFiltre={filtreCitoyens}
                                            onReinit={() => {
                                                setCitoyensVie(Object.values(citoyens.citoyensVie));
                                                setUpdateCompteur(true);
                                            }}
                                            onError={(message) => {
                                                setStatus(Status_error);
                                                setMessagePopUp(message);
                                                setShowPop(true);
                                            }}
                                            query_filtre={filtre_query}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div id="zoneGenerationList">
                            <div id="boutonGenerationCitoyen">
                                <input
                                    type="button"
                                    value={t("Générer pseudo dans liste à puce", {ns: "hotel"})}
                                    name="generateListPuce"
                                    onClick={listeCitoyensPuce}
                                />
                                <input
                                    type="button"
                                    value={t("Générer liste pseudo sans puce", {ns: "hotel"})}
                                    name="generateListColonne"
                                    onClick={listeCitoyensColonne}
                                />
                                <input
                                    type="button"
                                    value={t("Générer liste pseudo", {ns: "hotel"})}
                                    name="generateList"
                                    onClick={listeCitoyens}
                                />
                            </div>
                            {listingCitoyens && (
                                <div className="elementVisible" id="zoneListCitoyens">
                                    <div className="zoneText" id="listText">
                                        {HTMLParser(listingCitoyens)}
                                    </div>
                                    <div id={"boutonGestionListCitoyen"}>
                                        <BoutonCopy
                                            textAcopier={listingCitoyens}
                                            translate={{
                                                boutonCopier: t("Texte copié", {ns: "ville"}),
                                                boutonNormal: t("Copier le texte", {ns: "ville"}),
                                                boutonKo    : t("Appuyer sur \"Ctrl + C\" pour copier", {ns: "ville"}),
                                            }}
                                        />
                                        <input
                                            className={"btn btn-xs btn-warning"}
                                            type="button"
                                            value={t("Fermer liste", {ns: "hotel"})}
                                            name="fermerList"
                                            onClick={() => setListingCitoyens(null)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <p>
                            {t("Les citoyens en vie", {ns: "hotel"})} : {citoyenVie.length} /{" "}
                            {citoyenVie.length + citoyensMort.length}
                        </p>
                        <span id="ligneChamanGuide">
                            {citoyens.ville.chaman !== null && (<span><SvgIcone icone={"h_shaman"}/>{" " + t("Chaman :", {ns: "hotel"}) + " " + citoyens.ville.chaman.pseudo}</span>)}
                            {citoyens.ville.guide !== null && (<span><SvgIcone icone={"item_guide"}/>{" " + t("Guide de l'Outre-monde :", {ns: "hotel"}) + " " + citoyens.ville.guide.pseudo}</span>)}
                        </span>
                        {citoyens.user.user_personnalisation.citoyens_mode_compact && (
                            <div>
                                <table className="listCitoyensVVivantReduit">
                                    <thead>
                                    <tr className="lignCitoyenVReduit">
                                        <th className="citoyen_tab_reduit_avatar"></th>
                                        <th className="citoyen_tab_reduit_pseudo">
                                            {t("Pseudo", {ns: "hotel"})}
                                        </th>
                                        <th className="citoyen_tab_reduit_icone">
                                            <span className="infoBulle"><SvgIcone icone={"r_derwin"}/><span className="info">{t("Dernier pouvoir", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_pos">
                                            <span className="infoBulle"><SvgIcone icone={"r_explo2"}/><span className="info">{t("Position du joueur", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_icone">
                                            <span className="infoBulle"><SvgIcone icone={"h_arma"}/><span className="info">{t("Témoin de l'Arma", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_icone">
                                            <span className="infoBulle"><SvgIcone icone={"item_reveil"}/><span className="info">{t("Réveil légendaire", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_icone">
                                            <span className="infoBulle"><SvgIcone icone={"item_radius_mk2"}/><span className="info">{t("Moyen de contact", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_select">
                                            <span className="infoBulle"><SvgIcone icone={"r_ruine"}/><span className="info">{t("Niveau Ruine", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_select">
                                            <span className="infoBulle"><SvgIcone icone={"small_camp"}/><span className="info">{t("Nombre de camping", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"item_potion"}/><span className="info">{t("Immunisé", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_select">
                                            <span className="infoBulle"><SvgIcone icone={"f_cam"}/><span className="info">{t("APAG", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"r_heroac"}/><span className="info">{t("RDH", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"r_wrestl"}/><span className="info">{t("Uppercut", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"h_calim"}/><span className="info">{t("Sauvetage", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"r_share"}/><span className="info">{t("Camaraderie", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"r_share_don"}/><span className="info">{t("Camaraderie reçue", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"status_clean"}/><span className="info">{t("Corps sain", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"small_pa"}/><span className="info">{t("Second Souffle", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"h_arma"}/><span className="info">{t("PEF", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"h_death"}/><span className="info">{t("VLM", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"item_chest_hero"}/><span className="info">{t("Trouvaille", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_selectHab">
                                            <span className="infoBulle"><SvgIcone icone={"r_hbuild"}/><span className="info">{t("Habitations", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_select">
                                            <span className="infoBulle"><SvgIcone icone={"status_tired"}/><span className="info">{t("Coin sieste", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_select">
                                            <span className="infoBulle"><SvgIcone icone={"item_machine_2"}/><span className="info">{t("Cuisine", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_select">
                                            <span className="infoBulle"><SvgIcone icone={"item_drug"}/><span className="info">{t("Laboratoire", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_select">
                                            <span className="infoBulle"><SvgIcone icone={"item_bag"}/><span className="info">{t("Rangement", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_select">
                                            <span className="infoBulle"><SvgIcone icone={"item_fence"}/><span className="info">{t("Renfort", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coche">
                                            <span className="infoBulle"><SvgIcone icone={"item_wood_plate"}/><span className="info">{t("Clôture", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_select">
                                            <span className="infoBulle"><SvgIcone icone={"item_home_box"}/><span className="info">{t("Carton", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_input">
                                            <span className="infoBulle"><SvgIcone icone={"item_home_def"}/><span className="info">{t("Barricade", {ns: "hotel"})}</span></span>
                                        </th>
                                        <th className="citoyen_tab_reduit_coffre">
                                            <span className="infoBulle"><SvgIcone icone={"item_home_box_xl"}/><span className="info">{t("Coffre", {ns: "hotel"})}</span></span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {citoyenVie.sort((citoyen_a, citoyen_b) => {
                                        // Si on a l'option onTopCitoyen de mis, on met en premier le citoyen qui correspond à l'utilisateur, sinon, on garde le tri par ordre alphabétique
                                        if (citoyens.user.user_personnalisation.on_top_citoyen) {
                                            if (citoyen_a.citoyen.id === citoyens.user.id) {
                                                return -1;
                                            } else if (citoyen_b.citoyen.id === citoyens.user.id) {
                                                return 1;
                                            }
                                        }
                                        return citoyen_a.citoyen.pseudo.localeCompare(citoyen_b.citoyen.pseudo);
                                    }).map((citoyen, index) => {
                                        return (
                                            <LigneCitoyenVivantCompact
                                                citIdUpdate={citIdUpdate}
                                                citoyen={citoyen}
                                                formCitoyens={citoyens.formCitoyen}
                                                index={index}
                                                listIdPouvoir={citoyens.listIdPouvoir}
                                                maLigne={citoyen.citoyen.id === citoyens.user.id}
                                                moyenContact={moyenContact}
                                                nameFormIsUpdate={nameFormIsUpdate}
                                                onJoueurChange={majCitoyen}
                                                onMajCoffre={(citoyen, index: number) => {
                                                    setCitoyen_coffre(citoyen);
                                                    setCitIdUpdate(index);
                                                }}
                                                user={citoyens.user}
                                                ville={citoyens.ville}
                                                key={"citoyen_" + citoyen.citoyen.id}
                                            />
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {!citoyens.user.user_personnalisation.citoyens_mode_compact && (
                            <table className="listCitoyensVVivant">
                                <tbody>
                                {citoyenVie.sort((citoyen_a, citoyen_b) => {
                                    // Si on a l'option onTopCitoyen de mis, on met en premier le citoyen qui correspond à l'utilisateur, sinon, on garde le tri par ordre alphabétique
                                    if (citoyens.user.user_personnalisation.on_top_citoyen) {
                                        if (citoyen_a.citoyen.id === citoyens.user.id) {
                                            return -1;
                                        } else if (citoyen_b.citoyen.id === citoyens.user.id) {
                                            return 1;
                                        }
                                    }
                                    return citoyen_a.citoyen.pseudo.localeCompare(citoyen_b.citoyen.pseudo);
                                }).map((citoyen, index) => {
                                    return (
                                        <LigneCitoyenVivantExpend
                                            citIdUpdate={citIdUpdate}
                                            citoyen={citoyen}
                                            formCitoyens={citoyens.formCitoyen}
                                            index={index}
                                            listIdPouvoir={citoyens.listIdPouvoir}
                                            maLigne={citoyen.citoyen.id === citoyens.user.id}
                                            moyenContact={moyenContact}
                                            nameFormIsUpdate={nameFormIsUpdate}
                                            onJoueurChange={majCitoyen}
                                            onMajCoffre={(citoyen, index: number) => {
                                                setCitoyen_coffre(citoyen);
                                                setCitIdUpdate(index);
                                            }}
                                            user={citoyens.user}
                                            ville={citoyens.ville}
                                            key={"citoyen_" + citoyen.citoyen.id}
                                        />
                                    );
                                })}
                                </tbody>
                            </table>
                        )}
                    </React.Fragment>
                )}
                {!citoyens.myVille && (
                    <div className="listCitoyensHV fondWhite02">
                        <p>
                            {t("Les citoyens en vie", {ns: "hotel"})} : {citoyenVie.length} /{" "}
                            {citoyenVie.length + citoyensMort.length}
                        </p>
                        <div className="listCitoyensHVVivant">
                            {citoyenVie.map((citoyen) => (
                                <LigneCitoyenExterne
                                    key={"citoyen_" + citoyen.citoyen.id}
                                    citoyen={citoyen}
                                    ville={citoyens.ville}
                                />
                            ))}
                        </div>
                    </div>
                )}
                <p>
                    {t("Les citoyens morts", {ns: "hotel"})} : {citoyensMort.length} /{" "}
                    {citoyenVie.length + citoyensMort.length}
                </p>
                <div className="listCitoyensHVMort">
                    {citoyensMort.map((citoyen) => (
                        <LigneCitoyenExterne
                            key={"citoyen_" + citoyen.citoyen.id}
                            citoyen={citoyen}
                            ville={citoyens.ville}
                        />
                    ))}
                </div>
            </div>
            
            <Modal show={citoyen_coffre !== null} onHide={() => setCitoyen_coffre(null)} centered key={"modal_maj_manuel"}>
                {citoyen_coffre !== null && (
                    <div id="popUpMajCoffre">
                        <PopUpMajCoffre
                            popUpMaj={popUpMaj}
                            citoyen={citoyen_coffre}
                            onCancel={() => {
                                setCitoyen_coffre(null);
                                setRetourMajPopUp(null);
                                setTypeRetourMajPopup(null);
                            }}
                            onSaveCase={updateCoffreCitoyen}
                            retourMajPopUp={retourMajPopUp}
                            typeRetourMajPopup={typeRetourMajPopup}
                        />
                    </div>
                )}
            </Modal>
        </React.Fragment>
    );
}

export function LigneCitoyenExterne(props: {
    citoyen: CitoyensDTO;
    ville: VilleDTO;
}) {
    const {t} = useTranslation();
    const citoyen = props.citoyen;
    const ville = props.ville;
    return (
        <div className="lignCitoyenHV fondBlack02" id={"id_" + citoyen.citoyen.id} key={"id_" + citoyen.citoyen.id}>
            <div className="avatarCitoyenHV">
                <Avatar url={citoyen.citoyen.avatar}/>
            </div>
            <div className="zonePseudoMessageCitoyenHV">
                <div className="pseudoMetierCitoyenHV">
                    <span className="pseudoCitoyenHV"><a href={"/ame/" + citoyen.citoyen.id_my_hordes}>{citoyen.citoyen.pseudo}</a></span>
                    {!citoyen.mort && (<span><SvgIcone icone={"h_" + citoyen.job.icon}/></span>)}
                    <span className="infoBulle icone_citoyen_centre">
                        <SvgIcone icone={citoyen.citoyen.der_pouv.icon}/>
                        <span className="info">{citoyen.citoyen.der_pouv.nom}</span>
                    </span>
                    {citoyen.message !== null && (<span className="infoBulle">
                            <span><SvgIcone icone={"small_chat"}/></span>
                            <span className="info infoBulleMessage"><span>{citoyen.message}</span></span>
                    </span>)}
                </div>
                {!citoyen.mort && (<div className="positionCitoyenHV"><span><LibCoordonneCitoyen citoyen={citoyen} ville={ville}/></span></div>)}
                {!citoyen.mort && (<div className="niveauHab">
                    {citoyen.lvl_maison && <span className="infoBulle habitation_icone">
                        <SvgIcone icone={"home_" + citoyen.lvl_maison.icon}/>
                        <span className="info">{citoyen.lvl_maison.nom}</span>
                    </span>}
                </div>)}
                {citoyen.mort && (<div className="mortCitoyenHV">{citoyen.job !== null && (<span><SvgIcone icone={"h_" + citoyen.job.icon}/></span>)}
                    <span className="jourMort">{t("Jour", {ns: "hotel"})} {citoyen.death_day}</span>
                    {citoyen.death_type !== null && (<span className="infoBulle cause_mort_citoyen"><SvgIcone icone={"h_death"}/><span className="info">{t("Cause :", {ns: "hotel"})} {citoyen.death_type.label}{citoyen.clean_up_type !== null && (
                        <React.Fragment>
                            <br/>
                            <span>{citoyen.clean_up_type.label} {t("par", {ns: "hotel"})}{" "}<span className="nom_cleaneur_citoyen">{citoyen.clean_up_name === null ? t("inconnu", {ns: "hotel"}) : citoyen.clean_up_name.pseudo}</span></span>
                        </React.Fragment>)}
                        </span>
                    </span>)}
                </div>)}
            </div>
        </div>
    );
}
