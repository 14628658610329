import React                   from "react";
import { useRuineGameContext } from "./RuineGame";

export function JaugeMana({ manaStart }: { manaStart: number }) {
    const { mana } = useRuineGameContext();
    
    return <div id={"zone_jauge_mana"}>
        <div className="ruine_progress-bar">
            <div className="progress-fill-mana" style={{ height: `${(mana / manaStart * 100)}%` }}>{mana}</div>
        </div>
    </div>;
}