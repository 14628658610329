export const sauvetage = {
    id   : 0,
    recup: 0,
};
export const rdh = {
    id   : 1,
    recup: 1,
};

export const uppercut = {
    id   : 2,
    recup: 2,
};

export const trouvaille = {
    id   : 3,
    recup: 3,
};

export const pef = {
    id   : 4,
    recup: 4,
};

export const camaraderie = {
    id   : 9,
    recup: 9,
};

export const corps_sain = {
    id   : 12,
    recup: 12,
};

export const jolie_trouvaille = {
    id   : 16,
    recup: 16,
};

export const ss = {
    id   : 18,
    recup: 18,
};

export const armoire = {
    id   : 20,
    recup: 21,
};

export const vlm = {
    id   : 22,
    recup: 23,
};


