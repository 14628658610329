import * as React                                 from "react";
import { useEffect, useState }                    from "react";
import { BarLoader }                              from "react-spinners";
import { Status_error, Status_success, usePopUp } from "../../types/Context/PopUpContext";
import { AdminApi }                               from "../../services/api/AdminApi";
import ThemeChart                                 from "../../utils/ThemeChart";


export const DashBoard = () => {
    
    const adminApi = new AdminApi();
    
    const [stats, setStats] = useState({ theme: [], nbr: [], stats_count: [] });
    const [load, setLoad] = useState(false);
    const [fetchCount, setFetchCount] = useState(0);
    const { setStatus, setMessagePopUp, setShowPop, handleClose } = usePopUp();
    const [apiLoadingBat, setApiLoadingBat] = useState(false);
    const [apiLoadingCha, setApiLoadingCha] = useState(false);
    const [apiLoadingCha2, setApiLoadingCha2] = useState(false);
    const [apiLoadingPic, setApiLoadingPic] = useState(false);
    const [apiLoadingObj, setApiLoadingObj] = useState(false);
    
    const handleMajObjet = () => {
        setApiLoadingObj(true);
        adminApi.maj_objet().then((response) => {
            if (response.codeRetour === 0) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
                
                setTimeout(() => {
                    handleClose();
                }, 1000);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
            }
            setApiLoadingObj(false);
        }).catch((error) => {
            console.error("Error maj objets:", error);
        });
    };
    const handleMajBats = () => {
        setApiLoadingBat(true);
        adminApi.maj_bats().then((response) => {
            if (response.codeRetour === 0) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
                
                setTimeout(() => {
                    handleClose();
                }, 1000);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
            }
            setApiLoadingBat(false);
        }).catch((error) => {
            console.error("Error maj objets:", error);
        });
    };
    const handleMajPicto = () => {
        setApiLoadingPic(true);
        adminApi.maj_pictos().then((response) => {
            if (response.codeRetour === 0) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
                
                setTimeout(() => {
                    handleClose();
                }, 1000);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
            }
            setApiLoadingPic(false);
        }).catch((error) => {
            console.error("Error maj objets:", error);
        });
    };
    const handleMajChantier = () => {
        setApiLoadingCha(true);
        adminApi.maj_chantiers().then((response) => {
            if (response.codeRetour === 0) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
                
                setTimeout(() => {
                    handleClose();
                }, 1000);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
            }
            setApiLoadingCha(false);
        }).catch((error) => {
            console.error("Error maj objets:", error);
        });
    };
    const handleMajParentChantier = () => {
        setApiLoadingCha2(true);
        adminApi.maj_Parentchantiers().then((response) => {
            if (response.codeRetour === 0) {
                setStatus(Status_success);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
                
                setTimeout(() => {
                    handleClose();
                }, 1000);
            } else {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
            }
            setApiLoadingCha2(false);
        }).catch((error) => {
            console.error("Error maj objets:", error);
        });
    };
    
    const handleFetchComplete = () => {
        if ((fetchCount + 1) === 1) {
            // Une fois que tous les fetchs sont terminés, définissez load sur true
            setLoad(true);
            setFetchCount(fetchCount + 1); // Incrémentez le compteur après chaque fetch
        }
    };
    
    useEffect(() => {
        fetchStats().then(r => r);
    }, []);
    
    
    const fetchStats = async () => {
        adminApi.stats().then((response) => {
            setStats(response.stat);
            handleFetchComplete();
        }).catch((error) => {
            console.error("Error fetching heros:", error);
        });
    };
    
    
    return <div id="subContent">
        <div>
            {load && <>
                <ThemeChart stat={{ theme: stats.theme, nbr: stats.nbr }} id={"admin"} />
                <div id={"stats_maj"}>
                    <table>
                        <thead>
                        <tr>
                            <th>Nbr Ville</th>
                            <th>Moyenne maj</th>
                            <th>Moyenne maj script</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{stats.stats_count["totalCountMaj"]}</td>
                            <td>{stats.stats_count["averageCountMaj"]}</td>
                            <td>{stats.stats_count["averageCountMajScript"]}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </>}
            <div className={"admin_generaux"}>
                <h2>Appel des api pour mettre à jour les bâtiments, chantiers, objets et pictos</h2>
                <div id={"admin_maj_api_button"}>
                    <button className={"btn btn-primary btn-sm"} onClick={() => handleMajObjet()}>{apiLoadingObj ? (<BarLoader color={"#FFFFFF"} height={12} width={80} />) : ("Mise à jour des items")}</button>
                    <button className={"btn btn-success btn-sm"} onClick={() => handleMajBats()}>{apiLoadingBat ? (<BarLoader color={"#FFFFFF"} height={12} width={80} />) : ("Mise à jour des bats")}</button>
                    <button className={"btn btn-danger btn-sm"} onClick={() => handleMajPicto()}>{apiLoadingPic ? (<BarLoader color={"#FFFFFF"} height={12} width={80} />) : ("Mise à jour des citoyens")}</button>
                    <button className={"btn btn-warning btn-sm"} onClick={() => handleMajChantier()}>{apiLoadingCha ? (<BarLoader color={"#FFFFFF"} height={12} width={80} />) : ("Mise à jour des chantiers")}</button>
                    <button className={"btn btn-secondary btn-sm"} onClick={() => handleMajParentChantier()}>{apiLoadingCha2 ? (<BarLoader color={"#FFFFFF"} height={12} width={80} />) : ("Mise à jour des chantiers parents")}</button>
                </div>
            </div>
        </div>
    </div>;
};
