import React, { useEffect, useState } from "react";
import { RuineApi }                   from "../../services/api/RuineApi";
import RuineListing                   from "../../containers/Ville/Ruine/RuineListing";
import chargement                     from "../../../img/chargement.svg";
import { useNavigate, useParams }     from "react-router-dom";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export function RuineListingGeneral() {
    const { general, setGeneral } = useGeneralContext();
    const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const [ruineData, setRuineData] = useState(null);
    
    // Fonction pour recharger les données
    const reloadRuinesData = async () => {
        const ruineApi = new RuineApi(parseInt(params.mapId, 10));
        ruineApi.generallist(params.mapId).then((response) => {
            setIsOnRefresh(false);
            setIsOnUpdate(false);
            if (response.data.general !== undefined) {
                setGeneral(response.data.general);
                sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
            }
            if (response.data.ruine === undefined) {
                console.error("Erreur de chargement des données ruine listing", response);
            } else {
                setRuineData(response.data.ruine);
            }
            
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error?.data?.error);
        });
    };
    
    useEffect(() => {
        if (checkHabilitation("ville", "ruine")) {
            reloadRuinesData().then((r) => r);
        } else {
            navigate("/news");
        }
    }, [refreshKey]);
    
    if (ruineData === null) {
        return (
            <div className={"chargement_page"}>
                <img src={chargement} alt="Drapeau" />
                <span>{t("Chargement...", { ns: "app" })}</span>
            </div>
        );
    }
    return <RuineListing ruine={ruineData} />;
}
