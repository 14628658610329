import {BanqueItemPlans, ListPlansObtenu} from "../../../types/components/Hotel/PlansChantierType";
import React                              from "react";
import SvgIcone                           from "../../../components/generality/SvgIcone";
import {Chantier}                         from "./Chantier";
import {useECContext}                     from "../../../types/Context/Encyclopedie/Chantier/EncyChantierContext";
import {ChantierPrototypeDTO}             from "../../../types/models/chantierPrototype.dto";
import {ChantiersDTO}                     from "../../../types/models/chantiers.dto";
import {useTranslation}                   from "react-i18next";
import {useNormalizedSearch}              from "../../../services/hook/useNormalizedSearch";


interface LigneChantierTriProps {
    chantier: ChantierPrototypeDTO,
    niveau: number,
    generic: boolean,
    banque?: BanqueItemPlans[],
    listPlansVille?: ListPlansObtenu[],
    listChantiersVille?: ChantiersDTO[],
    listAvancement?: {
        pa: number | null
    }[],
    gestion_aff: {
        aff_construit: boolean,
        aff_reparer: boolean,
        aff_construction: boolean,
        aff_obtenu: boolean,
        aff_bloque: boolean,
    },
    reductionPA?: number,
    filtre_ressource?: string,
    orderTri?: string,
    triChantier?: string,
    indicateurChoix?: boolean,
}

function recupChantiersPlats(chantiersPlats: ChantierPrototypeDTO[], chantier: ChantierPrototypeDTO): ChantierPrototypeDTO[] {
    chantier.children.forEach((chantierChild) => {
        chantiersPlats.push(chantierChild);
        if (chantierChild.children.length > 0) {
            recupChantiersPlats(chantiersPlats, chantierChild);
        }
    });
    return chantiersPlats;
}

export default function LigneChantierTri({
    chantier,
    niveau,
    generic,
    banque = [],
    listPlansVille = [],
    listChantiersVille = [],
    listAvancement = [],
    gestion_aff,
    reductionPA = 0,
    filtre_ressource = "",
    orderTri = "asc",
    triChantier = "arbre",
    indicateurChoix = false,
}: LigneChantierTriProps) {
    const {t} = useTranslation();
    const {listChantierChoisi, setListChantierChoisi} = useECContext();
    const {normalizeSearch} = useNormalizedSearch();
    
    // Mise à plat des chantiers à partir des enfants qui ont des enfants etc, en mode récursif
    const chantiersPlats = [];
    recupChantiersPlats(chantiersPlats, chantier);
    
    // Tri des chantiers
    const triChantiers = chantiersPlats.sort((chantier1, chantier2) => {
        const aName = t(chantier1.nom, {ns: "chantiers"});
        const bName = t(chantier2.nom, {ns: "chantiers"});
        let result = 0;
        switch (triChantier) {
            case "nom":
                
                result = aName.localeCompare(bName);
                break;
            case "pa":
                result = chantier1.pa - chantier2.pa;
                break;
            case "pv":
                result = chantier1.pv - chantier2.pv;
                break;
            case "def":
                result = chantier1.def - chantier2.def;
                break;
            case "eau":
                result = chantier1.water - chantier2.water;
                break;
        }
        if (result === 0) {
            return aName.localeCompare(bName);
        } else {
            return (orderTri === "asc") ? result : -result;
        }
        
    });
    
    
    return <>{triChantiers.map((chantierligne, index) => {
        
        const id_chantier: number = chantierligne.id_chantier;
        
        
        const chantierClass = new Chantier({
            chantier    : chantierligne,
            planObtenu  : listPlansVille[id_chantier] !== undefined,
            construction: listChantiersVille[id_chantier] !== undefined && !listChantiersVille[id_chantier].detruit,
            avancement  : listAvancement[id_chantier],
            reparation  : listChantiersVille[id_chantier],
        });
        
        const plan_string: string = chantierClass.getPlansString();
        
        const classLigneChantier: string = chantierClass.getClassChantier();
        
        // gestion de l'affichage
        let affichage_ligne: boolean = true;
        switch (classLigneChantier) {
            case "":
                affichage_ligne = true;
                break;
            case "chEndom":
                affichage_ligne = gestion_aff.aff_reparer;
                break;
            case "chCons":
                affichage_ligne = gestion_aff.aff_construit;
                break;
            case "chEnCons":
                affichage_ligne = gestion_aff.aff_construction;
                break;
            case "chBlock":
                affichage_ligne = gestion_aff.aff_bloque;
                break;
            case "plObte":
                affichage_ligne = gestion_aff.aff_obtenu;
                break;
        }
        
        // Calcul de l'affichage du chantier si on a un filtre et que le chantier ne contient pas le nom de l'item recherché
        if (affichage_ligne && filtre_ressource !== "" && chantierClass.ressources !== null) {
            affichage_ligne = chantierClass.ressources.filter((ressource) => {
                return normalizeSearch(filtre_ressource, t(ressource.item.nom, {ns: "items"}));
            }).length > 0;
        }
        
        const coutPAChantier = Math.round(chantierClass.pa * (100 - reductionPA) / 100);
        const reductionPAChantier = Math.round(chantierClass.pa * reductionPA / 100);
        
        return <React.Fragment key={"regroupement_chantier_" + chantierClass.id_chantier}>
            {affichage_ligne && <tr className={"ligneChantier " + ((generic) ? "" : classLigneChantier)} id={"listChantier_" + chantierClass.id_chantier} key={"ligne_chantier_" + chantierClass.id_chantier}>
                <td className={"chantier_nom " + ((niveau > 0) ? ("lv-" + Math.min(niveau, 6)) : "root")}>
                    <span className={"infoBulle infoBulleHelpCursor"}>
                        {indicateurChoix && <span><input type={"checkbox"} checked={listChantierChoisi.includes(chantierClass.id_chantier)} onChange={(event) => {
                            const list_chantier_choixMod = [...listChantierChoisi];
                            if (event.target.checked) {
                                list_chantier_choixMod.push(chantierClass.id_chantier);
                            } else {
                                list_chantier_choixMod.splice(list_chantier_choixMod.indexOf(chantierClass.id_chantier), 1);
                            }
                            setListChantierChoisi(list_chantier_choixMod);
                        }}/></span>}
                        <SvgIcone icone={chantierClass.icon}/>{t(chantierClass.nom, {ns: "chantiers"})}
                        <span className={"infoChantier"}>{t(chantierClass.description, {ns: "chantiers"})}</span>
				</span>
                </td>
                <td className="chantier_plan"><SvgIcone icone={plan_string}/></td>
                <td className="chantier_temp">{chantierClass.temp && <i className="fas fa-check color-check"></i>}</td>
                <td className="chantier_repa">{chantierClass.pv > 0 && !chantierClass.indes && <i className="fas fa-check color-check"></i>}</td>
                <td className="chantier_pa">{coutPAChantier}</td>
                {!generic && <td className="chantier_pa paRestant">{(chantierClass.avancement.pa < chantierClass.pa) ?
                    (chantierClass.avancement.pa - reductionPAChantier) : ""}</td>}
                <td className="chantier_pv">{chantierClass.pv === 0 ? "" : chantierClass.pv}</td>
                {!generic && <td className="chantier_pv paRestant">{(chantierClass.reparation.pv < chantierClass.pv) ? chantierClass.reparation.pv : ""}</td>}
                <td className="chantier_def">{chantierClass.def === 0 ? "" : chantierClass.def}</td>
                <td className="chantier_eau">{chantierClass.water === 0 ? "" : chantierClass.water}</td>
                <td className="chantier_ressources">
                    <div>
                        {chantierClass.ressources !== null && chantierClass.ressources.map((ressource) => {
                                let classSpan;
                                let nombreItems;
                                if (generic) {
                                    classSpan = "ressourceChantier";
                                } else {
                                    // Recherche de l'item en banque
                                    const itemBanque = banque[ressource.item.id_objet];
                                    if (itemBanque === undefined) {
                                        nombreItems = 0;
                                    } else {
                                        nombreItems = itemBanque.nbrItem;
                                    }
                                    
                                    classSpan = ((nombreItems >= ressource.nombre) ? "ressOK" : "ressKO") + " ressourceChantierNotGeneric";
                                }
                                
                                return <span className={classSpan} key={"chantier_" + chantierligne.id_chantier + "_item_" + ressource.item.id_objet}>
                                    {!generic && <span className={"item_banque"}>{nombreItems} / </span>}
                                    {ressource.nombre}
                                    <span className="infoBulle">
                                        <SvgIcone icone={ressource.item.icon}/>
                                        <span className={"info"}>{t(ressource.item.nom, {ns: "items"})}</span>
                                    </span>
                                </span>;
                            },
                        )}
                    </div>
                </td>
            </tr>}
        </React.Fragment>;
    })}
    </>;
}