import { BoxAndWiskers, BoxPlotController }   from "@sgratzl/chartjs-chart-boxplot";
import { Chart, registerables }               from "chart.js";
import React, { useEffect, useRef, useState } from "react";
import { StatistiquesType }                   from "../../../types/components/Hotel/StatistiquesType";
import { useGeneralContext }                  from "../../../types/Context/GeneralContext";
import { useTranslation }                     from "react-i18next";

interface StatistiquesProps {
    stats: StatistiquesType;
}

export default function Statistiques({ stats }: StatistiquesProps) {
    const { t } = useTranslation();
    const chartAtk = useRef<HTMLCanvasElement>(null);
    const chartDef = useRef<HTMLCanvasElement>(null);
    const [minJour, setMinJour] = useState(1);
    const [maxJour, setMaxJour] = useState(stats.maxJour);
    const { general } = useGeneralContext();
    
    useEffect(() => {
        setMaxJour(stats.maxJour);
    }, [stats]);
    
    const couleurPolice = general.themeUser?.stats_font_color ?? "#FFFFFFFF";
    const couleurPoliceGrille = general.themeUser?.stats_border_color ?? "rgba(255,255,255,0.15)";
    
    const buildChartAtk = () => {
        const dataAttaque = stats.atk;
        
        Chart.register(BoxPlotController, BoxAndWiskers, ...registerables);
        
        
        const myChartRef = chartAtk.current.getContext("2d");
        
        const myChart = Chart.getChart("statsAtk");
        if (myChart) {
            myChart.destroy();
        }
        
        new Chart(myChartRef, {
            type: "boxplot",
            data: {
                labels  : Object.values(dataAttaque.day),
                datasets: [{
                    type            : "line",
                    fill            : false,
                    label           : t("Estimation Mini", { ns: "hotel" }),
                    borderColor     : "rgba(100,143,255,1)",
                    data            : Object.values(dataAttaque.minEstim),
                    pointRadius     : 4, // rayon des points
                    pointHoverRadius: 8, // rayon des points au survol de la souris
                    borderWidth     : 2,
                }, {
                    type            : "line",
                    fill            : false,
                    borderColor     : "rgba(120,94,240,1)",
                    label           : t("Estimation Max", { ns: "hotel" }),
                    data            : Object.values(dataAttaque.maxEstim),
                    pointRadius     : 4, // rayon des points
                    pointHoverRadius: 8, // rayon des points au survol de la souris
                    borderWidth     : 2,
                }, {
                    type            : "line",
                    fill            : false,
                    borderColor     : "rgba(220,38,127,1)",
                    label           : t("Attaque réelle", { ns: "hotel" }),
                    data            : Object.values(dataAttaque.attaqueJour),
                    pointRadius     : 4, // rayon des points
                    pointHoverRadius: 8, // rayon des points au survol de la souris
                    borderWidth     : 2,
                }, {
                    type            : "line",
                    fill            : false,
                    borderColor     : "rgba(254,97,0,1)",
                    label           : t("Défense à l'Attaque", { ns: "hotel" }),
                    data            : Object.values(dataAttaque.def),
                    pointRadius     : 4, // rayon des points
                    pointHoverRadius: 8, // rayon des points au survol de la souris
                    borderWidth     : 2,
                }, {
                    type            : "line",
                    fill            : false,
                    borderColor     : "rgba(144, 238, 144, 1)",
                    label           : t("Attaque théorique Mini", { ns: "hotel" }),
                    data            : Object.values(dataAttaque.atk_min_theo),
                    pointRadius     : 4, // rayon des points
                    pointHoverRadius: 8, // rayon des points au survol de la souris
                    borderWidth     : 2,
                    borderDash      : [5, 5], // Tableau définissant le motif du pointillé (5 pixels de ligne suivis de 5 pixels d'espace)
                }, {
                    type            : "line",
                    fill            : false,
                    borderColor     : "rgba(255, 182, 193, 1)",
                    label           : t("Attaque théorique Maxi", { ns: "hotel" }),
                    data            : Object.values(dataAttaque.atk_max_theo),
                    pointRadius     : 4, // rayon des points
                    pointHoverRadius: 8, // rayon des points au survol de la souris
                    borderWidth     : 2,
                    borderDash      : [5, 5], // Tableau définissant le motif du pointillé (5 pixels de ligne suivis de 5 pixels d'espace)
                    
                }, {
                    type                  : "boxplot",
                    label                 : t("Attaque de la saison", { ns: "hotel" }),
                    data                  : Object.values(dataAttaque.atk),
                    backgroundColor       : "rgba(255, 206, 86, 0.4)",
                    borderColor           : "rgb(255, 206, 86)",
                    borderWidth           : 2,
                    outlierBackgroundColor: "#908768",
                    outlierRadius         : 2,
                },
                ],
            },
            
            // Configuration options go here
            options: {
                scales : {
                    y: {
                        beginAtZero: true,
                        ticks      : {
                            color: couleurPolice,
                        },
                        grid       : {
                            color: couleurPoliceGrille,
                        },
                        ///max        : this.props.stats.maxAtk
                    },
                    x: {
                        min  : minJour,
                        max  : maxJour,
                        ticks: {
                            color: couleurPolice,
                        },
                        grid : {
                            color: couleurPoliceGrille,
                        },
                    },
                },
                plugins: {
                    title : {
                        color  : couleurPolice,
                        display: true,
                        text   : t("Statistiques des attaques et estimations de l'attaque de la ville", { ns: "hotel" }),
                    },
                    legend: {
                        labels: {
                            color: couleurPolice,
                        },
                    },
                },
            },
        });
    };
    const buildChartDef = () => {
        const dataDef = stats.def;
        
        Chart.register(...registerables);
        
        
        const myChartRef = chartDef.current.getContext("2d");
        
        const myChart = Chart.getChart("statsDef");
        if (myChart) {
            myChart.destroy();
        }
        
        new Chart(myChartRef, {
            type   : "bar",
            data   : {
                labels  : Object.values(dataDef.day),
                datasets: [{
                    type            : "line",
                    fill            : false,
                    borderColor     : "rgba(255,0,0,1)",
                    label           : t("Défense totale", { ns: "hotel" }),
                    data            : Object.values(dataDef.total),
                    pointRadius     : 4, // rayon des points
                    pointHoverRadius: 8, // rayon des points au survol de la souris
                    borderWidth     : 2,
                }, {
                    type           : "bar",
                    borderColor    : "rgba(255,0,0,0)",
                    backgroundColor: "rgba(100,143,255,1)",
                    label          : t("Chantier + évolution + âme", { ns: "hotel" }),
                    data           : Object.values(dataDef.chantiersUpChantierAme),
                }, {
                    type           : "bar",
                    borderColor    : "rgba(255,0,0,0)",
                    backgroundColor: "rgba(120,94,240,1)",
                    label          : t("Objet de défense", { ns: "hotel" }),
                    data           : Object.values(dataDef.objetDef),
                }, {
                    type           : "bar",
                    borderColor    : "rgba(255,0,0,0)",
                    backgroundColor: "rgba(220,38,127,1)",
                    label          : t("Maison de citoyen", { ns: "hotel" }),
                    data           : Object.values(dataDef.maisonCitizen),
                }, {
                    type           : "bar",
                    borderColor    : "rgba(255,0,0,0)",
                    backgroundColor: "rgba(254,97,0,1)",
                    label          : t("Gardien", { ns: "hotel" }),
                    data           : Object.values(dataDef.gardiens),
                }, {
                    type           : "bar",
                    borderColor    : "rgba(255,0,0,0)",
                    backgroundColor: "rgba(255,176,0,1)",
                    label          : t("Veille", { ns: "hotel" }),
                    data           : Object.values(dataDef.veilleurs),
                }, {
                    type           : "bar",
                    borderColor    : "rgba(255,0,0,0)",
                    backgroundColor: "rgba(255,0,255,1)",
                    label          : t("Temporaire", { ns: "hotel" }),
                    data           : Object.values(dataDef.tempos),
                },
                ],
            },
            options: {
                scales : {
                    y: {
                        min    : 0,
                        stacked: true,
                        ticks  : {
                            color: couleurPolice,
                        },
                        grid   : {
                            color: couleurPoliceGrille,
                        },
                    },
                    x: {
                        min    : minJour,
                        max    : maxJour,
                        stacked: true,
                        ticks  : {
                            color: couleurPolice,
                        },
                        grid   : {
                            color: couleurPoliceGrille,
                        },
                    },
                },
                plugins: {
                    title : {
                        display: true,
                        text   : t("Statistiques des défenses  de la ville", { ns: "hotel" }),
                        color  : couleurPolice,
                    },
                    legend: {
                        labels: {
                            color: couleurPolice,
                        },
                    },
                },
            },
        });
        
    };
    useEffect(() => {
        buildChartAtk();
        buildChartDef();
    });
    
    return <React.Fragment>
        <div id={"form_filtre"}>
            <div><label>{t("Jour mini :", { ns: "hotel" })}</label>
                <input type={"number"} value={minJour} onChange={event => setMinJour(parseInt(event.target.value, 10))} />
            </div>
            <div><label>{t("Jour max :", { ns: "hotel" })}</label>
                <input type={"number"} value={maxJour} onChange={event => setMaxJour(parseInt(event.target.value, 10))} />
            </div>
            <div><input type={"button"} value={t("Réinitialiser", { ns: "hotel" })} onClick={() => {
                setMinJour(1);
                setMaxJour(stats.maxJour);
            }} />
            </div>
        </div>
        <div className="graphStat">
            <div id="graphStat1">
                <canvas ref={chartAtk} id="statsAtk" width="420" height="240"></canvas>
            </div>
            <div id="graphStat2">
                <canvas ref={chartDef} id="statsDef" width="420" height="240"></canvas>
            </div>
        </div>
    </React.Fragment>;
}