import { DefenseDTO } from "../../../types/models/defense.dto";

export class DefenseClass {
    
    defense: DefenseDTO;
    chantier: number; // def chantier non construit
    objet_def: number; // nombre d'objets de def
    bonus_od: number; // bonus od (construction et/ou upgrade)
    upgrade: number; // def chantier d'un upgrade
    maison_citoyen: number; // def habitation à construire
    gardiens: number; // nombre de gardien
    veilleurs: number; // def veilles
    ames: number; // nombre d'ame à purifier
    morts: number; // nombre de morts
    bonusSD: number; // bonus du SD → construit, à construire ou upgrade
    tdga_construit: boolean; // si tdga construit ou non
    pa_tdga: number; // nombre de pa dans la tour
    decharges: number; // defense des décharges prévues (outils décharge)
    cimetiere_construit: boolean; //cimetiere construit ou prévu
    cercueil_construit: boolean; // cercueil construit ou prévu
    hamame_construit: boolean; // hamame construit ou prévu
    def_repa: number; // def des réparations
    
    
    constructor({ def_base, chantier, objet_def, bonus_od, upgrade, maison_citoyen, gardiens, veilleurs, ames, morts, bonusSD, tdga_construit, pa_tdga, decharges, cimetiere_construit, cercueil_construit, hamame_construit, def_repa }: {
        def_base: DefenseDTO,
        chantier: number,
        objet_def: number,
        bonus_od: number,
        upgrade: number,
        maison_citoyen: number,
        gardiens: number,
        veilleurs: number,
        ames: number,
        morts: number,
        bonusSD: number,
        tdga_construit: boolean,
        pa_tdga: number,
        decharges: number,
        cimetiere_construit: boolean,
        cercueil_construit: boolean,
        hamame_construit: boolean,
        def_repa: number,
    }) {
        this.defense = def_base;
        this.chantier = chantier;
        this.objet_def = objet_def;
        this.bonus_od = bonus_od;
        this.upgrade = upgrade;
        this.maison_citoyen = maison_citoyen;
        this.gardiens = gardiens;
        this.veilleurs = veilleurs;
        this.ames = ames;
        this.morts = morts;
        this.bonusSD = bonusSD;
        this.tdga_construit = tdga_construit;
        this.pa_tdga = pa_tdga;
        this.decharges = decharges;
        this.cimetiere_construit = cimetiere_construit;
        this.cercueil_construit = cercueil_construit;
        this.hamame_construit = hamame_construit;
        this.def_repa = def_repa;
    }
    
    getChantiers(): number {
        return this.defense.buildings + (this.chantier || 0) + this.def_repa;
    }
    
    getUpgrades(): number {
        return this.defense.upgrades + this.upgrade || 0;
    }
    
    getObjets(): number {
        return Math.floor(this.objet_def * this.bonus_od / 10);
    }
    
    getMaisons(): number {
        return this.defense.maison_citoyen + this.maison_citoyen || 0;
    }
    
    getGardiens(): number {
        return this.gardiens * ((this.tdga_construit) ? 10 : 5);
    }
    
    getVeilleurs(): number {
        return this.veilleurs;
    }
    
    getTempos(): number {
        return ((this.tdga_construit) ? 10 : 0) * this.pa_tdga + this.decharges;
    }
    
    getMorts(): number {
        return ((this.cercueil_construit) ? 20 : ((this.cimetiere_construit) ? 10 : 0)) * this.morts;
    }
    
    getAmes(): number {
        return (this.hamame_construit) ? this.ames * 5 : 0;
    }
    
    getTotalDef(): number {
        return 10 + this.getChantiers() + this.getUpgrades() + this.getObjets() + this.getMaisons() + this.getGardiens() + this.getVeilleurs() + this.getTempos();
    }
    
    getDefSD(): number {
        return Math.floor((this.getTotalDef()) * (this.bonusSD || 0) / 100);
    }
    
    
}