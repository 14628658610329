import React                  from "react";
import { ListeCoalitionType } from "../../../types/components/Jump/ListCoalition";
import HTMLParser             from "html-react-parser";
import { formatInTimeZone }   from "date-fns-tz";
import { Link }               from "react-router-dom";
import { useGeneralContext }  from "../../../types/Context/GeneralContext";
import { UserDTO }            from "../../../types/models/user.dto";
import { useTranslation }     from "react-i18next";

export default function ListCoalition(props: { list: ListeCoalitionType }) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const listJump = props.list.listJump;
    
    return <div className="jumpCorps">
        
        {(listJump.length === 0) ? (<div id={"list_jump_aucun"}>
                <h2 style={{ color: "red", textAlign: "center" }}>{t("Vous êtes actuellement inscrit dans aucun jump prévu prochainnement", { ns: "jumpEvent" })}</h2>
                <Link to={"/jump/inscription"} style={{ textDecoration: "none", color: "inherit" }}>
                    <button type={"button"} className={"btn btn-primary btn-lg"}>{t("Vers les jumps", { ns: "jumpEvent" })}</button>
                </Link>
            </div>
        ) : (<table id={"list_coalition"}>
            <thead>
            <tr>
                <th className="th_nomJump">{t("Nom du jump", { ns: "jumpEvent" })}</th>
                <th className="td_orgaJump">{t("Organisateur(s)", { ns: "jumpEvent" })}</th>
                <th className="td_dateJump">{HTMLParser(t("Date de jump <br/> approximatif", { ns: "jumpEvent" }))}</th>
                <th className="td_lienCoaJump">{HTMLParser(t("Lien vers <br/> la page coalition", { ns: "jumpEvent" }))}</th>
            </tr>
            </thead>
            <tbody>
            {Object.values(listJump).map((jump) => {
                const listOrga = Object.values(jump.gestionnaires).map((user: UserDTO) => user.pseudo).sort((a: string, b: string) => a.localeCompare(b));
                
                const orga = listOrga.length === 0 ? " " : (listOrga.length === 1
                    ? listOrga[0]
                    : listOrga.slice(0, -1).join(", ") + " " + t("et", { ns: "app" }) + " " + listOrga[listOrga.length - 1]);
                
                return <tr key={"jump_" + jump.id}>
                    <td className="td_nomJump">{jump.nom}</td>
                    <td className="td_orgaJump">{orga}</td>
                    <td className="td_dateJump">{formatInTimeZone(new Date(jump.date_approx_jump), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
                    <td className="td_lienCoaJump">
                        <Link to={"/coalition/" + jump.id} style={{ textDecoration: "none", color: "inherit" }}>
                            <button type={"button"}>{t("Accéder", { ns: "jumpEvent" })}</button>
                        </Link>
                    </td>
                </tr>;
            })}
            </tbody>
        </table>)}
    
    </div>;
    
    
}

