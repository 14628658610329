import React, { createContext, useContext, useEffect, useState } from "react";
import { RuineGameMainType, RuineGameType, ScoreRuineGameType }  from "../../types/components/RuineGame/RuineGameType";
import Tabs                                                      from "react-bootstrap/Tabs";
import Tab                                                       from "react-bootstrap/Tab";
import { RetourRuineGameMaze, RuineGameApi }                     from "../../services/api/RuineGameApi";
import { RuineGameOptionRandom }                                 from "./RuineGameOptionRandom";
import { RuineGamePlay }                                         from "./RuineGamePlay";
import { CaseRuine }                                             from "../../types/components/ville/RuinesType";
import { Helmet }                                                from "react-helmet-async";
import { fromZonedTime }                                         from "date-fns-tz";
import SvgIcone                                                  from "../../components/generality/SvgIcone";
import { ListingHistoriqueRuineGame }                            from "./ListingHistoriqueRuineGame";
import { MapResultExploration }                                  from "./MapResultExploration";
import { Status_error, usePopUp }                                from "../../types/Context/PopUpContext";
import { UserDTO }                                               from "../../types/models/user.dto";
import { useTranslation }                                        from "react-i18next";

interface RuineGameProps {
    ruineGame: RuineGameMainType;
}

interface RuineGameContextType {
    plan: CaseRuine[][][];
    setPlan: (plan: CaseRuine[][][]) => void;
    planMap: CaseRuine[][][];
    setPlanMap: (plan: CaseRuine[][][]) => void;
    x: number;
    setX: (x: number) => void;
    y: number;
    setY: (y: number) => void;
    z: number;
    setZ: (z: number) => void;
    oxygene: number;
    setOxygene: (prevCurrentOxygene: number) => void;
    mana: number;
    setMana: (mana: number) => void;
    isCallApi: boolean;
    setIsCallApi: (isCallApi: boolean) => void;
    isEndGame: boolean;
    setIsEndGame: (isEndGame: boolean) => void;
}

const RuineGameContext = createContext<RuineGameContextType>({
    plan        : [],
    setPlan     : () => {
    },
    planMap     : [],
    setPlanMap  : () => {
    },
    x           : 7,
    setX        : () => {
    },
    y           : 0,
    setY        : () => {
    },
    z           : 0,
    setZ        : () => {
    },
    oxygene     : 0,
    setOxygene  : () => {
    },
    mana        : 0,
    setMana     : () => {
    },
    isCallApi   : false,
    setIsCallApi: () => {
    },
    isEndGame   : false,
    setIsEndGame: () => {
    },
});

export function useRuineGameContext() {
    return useContext(RuineGameContext);
}

const generateCSSUser = (user: UserDTO) => {
    return `
		.ruine_escalier_up {color:${user.user_personnalisation.color_stair_up};}
		.ruine_escalier_down {color: ${user.user_personnalisation.color_stair_down}; }
		`;
};

export function RuineGame({ ruineGame }: RuineGameProps) {
    const { t } = useTranslation();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const [activeKey, setActiveKey] = useState("game");
    const [gameStarted, setGameStarted] = useState(false);
    const [plan, setPlan] = useState<CaseRuine[][][]>();
    const [planMap, setPlanMap] = useState<CaseRuine[][][]>();
    const [x, setX] = useState(7);
    const [y, setY] = useState(0);
    const [z, setZ] = useState(0);
    const [oxygene, setOxygene] = useState<number>(0);
    const [mana, setMana] = useState<number>(0);
    const [isCallApi, setIsCallApi] = useState<boolean>(false);
    const [isEndGame, setIsEndGame] = useState<boolean>(false);
    const [isEjected, setIsEjected] = useState<boolean>(false);
    const [gameRuine, setGameRuine] = useState<RuineGameType>();
    const [score, setScore] = useState<ScoreRuineGameType>({ pct_kill: 0, pct_explo: 0, pct_fouille: 0, ejected: false, oxygen_restant: 0 });
    const [finish, setFinish] = useState<boolean>(false);
    
    const ruineGameApi = new RuineGameApi();
    
    const initialisation = () => {
        setIsEndGame(false);
        setIsEjected(false);
        setFinish(false);
    };
    
    const handleStartGame = (etage: number, typePlan: number, nbOxygene: number, nbZombie: number) => {
        initialisation();
        ruineGameApi.getMaze({ nombre_etage: etage, nb_zombie: nbZombie, oxygene: nbOxygene, type_plan: typePlan })
            .then((response: RetourRuineGameMaze) => {
                if (response.data.ruine_maze !== undefined || response.data.game !== undefined) {
                    setPlan(response.data.ruine_maze);
                    setGameRuine(response.data.game);
                    if (response.data.game.type_plan === 0) {
                        setPlanMap(response.data.ruine_maze);
                    } else {
                        setPlanMap(response.data.ruine_maze_vide);
                    }
                    setX(7);
                    setY(0);
                    setZ(0);
                    setMana(response.data.game.mana_kill);
                    const oxygene = response.data.game.qte_oxygene ?? 0;
                    const diffTime = Math.abs(fromZonedTime(new Date().getTime(), "Europe/Paris").getTime() -
                        fromZonedTime(new Date(response.data.game.begin_at), "Europe/Paris").getTime());
                    // Si la différence entre le startGame et la date actuelle inférieure à 1 oxygène, on ne touche pas à l'oxygène
                    if (diffTime > 3000) {
                        const oxygeneSupp = Math.floor(diffTime / 3000);
                        const newOxygene = Math.max(oxygene - oxygeneSupp, 0);
                        setOxygene(newOxygene);
                    } else {
                        setOxygene(oxygene);
                    }
                    setGameStarted(true);
                }
                
            }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.data.error);
        });
    };
    
    useEffect(() => {
        if (ruineGame.game !== null && ruineGame.ruine_maze !== null && ruineGame.game.begin_at !== null) {
            setPlan(ruineGame.ruine_maze);
            setGameRuine(ruineGame.game);
            setX(ruineGame.game.x);
            setY(ruineGame.game.y);
            setZ(ruineGame.game.z);
            if (ruineGame.game.type_plan === 0) {
                setPlanMap(ruineGame.ruine_maze);
            } else {
                setPlanMap(ruineGame.ruine_maze_vide);
            }
            setMana(ruineGame.game.mana_kill);
            const oxygene = (ruineGame.game.qte_oxygene ?? 0) - (ruineGame.game.oxygen_lost ?? 0);
            const diffTime = Math.abs(
                fromZonedTime(new Date().getTime(), "Europe/Paris").getTime() - fromZonedTime(new Date(ruineGame.game.begin_at), "Europe/Paris").getTime());
            // Si la différence entre le startGame et la date actuelle inférieure à 1 oxygène, on ne touche pas à l'oxygène
            if (diffTime > 3000) {
                const oxygeneSupp = Math.floor(diffTime / 3000);
                const newOxygene = Math.max(oxygene - oxygeneSupp, 0);
                setOxygene(newOxygene);
            } else {
                setOxygene(oxygene);
            }
            setGameStarted(true);
        }
    }, []);
    
    useEffect(() => {
        if (!isCallApi && isEndGame && gameStarted) {
            setIsCallApi(true);
            ruineGameApi.endGame({
                idUser       : ruineGame.user_css.id,
                plan_ruine   : plan,
                id_ruine_game: gameRuine.id,
                oxygene      : oxygene,
                mana         : mana,
                ejecter      : isEjected,
            }).then((response) => {
                const newScore = { ...score };
                newScore.pct_kill = response.data.score.pct_kill;
                newScore.pct_explo = response.data.score.pct_explo;
                newScore.pct_fouille = response.data.score.pct_fouille;
                newScore.ejected = response.data.score.ejected;
                newScore.oxygen_restant = response.data.score.oxygen_restant;
                setScore(newScore);
            }).catch((error) => {
                setStatus(Status_error);
                setMessagePopUp(error.data.error);
                setShowPop(true);
            }).finally(() => {
                setIsCallApi(false);
                setGameStarted(false);
                setFinish(true);
            });
        }
    }, [isEndGame]);
    
    return <>
        <Helmet>
            <style>{generateCSSUser(ruineGame.user_css)}</style>
        </Helmet>
        <RuineGameContext.Provider
            value={{
                plan,
                setPlan,
                x,
                setX,
                y,
                setY,
                z,
                setZ,
                oxygene,
                setOxygene,
                mana,
                setMana,
                isCallApi,
                setIsCallApi,
                planMap,
                setPlanMap,
                isEndGame,
                setIsEndGame,
            }}>
            <div id={"zone_ruineGame_body"}>
                <h2>{t("Exploration de ruine", { ns: "ruineGame" })}</h2>
                <div style={{ width: "842px", margin: "auto" }}>
                    <Tabs defaultActiveKey="game" activeKey={activeKey} onSelect={setActiveKey}>
                        <Tab eventKey="game" title={t("Jeu", { ns: "ruineGame" })}>
                            <div id={"zone_tabs_game"}>
                                {!gameStarted && <RuineGameOptionRandom liste_niveau_difficulte={ruineGame.liste_niveau_difficulte} onStartGame={handleStartGame} />}
                                {gameStarted && <RuineGamePlay tradPorte={ruineGame.trad_porte} game={gameRuine} setEjected={setIsEjected} />}
                                {finish && <div id={"zone_score_ruine_game"}>
                                    <h2>{t("Score :", { ns: "ruineGame" })}</h2>
                                    <div className={"score_game_ruine"}>
                                        <div className={"score_game_ruine_libelle"}><span>{t("Pourcentage d'extermination de zombie", { ns: "ruineGame" })}</span><SvgIcone icone={"r_killz"} /><span>:</span></div>
                                        <div>{score.pct_kill}%</div>
                                    </div>
                                    <div className={"score_game_ruine"}>
                                        <div className={"score_game_ruine_libelle"}><span>{t("Pourcentage d'exploration de la ruine", { ns: "ruineGame" })}</span><SvgIcone icone={"r_explor"} /><span>:</span>
                                        </div>
                                        <div>{score.pct_explo}%</div>
                                    </div>
                                    <div className={"score_game_ruine"}>
                                        <div className={"score_game_ruine_libelle"}><span>{t("Pourcentage de fouille de pièce", { ns: "ruineGame" })}</span><SvgIcone icone={"r_ruine"} /><span>:</span>
                                        </div>
                                        <div>{score.pct_fouille}%</div>
                                    </div>
                                    <div className={"score_game_ruine"}>
                                        <div className={"score_game_ruine_libelle"}><span>{t("Sorti de la ruine", { ns: "ruineGame" })}</span><span>:</span></div>
                                        <div>{(score.ejected) ? <i className="fa-solid fa-xmark fa-xl color-red"></i> :
                                            <i className="fa-solid fa-check fa-xl color-green"></i>}</div>
                                    </div>
                                    <div className={"score_game_ruine"}>
                                        <div className={"score_game_ruine_libelle"}><span>{t("Oxygène restant", { ns: "ruineGame" })}</span><span>:</span></div>
                                        <div>{score.oxygen_restant}</div>
                                    </div>
                                    <div>
                                        <MapResultExploration tradPorte={ruineGame.trad_porte} plan={plan} />
                                    </div>
                                </div>}
                            </div>
                        </Tab>
                        {<Tab eventKey="histo" title={t("Historique des parties", { ns: "ruineGame" })}>
                            <ListingHistoriqueRuineGame tradPorte={ruineGame.trad_porte} activeKey={activeKey} liste_niveaux={ruineGame.liste_niveau_difficulte} />
                        </Tab>}
                        {/*<Tab eventKey="game" title={translate.game}></Tab>*/}
                    </Tabs>
                </div>
            </div>
        </RuineGameContext.Provider>
    </>;
}