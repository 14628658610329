import React                        from "react";
import {InscriptionOptionsListType} from "../../../types/components/Jump/InscriptionJumpType";
import {formatInTimeZone}           from "date-fns-tz";
import SvgIcone                     from "../../../components/generality/SvgIcone";
import {useGeneralContext}          from "../../../types/Context/GeneralContext";
import {InscriptionJumpDTO}         from "../../../types/models/inscriptionJump.dto";
import {UserDTO}                    from "../../../types/models/user.dto";
import {JobPrototypeDTO}            from "../../../types/models/jobPrototype.dto";
import {LevelRuinePrototypeDTO}     from "../../../types/models/levelRuinePrototype.dto";
import {HerosPrototypeDTO}          from "../../../types/models/herosPrototype.dto";
import {LogEventInscriptionDTO}     from "../../../types/models/logEventInscription.dto";
import {useTranslation}             from "react-i18next";

export default function HistoriqueModification(props: { inscription: InscriptionJumpDTO, options: InscriptionOptionsListType, user: UserDTO, inGestion: boolean }) {
    const {t} = useTranslation();
    const {general} = useGeneralContext();
    const inscription = props.inscription;
    const options = props.options;
    const user = props.user;
    const inGestion = props.inGestion;
    
    const determineValeurAffichage = (valeurLog: string, typologie: number, options: InscriptionOptionsListType) => {
        if (typologie === 0) {
            return <div className={"valeur_historique_log_inscription"}>{t(valeurLog, {ns: "jumpEvent"})}</div>;
        } else if (typologie === 1) {
            const job: JobPrototypeDTO = Object.values(options.listJob).find((job: JobPrototypeDTO) => job.id === parseInt(valeurLog, 10));
            return <div className={"valeur_historique_log_inscription"}><SvgIcone icone={"h_" + job?.icon}/>{t(job?.alternatif, {ns: "jump"})}</div>;
        } else if (typologie === 4) {
            return <div className={"valeur_historique_log_inscription"}>{t(valeurLog, {ns: "jump"})}</div>;
        } else if (typologie === 2) {
            const ruine: LevelRuinePrototypeDTO = Object.values(options.listLvlRuine).find((ruine: LevelRuinePrototypeDTO) => ruine.id === parseInt(valeurLog, 10));
            return <div className={"valeur_historique_log_inscription"}>{ruine.level}-{t(ruine.nom, {ns: "jump"})}</div>;
        } else if (typologie === 3) {
            const pouvoir: HerosPrototypeDTO = Object.values(options.listPouvoir).find((pouvoir: HerosPrototypeDTO) => pouvoir.id === parseInt(valeurLog, 10));
            return <div className={"valeur_historique_log_inscription"}><SvgIcone icone={pouvoir.icon}/>{t(pouvoir.nom, {ns: "game"})}</div>;
        } else {
            return <div className={"valeur_historique_log_inscription"}>{valeurLog}</div>;
        }
    };
    
    
    return <div id="listLog" className="fondWhite02">
        <h3>{t("Historique des modifications", {ns: "jumpEvent"})}</h3>
        <table>
            <thead>
            <tr>
                <th className="td_log_date">{t("Date", {ns: "jumpEvent"})}</th>
                <th className="td_log_user">{t("Qui ?", {ns: "jumpEvent"})}</th>
                <th className="td_log_libelle">{t("Evénement", {ns: "jumpEvent"})}</th>
                <th className="td_log_valeur_av">{t("Valeur avant", {ns: "jumpEvent"})}</th>
                <th className="td_log_valeur_ap">{t("Valeur après", {ns: "jumpEvent"})}</th>
            </tr>
            </thead>
            <tbody>
            {Object.values(inscription.log_event_inscriptions).filter((log: LogEventInscriptionDTO) => log.visible || inGestion).sort((a: LogEventInscriptionDTO, b: LogEventInscriptionDTO) => a.id > b.id ? -1 : 1).map((log: LogEventInscriptionDTO) => {
                
                return <tr key={"jump_id_" + log.id}>
                    <td className="td_log_date">{formatInTimeZone(new Date(log.event_at), general.fuseau, t("dd / MM / yyyy à H:mm", {ns: "app"}))}</td>
                    <td className="td_log_user">{(log.declencheur.id !== user.id && !inGestion) ? t("Organisateur", {ns: "jumpEvent"}) : log.declencheur.pseudo}</td>
                    <td className="td_log_libelle">{t(log.libelle, {ns: "jumpEvent"})}</td>
                    <td className="td_log_valeur_av">{determineValeurAffichage(log.valeur_avant, log.typologie, options)}</td>
                    <td className="td_log_valeur_ap">{determineValeurAffichage(log.valeur_apres, log.typologie, options)}</td>
                </tr>;
            })}
            </tbody>
        </table>
    </div>;
}