import React, { useEffect, useState }               from "react";
import Spinner                                      from "react-bootstrap/Spinner";
import { NiveauDifficulteArrayType, RuineGameType } from "../../types/components/RuineGame/RuineGameType";
import { RetourRuineGameMazeOld, RuineGameApi }     from "../../services/api/RuineGameApi";
import SvgIcone                                     from "../../components/generality/SvgIcone";
import { formatInTimeZone }                         from "date-fns-tz";
import { CaseRuine }                                from "../../types/components/ville/RuinesType";
import Modal                                        from "react-bootstrap/Modal";
import Button                                       from "react-bootstrap/Button";
import { MapResultExploration }                     from "./MapResultExploration";
import { useGeneralContext }                        from "../../types/Context/GeneralContext";
import { useTranslation }                           from "react-i18next";

export function ListingHistoriqueRuineGame({ tradPorte, activeKey, liste_niveaux }: {
    tradPorte: string[], activeKey: string, liste_niveaux: NiveauDifficulteArrayType
}) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const [chargement, setChargement] = useState(true);
    const [historique, setHistorique] = useState<RuineGameType[]>([]);
    const [traductionRuine, setTraductionRuine] = useState([]);
    const [mapRuine, setMapRuine] = useState<CaseRuine[][][]>();
    const [oldMapRuineCharged, setOldMapRuineCharged] = useState(false);
    const ruineGameApi = new RuineGameApi();
    
    useEffect(() => {
        if (activeKey === "histo") {
            const ruineGameApi = new RuineGameApi();
            ruineGameApi.historique().then((res) => {
                setHistorique(res.data.list_historique);
                setTraductionRuine(res.data.traduction_ruine_game);
                setChargement(false);
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [activeKey]);
    
    if (chargement) {
        return <div id={"zone_historique_game"}>
            <div id="list_chargement_historique">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>{t("Chargement en cours", { ns: "app" })}</p>
            </div>
        </div>;
    }
    
    const handleClose = () => {
        setOldMapRuineCharged(false);
    };
    
    const handleOpenRuine = (idRuine: string) => {
        ruineGameApi.getOldMaze(idRuine).then((res: RetourRuineGameMazeOld) => {
            if (res.data.ruine_plan !== undefined) {
                setMapRuine(res.data.ruine_plan);
                setOldMapRuineCharged(true);
            }
        }).catch((err) => {
            console.error(err);
        });
    };
    
    return <div id={"zone_historique_game"}>
        <table>
            <thead>
            <tr>
                <th rowSpan={2} className={"histo_ruine_game_date"}>{t("Date de la partie", { ns: "ruineGame" })}</th>
                <th rowSpan={2} className={"histo_ruine_game_options"}><span className={"infoBulle"}><span>O<sub>2</sub></span><span
                    className={"info"}>{t("Quantité d'oxygène", { ns: "ruineGame" })}</span></span></th>
                <th rowSpan={2} className={"histo_ruine_game_options"}><span className={"infoBulle"}><span><SvgIcone icone={"r_killz"} /></span><span
                    className={"info"}>{t("Nombre de zombie", { ns: "ruineGame" })}</span></span></th>
                <th rowSpan={2} className={"histo_ruine_game_options"}><span className={"infoBulle"}><span><SvgIcone icone={"r_ruine"} /></span><span
                    className={"info"}>{t("Nombre d'étage", { ns: "ruineGame" })}</span></span></th>
                <th rowSpan={2} className={"histo_ruine_game_carte"}><span className={"infoBulle"}><span><SvgIcone icone={"r_explo2"} /></span><span
                    className={"info"}>{t("Type carte", { ns: "ruineGame" })}</span></span></th>
                <th rowSpan={2} className={"histo_ruine_game_options"}><span className={"infoBulle"}><span><SvgIcone icone={"r_homeup"} /></span><span
                    className={"info"}>{t("Type ruine", { ns: "ruineGame" })}</span></span></th>
                <th rowSpan={2} className={"histo_ruine_game_options"}><span className={"infoBulle"}><span>O<sub>2</sub>R</span><span
                    className={"info"}>{t("Quantité d'oxygène restant", { ns: "ruineGame" })}</span></span></th>
                <th rowSpan={2} className={"histo_ruine_game_options"}><span className={"infoBulle"}><span><SvgIcone
                    icone={"h_rage"} /><sub>R</sub></span><span className={"info"}>{t("Quantité d'énergie restante", { ns: "ruineGame" })}</span></span></th>
                <th colSpan={4} className={"histo_ruine_game_score_tot"}>{t("Score", { ns: "ruineGame" })}</th>
                <th rowSpan={2} className={"histo_ruine_game_voir_ruine"}>{t("Revoir la map", { ns: "ruineGame" })}</th>
            </tr>
            <tr>
                <th className={"histo_ruine_game_score"}><span className={"infoBulle"}><span><SvgIcone icone={"r_killz"} /></span><span
                    className={"info"}>{t("Extermination", { ns: "ruineGame" })}</span></span></th>
                <th className={"histo_ruine_game_score"}><span className={"infoBulle"}><span><SvgIcone icone={"r_explor"} /></span><span
                    className={"info"}>{t("Exploration", { ns: "ruineGame" })}</span></span></th>
                <th className={"histo_ruine_game_score"}><span className={"infoBulle"}><span><SvgIcone icone={"r_ruine"} /></span><span
                    className={"info"}>{t("Fouille", { ns: "ruineGame" })}</span></span></th>
                <th className={"histo_ruine_game_score"}><span className={"infoBulle"}><span><SvgIcone icone={"r_cgarb"} /></span><span
                    className={"info"}>{t("Sorti", { ns: "ruineGame" })}</span></span></th>
            </tr>
            </thead>
            <tbody>
            {historique.map((partie: RuineGameType, index) => {
                return <tr key={"partie_" + index}>
                    <td>{formatInTimeZone(new Date(partie.begin_at), general.fuseau, t("dd / MM / yyyy à H:mm", { ns: "app" }))}</td>
                    <td>{partie.qte_oxygene}</td>
                    <td>{partie.nbr_zombie}</td>
                    <td>{partie.nbr_etage}</td>
                    <td>{t(liste_niveaux.plan[partie.type_plan].toString(), { ns: "ruineGame" })}</td>
                    <td>{t(traductionRuine[partie.type_ruine], { ns: "ruineGame" })}</td>
                    <td>{Math.max(partie.oxygene_restant, 0)}</td>
                    <td>{partie.mana_restant}</td>
                    <td>{partie.pct_zombie_kill}%</td>
                    <td>{partie.pct_exploration}%</td>
                    <td>{partie.pct_fouille}%</td>
                    <td>{partie.ejected ? <i className="fa-solid fa-xmark fa-xl color-red"></i> : <i className="fa-solid fa-check fa-xl color-green"></i>}</td>
                    <td onClick={() => handleOpenRuine(partie.id)} style={{ cursor: "pointer" }}><SvgIcone icone={"r_explor"} /></td>
                </tr>;
            })}
            </tbody>
        </table>
        <Modal show={oldMapRuineCharged} onHide={handleClose} centered={true}>
            <Modal.Body><MapResultExploration tradPorte={tradPorte} plan={mapRuine} /></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>{t("Fermer", { ns: "ruineGame" })}</Button>
            </Modal.Footer>
        </Modal>
    </div>;
}