import React, {useEffect, useState} from "react";
import BanniereJump                 from "./BanniereJump";
import SvgIcone                     from "../../components/generality/SvgIcone";
import Form                         from "react-bootstrap/Form";
import {FormJumpType}               from "../../types/components/Jump/JumpEventFormType";
import SvgDrapeau                   from "../../components/generality/SvgDrapeau";
import {JumpDTO}                    from "../../types/models/jump.dto";
import {ObjectifVillePrototypeDTO}  from "../../types/models/objectifVillePrototype.dto";
import {useTranslation}             from "react-i18next";
import EditorComponent              from "../../components/editor/editor";
import HTMLParser                   from "html-react-parser";

export default function FormJump({formJump, onChange, onRetour, isArchMode = false}: {
    formJump: FormJumpType,
    onRetour: (isError: boolean, showMod: boolean, messagePop: string) => void,
    onChange: (jumpMod: JumpDTO) => void,
    isArchMode?: boolean
}) {
    const {t} = useTranslation();
    const options = formJump.options;
    const user = formJump.user;
    
    const [jump, setJump] = useState(formJump.jump);
    
    useEffect(() => {
        setJump(formJump.jump);
    }, [formJump.jump]);
    
    function formatDateTimeLocal(inputDate) {
        // Récupère uniquement la date et l'heure, sans les secondes ni le fuseau horaire
        return inputDate.split(".")[0].slice(0, 16);
    }
    
    const handleJumpChange = (updatedProperties: Partial<JumpDTO>) => {
        const jumpMod = {...jump, ...updatedProperties};
        setJump(jumpMod);
        onChange(jumpMod);
    };
    
    const handleBanniereJump = (urlBanniere: string, isErrorBan: boolean, showModBan: boolean, messagePopBan: string) => {
        if (isErrorBan) {
            onRetour(true, showModBan, messagePopBan);
        } else {
            handleJumpChange({banniere: urlBanniere});
            onRetour(false, showModBan, messagePopBan);
            
            setTimeout(() => {
                onRetour(false, false, "");
            }, 1500);
        }
    };
    
    const isObjectifCheckedJump = (objectifId: number): boolean => {
        return jump.objectif.some(obj => obj.id === objectifId);
    };
    
    const handleCheckboxChangeJump = (event: React.ChangeEvent<HTMLInputElement>, objectifToAddOrRemove: ObjectifVillePrototypeDTO) => {
        const {checked} = event.target;
        
        if (checked) {
            handleJumpChange({objectif: [...jump.objectif, objectifToAddOrRemove]});
        } else {
            handleJumpChange({objectif: jump.objectif.filter(obj => obj.id !== objectifToAddOrRemove.id)});
        }
    };
    
    const handleTypeVilleChange = (selectedTypeVilleId: number) => {
        // Trouvez le typeVille correspondant dans la liste
        const selectedTypeVille = options.listTypeVille.find(typeVille => typeVille.id === selectedTypeVilleId);
        
        handleJumpChange({type_ville: selectedTypeVille});
    };
    const handleCommuVilleChange = (selectedCommu: string) => {
        // Trouvez le typeVille correspondant dans la liste
        handleJumpChange({community: selectedCommu});
    };
    
    
    return <div id="zone_jump">
        <div id="formJump" className="fondWhite02">
            <div className={"simpleForm"}>
                <div id={"nomJumpCreation"}>
                    <label htmlFor={"jump_jump"}>{t("Nom de la ville :", {ns: "jumpEvent"})}</label>
                    <input id={"jump_jump"} name={"jump_jump"} type={"text"} value={jump.nom} disabled={isArchMode} onChange={(e) => handleJumpChange({nom: e.target.value})}/>
                </div>
            </div>
            <p className="label_banniere_creation">{t("Bannière :", {ns: "jumpEvent"})}</p>
            <div className="simpleForm">
                <div id="banniereJumpCreation">
                    <BanniereJump typeCreation={"jump"} urlImage={jump.banniere} onRetour={handleBanniereJump} disabled={isArchMode}/>
                </div>
            </div>
            <div className={"simpleForm"}>
                <div id="textAreaJumpCreation">
                    <span>{t("Descriptions :", {ns: "jumpEvent"})}</span>
                    <div className={"inscription_bloc_text"}>
                        {isArchMode ? (
                            <div>{HTMLParser(jump.description ?? "")}</div>
                        ) : <EditorComponent width={941} data={jump.description ?? ""} disabled={isArchMode} lang={user.lang} onTextChange={(text) => {
                            handleJumpChange({description: text});
                        }}/>}
                    </div>
                </div>
            </div>
            <div className={"doubleForm"}>
                <div className="dateJumpCreation">
                    <label htmlFor={"jump_dateDebInscription"}>{t("Date début d'inscription :", {ns: "jumpEvent"})}</label>
                    <input id={"jump_dateDebInscription"} name={"jump_dateDebInscription"} type={"datetime-local"} disabled={isArchMode}
                           value={formatDateTimeLocal(jump.date_deb_inscription)}
                           onChange={(e) => handleJumpChange({date_deb_inscription: e.target.value})}/>
                </div>
                <div className="dateJumpCreation">
                    <label htmlFor={"jump_dateFinInscription"}>{t("Date fin d'inscription :", {ns: "jumpEvent"})}</label>
                    <input id={"jump_dateFinInscription"} name={"jump_dateFinInscription"} type={"datetime-local"} disabled={isArchMode}
                           value={formatDateTimeLocal(jump.date_fin_inscription)}
                           onChange={(e) => handleJumpChange({date_fin_inscription: e.target.value})}/>
                </div>
            </div>
            <div className={"doubleForm"}>
                <div className="dateJumpCreation">
                    <label htmlFor={"jump_dateApproxJump"}>{t("Date approximative du jump :", {ns: "jumpEvent"})}</label>
                    <input id={"jump_dateApproxJump"} name={"jump_dateApproxJump"} type={"datetime-local"} value={formatDateTimeLocal(jump.date_approx_jump)} disabled={isArchMode}
                           onChange={(e) => handleJumpChange({date_approx_jump: e.target.value})}/>
                </div>
            </div>
            {(jump.event === undefined || jump.event === null) && <div className="simpleForm">
                <div className="objectifJumpCreation">
                    <label className={"required"}>{t("Objectif de la ville (plusieurs choix possibles) :", {ns: "jumpEvent"})}</label>
                    <div id={"creation_objectif"}>
                        {options.listObjectif.map((objectif) => {
                            return <div key={objectif.id}>
                                <input
                                    type="checkbox"
                                    id={"objectif_" + objectif.id}
                                    name={"objectif_" + objectif.id}
                                    checked={isObjectifCheckedJump(objectif.id)}
                                    onChange={e => handleCheckboxChangeJump(e, objectif)}
                                    disabled={isArchMode}
                                />
                                <label htmlFor={"objectif_" + objectif.id}><SvgIcone icone={objectif.icon}/><span>{t(objectif.nom, {ns: "jump"})}</span></label>
                            </div>;
                        })}
                    </div>
                </div>
            </div>}
            <div className="doubleForm">
                <div id="typeJumpCreation">
                    <label className="required">{t("Type de ville :", {ns: "jumpEvent"})}</label>
                    <div id="creation_typeVille">
                        {options.listTypeVille.map((typeVille) => {
                            return <React.Fragment key={"type_jump_ville_" + typeVille.id}>
                                <input type={"radio"}
                                       name={"type_jump_ville_" + typeVille.id}
                                       value={typeVille.id}
                                       id={"type_jump_ville_" + typeVille.id}
                                       checked={jump.type_ville?.id === typeVille.id}
                                       onChange={() => handleTypeVilleChange(typeVille.id)}
                                       disabled={isArchMode}
                                />
                                <label htmlFor={"type_ville_" + typeVille.id}><SvgIcone icone={typeVille.icon}/>{t(typeVille.abrev, {ns: "game"})}</label>
                            </React.Fragment>;
                        })}
                    </div>
                </div>
                <div id="typeVilleJumpCreation">
                    <label htmlFor="creation_villePrive">{t("Ville privée (création ville privée) :", {ns: "jumpEvent"})}</label>
                    <Form.Check
                        name={"creation_villePrive"}
                        id={"creation_villePrive"}
                        type="switch"
                        checked={jump.ville_prive}
                        onChange={(event) => handleJumpChange({ville_prive: event.target.checked})}
                        disabled={isArchMode}
                    />
                </div>
            </div>
            <div className="doubleForm">
                <div id="communityJumpCreation">
                    <label className="required">{t("Communauté :", {ns: "jumpEvent"})}</label>
                    <div id="creation_communityVille_jump">
                        {options.listCommu.map((commu) => {
                            return <div key={"type_jump_ville_" + commu}>
                                <input type={"radio"}
                                       name={"type_jump_ville_" + commu}
                                       value={commu}
                                       id={"type_jump_ville_" + commu}
                                       checked={jump.community === commu}
                                       onChange={() => handleCommuVilleChange(commu)}
                                        disabled={isArchMode}
                                />
                                <label htmlFor={"type_ville_" + commu}><SvgDrapeau drapeau={commu}/></label>
                            </div>;
                        })}
                    </div>
                </div>
            </div>
        </div>
    
    </div>;
    
    
}