import React                        from "react";
import { OutilsChantiersTypeProps } from "../../../../types/components/Outils/OutilsChantiers";
import { components }               from "react-select";
import { useOutilsChantiers }       from "../TabsOutilsChantiers";
import { RessourceClass }           from "../RessourceClass";

const { SingleValue, Option, GroupHeading } = components;

export default function RessourcesOutils(props: { outils: OutilsChantiersTypeProps }) {
    const { outilsChantiers, setOutilsChantiers } = useOutilsChantiers();
    const outils: OutilsChantiersTypeProps = props.outils;
    
    const ressources = new RessourceClass({ listChantiers: outilsChantiers.chantiers_programmes ?? [] });
    
    return <div id={"ressources_outils_chantier"}>
    
    </div>;
}

