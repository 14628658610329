import {ItemNeedDTO}      from "../../../types/models/itemNeed.dto";
import {ItemPrototypeDTO} from "../../../types/models/itemPrototype.dto";
import React              from "react";
import ItemAssemblageList from "./ItemAssemblageList";

export default function ItemNeeds({listItem, itemPp, listObjet, inEncyclo = true}: { listItem: ItemNeedDTO[], listObjet: ItemPrototypeDTO[], itemPp?: ItemPrototypeDTO, inEncyclo?: boolean }) {
    
    
    if (Object.values(listItem).length === 1) {
        
        const valeurs = Object.values(listItem);
        const item = valeurs[0];
        
        let complement = "";
        let classComplement = "";
        let classGroup = "groupAssemblage";
        
        if (item.number !== 1) {
            complement = "x" + item.number;
            classComplement = "item_nombre_ency";
            classGroup = "groupAssemblageNombre";
        }
        
        return <ItemAssemblageList id={item.item.id_objet}
                                   icon={listObjet[item.item.id_objet].icon}
                                   nom={listObjet[item.item.id_objet].nom}
                                   classProps={classGroup}
                                   complement={complement}
                                   classAdd={""}
                                   classComplement={classComplement}
                                   inEncyclo={inEncyclo}
        />;
    } else {
        
        return <React.Fragment>{Object.values(listItem).map((item, index) => {
            let classAdd = "";
            
            if (itemPp !== undefined &&
                listObjet[itemPp.id_objet].list_assemblage !== null &&
                listObjet[itemPp.id_objet].list_assemblage.type_action.id === 1 &&
                itemPp.id_objet === item.item.id_objet) {
                
                classAdd = " itemPrincipale";
            }
            
            return <React.Fragment key={"assemblage_need_" + item.id}>{index > 0 && <span className={"ency_space"}>+</span>}
                <ItemAssemblageList id={item.item.id_objet}
                                    icon={listObjet[item.item.id_objet].icon}
                                    nom={listObjet[item.item.id_objet].nom}
                                    classProps={"groupAssemblage"}
                                    classAdd={classAdd}
                                    classComplement={""}
                                    complement={""}
                                    inEncyclo={inEncyclo}
                />
            </React.Fragment>;
        })}</React.Fragment>;
        
    }
    
    
}

