import React                    from "react";
import SvgIcone                 from "../../../components/generality/SvgIcone";
import {BanqueType}             from "../../../types/components/ville/BanqueType";
import {useTranslation}         from "react-i18next";
import {ItemPrototypeDTO}       from "../../../types/models/itemPrototype.dto";
import {Modal}                  from "react-bootstrap";
import DetailItem               from "./DetailItem";
import {BanqueApi}              from "../../../services/api/BanqueApi";
import {useGeneralContext}      from "../../../types/Context/GeneralContext";
import {Status_error, usePopUp} from "../../../types/Context/PopUpContext";
import InfoBanner               from "../../../components/utils/InfoBanner";

interface BanquesProps {
    banque: BanqueType;
}

interface AffNbrIconProps {
    item: ItemPrototypeDTO;
    nombre: number;
    broked: boolean;
    onItemClick?: (id: number) => void;
}


export default function Banques({banque}: BanquesProps) {
    const {t} = useTranslation();
    const {general} = useGeneralContext();
    const {setStatus, setMessagePopUp, setShowPop} = usePopUp();
    const {categories, banque: banqueData} = banque;
    const banqueTri = Object.values(banqueData).sort((a, b) => b.nombre - a.nombre);
    const [showDetail, setShowDetail] = React.useState(false);
    const [itemDetail, setItemDetail] = React.useState<ItemPrototypeDTO | null>(null);
    
    const handleItemClick = (id: number) => {
        const banqueApi = new BanqueApi(general.ville.map_id);
        banqueApi.getDetailItem(id).then(response => {
            if (response.data.item) {
                setItemDetail(response.data.item);
                setShowDetail(true);
            }
            
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error.data?.message || error.message);
            setShowPop(true);
        });
    };
    
    return <div id={"banqueTab"}>
        <InfoBanner>
            {t("En cliquant sur un objet, vous pourrez avoir des informations sur celui-ci", {ns: "ville"})}
        </InfoBanner>
        {categories.map(categorie => (
            <div className="catBanque" key={"cat_" + categorie.id}>
                <div className="catBanqueTitre fondWhite02">{t(categorie.nom, {ns: "items"})} ({banque.totalCat[categorie.id]}{" " + t("objets", {ns: "ville"})})</div>
                <div className="objetBanque fondWhite01">
                    {banqueTri.map(itemBanque => itemBanque.item.category_objet.id === categorie.id && (
                        <AffNbrIconBank item={itemBanque.item} broked={itemBanque.broked} nombre={itemBanque.nombre} onItemClick={handleItemClick} key={"item_" + itemBanque.item.id_objet + itemBanque.broked}/>
                    ))}
                </div>
            </div>
        ))}
        <div className="totalBanque">{t("Total d'objets en banque", {ns: "ville"}) + " : " + banque.total}</div>
        <Modal show={showDetail} onHide={() => setShowDetail(false)} centered={true}>
            {itemDetail !== null && <DetailItem item={itemDetail} listAction={banque.listAction} listNeeds={banque.listNeeds} listObtains={banque.listObtains} listObjets={banque.listObjets}/>}
        </Modal>
    </div>;
}


export function AffNbrIconBank({item, nombre, broked, onItemClick}: AffNbrIconProps) {
    
    const {t} = useTranslation();
    
    enum IconInfo {
        DefaultImage     = "videImg",
        BrokedImage      = "videImg2",
        EmpoissonedImage = "videImg3",
    }
    
    enum ObjectIds {
        SpecialStart = 2000,
        SpecialEnd   = 3000,
    }
    
    const getIconName = (itemId: number, isBroked: boolean): IconInfo => {
        if (itemId >= ObjectIds.SpecialStart && itemId < ObjectIds.SpecialEnd) {
            return IconInfo.EmpoissonedImage;
        } else if (isBroked) {
            return IconInfo.BrokedImage;
        } else {
            return IconInfo.DefaultImage;
        }
    };
    
    const iconName = getIconName(item.id_objet, broked);
    
    
    return (
        <div className={"videItem"} style={{cursor: "pointer"}} onClick={() => onItemClick(item.id_objet)}>
            <span className={"nbrItems"}>{nombre}</span>
            <span className={iconName}>
                <span className={"infoBulle"}>
                    <SvgIcone icone={item.icon}/>
                    <span className={"infoBanque"}>
                        <span className={"itemBanque"}>{t(item.nom, {ns: "items"})} (id : {item.id_objet})</span>
                    </span>
                </span>
            </span>
        </div>
    );
}

