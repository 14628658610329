import { Badge }              from "react-bootstrap";
import React                  from "react";
import { useTranslation }     from "react-i18next";
import { ContentsVersionDTO } from "../../types/models/contentsVersion.dto";

interface ChangelogListProps {
    contents: ContentsVersionDTO[];
}

const ChangelogList = (props: ChangelogListProps) => {
    const { t } = useTranslation();
    
    const getBadgeVariant = (typeContent: number): string => {
        switch (typeContent) {
            case 1:
                return "success"; // FEATURE
            case 2:
                return "danger";  // FIX
            default:
                return "secondary"; // OTHER
        }
    };
    
    const getContentLabel = (typeContent: number): string => {
        switch (typeContent) {
            case 1:
                return t("Nouveau", { ns: "version" });
            case 2:
                return t("Correction", { ns: "version" });
            default:
                return t("Autre", { ns: "version" });
        }
    };
    
    const { contents } = props;
    
    return <ul className={"contenu_changelog"}>
        {contents.map((content) => (
            <li key={"ligne_" + content.id_relatif}>
                <Badge bg={getBadgeVariant(content.type_content)} pill as={"div"} key={"badge_" + content.id_relatif}>
                    <div className={"changelog_content"}>{getContentLabel(content.type_content)}</div>
                </Badge>
                <div className={"ligne_changelog"}>{t(content.contents, { ns: "version" })}</div>
            </li>
        ))}
    </ul>;
};

export default ChangelogList;