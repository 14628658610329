import * as React from "react";
import * as RA    from "react-admin";
import Button     from "@mui/material/Button";
import { Link }   from "react-router-dom";


// Ensuite, vous pouvez utiliser les composants de react-admin en utilisant l'alias RA
const {
          BooleanInput,
          Datagrid,
          DateField,
          DateTimeInput,
          Edit,
          EditButton,
          List,
          NumberField,
          NumberInput,
          ReferenceInput,
          SimpleForm,
          TextField,
          TextInput,
          useRecordContext,
      } = RA;

export const VilleList = (props) => {
    const postFilters = [
        <TextInput source="q" label="Search" alwaysOn key={"search_ville"} />,
        <ReferenceInput source="id" label="Map Id" reference="ville" key={"search_ville_id"} />,
    ];
    
    return <List {...props} filters={postFilters}>
        <Datagrid>
            <NumberField source="id" />
            <NumberField source="map_id" />
            <TextField source="nom" />
            <DateField source={"date_time"} label={"Derniere mise à jour"} />
            <NumberField source={"jour"} />
            <EditButton />
        </Datagrid>
    </List>;
};

const ExpeditionButton = () => {
    const record = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/admin/ville/${record.id}/expedition`}
            color="primary"
        >
            Expeditions
        </Button>
    );
};
const BatimentButton = () => {
    const record = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/admin/ville/${record.id}/batiment`}
            color="primary"
        >
            Bâtiments
        </Button>
    );
};
const RuineButton = () => {
    const record = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/admin/ville/${record.id}/ruine`}
            color="primary"
        >
            Ruines
        </Button>
    );
};


export const VilleEdit = (props) => {
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition de {record ? `"${record.nom}" - ${record.map_id}` : ""}</span>;
    };
    
    
    return (<Edit {...props} title={<PostTitle />}>
        <SimpleForm>
            <div id={"admin_zone_ville_info"}>
                <h2>Info diverse</h2>
                <div id={"admin_ville_info_disable"}>
                    <NumberInput name={"origin"} source={"origin"} label={"Origine"} disabled={true} />
                    <NumberInput name={"jour"} source={"jour"} label={"Jour"} disabled={true} />
                    <NumberInput name={"pos_x"} source={"pos_x"} label={"Position X"} disabled={true} />
                    <NumberInput name={"pos_y"} source={"pos_y"} label={"Position Y"} disabled={true} />
                    <NumberInput name={"weight"} source={"weight"} label={"Largeur"} disabled={true} />
                    <NumberInput name={"height"} source={"height"} label={"Hauteur"} disabled={true} />
                    <NumberInput name={"bonus"} source={"bonus"} label={"Bonus"} disabled={true} />
                    <BooleanInput name={"prived"} source={"prived"} label={"Privée"} disabled={true} />
                    <BooleanInput name={"porte"} source={"porte"} label={"Porte"} disabled={true} />
                    <BooleanInput name={"hard"} source={"hard"} label={"Pandemonium"} disabled={true} />
                    <BooleanInput name={"chaos"} source={"chaos"} label={"Chaos"} disabled={true} />
                    <BooleanInput name={"devast"} source={"devast"} label={"Dévasté"} disabled={true} />
                    <NumberInput name={"water"} source={"water"} label={"Eau"} disabled={true} />
                    <NumberInput name={"saison"} source={"saison"} label={"Saison"} disabled={true} />
                    <TextInput name={"phase"} source={"phase"} label={"Phase"} disabled={true} />
                    <DateTimeInput name={"date_time"} source={"date_time"} label={"Dernière mise à jour"} disabled={true} />
                    <TextInput name={"chaman"} source={"chaman"} format={(value) => (value ? value.pseudo : "")} label={"Chaman"} disabled={true} />
                    <TextInput name={"guide"} source={"guide"} format={(value) => (value ? value.pseudo : "")} label={"Guide"} disabled={true} />
                    <TextInput name={"update_by"} source={"update_by"} format={(value) => (value ? value.pseudo : "")} label={"Dernière MAJ par"}
                               disabled={true} />
                    <NumberInput name={"count_maj"} source={"count_maj"} label={"Nombre de maj"} disabled={true} />
                    <NumberInput name={"count_maj_script"} source={"count_maj_script"} label={"Nombre de maj via script"} disabled={true} />
                </div>
                <div id={"admin_ville_info_edit"}>
                    <NumberInput name={"day_maj_scrut"} source={"day_maj_scrut"} label={"Jour MAJ scrut"} />
                    <BooleanInput name={"premiere_maj"} source={"premiere_maj"} label={"Premiere MAJ"} />
                    <BooleanInput name={"maj_all_picto"} source={"maj_all_picto"} label={"Maj picto ville faite"} />
                    <BooleanInput name={"maj_all_old_town"} source={"maj_all_old_town"} label={"Maj ville passé faite"} />
                </div>
                <div>
                    <ExpeditionButton />
                    <BatimentButton />
                    <RuineButton />
                </div>
            </div>
        
        </SimpleForm>
    </Edit>);
};