import React                        from "react";
import Tab                          from "react-bootstrap/Tab";
import Tabs                         from "react-bootstrap/Tabs";
import Cookies                      from "js-cookie";
import { EncyclopedieCitoyensType } from "../../types/components/Encyclopedie/EncyclopedieCitoyensType";
import SvgIcone                     from "../../components/generality/SvgIcone";
import { useTranslation }           from "react-i18next";

interface EncyclopedieCitoyenProps {
    citoyens: EncyclopedieCitoyensType;
}

export default function EncyCitoyens(props: EncyclopedieCitoyenProps) {
    const { t } = useTranslation();
    const storedActiveTab = Cookies.get("activeTab_ency_citoyens");
    const [activeTab, setActiveTab] = React.useState(storedActiveTab || "ame");
    
    const handleTabChange = (eventKey: string) => {
        setActiveTab(eventKey);
        Cookies.set("activeTab_ency_citoyens", eventKey, { expires: 365 });
    };
    const tabPointDame = props.citoyens.tabPointDame;
    const tabPointsClean = props.citoyens.tabPointsClean;
    const tabPointsPande = props.citoyens.tabPointsPande;
    const tabDespoir = props.citoyens.tabDespoir;
    const tabDespoirMort = props.citoyens.tabDespoirMort;
    const tabDespoirCascade = props.citoyens.tabDespoirCascade;
    const tabDespoirCascadeMortJ1 = props.citoyens.tabDespoirCascadeMortJ1;
    const tabDespoirCascadeMortJ2 = props.citoyens.tabDespoirCascadeMortJ2;
    const listHeros = props.citoyens.listHeros;
    
    return <div id={"ency_citoyens"}>
        <Tabs
            fill
            activeKey={activeTab}
            onSelect={handleTabChange}
        >
            <Tab eventKey="ame" title={t("Points d'âme", { ns: "ency" })}>
                <div id="zone_pda">
                    <div id="zonePda">
                        <p>{t("Tableau donnant le nombre de point d'âme qu'il est possible de gagner en fonction du jours survécus.", { ns: "ency" })}</p>
                        {Object.values(tabPointDame).map((ligneAme, index) => {
                            return <div className={"point_d_ame"} key={"tab_ame_" + index}>
                                <div className="pda_jour_point">
                                    <div className="pda_jour enteteTab_ency">
                                        <span className="infoBulle"><i className="fa-solid fa-calendar-days"></i><span className="info">{t("Jour de survie", { ns: "app" })}</span></span>
                                    </div>
                                    <div className="pda_point enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_ptame"} /><span className="info">{t("Nombre de point d'âme", { ns: "app" })}</span></span>
                                    </div>
                                </div>
                                {Object.values(ligneAme).map((point, key) => {
                                    return <div className="pda_jour_point" key={"tab_ame_" + index + "_" + key}>
                                        <div className="pda_jour">{key + index * Object.values(ligneAme).length}</div>
                                        <div className="pda_point">{point}</div>
                                    </div>;
                                })}
                            </div>;
                        })}
                    </div>
                </div>
            </Tab>
            <Tab eventKey="clean" title={t("Points clean", { ns: "ency" })}>
                <div id="zone_pc">
                    <div id="zonePc">
                        <p>{t("Tableau donnant le nombre de point clean qu'il est possible de gagner en fonction du nombre de jours sans avoir consommé une drogue de son existance dans la ville.", { ns: "ency" })}</p>
                        {Object.values(tabPointsClean).map((lignePc, index) => {
                            return <div className={"point_clean"} key={"tab_clean_" + index}>
                                <div className="pc_jour_point">
                                    <div className="pc_jour enteteTab_ency">
                                        <span className="infoBulle"><i className="fa-solid fa-calendar-days"></i><span className="info">{t("Jour de survie", { ns: "app" })}</span></span>
                                    </div>
                                    <div className="pc_point enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_nodrug"} /><span className="info">{t("Nombre de point clean", { ns: "app" })}</span></span>
                                    </div>
                                </div>
                                {Object.values(lignePc).map((point, key) => {
                                    return <div className="pc_jour_point" key={"tab_clean_" + index + "_" + key}>
                                        <div className="pc_jour">{key + index * Object.values(lignePc).length}</div>
                                        <div className="pc_point">{point}</div>
                                    </div>;
                                })}
                            </div>;
                        })}
                    </div>
                </div>
            </Tab>
            <Tab eventKey="surv" title={t("Points survivant de l'enfer", { ns: "ency" })}>
                <div id="zone_pp">
                    <div id="zonePp">
                        <p>{t("Tableau donnant le nombre de point survivant de l'enfer qu'il est possible de gagner en fonction du nombre de jours survécus.", { ns: "ency" })}</p>
                        {Object.values(tabPointsPande).map((lignePp, index) => {
                            return <div className={"point_pande"} key={"tab_surv_" + index}>
                                <div className="pp_jour_point">
                                    <div className="pp_jour enteteTab_ency">
                                        <span className="infoBulle"><i className="fa-solid fa-calendar-days"></i><span className="info">{t("Jour de survie", { ns: "app" })}</span></span>
                                    </div>
                                    <div className="pp_point enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_pande"} /><span className="info">{t("Nombre de point survivant de l'enfer", { ns: "app" })}</span></span>
                                    </div>
                                </div>
                                {Object.values(lignePp).map((point, key) => {
                                    return <div className="pp_jour_point" key={"tab_surv_" + index + "_" + key}>
                                        <div className="pp_jour">{key + index * Object.values(lignePp).length}</div>
                                        <div className="pp_point">{point}</div>
                                    </div>;
                                })}
                            </div>;
                        })}
                    </div>
                </div>
            </Tab>
            <Tab eventKey="mort" title={t("Mort par désespoir", { ns: "jumpEvent" })}>
                <div id="zone_kz">
                    <div id="zoneKillDespoir">
                        <h2>{t("Mort par désespoir pour le lendemain", { ns: "ency" })}</h2>
                        <p>{t("Tableau donnant le nombre de zombies à éliminer avec le nombre de zombies mort de désespoir lors de l'attaque afin d'avoir 0 zombie le lendemain.", { ns: "ency" })}</p>
                        {Object.entries(tabDespoir).map(([keyLign, ligneDes]: [string, number[]]) => {
                            return <div className={"kill_despoir"} key={"tab_kill_cascade_" + keyLign}>
                                <div className="kd_zz_kill">
                                    <div className="kd_zombie enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_dcity"} /><span className="info">{t("Nombre de zombies sur la case", { ns: "app" })}</span></span>
                                    </div>
                                    <div className="kd_kill enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_killz"} /><span className="info">{t("Nombre de zombies à éliminer pour avoir 0 zombie le lendemain", { ns: "app" })}</span></span>
                                    </div>
                                    <div className="kd_Mort enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_cgarb"} /><span className="info">{t("Nombre de zombies mort par désespoir lors de l'attaque", { ns: "app" })}</span></span>
                                    </div>
                                </div>
                                {Object.entries(ligneDes).map(([key, point]: [string, number]) => {
                                    return <div className="kd_zz_kill" key={"tab_kill_cascade_" + keyLign + "_" + key}>
                                        <div className="kd_zombie">{key}</div>
                                        <div className="kd_kill">{point}</div>
                                        <div className="kd_Mort">{tabDespoirMort[keyLign][key]}</div>
                                    </div>;
                                })}
                            </div>;
                        })}
                        <h2>{t("Mort par désespoir en cascade pour le surlendemain", { ns: "ency" })}</h2>
                        <p>{t("Tableau donnant le nombre de zombies à éliminer avec le nombre de zombies mort de désespoir lors de l'attaque et lors de l'attaque du lendemain afin d'avoir 0 zombie le surlendemain.", { ns: "ency" })}</p>
                        {Object.entries(tabDespoirCascade).map(([keyLignCas, ligneDesCas]: [string, number[]]) => {
                            return <div className={"kill_despoir"} key={"tab_kill_cascade2_" + keyLignCas}>
                                <div className="kd_zz_kill">
                                    <div className="kd_zombie enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_dcity"} /><span className="info">{t("Nombre de zombies sur la case", { ns: "app" })}</span></span>
                                    </div>
                                    <div className="kd_killCas enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_killz"} /><span className="info">{t("Nombre de zombies à éliminer pour avoir 0 zombie le surlendemain", { ns: "app" })}</span></span>
                                    </div>
                                    <div className="kd_MortJ1 enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_cgarb"} /><span className="info">{t("Nombre de zombies mort par désespoir lors de l'attaque", { ns: "app" })}</span></span>
                                    </div>
                                    <div className="kd_MortJ2 enteteTab_ency">
                                        <span className="infoBulle"><SvgIcone icone={"r_cgarb"} /><span className="info">{t("Nombre de zombies mort par désespoir lors de l'attaque du lendemain", { ns: "app" })}</span></span>
                                    </div>
                                </div>
                                {Object.entries(ligneDesCas).map(([key, point]: [string, number]) => {
                                    return <div className="kd_zz_kill" key={"tab_kill_cascade2_" + keyLignCas + "_" + key}>
                                        <div className="kd_zombie">{key}</div>
                                        <div className="kd_killCas">{Math.round(point)}</div>
                                        <div className="kd_MortJ1">{Math.round(tabDespoirCascadeMortJ1[keyLignCas][key])}</div>
                                        <div className="kd_MortJ2">{Math.round(tabDespoirCascadeMortJ2[keyLignCas][key])}</div>
                                    </div>;
                                })}
                            </div>;
                        })}
                    </div>
                </div>
            </Tab>
            <Tab eventKey="exp" title={t("Expériences héroiques", { ns: "ency" })}>
                <div id="zone_expHeros">
                    <div id="zoneExpHeros">
                        <p>{t("Tableau donnant les différents pouvoirs héros obtenables en fonction du nombre d'expérience jour héros.", { ns: "ency" })}</p>
                        <table id="ency_experience_heros">
                            <thead>
                            <tr className="lign_exp_heros_ency">
                                <th className="tab_ency_heros_img">{t("Image", { ns: "ency" })}</th>
                                <th className="tab_ency_heros_nom">{t("Nom", { ns: "ency" })}</th>
                                <th className="tab_ency_heros_desc">{t("Description", { ns: "ency" })}</th>
                                <th className="tab_ency_heros_jour">{t("Jours différences", { ns: "ency" })}</th>
                                <th className="tab_ency_heros_jourCumul">{t("Jours cumulés", { ns: "ency" })}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.values(listHeros).map((heros, index) => {
                                return <tr className="lign_exp_heros_ency" key={"heros_" + index}>
                                    <td className="tab_ency_heros_img">
                                        <div>
                                            <div className="iconItems"><SvgIcone icone={heros.icon} /></div>
                                        </div>
                                    </td>
                                    <td className="tab_ency_heros_nom">{t(heros.nom, { ns: "game" })}</td>
                                    <td className="tab_ency_heros_desc">{t(heros.description, { ns: "game" })}</td>
                                    <td className="tab_ency_heros_jour">{heros.jour}</td>
                                    <td className="tab_ency_heros_jourCumul">{heros.jour_cumul}</td>
                                </tr>;
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Tab>
        </Tabs>
    </div>;
    
}
