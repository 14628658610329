export const useCalculKm = (x: number, y: number, posX: number, posY: number) => {
    return Math.round(Math.sqrt(Math.pow((x - posX), 2) + Math.pow((posY - y), 2)));
};

export const useCalculDirection = (xNorm: number, yNorm: number): string => {
    
    const DIRECTION_VILLE = "1";
    const DIRECTION_NORD = "2";
    const DIRECTION_EST = "3";
    const DIRECTION_SUD = "4";
    const DIRECTION_OUEST = "5";
    const DIRECTION_NORD_EST = "6";
    const DIRECTION_SUD_EST = "7";
    const DIRECTION_SUD_OUEST = "8";
    const DIRECTION_NORD_OUEST = "9";
    
    
    if (xNorm === 0 && yNorm === 0) {
        return DIRECTION_VILLE;
    } else if (xNorm !== 0 && yNorm !== 0 && (Math.abs(Math.abs(xNorm) - Math.abs(yNorm)) < Math.min(Math.abs(xNorm), Math.abs(yNorm)))) {
        if (xNorm < 0 && yNorm < 0) {
            return DIRECTION_SUD_OUEST;
        }
        if (xNorm < 0 && yNorm > 0) {
            return DIRECTION_NORD_OUEST;
        }
        if (xNorm > 0 && yNorm < 0) {
            return DIRECTION_SUD_EST;
        }
        if (xNorm > 0 && yNorm > 0) {
            return DIRECTION_NORD_EST;
        }
    } else {
        if (Math.abs(xNorm) > Math.abs(yNorm) && xNorm < 0) {
            return DIRECTION_OUEST;
        }
        if (Math.abs(xNorm) > Math.abs(yNorm) && xNorm > 0) {
            return DIRECTION_EST;
        }
        if (Math.abs(xNorm) < Math.abs(yNorm) && yNorm < 0) {
            return DIRECTION_SUD;
        }
        if (Math.abs(xNorm) < Math.abs(yNorm) && yNorm > 0) {
            return DIRECTION_NORD;
        }
    }
    
    return "0";
    
};