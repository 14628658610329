export interface RGBAColor {
    r: number;
    g: number;
    b: number;
    a: number;
}

export function hexToRgba(hex: string): RGBAColor {
    // Assurez-vous que l'entrée est au format "#RRGGBBAA" ou "#RRGGBB"
    const match = hex.match(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i);
    
    if (!match) {
        throw new Error("Le format HEX n'est pas valide.");
    }
    
    const [, rHex, gHex, bHex, aHex] = match;
    
    // Convertissez les valeurs r, g, b en décimal
    const rDecimal = parseInt(rHex, 16);
    const gDecimal = parseInt(gHex, 16);
    const bDecimal = parseInt(bHex, 16);
    
    // Convertissez la valeur alpha (00-FF) en décimal (0-1)
    const aDecimal = aHex ? parseInt(aHex, 16) / 255 : 1;
    
    // Composez l'objet RGBA
    return { r: rDecimal, g: gDecimal, b: bDecimal, a: aDecimal };
}

export function rgbaToHex(rgba: RGBAColor): string {
    const { r, g, b, a } = rgba;
    
    // Convertissez les valeurs r, g, b en hexadécimal
    const rHex = Math.round(r).toString(16).padStart(2, "0");
    const gHex = Math.round(g).toString(16).padStart(2, "0");
    const bHex = Math.round(b).toString(16).padStart(2, "0");
    
    // Convertissez la valeur alpha (0-1) en hexadécimal (00-FF)
    const aHex = Math.round(a * 255).toString(16).padStart(2, "0");
    
    // Composez la chaîne HEX
    const hexColor = `#${rHex}${gHex}${bHex}${aHex}`;
    
    return hexColor.toUpperCase(); // Retournez en majuscules pour la normalisation
}