import React, {useEffect, useState} from "react";
import {useTranslation}             from "react-i18next";
import EditorComponent              from "../../../components/editor/editor";

interface PopUpConsigneProps {
    translate: {
        valider: string;
        annuler: string;
        phrase: string;
        choix: string;
    };
    texte: string;
    onChange: (texte: string) => void;
    onCancel: () => void;
    lang: string;
    listeConsignes?: string[];
}

export default function PopUpConsigne({translate, texte, onChange, onCancel, lang, listeConsignes}: PopUpConsigneProps) {
    const {t} = useTranslation();
    const [consigne, setConsigne] = useState(texte);
    const [choixConsigne, setChoixConsigne] = useState<number>(-1);
    
    useEffect(() => {
        setConsigne(texte);
    }, [texte]);
    
    const listeConsigneSorted = listeConsignes.sort((a, b) => a.localeCompare(b));
    
    return (
        <React.Fragment>
            <div id={"zone_phrase_prefaite"}>
                <label>{translate.phrase}</label>
                <select value={choixConsigne}
                        onChange={(e) => {
                            let consigneMod = consigne;
                            if (consigneMod === null || consigneMod === undefined) {
                                consigneMod = "";
                            }
                            consigneMod += listeConsigneSorted[e.target.value];
                            setConsigne(consigneMod);
                            setChoixConsigne(parseInt(e.target.value, 10));
                        }}>
                    <option value={-1}>{translate.choix}</option>
                    {listeConsigneSorted.map((consigne, index) => (
                        <option key={index} value={index}>{t(consigne, {ns: "outils"})}</option>
                    ))}
                </select>
            </div>
            <div id={"consigneZone"}>
                <EditorComponent width={635} data={consigne ?? ""} lang={lang} onTextChange={(text) => {
                    setConsigne(text);
                }}/>
            </div>
            <div id={"boutonMajConsigne"} style={{marginBottom: "10px"}}>
                <button className={"btn btn-success btn-sm"} onClick={() => onChange(consigne)}>{translate.valider}</button>
                <button className={"btn btn-warning btn-sm"} onClick={onCancel}>{translate.annuler}</button>
            </div>
        </React.Fragment>
    );
}
