import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { CitoyensApi }                from "../../services/api/CitoyensApi";
import Citoyens                       from "../../containers/Hotel/Citoyens/Citoyens";
import { useNavigate, useParams }     from "react-router-dom";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export function CitoyensGeneral() {
    const { general, setGeneral } = useGeneralContext();
    const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [citoyensData, setCitoyensData] = useState(null);
    const params = useParams();
    
    // Fonction pour recharger les données
    const reloadCitoyensData = async () => {
        const citoyensApi = new CitoyensApi(parseInt(params.mapId, 10));
        
        citoyensApi.main(params.mapId).then((response) => {
            setIsOnRefresh(false);
            setIsOnUpdate(false);
            if (response.codeRetour === 0) {
                if (response.zoneRetour.general !== undefined) {
                    setGeneral(response.zoneRetour.general);
                    sessionStorage.setItem("mapId", response.zoneRetour.general.ville ? response.zoneRetour.general.ville.map_id.toString() : null);
                }
                if (response.zoneRetour.citoyens !== undefined && response.zoneRetour.popUpMaj !== undefined) {
                    setCitoyensData(response.zoneRetour);
                } else {
                    console.error("Erreur de chargement des données citoyens", response);
                }
            } else if (response.codeRetour === 1) {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
                navigate("/news");
            }
        });
    };
    
    useEffect(() => {
        
        if (checkHabilitation("hotel", "citoyens")) {
            reloadCitoyensData().then(r => r);
        } else {
            navigate("/news");
        }
    }, [refreshKey]);
    
    return (
        <>
            {citoyensData === null ? (
                <div className={"chargement_page"}>
                    <img src={chargement} alt="Drapeau" />
                    <span>{t("Chargement...", { ns: "app" })}</span>
                </div>
            ) : (
                <Citoyens citoyens={citoyensData.citoyens} popUpMaj={citoyensData.popUpMaj} />
            )}
        
        </>
    );
}