import React              from "react";
import Pagination         from "react-bootstrap/Pagination";
import SvgRuine           from "../../components/generality/SvgRuine";
import SvgIcone           from "../../components/generality/SvgIcone";
import { CaseRuine }      from "../../types/components/ville/RuinesType";
import { useTranslation } from "react-i18next";

const numbers = Array.from({ length: 15 }, (_, index) => index);

export function MapResultExploration({ tradPorte, plan }: { tradPorte: string[], plan: CaseRuine[][][] }) {
    const [etageVisite, setEtageVisite] = React.useState(0);
    const { t } = useTranslation();
    
    const pageEtage = [];
    for (let number = 0; number <= plan.length - 1; number++) {
        pageEtage.push(
            <Pagination.Item key={number} active={number === etageVisite} onClick={() => setEtageVisite(number)}>
                {number}
            </Pagination.Item>,
        );
    }
    
    
    return <div id={"zone_recap_map"}>
        <fieldset>
            <legend>{t("Légende", { ns: "ruineGame" })}</legend>
            <div id={"zone_legend_map"}>
                <div className={"legende_map"}>
                    <div className={"caseCarteRuine"}>
                        <div className={"ruineCarte_zomb_legend ruineCarte_z_0"}></div>
                    </div>
                    <span>{t("Case explorée", { ns: "ruineGame" })}</span>
                </div>
                <div className={"legende_map"}>
                    <div className={"caseCarteRuine"}>
                        <div className={"ruineCarte_fouille"}><i className="fa-solid fa-xmark color-red"></i></div>
                        <div className={"ruineCarte_porte"}><SvgIcone icone={tradPorte["p"]} /></div>
                    </div>
                    <span>{t("Piéce non fouillée", { ns: "ruineGame" })}</span>
                </div>
                <div className={"legende_map"}>
                    <div className={"caseCarteRuine"}>
                        <div className={"ruineCarte_zomb_legend ruineCarte_z_1"}></div>
                    </div>
                    <span>{t("1 zombie", { ns: "ruineGame" })}</span>
                </div>
                <div className={"legende_map"}>
                    <div className={"caseCarteRuine"}>
                        <div className={"ruineCarte_zomb_legend ruineCarte_z_2"}></div>
                    </div>
                    <span>{t("2 zombies", { ns: "ruineGame" })}</span>
                </div>
                <div className={"legende_map"}>
                    <div className={"caseCarteRuine"}>
                        <div className={"ruineCarte_zomb_legend ruineCarte_z_3"}></div>
                    </div>
                    <span>{t("3 zombies", { ns: "ruineGame" })}</span>
                </div>
                <div className={"legende_map"}>
                    <div className={"caseCarteRuine"}>
                        <div className={"ruineCarte_zomb_legend ruineCarte_z_4"}></div>
                    </div>
                    <span>{t("4 zombies", { ns: "ruineGame" })}</span>
                </div>
            </div>
        </fieldset>
        {plan.length > 1 &&
            <div id={"etage_ruine_game_vision"}><span>{t("Voir l'étage", { ns: "ruineGame" })}</span><Pagination size="sm">{pageEtage}</Pagination></div>}
        <table>
            <tbody>
            <tr>
                <td className="bordCarteRuine"></td>
                {numbers.map((x_tab) => (
                    <td className="bordCarteRuine" key={x_tab}>{x_tab - 7}</td>
                ))}
                <td className="bordCarteRuine"></td>
            </tr>
            {Object.values(plan[etageVisite]).map((ligne, y_tab) => (
                <tr key={`${etageVisite}-${y_tab}`}>
                    <td className="bordCarteRuine">{y_tab}</td>
                    {ligne.map((caseRuine, x_tab) => (
                        <td className="caseCarteRuine" key={`${y_tab}-${x_tab}-${etageVisite}`}>
                            <div className={"caseCarteRuineD"}>
                                <SvgRuine icone={"ruineCarte_" + caseRuine.type_case} classRuine={"ruineCarte"} />
                                {caseRuine.type_case > 0 && caseRuine.nbr_zombie !== null && caseRuine.nbr_zombie > 0 && <div className={`ruineCarte_zomb ruineCarte_z_${caseRuine.nbr_zombie}`}></div>}
                                {caseRuine.type_case > 0 && caseRuine.case_vue !== null && caseRuine.case_vue && <div className={"ruineCarte_zomb ruineCarte_z_0"}></div>}
                                {caseRuine.type_porte !== null && !caseRuine.porte_fouillee && <div className={"ruineCarte_fouille"}><i className="fa-solid fa-xmark color-red"></i></div>}
                                {caseRuine.type_porte !== null && <div className={"ruineCarte_porte"}><SvgIcone icone={tradPorte[caseRuine.type_porte]} /></div>}
                                {caseRuine.type_escalier !== null && <div className={"ruineCarte_escalier"}><SvgRuine icone={caseRuine.type_escalier} classRuine={"ruineCarte " + ((caseRuine.type_escalier === "up") ? "ruine_escalier_up" : "ruine_escalier_down")} /></div>}
                            </div>
                        </td>
                    ))}
                    <td className="bordCarteRuine">{y_tab}</td>
                </tr>
            ))}
            <tr>
                <td className="bordCarteRuine"></td>
                {numbers.map((x_tab) => (
                    <td className="bordCarteRuine" key={x_tab}>{x_tab - 7}</td>
                ))}
                <td className="bordCarteRuine"></td>
            </tr>
            </tbody>
        </table>
    </div>;
    
}