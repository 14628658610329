import { ListPlansObtenu }      from "../../../types/components/Hotel/PlansChantierType";
import SvgIcone                 from "../../../components/generality/SvgIcone";
import React                    from "react";
import { ChantierPrototypeDTO } from "../../../types/models/chantierPrototype.dto";
import { useTranslation }       from "react-i18next";

export interface LignePlanChantierProps {
    chantier: ChantierPrototypeDTO,
    chantierObtenu: ListPlansObtenu[],
    onClickChantier?: (listChantier: ListPlansObtenu[]) => void,
    type: string,
    sous_type?: string
}

export default function LignePlanChantier({ chantier, chantierObtenu, onClickChantier, type, sous_type }: LignePlanChantierProps) {
    const { t } = useTranslation();
    let prefix = "";
    let disabled_form = false;
    if (sous_type !== undefined) {
        prefix = sous_type;
        disabled_form = true;
    }
    
    /*if (!disabled_form) {
     disabled_form = !props.myVille;
     }*/
    
    
    const icon: string = "item_" + prefix + "bplan_" + type;
    
    const obtenu: boolean = chantierObtenu[chantier.id_chantier] !== undefined;
    
    const parent = chantier.parent;
    
    let parentObtenu: boolean;
    let parentPlan: boolean = false;
    if (parent.plan > 0) {
        parentPlan = true;
        parentObtenu = chantierObtenu[parent.id_chantier] !== undefined;
    }
    
    const classForm = "plansSpan" + ((parentPlan && !parentObtenu) ? " blocPlan" : "");
    
    return <span className={classForm}>
        <input type={"checkbox"}
               id={"plan_chantier_" + chantier.id_chantier}
               name={"plan_chantier_" + chantier.id_chantier}
               disabled={disabled_form || (parentPlan && !parentObtenu)}
               checked={obtenu}
               onChange={(event) => {
                   const { checked } = event.target;
                   let index: number;
                   let newElement: ListPlansObtenu;
                   if (checked) {
                       index = chantier.id_chantier;
                       newElement = { chantier: chantier };
                       const updatedObj: ListPlansObtenu[] = { ...chantierObtenu, [index]: newElement };
                       
                       onClickChantier && onClickChantier(updatedObj);
                   } else {
                       index = chantier.id_chantier;
                       // eslint-disable-next-line @typescript-eslint/no-unused-vars
                       const { [index]: _, ...updatedObj } = chantierObtenu;
                       
                       onClickChantier && onClickChantier(updatedObj);
                   }
                   
               }}
        />
        <label htmlFor={"plan_chantier_" + chantier.id_chantier}><SvgIcone icone={icon} />{t(chantier.nom, { ns: "chantiers" })}</label>
    </span>;
}