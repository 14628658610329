import React, {useState}                        from "react";
import {ContactType, ImageFormData}             from "../../types/components/General/ContactType";
import TurndownService                          from "turndown";
import {ContactApi}                             from "../../services/api/ContactApi";
import ImageContact                             from "./ImageContact";
import {Status_error, Status_success, usePopUp} from "../../types/Context/PopUpContext";
import {useGeneralContext}                      from "../../types/Context/GeneralContext";
import {useTranslation}                         from "react-i18next";
import EditorComponent                          from "../../components/editor/editor";

interface ContactProps {
    contact: ContactType;
}

export function Contact({contact}: ContactProps) {
    const {t} = useTranslation();
    const [message, setMessage] = useState<string>("");
    const [titre, setTitre] = useState<string>("");
    const [selectedTags, setSelectedTags] = useState([]);
    //const [confidentiel, setConfidentiel] = useState<boolean>(false);
    const [pastedImages, setPastedImages] = useState<ImageFormData[]>([]);
    const {setStatus, setMessagePopUp, setShowPop} = usePopUp();
    const {general} = useGeneralContext();
    
    
    const handleEditorChange = (html) => {
        setMessage(html);
    };
    
    const handleCheckboxChange = (e) => {
        const {value, checked} = e.target;
        if (checked) {
            setSelectedTags([...selectedTags, value]);
        } else {
            setSelectedTags(selectedTags.filter(tag => tag !== value));
        }
    };
    
    const convertToMarkdown = () => {
        const turndownService = new TurndownService();
        // Configuration pour la conversion des listes non ordonnées
        turndownService.addRule("strike", {
            filter     : "s",
            replacement: function (content) {
                return "~~" + content + "~~";
            },
        });
        turndownService.addRule("paragraphe", {
            filter     : ["p"],
            replacement: function (content) {
                return content.trim() + "\n";
            },
        });
        turndownService.addRule("sautLigne", {
            filter     : "br",
            replacement: function () {
                return "\n\n";
            },
        });
        turndownService.addRule("quote", {
            filter     : ["blockquote"],
            replacement: function (content) {
                return "> " + content.trim() + "\n";
            },
        });
        turndownService.addRule("paragraphWithBreak", {
            filter     : function (node) {
                return node.nodeName === "P" && node.childNodes.length === 1 && node.firstChild.nodeName === "BR";
            },
            replacement: function () {
                return "\n";
            },
        });
        return turndownService.turndown(message);
    };
    
    const handleSend = () => {
        // On convertit le message en Markdown
        const markdown = convertToMarkdown();
        // Envoi du formulaire
        const contactApi = new ContactApi();
        contactApi.contactPost({titre: titre, message: markdown, tag: selectedTags, urlImage: pastedImages, confidential: false})
            .then((response) => {
                const urlForum = response.data.contact.urlForum;
                const lienMessageDiscord = t("Cliquez ici pour rejoindre votre post sur discord", {ns: "app"});
                
                const lienEnString = `<a href="${urlForum}" class="url-contact">${lienMessageDiscord}</a>`;
                setStatus(Status_success);
                setMessagePopUp(lienEnString);
                setShowPop(true);
                setMessage("");
                setTitre("");
                setSelectedTags([]);
                setPastedImages([]);
            })
            .catch((error) => {
                console.error(error);
                setStatus(Status_error);
                setMessagePopUp("Erreur lors de l'envoi du message");
                setShowPop(true);
            });
    };
    
    const calculDisabled = () => {
        if (message.length === 0) {
            return titre.length === 0 || message.length === 0 || selectedTags.length === 0;
        } else {
            const markdown = convertToMarkdown();
            return titre.length === 0 || markdown.length === 0 || selectedTags.length === 0;
        }
    };
    
    return <div id={"zone_contact_body"}>
        <h2>{t("Formulaire de contact", {ns: "app"})}</h2>
        <div>
            <label htmlFor={"titre_contact"}>{t("Titre", {ns: "app"})}<span className={"color-red"}><sup>*</sup></span> : </label>
            <input id={"titre_contact"} value={titre} onChange={(event) => setTitre(event.target.value)} placeholder={t("Votre titre ici", {ns: "app"})}/>
        </div>
        <div>
            <label htmlFor={"tag_contact"}>{t("Tag", {ns: "app"})}<span className={"color-red"}><sup>*</sup></span> : </label>
            <label>
                <input
                    type="checkbox"
                    value="Bug"
                    checked={selectedTags.includes("Bug")}
                    onChange={handleCheckboxChange}
                />
                {t("Bug", {ns: "app"})}
            </label>
            <label>
                <input
                    type="checkbox"
                    value="Suggestion"
                    checked={selectedTags.includes("Suggestion")}
                    onChange={handleCheckboxChange}
                />
                {t("Suggestion", {ns: "app"})}
            </label>
        </div>
        <div>
            <label htmlFor={"titre_contact"}>{t("Message", {ns: "app"})}<span className={"color-red"}><sup>*</sup></span> : </label>
            <EditorComponent width={1200} data={message} onTextChange={(text) => handleEditorChange(text)} lang={general.user.lang}/>
        </div>
        <fieldset>
            <legend>{t("Pièce jointe (vous pouvez collés les images dans le cadre ou les insérez via le bouton)", {ns: "app"})}</legend>
            <ImageContact imageArray={pastedImages} onImageArray={setPastedImages}/>
        </fieldset>
        {/*<div>
         <input type={"checkbox"} id={"confidentiel_contact"} checked={confidentiel} onChange={(event) => setConfidentiel(event.target.checked)} />
         <label htmlFor={"confidentiel_contact"}>Communication confidentielle</label>
         </div>*/}
        <div><span className={"color-red"}><sup>*</sup></span>{t("Saisie obligatoire", {ns: "app"})}</div>
        <div className={"color-red"}>{t("Attention, toutes les informations fournis seront visibles sur le discord de Gest'Hordes", {ns: "app"})} (<a href={general.lienDiscord} style={{textDecoration: "underline red", color: "red"}}>{t("Cliquez-ici pour rejoindre", {ns: "app"})}</a>)</div>
        <button type={"button"} onClick={handleSend} className={"btn btn-success btn-sm"} disabled={calculDisabled()}>{t("Créer un ticket", {ns: "app"})}</button>
    </div>;
}