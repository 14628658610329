import React, { useState }           from "react";
import { NiveauDifficulteArrayType } from "../../types/components/RuineGame/RuineGameType";
import HTMLParser                    from "html-react-parser";
import { useRuineGameContext }       from "./RuineGame";
import { useTranslation }            from "react-i18next";

interface RuineGameOptionRandomProps {
    liste_niveau_difficulte: NiveauDifficulteArrayType,
    onStartGame: (etage: number, typePlan: number, nbOxygene: number, nbZombie: number) => void;
}

export function RuineGameOptionRandom({ liste_niveau_difficulte, onStartGame }: RuineGameOptionRandomProps) {
    const { t } = useTranslation();
    const { isEndGame } = useRuineGameContext();
    const [etage, setEtage] = useState(1);
    const [typePlan, setTypePlan] = useState(0);
    const [nbOxygene, setNbOxygene] = useState(150);
    const [nbZombie, setNbZombie] = useState(10);
    const [voirRegle, setVoirRegle] = useState(false);
    
    return <div id={"zone_option_game"}>
        <div id={"ruine_gem_regle_jeu"}>
            {voirRegle && <div>
                <h2>{t("Règles du jeu", { ns: "ruineGame" })}</h2>
                <p><span>{t("Ce jeu vous permet d'explorer des ruines, comme vous pouvez le faire sur MyHordes, à quelques exceptions près.", { ns: "ruineGame" })}</span><span>{t("En effet, vous avez plusieurs niveaux de difficulté à disposition.", { ns: "ruineGame" })}</span></p>
                <p>
                    <span>{t("Une fois la partie lancée, vous aurez deux jauges : une d'oxygène et une d'énergie, vous permettant d'essayer de tuer des zombies (tuer n'est pas garantie).", { ns: "ruineGame" })}</span><span>{t("Une fois celle-ci à 0, vous ne pourrez plus tuer de zombie, il faudra fuir, et donc perdre de l'oxygène. Cette jauge est en fonction de vos options choisies.", { ns: "ruineGame" })}</span>
                </p>
                <p><span>{t("La partie s'arrête quand vous sortez de la ruine ou que vous n'avez plus d'oxygène. Vous pourrez ensuite refaire une nouvelle partie !", { ns: "ruineGame" })}</span><span>{t("À chaque lancement de partie, c'est une nouvelle ruine qui s'offre à vous !", { ns: "ruineGame" })}</span></p>
            </div>}
            <div><input type={"button"} className={"btn-sm btn btn-secondary"} value={voirRegle ? t("Masquer les règles du jeu", { ns: "ruineGame" }) : t("Voir les règles du jeu", { ns: "ruineGame" })}
                        onClick={() => setVoirRegle(!voirRegle)} /></div>
        
        </div>
        <hr style={{ width: "80%" }} />
        <h4>{t("Choix des options possibles :", { ns: "ruineGame" })}</h4>
        <div id={"option_ruine_game"}>
            <div className={"option_ruine_input"}>
                <label>{t("Nombre d'étage :", { ns: "ruineGame" })}</label>
                <select value={etage} onChange={(event) => setEtage(parseInt(event.target.value))}>
                    {liste_niveau_difficulte.etages.map((niveau: number, index) => {
                        return <option value={niveau} key={"niveau_" + index}>{niveau}</option>;
                    })}
                </select>
            </div>
            <div className={"option_ruine_input"}>
                <label>{t("Quantité d'oxygène :", { ns: "ruineGame" })}</label>
                <select value={nbOxygene} onChange={(event) => setNbOxygene(parseInt(event.target.value))}>
                    {liste_niveau_difficulte.oxygene.map((oxy: number, index) => {
                        return <option value={oxy} key={"oxy_" + index}>{oxy}</option>;
                    })}
                </select>
            </div>
            <div className={"option_ruine_input"}>
                <label>{t("Type de plan :", { ns: "ruineGame" })} <span className={"infoBulle"}><i className="fa-solid fa-circle-info"></i><span
                    className={"infoRuineGame"}>{HTMLParser(t("<ul><li>Plan complet : le plan est visible à côté de la ruine.</li><li>Plan autotracé : le plan se dessine au fur et à mesure de votre exploration</li><li>Sans plan : comme sur MyHordes, exploration à l'aveugle.</li></ul>", { ns: "ruineGame" }))}</span></span></label>
                <select value={typePlan} onChange={(event) => setTypePlan(parseInt(event.target.value))}>
                    {liste_niveau_difficulte.plan.map((plan: number, index) => {
                        return <option value={index} key={"plan_" + index}>{t(plan.toString(), { ns: "ruineGame" })}</option>;
                    })}
                </select>
            </div>
            <div className={"option_ruine_input"}>
                <label>{t("Nombre de zombie par étage :", { ns: "ruineGame" })}</label>
                <select value={nbZombie} onChange={(event) => setNbZombie(parseInt(event.target.value))}>
                    {liste_niveau_difficulte.zombie.map((zombie: number, index) => {
                        return <option value={zombie} key={"zombie_" + index}>{zombie}</option>;
                    })}
                </select>
            </div>
        </div>
        <div id={"zone_ruinegame_bouton"}>
            {!isEndGame && <button className={"btn btn-success"}
                                   onClick={() => onStartGame(etage, typePlan, nbOxygene, nbZombie)}>{t("Entrer dans la ruine", { ns: "ruineGame" })}</button>}
            {isEndGame &&
                <button className={"btn btn-success"} onClick={() => onStartGame(etage, typePlan, nbOxygene, nbZombie)}>{t("Relancer une partie", { ns: "ruineGame" })}</button>}
        </div>
    </div>;
}