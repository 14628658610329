import React                   from "react";
import { CarteType, PopUpMaj } from "../../../types/components/ville/CarteType";
import Carte                   from "../../Ville/Carte/Carte";
import { useGHContext }        from "../../../types/Context/GHContext";

export default function OutilsExpeditions(props: { carte: CarteType, popUpMaj: PopUpMaj }) {
    const { triggerRefresh } = useGHContext();
    
    return <div id="corpsOutilsExpedition">
        <Carte carte={props.carte} popUpMaj={props.popUpMaj} onRefresh={triggerRefresh} />
    </div>;
    
}

