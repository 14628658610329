import { Fetch }            from "../../components/generality/Fetch";
import { TourDeGuetType }   from "../../types/components/Hotel/TourDeGuetType";
import { GeneralType }      from "../../types/components/Generality/GeneralType";
import { EstimationTdgDTO } from "../../types/models/estimationTdg.dto";


export class TourDeGuetApi {
    
    private fetch: Fetch;
    
    constructor(mapId?: number) {
        this.fetch = new Fetch("tdg", mapId ?? 0);
    }
    
    public recup_estims(data: {
        mapId: number,
        userId: number,
        jour: number,
    }): Promise<RetourRecupEstimations> {
        return this.fetch.from("/recupEstims").request().post(data);
    }
    
    public sauvegarde_estims(data: {
        mapId: number,
        userId: number,
        jour: number,
        tdg: EstimationTdgDTO[],
        planif: EstimationTdgDTO[],
    }): Promise<RetourSauvegardeEstimations> {
        return this.fetch.from("/saveEstims").request().post(data);
    }
    
    public main(mapId?: string): Promise<AffichageTourDeGuet> {
        return this.fetch.from("/" + ((mapId) ? mapId : "")).request().get();
    }
}

export interface RetourRecupEstimations {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        tdg: EstimationTdgDTO[],
        planif: EstimationTdgDTO[],
        planif_cons: boolean,
    },
    
}

export interface RetourSauvegardeEstimations {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: object,
}

export interface AffichageTourDeGuet {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        tdg: TourDeGuetType
        general: GeneralType,
    },
}