import { OptionsPersoType }           from "../../types/components/OptionsPerso/OptionsPersoType";
import { GeneralType }                from "../../types/components/Generality/GeneralType";
import { FetchGH }                    from "../../components/generality/FetchGH";
import { UserDTO }                    from "../../types/models/user.dto";
import { DispoUserTypeExpeditionDTO } from "../../types/models/dispoUserTypeExpedition.dto";
import { ThemeUserDTO }               from "../../types/models/themeUser.dto";


export class OptionPersoApi {
    
    private fetchGH: FetchGH;
    
    constructor(mapId?: number) {
        this.fetchGH = new FetchGH("perso", mapId ?? 0);
    }
    
    public main(): Promise<AffichageOptionsPerso> {
        return this.fetchGH.get("/");
    }
    
    public majUser(data: { user: UserDTO, themeId: number }): Promise<RetourOptionPersoBase> {
        return this.fetchGH.post("/maj_options", data);
    }
    
    public majThemeUser(data: { userId: number, themeUser: ThemeUserDTO, themeToSelect: boolean }): Promise<RetourOptionPersoBase> {
        return this.fetchGH.post("/maj_theme_user", data);
    }
    
    public majDispoUser(data: { user_id: number, dispo_type: DispoUserTypeExpeditionDTO }): Promise<RetourDispoUserExpe> {
        return this.fetchGH.post("/maj_dispo_user", data);
    }
    
    public suppDispoUser(dispo_type_id: number): Promise<RetourSuppDispoUserExpe> {
        return this.fetchGH.delete("/suppDispoUser/" + dispo_type_id);
    }
    
    public suppThemeUser(data: { userId: number, themeUserId: number }): Promise<RetourOptionPersoBase> {
        return this.fetchGH.post("/supp_theme_user", data);
    }
    
}

export interface RetourOptionPersoBase {
    data: {
        user?: UserDTO,
        general?: GeneralType,
        libRetour?: string,
        error?: string,
    }
    status?: number,
    message?: string,
}

export interface RetourDispoUserExpe {
    data: {
        dispo?: DispoUserTypeExpeditionDTO,
        error?: string,
    }
    status?: number,
    message?: string,
}

export interface RetourSuppDispoUserExpe {
    data: {
        error?: string,
    }
    status?: number,
    message?: string,
}

export interface AffichageOptionsPerso {
    data: {
        options?: OptionsPersoType,
        general?: GeneralType,
        libRetour?: string,
        error?: string,
    }
    status?: number,
    message?: string,
}