import React from "react";
import svg   from "../../../img/sprite.svg";

export default function SvgIcone(props: { icone: string, classIcone?: string }) {
    
    let classIcone = "itemHordes";
    if (props.classIcone !== undefined) {
        classIcone = props.classIcone;
    }
    
    return <svg className={classIcone}>
        <use href={svg + "#" + props.icone} />
    </svg>;
}