import React                        from "react";
import { OutilsChantiersTypeProps } from "../../../../types/components/Outils/OutilsChantiers";
import { components }               from "react-select";
import { useOutilsChantiers }       from "../TabsOutilsChantiers";

const { SingleValue, Option, GroupHeading } = components;

export default function UpChantiers(props: { outils: OutilsChantiersTypeProps }) {
    const { outilsChantiers, setOutilsChantiers } = useOutilsChantiers();
    const outils: OutilsChantiersTypeProps = props.outils;
    
    return <div id={"upChantiers"}>
    
    </div>;
}

