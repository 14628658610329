import React                    from "react";
import { Chantier }             from "./Chantier";
import SvgIcone                 from "../../../components/generality/SvgIcone";
import { BatPrototypeDTO }      from "../../../types/models/batPrototype.dto";
import { ChantierPrototypeDTO } from "../../../types/models/chantierPrototype.dto";
import { useTranslation }       from "react-i18next";

interface ListPlansRuineProps {
    listPlans: ChantierPrototypeDTO[],
    listRuines: BatPrototypeDTO[],
}


const ListPlansRuine = ({ listPlans, listRuines }: ListPlansRuineProps) => {
    const { t } = useTranslation();
    
    const plansTrier = Object.values(listPlans).sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => {
        const aName = t(a.nom, { ns: "chantiers" }).toLowerCase();
        const bName = t(b.nom, { ns: "chantiers" }).toLowerCase();
        return aName.localeCompare(bName);
    });
    
    const plans: { [id: number]: { [plan: number]: ChantierPrototypeDTO[] } } = {};
    
    if (!plans[61]) {
        plans[61] = {};
    }
    if (!plans[62]) {
        plans[62] = {};
    }
    if (!plans[63]) {
        plans[63] = {};
    }
    
    plansTrier.map((chantier: ChantierPrototypeDTO) => {
        
        if (!plans[61][chantier.plan]) {
            plans[61][chantier.plan] = [];
        }
        if (!plans[62][chantier.plan]) {
            plans[62][chantier.plan] = [];
        }
        if (!plans[63][chantier.plan]) {
            plans[63][chantier.plan] = [];
        }
        
        if (chantier.ruine_bu) {
            plans[61][chantier.plan].push(chantier);
        }
        if (chantier.ruine_ho) {
            plans[62][chantier.plan].push(chantier);
        }
        if (chantier.ruine_hs) {
            plans[63][chantier.plan].push(chantier);
        }
    });
    
    return <div>
        {Object.values(listRuines).map((ruine: BatPrototypeDTO) => {
            return <div className={"ruine_list"} key={"plan_ruine_" + ruine.id_bat}>
                <div className={"titreRuine"} key={"plan_ruine_" + ruine.id_bat + "_nom"}>{ruine.nom}</div>
                {Object.values(plans[ruine.id_bat]).map((plans, index) => {
                    return <div className={"plans_lvl_" + (index + 2)} key={"plan_ruine_" + ruine.id_bat + "_lvl_" + index}>{plans.map(plan => {
                        
                        const chantier = new Chantier({ chantier: plan });
                        
                        return <div className={"ligne_plans_ruine"} key={"plan_ruine_" + ruine.id_bat + "_lvl_" + index + "_chantier_" + plan.id_chantier}><SvgIcone icone={chantier.getPlansRuineString(ruine.id_bat)} /><span>{t(chantier.nom, { ns: "chantiers" })}</span></div>;
                    })}</div>;
                })}
            </div>;
        })}
    </div>;
};

export default ListPlansRuine;