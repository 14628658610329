import React, { useEffect, useState } from "react";
import { ImageFormData }              from "../../types/components/General/ContactType";
import { ContactApi }                 from "../../services/api/ContactApi";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export default function ImageContact(props: { imageArray: ImageFormData[], onImageArray: (imageArray: ImageFormData[]) => void }) {
    const { t } = useTranslation();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const [imageArray, setImageArray] = useState<ImageFormData[]>([]);
    
    const handleImageAdd = (e) => {
        
        /** @var file File */
        const file = e.target.files[0];
        const type_info = file.type.split("/", 2);
        if (type_info.length < 2 || type_info[0] !== "image") {
            setStatus(Status_error);
            setMessagePopUp("error - pas image");
            setShowPop(true);
            return;
        }
        
        const reader = new FileReader();
        
        reader.onload = function() {
            const imageContact = new ContactApi();
            imageContact.creation_image({ image: reader.result, up: 1, mime: type_info[1] }).then((response) => {
                const imageArrayMod = [...imageArray];
                // on ajoute la nouvelle url à la liste des images
                imageArrayMod.push({ src: response.data.urlBanniere, legend: "" });
                setImageArray(imageArrayMod);
                props.onImageArray(imageArrayMod);
            }).catch((error) => {
                console.error(error);
                setStatus(Status_error);
                setMessagePopUp("Erreur lors de l'envoi du message");
                setShowPop(true);
            });
        };
        reader.readAsDataURL(file);
    };
    
    const handleDelete = (event, index) => {
        const imageContact = new ContactApi();
        // on duplique le tableau pour ne pas modifier l'original, et pouvoir récupérer l'image de l'index et pouvoir mettre à jour
        const imageArrayMod = [...imageArray];
        imageContact.creation_image({ up: 0, oldName: imageArrayMod[index].src }).then(() => {
            imageArrayMod.splice(index, 1);
            setImageArray(imageArrayMod);
            props.onImageArray(imageArrayMod);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error.data.error);
            setShowPop(true);
        });
    };
    
    const handleChangeLegend = (index, event) => {
        const images: ImageFormData[] = [...imageArray];
        images[index].legend = event.target.value;
        setImageArray(images);
        props.onImageArray(images);
    };
    
    const handlePaste = (e: ClipboardEvent) => {
        const items = e.clipboardData?.items;
        if (!items) {
            return;
        }
        const lastImage = items[items.length - 1];
        if (lastImage.type.indexOf("image") !== -1) {
            const blob = lastImage.getAsFile();
            if (!blob) {
                setStatus(Status_error);
                setMessagePopUp("Erreur lors de l'envoi du message");
                setShowPop(true);
            }
            
            const reader = new FileReader();
            reader.onload = () => {
                const imageContact = new ContactApi();
                const type_info = blob.type.split("/", 2);
                imageContact.creation_image({
                    image: reader.result as string,
                    up   : 1,
                    mime : type_info[1],
                }).then((response) => {
                    const imageArrayMod = [...imageArray];
                    imageArrayMod.push({ src: response.data.urlBanniere, legend: "" });
                    setImageArray(imageArrayMod);
                    props.onImageArray(imageArrayMod);
                }).catch((error) => {
                    console.error(error);
                    setStatus(Status_error);
                    setMessagePopUp("Erreur lors de l'envoi du message");
                    setShowPop(true);
                });
            };
            reader.readAsDataURL(blob);
        }
    };
    
    useEffect(() => {
        setImageArray(props.imageArray);
    }, [props.imageArray]);
    
    return <div id={"group_image_paste_insert"}>
        <div id={"zoneDropImage_contact"} onPaste={(e) => handlePaste(e as unknown as ClipboardEvent)}>
            {Object.values(imageArray).map((image, index) => (
                <div key={index} className={"zone_image_legend"}>
                    <img src={"/uploads/discord/" + image.src} alt={`Image collée ${index}`} />
                    <button
                        onClick={(event) => handleDelete(event, index)}
                        style={{
                            position    : "absolute",
                            top         : "3px",
                            right       : "3px",
                            background  : "white",
                            borderRadius: "50%",
                            color       : "red",
                            cursor      : "pointer",
                            fontSize    : "12px",
                            padding     : "2px 4px 2px 4px",
                        }}
                    >
                        &#x2716; {/* Croix rouge Unicode */}
                    </button>
                    <input value={image.legend} onChange={(event) => handleChangeLegend(index, event)} />
                </div>
            ))}
        </div>
        <div>
            <input className="elementNonVisible banniere_file_select_creation" type="file" id={"image_insert_contact"}
                   accept=".gif,.jpg,.jpeg,.jif,.jfif,.png,.webp,.bmp" onChange={(event) => handleImageAdd(event)} />
            <label className="btn btn-primary btn-sm" htmlFor={"image_insert_contact"}>{t("Insérer une image", { ns: "app" })}</label>
        </div>
    </div>;
    
}