import React, { useEffect, useState }                                    from "react";
import { useRuineContext }                                               from "./Ruine";
import { CaseRuine, DIRECTION_E, DIRECTION_N, DIRECTION_O, DIRECTION_S } from "../../../types/components/ville/RuinesType";
import RuineZoomAffichage                                                from "./Utils/RuineZoomAffichage";

export const calculCase = (type: string, direction: string, plan: CaseRuine[][][], typeCase: number, x: number, y: number, z: number) => {
    let typeCaseMod = typeCase;
    
    if (typeCaseMod === 17) {
        typeCaseMod = 0;
    }
    
    const updatedPlan = [...plan];
    
    if (type === "add") {
        switch (direction) {
            case "haut":
                typeCaseMod += DIRECTION_N;
                break;
            case "bas":
                typeCaseMod += DIRECTION_S;
                break;
            case "gauche":
                typeCaseMod += DIRECTION_O;
                break;
            case "droite":
                typeCaseMod += DIRECTION_E;
                break;
        }
    } else {
        switch (direction) {
            case "haut":
                typeCaseMod -= DIRECTION_N;
                break;
            case "bas":
                typeCaseMod -= DIRECTION_S;
                break;
            case "gauche":
                typeCaseMod -= DIRECTION_O;
                break;
            case "droite":
                typeCaseMod -= DIRECTION_E;
                break;
        }
    }
    // on récupère la case de coordonnée x, y pour mettre à jour la case actuelle
    const caseActuelle = updatedPlan[z][y][x];
    // On met à jour les propriétés spécifiées
    // On remplace la case d'origine par la nouvelle version dans le tableau cloné
    updatedPlan[z][y][x] = { ...caseActuelle, ...{ type_case: typeCaseMod } };
    
    // On détermine la case adjacente dans la direction, si nous ne sommes pas sur les bords de la ruine
    if (!((x === 0 && direction === "gauche") || (x === 15 && direction === "droite") || (y === 0 && direction === "haut") || (y === 13 && direction === "bas"))) {
        let ySuivante = y;
        let xSuivante = x;
        let zombieSuivante: number | null;
        switch (direction) {
            case "bas":
                ySuivante++;
                break;
            case "haut":
                ySuivante--;
                break;
            case "gauche":
                xSuivante--;
                break;
            case "droite":
                xSuivante++;
                break;
        }
        // on récupère la case de coordonnée xSuivante, ySuivante pour mettre à jour la case suivante
        const caseSuivante = updatedPlan[z][ySuivante][xSuivante];
        
        let typeCaseSuivante = caseSuivante.type_case;
        
        if (typeCaseSuivante > 17) {
            typeCaseSuivante -= 17;
        }
        
        if (type === "add") {
            switch (direction) {
                case "haut":
                    typeCaseSuivante += DIRECTION_S;
                    break;
                case "bas":
                    typeCaseSuivante += DIRECTION_N;
                    break;
                case "gauche":
                    typeCaseSuivante += DIRECTION_E;
                    break;
                case "droite":
                    typeCaseSuivante += DIRECTION_O;
                    break;
            }
            zombieSuivante = caseSuivante.nbr_zombie;
        } else {
            switch (direction) {
                case "haut":
                    typeCaseSuivante -= DIRECTION_S;
                    break;
                case "bas":
                    typeCaseSuivante -= DIRECTION_N;
                    break;
                case "gauche":
                    typeCaseSuivante -= DIRECTION_E;
                    break;
                case "droite":
                    typeCaseSuivante -= DIRECTION_O;
                    break;
            }
            if (typeCaseSuivante === 0) {
                zombieSuivante = null;
            } else {
                zombieSuivante = caseSuivante.nbr_zombie;
            }
        }
        if (caseSuivante.nbr_zombie === null) {
            if (updatedPlan[z][ySuivante][xSuivante].type_case === 0) {
                typeCaseSuivante += 17;
            }
        }
        
        // On met à jour les propriétés spécifiées
        // On remplace la case d'origine par la nouvelle version dans le tableau cloné
        updatedPlan[z][ySuivante][xSuivante] = { ...caseSuivante, ...{ type_case: typeCaseSuivante, nbr_zombie: zombieSuivante } };
        
    }
    
    return {
        updatedPlan,
        typeCaseMod,
    };
    
};

export const directionMap = {
    1 : ["direction", "plus", "plus", "plus"],
    2 : ["plus", "plus", "direction", "plus"],
    3 : ["direction", "plus", "direction", "plus"],
    4 : ["plus", "direction", "plus", "plus"],
    5 : ["direction", "direction", "plus", "plus"],
    6 : ["plus", "direction", "direction", "plus"],
    7 : ["direction", "direction", "direction", "plus"],
    8 : ["plus", "plus", "plus", "direction"],
    9 : ["direction", "plus", "plus", "direction"],
    10: ["plus", "plus", "direction", "direction"],
    11: ["direction", "plus", "direction", "direction"],
    12: ["plus", "direction", "plus", "direction"],
    13: ["direction", "direction", "plus", "direction"],
    14: ["plus", "direction", "direction", "direction"],
    15: ["direction", "direction", "direction", "direction"],
    16: ["ruineEntry", "ruineEntry", "ruineEntry", "direction"],
    17: ["plus", "plus", "plus", "plus"],
};

export default function RuineZoom() {
    const [aff, setAff] = useState({ gauche: "", droite: "", haut: "", bas: "" });
    const { plan, setPlan, x, setX, y, setY, z } = useRuineContext();
    const caseRuine = plan[z][y][x];
    const [typeCase, setTypeCase] = useState(caseRuine.type_case);
    
    const calculDirection = (direction: string) => {
        
        let yMod = y;
        let xMod = x;
        
        switch (direction) {
            case "bas":
                yMod++;
                break;
            case "haut":
                yMod--;
                break;
            case "gauche":
                xMod--;
                break;
            case "droite":
                xMod++;
                break;
        }
        
        
        const updatedPlan = [...plan];
        
        // on récupère la case de coordonnée x, y
        const caseSuivante = updatedPlan[z][yMod][xMod];
        const typeCaseSuivante = caseSuivante.type_case;
        
        if ((typeCaseSuivante === 0 && updatedPlan[z][y][x].type_case === 16) || typeCaseSuivante > 17 || caseSuivante.nbr_zombie === null) {
            
            let typeCaseMod: number = typeCaseSuivante;
            if (typeCaseSuivante > 17) {
                typeCaseMod -= 17;
            } else if (typeCaseSuivante === 0 && updatedPlan[z][y][x].type_case === 16) {
                typeCaseMod = 2;
            }
            
            
            // On met à jour les propriétés spécifiées
            // On remplace la case d'origine par la nouvelle version dans le tableau cloné
            updatedPlan[z][yMod][xMod] = { ...caseSuivante, ...{ type_case: typeCaseMod, nbr_zombie: 0 } };
            
            setPlan(updatedPlan);
            setTypeCase(typeCaseMod);
        } else {
            setTypeCase(caseSuivante.type_case);
        }
        setX(xMod);
        setY(yMod);
    };
    
    const calculCaseSpecifique = (type: string, direction: string) => {
        const retour = calculCase(type, direction, plan, typeCase, x, y, z);
        setPlan(retour.updatedPlan);
        setTypeCase(retour.typeCaseMod);
    };
    
    
    const calculAffichage = () => {
        let typeCaseMod = typeCase;
        let affGaucheMod = aff.gauche;
        let affDroiteMod = aff.droite;
        let affHautMod = aff.haut;
        let affBasMod = aff.bas;
        
        if (typeCaseMod > 17) {
            typeCaseMod -= 17;
        }
        
        // On détermine les affichages possibles
        [affGaucheMod, affDroiteMod, affHautMod, affBasMod] = directionMap[typeCaseMod] || [];
        
        setAff({ gauche: (x === 0) ? "" : affGaucheMod, droite: (x === 14) ? "" : affDroiteMod, haut: (y === 0) ? "" : affHautMod, bas: (y === 13) ? "" : affBasMod });
        setTypeCase(typeCaseMod);
    };
    
    useEffect(() => {
        calculAffichage();
    }, [typeCase, x, y, z]);
    
    useEffect(() => {
        setTypeCase(plan[z][y][x].type_case);
    }, [plan[z][y][x].type_case]);
    
    const calculClasseAffichage = (typeAffichage: string, direction: string, classString: string, typeCase?: number) => {
        if (aff[direction] === "entry") {
            return "ruineEntry " + classString;
        } else {
            if (typeCase === undefined) {
                if (typeAffichage === "plus" && caseRuine.type_case === 16) {
                    if (aff[direction] === "direction") {
                        return "ruineEntry " + classString;
                    } else {
                        return ((aff[direction] === "plus") ? "" : "ruineMasque ") + classString;
                    }
                } else {
                    return ((aff[direction] === typeAffichage) ? "" : "ruineMasque ") + classString;
                }
            } else {
                if (aff[direction] === "direction" && (caseRuine.type_case !== typeCase || (caseRuine.type_escalier !== null && z > 0))) {
                    return classString;
                } else {
                    return ((caseRuine.type_case === typeCase) ? "ruineCache " : "ruineMasque ") + classString;
                }
            }
        }
        
    };
    
    return <RuineZoomAffichage plan={plan} x={x} y={y} z={z} calculClasseAffichage={calculClasseAffichage} calculDirection={calculDirection} calculCase={calculCaseSpecifique} />;
    
}