import * as React                                                                                                                               from "react";
import { ArrayInput, Datagrid, DateTimeInput, Edit, List, NumberInput, SimpleForm, SimpleFormIterator, TextField, TextInput, useRecordContext } from "react-admin";
import { Link, useParams }                                                                                                                      from "react-router-dom";
import { Button }                                                                                                                               from "@mui/material";
import { BiEditAlt }                                                                                                                            from "react-icons/bi";


export const RuineList = (props) => {
    
    const { id } = useParams();
    
    /*const postFilters = [
     <TextInput source="q" label="Search" alwaysOn/>,
     <ReferenceInput source="id" label="Map Id" reference="ville"/>,
     ];*/
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition des Ruines de la ville {record ? `"${record.ville.nom}" - ${record.ville.id}` : ""}</span>;
    };
    
    
    //return <List {...props} filters={postFilters}>
    return <List {...props} title={<PostTitle />} resource={"ville_ruine"} filter={{ villeId: id }}>
        <Datagrid>
            <TextField source="id" />
            <TextField source={"bat.nom"} />
            <TextField source={"nombre_plan"} />
            <EditRuineButton />
        </Datagrid>
    </List>;
};


const EditRuineButton = () => {
    const ruine = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/admin/ville/${ruine?.ville.id}/ruine/${ruine?.id}`}
            color="primary"
        >
            <BiEditAlt />
            Editer
        </Button>
    );
};

const ListCasesButton = () => {
    const params = useParams();
    return (
        <Button
            component={Link}
            to={`/admin/ville/${params.id}/ruine/${params.ruine_id}/cases`}
            color="primary"
        >
            Cases
        </Button>
    );
};
export const RuineEdit = (props) => {
    
    const { id, ruine_id } = useParams();
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition de la ruine {record ? `"${record.bat.nom}" - ${record.id}` : ""}</span>;
    };
    
    
    return (<Edit {...props} title={<PostTitle />} resource={"ville_ruine"} id={ruine_id} redirect={`admin/ville/${id}/ruine`}>
        <SimpleForm>
            <div id={"admin_zone_ville_info"}>
                <h2>Info Ruine</h2>
                <div id={"admin_ville_info_disable"}>
                    <TextInput name={"bat"} source={"bat"} format={(value) => (value ? value.nom : "")} label={"Bâtiment"} disabled={true} />
                    <NumberInput source={"x"} label={"X"} disabled={true} />
                    <NumberInput source={"y"} label={"Y"} disabled={true} />
                </div>
                <div id={"admin_ville_info_edit"}>
                    <ArrayInput source="plans">
                        <SimpleFormIterator inline={true}>
                            <TextInput source="create_by.pseudo" label={"Créateur"} disabled={true} />
                            <DateTimeInput source="created_at" label={"Date création"} disabled={true} />
                            <TextInput source="modify_by.pseudo" label={"Modificateur"} disabled={true} />
                            <DateTimeInput source="modify_at" label={"Créateur"} disabled={true} />
                            <TextInput source="trace_safe" label={"Fil d'Ariane"} />
                            <ListCasesButton />
                        </SimpleFormIterator>
                    </ArrayInput>
                </div>
            </div>
        </SimpleForm>
    </Edit>);
};