import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { StatistiqueApi }             from "../../services/api/StatistiqueApi";
import Statistiques                   from "../../containers/Hotel/Statistique/Statistiques";
import { useNavigate, useParams }     from "react-router-dom";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export function StatistiquesGeneral() {
    const { general, setGeneral } = useGeneralContext();
    const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const [statsData, setStatsData] = useState(null);
    const params = useParams();
    
    // Fonction pour recharger les données
    const reloadStatsData = async () => {
        const statistiquesApi = new StatistiqueApi(parseInt(params.mapId) ?? 0);
        
        statistiquesApi.main(params.mapId).then((response) => {
            setIsOnRefresh(false);
            setIsOnUpdate(false);
            if (response.codeRetour === 0) {
                if (response.zoneRetour.general !== undefined) {
                    setGeneral(response.zoneRetour.general);
                    sessionStorage.setItem("mapId", response.zoneRetour.general.ville ? response.zoneRetour.general.ville.map_id.toString() : null);
                }
                if (response.zoneRetour.stats === undefined) {
                    console.error("Erreur de chargement des données statistiques ville", response);
                } else {
                    setStatsData(response.zoneRetour.stats);
                }
            } else if (response.codeRetour === 1) {
                setStatus(Status_error);
                setShowPop(true);
                setMessagePopUp(response.libRetour);
                navigate("/news");
            }
        });
    };
    
    useEffect(() => {
        
        if (checkHabilitation("hotel", "stats")) {
            reloadStatsData().then(r => r);
        } else {
            navigate("/news");
        }
    }, [refreshKey]);
    
    return (
        <>
            {statsData === null ? (
                <div className={"chargement_page"}>
                    <img src={chargement} alt="Drapeau" />
                    <span>{t("Chargement...", { ns: "app" })}</span>
                </div>
            ) : (
                <Statistiques stats={statsData} />
            )}
        
        </>
    );
}