import React, {useEffect, useRef, useState} from "react";
import SvgDrapeau                           from "../../components/generality/SvgDrapeau";
import {GeneralType}                        from "../../types/components/Generality/GeneralType";
import {GeneralApi}                         from "../../services/api/GeneralApi";
import {useGeneralContext}                  from "../../types/Context/GeneralContext";
import {useGHContext}                       from "../../types/Context/GHContext";
import "../../config/i18n"; // Charger la configuration i18n
import i18n                                 from "../../config/i18n";

export function LanguageSelector() {
    const wrapperRef = useRef(null); // La référence à l'élément racine
    const {general, setGeneral} = useGeneralContext();
    const {triggerRefresh} = useGHContext();
    const [openList, setOpenList] = useState(false);
    
    const changeLanguage = (lang) => {
        const generalMod: GeneralType = {
            ...general,
            lang: lang,
        };
        
        const generalApi = new GeneralApi();
        generalApi.majLang(lang).then((response) => {
            if (response.ok) {
                // Utilisez la fonction de mise à jour avec le nouvel état
                i18n.changeLanguage(lang);
                setGeneral(generalMod);
                setOpenList(false);
                triggerRefresh();
            }
        });
        
        
    };
    
    // Fonction pour fermer la liste si on clique en dehors
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpenList(false);
        }
    };
    
    useEffect(() => {
        if (openList) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        
        // Clean up
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openList]); // Se réexécute seulement si openList change
    
    
    return (
        <div className="select_wrap" ref={wrapperRef}>
            <div onClick={() => setOpenList(openList => !openList)}><SvgDrapeau drapeau={general.lang} classIcone={"langue"}/></div>
            {openList && <ul>
                <li onClick={() => changeLanguage("de")}><SvgDrapeau drapeau={"de"} classIcone={"langue"}/></li>
                <li onClick={() => changeLanguage("en")}><SvgDrapeau drapeau={"en"} classIcone={"langue"}/></li>
                <li onClick={() => changeLanguage("es")}><SvgDrapeau drapeau={"es"} classIcone={"langue"}/></li>
                <li onClick={() => changeLanguage("fr")}><SvgDrapeau drapeau={"fr"} classIcone={"langue"}/></li>
            </ul>}
        </div>
    );
}
