import React, { useEffect, useState }             from "react";
import { CoalitionPropsType }                     from "../../../types/components/Jump/CoalitionPropsType";
import Tabs                                       from "react-bootstrap/Tabs";
import Cookies                                    from "js-cookie";
import ListingCoalitions                          from "./ListingCoalitions";
import Tab                                        from "react-bootstrap/Tab";
import RepartitionCoalitions                      from "./RepartitionCoalitions";
import { Status_error, Status_success, usePopUp } from "../../../types/Context/PopUpContext";
import { useTranslation }                         from "react-i18next";

export default function Coalition(props: { coalition: CoalitionPropsType }) {
    const { t } = useTranslation();
    
    const storedActiveTab = Cookies.get("activeTab_coalition_jump");
    
    const [activeTab, setActiveTab] = useState(storedActiveTab || "coa");
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    
    const [jump, setJump] = useState(props.coalition.jump);
    const [listInscription, setListInscription] = useState(
        Object.values(props.coalition.jump.inscription_jumps).filter((inscription) => inscription.statut.id === props.coalition.options.statut_acc));
    
    useEffect(() => {
        setJump(props.coalition.jump);
        setListInscription(Object.values(props.coalition.jump.inscription_jumps).filter((inscription) => {
            return inscription.statut.id === props.coalition.options.statut_acc;
        }));
    }, [props.coalition]);
    
    const handleTabChange = (eventkey) => {
        setActiveTab(eventkey);
        Cookies.set("activeTab_coalition_jump", eventkey, { expires: 365 });
    };
    
    const onRetour = (isErrorCoalition: boolean, showModCoalition: boolean, messagePopCoalition: string) => {
        setStatus(isErrorCoalition ? Status_error : Status_success);
        setShowPop(showModCoalition);
        setMessagePopUp(messagePopCoalition);
        if (!isErrorCoalition) {
            setTimeout(() => {
                setShowPop(false);
                setMessagePopUp("");
            }, 1500);
        }
    };
    
    return <div id="infoCoa">
        <div id={"zone_total_coa"}>
            <Tabs activeKey={activeTab} onSelect={handleTabChange}>
                <Tab eventKey={"coa"} title={t("Coalition", { ns: "jumpEvent" })}>
                    <ListingCoalitions coalition={props.coalition} jump={jump} listInscription={listInscription} onRetour={onRetour} onJumpMaj={setJump} />
                </Tab>
                <Tab eventKey={"repartition"} title={t("Repartition", { ns: "jumpEvent" })}>
                    <RepartitionCoalitions coalition={props.coalition} jump={jump} listInscription={listInscription} onRetour={onRetour} onChangeJump={setJump}
                                           onChangeListInscrit={setListInscription} />
                </Tab>
            </Tabs>
        </div>
    </div>;
    
    
}

