import { Fetch }                                     from "../../components/generality/Fetch";
import { GeneralType }                               from "../../types/components/Generality/GeneralType";
import { FetchGH }                                   from "../../components/generality/FetchGH";
import { ContactPost, ContactType, ContactTypePost } from "../../types/components/General/ContactType";


export class ContactApi {
    
    private fetch: Fetch;
    private fetchGH: FetchGH;
    
    constructor() {
        this.fetch = new Fetch("contact");
        this.fetchGH = new FetchGH("contact");
    }
    
    
    public contact(): Promise<RetourContacts> {
        return this.fetchGH.get("/contact");
    }
    
    public contactPost(data: ContactPost): Promise<RetourContactsPost> {
        return this.fetchGH.post("/contact", data);
    }
    
    public creation_image(data: { image?: any, up: number, mime?: any, oldName?: any }): Promise<RetourImage> {
        return this.fetchGH.post("/creation_image", data);
    }
    
    
}

export interface RetourContacts {
    data: {
        general?: GeneralType,
        contact?: ContactType,
        error?: string,
    }
    status?: number,
    message?: string,
}

export interface RetourContactsPost {
    data: {
        general?: GeneralType,
        contact?: ContactTypePost,
        error?: string,
    }
    status?: number,
    message?: string,
}

export interface RetourImage {
    data: {
        urlBanniere?: string,
        error?: string,
    }
    status?: number,
    message?: string,
}