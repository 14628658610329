import { createContext, useContext } from "react";
import { GHContextType }             from "./GHContextType";

export const GHContext = createContext<GHContextType>({
    checkHabilitation: (_niveau1, _niveau2): boolean => {
        return true;
    },
    handleClose      : () => {
    },
    refreshKey       : 0,
    triggerRefresh   : () => {
    },
    triggerUpdate    : () => {
    },
    isOnUpdate       : false,
    setIsOnUpdate    : () => {
    },
    isOnRefresh      : false,
    setIsOnRefresh   : () => {
    },
});

export function useGHContext() {
    return useContext(GHContext);
}