import { Fetch }                             from "../../components/generality/Fetch";
import { CitoyensType, PopUpMajCoffreProps } from "../../types/components/Hotel/Citoyens";
import { ParameterizedNamedSQL }             from "react-querybuilder/dist/cjs/react-querybuilder.cjs.development";
import { GeneralType }                       from "../../types/components/Generality/GeneralType";
import { CitoyensDTO }                       from "../../types/models/citoyens.dto";


export class CitoyensApi {
    
    private fetch: Fetch;
    
    constructor(mapId?: number) {
        this.fetch = new Fetch("citoyens", mapId ?? 0);
    }
    
    public save_citoyen(data: {
        mapId: number,
        userId: number,
        citoyens: CitoyensDTO,
        nameForm: string,
    }): Promise<RetourSaveCitoyen> {
        return this.fetch.from("/majCitoyens").request().post(data);
    }
    
    public filtre_citoyen(data: {
        mapId: number,
        userId: number,
        filtre: ParameterizedNamedSQL
    }): Promise<RetourFiltreCitoyen> {
        return this.fetch.from("/filtre").request().post(data);
    }
    
    public main(mapId?: string): Promise<AffichageCitoyens> {
        return this.fetch.from("/" + ((mapId) ? mapId : "")).request().get();
    }
    
}

export type RetourSaveCitoyen = {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        citoyen: CitoyensDTO
    },
}

export type RetourFiltreCitoyen = {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        citoyen: CitoyensDTO[]
    },
}

export type AffichageCitoyens = {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        citoyens: CitoyensType,
        popUpMaj: PopUpMajCoffreProps,
        general: GeneralType,
    },
}