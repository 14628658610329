import React    from "react";
import colormap from "colormap";

export default function ColorMapVisu(props: { colormapName: string }) {
    
    const colormap_carte = colormap({
        colormap: props.colormapName,
        nshades : 20,
        format  : "rgbaString",
        alpha   : 1,
    });
    
    return <div className={"colorMapVisu"}>
        <span style={{ backgroundColor: colormap_carte[0] }}></span>
        <span style={{ backgroundColor: colormap_carte[1] }}></span>
        <span style={{ backgroundColor: colormap_carte[2] }}></span>
        <span style={{ backgroundColor: colormap_carte[3] }}></span>
        <span style={{ backgroundColor: colormap_carte[4] }}></span>
        <span style={{ backgroundColor: colormap_carte[5] }}></span>
        <span style={{ backgroundColor: colormap_carte[6] }}></span>
        <span style={{ backgroundColor: colormap_carte[7] }}></span>
        <span style={{ backgroundColor: colormap_carte[8] }}></span>
        <span style={{ backgroundColor: colormap_carte[9] }}></span>
        <span style={{ backgroundColor: colormap_carte[10] }}></span>
        <span style={{ backgroundColor: colormap_carte[11] }}></span>
        <span style={{ backgroundColor: colormap_carte[12] }}></span>
        <span style={{ backgroundColor: colormap_carte[13] }}></span>
        <span style={{ backgroundColor: colormap_carte[14] }}></span>
        <span style={{ backgroundColor: colormap_carte[15] }}></span>
        <span style={{ backgroundColor: colormap_carte[16] }}></span>
        <span style={{ backgroundColor: colormap_carte[17] }}></span>
        <span style={{ backgroundColor: colormap_carte[18] }}></span>
        <span style={{ backgroundColor: colormap_carte[19] }}></span>
    </div>;
}