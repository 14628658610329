import { Fetch }       from "../../components/generality/Fetch";
import { JournalType } from "../../types/components/Hotel/JournalType";
import { GeneralType } from "../../types/components/Generality/GeneralType";


export class JournalApi {
    
    private fetch: Fetch;
    
    constructor(mapId?: number) {
        this.fetch = new Fetch("journal", mapId ?? 0);
    }
    
    public main(mapId?: string): Promise<AffichageJournal> {
        return this.fetch.from("/" + ((mapId) ? mapId : "")).request().get();
    }
    
}

export type AffichageJournal = {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        journal: JournalType
        general: GeneralType,
    },
}