import Cookies                                    from "js-cookie";
import React, {useEffect, useState}               from "react";
import ExpeditionModule                           from "../../Outils/Expedition/ExpeditionModule";
import SvgIcone                                   from "../../../components/generality/SvgIcone";
import {CarteType, CategoryObjetCarte, ItemCount} from "../../../types/components/ville/CarteType";
import {Expedition}                               from "../../../types/components/ville/Expedition";
import {ParamCarte}                               from "../../../types/components/ville/ParamCarte";
import {SelectedArray}                            from "../../../types/components/ville/SelectedArray";
import {useCalculKm}                              from "./calculCarte";
import Form                                       from "react-bootstrap/Form";
import ModuleOutilsExpeditions                    from "../../Outils/Expedition/ModuleOutilsExpeditions";
import Modal                                      from "react-bootstrap/Modal";
import InformationBatiment                        from "../../Encyclopedie/Batiments/InformationBatiment";
import {ChantierPrototypeDTO}                     from "../../../types/models/chantierPrototype.dto";
import {CitoyensDTO}                              from "../../../types/models/citoyens.dto";
import {UpChantierDTO}                            from "../../../types/models/upChantier.dto";
import {ZoneMapDTO}                               from "../../../types/models/zoneMap.dto";
import InputNumber                                from "rc-input-number";


import {useTranslation}      from "react-i18next";
import {useNormalizedSearch} from "../../../services/hook/useNormalizedSearch";

interface MenuCarteProps {
    paramCarte: ParamCarte,
    carte: CarteType,
    mode_expe: boolean,
    expeManuel: Expedition,
    selectedCarteArray: SelectedArray,
    onUpdateParamCarte: (paramCarte) => void,
    onUpdateSelectionCarte: (any) => void,
    onUpdateModeExpe: (mode_expe: boolean) => void,
    onUpdateExpedition: (expedition: Expedition, retracage: boolean) => void,
    onEditExpedition: (idExpe: string) => void,
    onMajScrut: () => void,
    onRefresh: () => void,
    onSaveExpedition: (expedition: Expedition) => void,
    onSuppExpedition: (idExpe: string) => void,
    onApplyFiltre: (isFiltred: boolean, type_filtre: number, min: number, max: number) => void,
    isApiCallInProgress: boolean,
    onMajEstimDay: (day: number) => void,
    onShowCase: (idCase: string) => void,
}

interface Coordonnees {
    x: number,
    y: number,
}

export default function MenuCarte(props: MenuCarteProps) {
    const {t} = useTranslation();
    const carte: CarteType = props.carte;
    
    const selectOngletCookie = Cookies.get("selectedOngletCarte");
    const [idxSelected, setIdxSelected] = useState<string>(selectOngletCookie || "ville");
    const [paramCarte, setParamCarte] = useState<ParamCarte>(props.paramCarte);
    const [menuPinned, setMenuPinned] = useState<boolean>(true);
    //const [dragDropState,setDragDropState] = useState<"dragging" | "dropped">("dropped");
    const [selectedCarte, setSelectedCarte] = useState<SelectedArray>(props.selectedCarteArray);
    const [searchItem, setSearchItem] = useState<string>("");
    const [isApiCallInProgress, setIsApiCallInProgress] = useState<boolean>(props.isApiCallInProgress);
    const [isFiltred, setIsFiltred] = useState<boolean>(false);
    const [filtre_min, setFiltre_min] = useState<number>(0);
    const [filtre_max, setFiltre_max] = useState<number>(props.carte.maxKm);
    const [typeFiltre, setTypeFiltre] = useState<number>(0);
    const [statsZone, setStatsZone] = useState<number>(99);
    const [showModalBat, setShowModalBat] = useState<boolean>(false);
    const [idBatShow, setIdBatShow] = useState<number>(null);
    const [coordonnees, setCoordonnees] = useState<Coordonnees>({x: 0, y: 0});
    const {normalizeSearch} = useNormalizedSearch();
    
    const nbrBatJauneTotal = Object.keys(carte.batCarte.batJaune).length;
    const nbrBatBleuTotal = Object.keys(carte.batCarte.batBleu).length;
    
    const tabsMenu = Object.entries({
        ville     : t("Info Ville", {ns: "ville"}),
        citoyen   : t("Citoyens", {ns: "ville"}),
        objet     : t("Objets", {ns: "ville"}),
        batiment  : t("Bâtiments", {ns: "ville"}),
        expe      : t("Expéditions", {ns: "ville"}),
        outilsExpe: t("Gestion Expéditions", {ns: "ville"}),
        param     : t("Paramètres", {ns: "ville"}),
        outils    : t("Outils", {ns: "ville"}),
    });
    
    const citoyensOutWithBorderCount = Math.ceil((carte.citoyensDehors.length - 10) / 10) * 10;
    const citoyensInWithBorderCount = Math.ceil((carte.citoyensVille.length - 10) / 10) * 10;
    
    useEffect(() => {
        if (props.isApiCallInProgress !== isApiCallInProgress) {
            setIsApiCallInProgress(props.isApiCallInProgress);
        }
    }, [props.isApiCallInProgress]);
    
    useEffect(() => {
        if (props.paramCarte !== paramCarte) {
            setParamCarte(props.paramCarte);
        }
    }, [props.paramCarte]);
    
    useEffect(() => {
        if (props.selectedCarteArray !== selectedCarte) {
            setSelectedCarte(props.selectedCarteArray);
        }
    }, [props.selectedCarteArray]);
    
    useEffect(() => {
        if (props.carte.maxKm !== filtre_max) {
            setFiltre_max(props.carte.maxKm);
        }
    }, [props.carte.maxKm]);
    
    useEffect(() => {
        if (props.carte.listCategorie !== props.carte.listCategorie) {
            setIsFiltred(false);
            setFiltre_min(0);
            setFiltre_max(props.carte.maxKm);
            setTypeFiltre(0);
        }
    }, [props.carte.listCategorie]);
    
    /*const handleFiltreActiveDesactive = () => {
     if (!isFiltred) {
     setFiltre_min(0);
     setFiltre_max(props.carte.maxKm);
     setTypeFiltre(0);
     props.onApplyFiltre(isFiltred, 0, 0, props.carte.maxKm);
     }
     };*/
    
    const handleOngletChange = (onglet: string) => {
        let valeur = onglet;
        if (idxSelected === onglet) {
            valeur = null;
        }
        setIdxSelected(valeur);
        const expirationDate = new Date();
        expirationDate.setDate(
            expirationDate.getDate() + 365);
        Cookies.set("selectedOngletCarte",
            valeur, {
                expires: expirationDate,
                path   : "/",
                secure : true,
            });
    };
    
    const handleMouseUp = () => {
        //setDragDropState("dropped");
        document.onmousemove = null;
    };
    
    const handleParamChange = (event) => {
        const {name, checked} = event.target;
        const newParamCarte = {...paramCarte, [name]: checked};
        setParamCarte(newParamCarte);
        props.onUpdateParamCarte(newParamCarte);
    };
    
    const handleKmChange = (event) => {
        const {value, checked} = event.target;
        const newArrKm = paramCarte.arrKm;
        const valueInt = parseInt(value, 10);
        if (checked && !newArrKm.includes(valueInt)) {
            newArrKm.push(valueInt);
        } else if (!checked && newArrKm.includes(valueInt)) {
            newArrKm.splice(newArrKm.indexOf(valueInt), 1);
        }
        const newParamCarte = {...paramCarte, arrKm: newArrKm};
        setParamCarte(newParamCarte);
        props.onUpdateParamCarte(newParamCarte);
    };
    
    const handlePaChange = (event) => {
        const {value, checked} = event.target;
        const newArrPa = paramCarte.arrPa;
        const valueInt = parseInt(value, 10);
        if (checked && !newArrPa.includes(valueInt)) {
            newArrPa.push(valueInt);
        } else if (!checked && newArrPa.includes(valueInt)) {
            newArrPa.splice(newArrPa.indexOf(valueInt), 1);
        }
        const newParamCarte = {...paramCarte, arrPa: newArrPa};
        setParamCarte(newParamCarte);
        props.onUpdateParamCarte(newParamCarte);
    };
    
    const handleZonageChange = (event) => {
        const {value, checked} = event.target;
        const newArrZonage = paramCarte.arrZonage;
        const valueInt = parseInt(value, 10);
        if (checked && !newArrZonage.includes(valueInt)) {
            newArrZonage.push(valueInt);
        } else if (!checked && newArrZonage.includes(valueInt)) {
            newArrZonage.splice(newArrZonage.indexOf(valueInt), 1);
        }
        const newParamCarte = {...paramCarte, arrZonage: newArrZonage};
        setParamCarte(newParamCarte);
        props.onUpdateParamCarte(newParamCarte);
    };
    
    const handleDechargeChange = (event) => {
        const {value, checked} = event.target;
        const newArrAlter = paramCarte.arrAlter;
        const valueInt = parseInt(value, 10);
        if (checked && !newArrAlter.includes(valueInt)) {
            newArrAlter.push(valueInt);
        } else if (!checked && newArrAlter.includes(valueInt)) {
            newArrAlter.splice(newArrAlter.indexOf(valueInt), 1);
        }
        const newParamCarte = {...paramCarte, arrAlter: newArrAlter};
        setParamCarte(newParamCarte);
        props.onUpdateParamCarte(newParamCarte);
    };
    
    const handleMouseDown = (event) => {
        //setDragDropState("dragging");
        
        const zoneInfoCarte: HTMLDivElement = document.querySelector("#zoneInfoCarte");
        
        event.target.style.userSelect = "none";
        event.preventDefault();
        // get the mouse cursor position at startup:
        let pos3 = event.clientX;
        let pos4 = event.clientY;
        // call a function whenever the cursor moves:
        document.onmousemove = (e) => {
            e.preventDefault();
            // calculate the new cursor position:
            const pos1 = pos3 - e.clientX;
            const pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:
            zoneInfoCarte.style.top = (zoneInfoCarte.offsetTop - pos2) + "px";
            zoneInfoCarte.style.left = (zoneInfoCarte.offsetLeft - pos1) + "px";
        };
    };
    
    const handleSelectionCarte = (id: string | number, type: string, force?: boolean) => {
              if (force === undefined) {
                  force = false;
              }
              
              // Récup le tableau spécifique
              const newArrSpecifique = selectedCarte[type];
              // Si on est en mode forçage de déseclection
              if (force) {
                  if (newArrSpecifique.includes(id)) {
                      newArrSpecifique.splice(newArrSpecifique.indexOf(id), 1);
                  }
              } else {
                  // si sur ce qu'on a cliqué est déjà présent, on retire, sinon on rajoute
                  if (newArrSpecifique.includes(id)) {
                      newArrSpecifique.splice(newArrSpecifique.indexOf(id), 1);
                  } else {
                      newArrSpecifique.push(id);
                  }
              }
              
              const newSelectedCarte = {...selectedCarte, [type]: newArrSpecifique};
              setSelectedCarte(newSelectedCarte);
              props.onUpdateSelectionCarte(newSelectedCarte);
          }
    ;
    
    const filterData = () => {
        if (searchItem === "") {
            return props.carte.listCategorie;
        }
        
        return props.carte.listCategorie
            .filter(category => Object.values(category.objetSol).some((item: ItemCount) => normalizeSearch(searchItem, t(item.nom, {ns: "items"}))))
            .map(category => ({
                ...category,
                objetSol: Object.entries(category.objetSol)
                    .filter(([, item]) => normalizeSearch(searchItem, t(item.nom, {ns: "items"})))
                    .reduce((acc, [index, item]) => {
                        if (normalizeSearch(searchItem, t(item.nom, {ns: "items"}))) {
                            acc[index] = item;
                        }
                        return acc;
                    }, {}),
            }));
    };
    
    const handleChangeCoordonnees = (coord: Partial<Coordonnees>) => {
        setCoordonnees({...coordonnees, ...coord});
    };
    
    const handleBlur = (axis) => (event) => {
        const value = parseInt(event.target.value, 10);
        handleChangeCoordonnees({[axis]: isNaN(value) ? "" : value});
    };
    
    useEffect(() => {
        if (coordonnees.x !== 0 || coordonnees.y !== 0) {
            // On dénormalise les coordonnées
            const x = coordonnees.x + carte.ville.pos_x;
            const y = -coordonnees.y + carte.ville.pos_y;
            props.onShowCase(x + "_" + y);
        }
    }, [coordonnees]);
    
    
    const checkboxesKm = [];
    for (let i = 1; i <= carte.maxKm; i++) {
        checkboxesKm.push(
            <div key={"checkKm_" + i}>
                <input
                    type="checkbox"
                    name={i.toString()}
                    value={i}
                    checked={paramCarte.arrKm.includes(i)}
                    onChange={handleKmChange}
                />
                <label>{i} km</label>
            </div>,
        );
    }
    const checkboxesPa = [];
    for (let i = 1; i <= carte.maxPa; i++) {
        checkboxesPa.push(
            <div key={"checkPa_" + i}>
                <input
                    type="checkbox"
                    name={i.toString()}
                    value={i}
                    checked={paramCarte.arrPa.includes(i)}
                    onChange={handlePaChange}
                />
                <label>{i}</label>
            </div>,
        );
    }
    
    const checkboxesZonage = [];
    for (let i = 0; i < carte.listZonage.length; i++) {
        const zonePa = carte.listZonage[i];
        checkboxesZonage.push(
            <div key={"checkZone_" + zonePa}>
                <input
                    type="checkbox"
                    name={zonePa.toString()}
                    value={zonePa}
                    checked={paramCarte.arrZonage.includes(zonePa)}
                    onChange={handleZonageChange}
                />
                <label>{zonePa}</label>
            </div>,
        );
    }
    
    const checkboxesDecharge = [];
    carte.listDecharge.sort((a: ChantierPrototypeDTO, b: ChantierPrototypeDTO) => a.nom.localeCompare(b.nom)).map((chantier) => {
        checkboxesDecharge.push(
            <div key={"checkdecharge_" + chantier.id_chantier}>
                <input
                    type="checkbox"
                    name={"carte_dech_" + chantier.id_chantier}
                    id={"carte_dech_" + chantier.id_chantier}
                    value={chantier.id_chantier}
                    checked={paramCarte.arrAlter.includes(chantier.id_chantier)}
                    onChange={handleDechargeChange}
                />
                <label htmlFor={"carte_dech_" + chantier.id_chantier}><SvgIcone icone={chantier.icon}/><span>{chantier.nom}</span></label>
            </div>,
        );
    });
    
    // Changement de la valeur de l'onglet selectionné dans le cas où on était sur outils et qu'on va sur la carte normale
    if (idxSelected === "outilsExpe" && !carte.outilsExpe) {
        handleOngletChange("ville");
    }
    
    return <div id="zoneInfoCarte" className={`${menuPinned === true ? "" : "movable"}` + ((carte.user.user_personnalisation.fige_menu && idxSelected !== "outilsExpe") ? " fixedTop" : "")}
                style={{height: menuPinned === true ? "unset" : undefined, width: menuPinned === true ? "unset" : undefined}}>
        <div id="zoneInfoVilleAutre">
            {!menuPinned && <span id="move_info_carte_button" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>&#10021;</span>}
            <ul>
                {tabsMenu.map(([key, value]) => {
                    if ((key !== "outilsExpe" || (key === "outilsExpe" && carte.outilsExpe))) {
                        return <li className={"boutonMenuCarte" + ((key === idxSelected) ? " selectedOngletCarte" : "")}
                                   key={"menu_" + key}
                                   onClick={() => {
                                       handleOngletChange(key);
                                   }}>
                            <i className="tabs" id="#zoneInfoCitoyens"><span>{value}</span></i>
                        </li>;
                    } else {
                        return null;
                    }
                })}
                {!carte.user.user_personnalisation.fige_menu &&
                    <span id="unpinInfoCarteButton" onClick={() => setMenuPinned(!menuPinned)}><span className={"infoBulle"}>&#128204;<span
                        className={"info"}>{menuPinned ? t("Détacher et déplacer le menu", {ns: "ville"}) : t("Rattacher le menu", {ns: "ville"})}</span></span></span>}
            </ul>
            {idxSelected === "ville" &&
                <div id="zoneInfoVille">
                    <div className="bordureHaut">
                        <span className="bordureInvi"></span>
                        <span id="bordureInfoVille"></span>
                    </div>
                    <div id="contenuZoneInfoCarte">
                        <div id="infoGénéral">
                            <p>{t("Récapitulatif ville", {ns: "ville"})}</p>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Nom de la ville :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.ville.nom}</span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Jour :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.ville.jour}</span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Type de ville :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.typeLibelle}</span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Statut de la ville :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.devastLibelle}</span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Portes de la ville :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{(carte.ville.porte) ? t("Ouvertes", {ns: "ville"}) : t("Fermées", {ns: "ville"})}</span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Le puits a encore :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.ville.water} <SvgIcone icone={"h_water"}/></span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("La banque a :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.water_banque} <SvgIcone icone={"item_water"}/>, {carte.chaman_banque} <SvgIcone
                                    icone={"item_potion"}/></span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Nourriture en banque :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.nourriture_banque_b6} <SvgIcone icone={"item_food_bag"}/>, {carte.nourriture_banque_b7}
                                    <SvgIcone icone={"item_dish_tasty"}/></span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Alcool en banque :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.alcool_banque} <SvgIcone icone={"r_alcool"}/></span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Drogue en banque :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.drogue_banque} <SvgIcone icone={"r_drug"}/></span>
                            </div>
                        </div>
                        <div className="separateurVertical"></div>
                        <div id="recapDefenseVille">
                            <p>{t("Récapitulatif défense", {ns: "ville"})}</p>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Muraille :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">10</span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Chantiers :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.defense.buildings +
                                    carte.defense.upgrades} ➔ ({carte.defense.buildings} + {carte.defense.upgrades})</span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Objets de défense :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{Math.floor(
                                    carte.defense.objet * carte.defense.bonus_od / 10)} - ({carte.defense.objet} {(carte.defense.objet > 1) ?
                                    t("objets", {ns: "ville"}) : t("objet", {ns: "ville"})} x {carte.defense.bonus_od / 10})</span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Maisons des citoyens :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.defense.maison_citoyen} </span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Gardiens :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.defense.gardiens} </span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Veilleurs :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.defense.veilleurs} </span>
                            </div>
                            <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Tempos :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.defense.tempos} </span>
                            </div>
                            {carte.defense.ames > 0 && <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Âmes :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.defense.ames} </span>
                            </div>}
                            {carte.defense.morts > 0 && <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Morts :", {ns: "ville"})}</span>
                                <span className="resultZoneInfoVille">{carte.defense.morts} </span>
                            </div>}
                            {carte.defense.def_s_d > 0 && <div className="divZoneInfoVille">
                                <span className="titreZoneInfoVille">{t("Bonus SD (+{bonusSd}%) :", {ns: "ville"}).replace("{bonusSd}", carte.defense.bonus_sd_pct.toString())}</span>
                                <span className="resultZoneInfoVille">{carte.defense.def_s_d} </span>
                            </div>}
                            <div className="divZoneInfoVille" id="derZoneInfoVille">
                                <span className="titreZoneInfoVille">
                                    <span>{t("Total", {ns: "ville"})}</span>
                                    <SvgIcone icone={"h_guard"}/> :
                                </span>
                                <span className="resultZoneInfoVille">{carte.defense.total} ({carte.defense.def_h_s_d} + {carte.defense.def_s_d})</span>
                            </div>
                        </div>
                        {carte.ville.jour > 1 && <div className="separateurVertical"></div>}
                        {carte.ville.jour > 1 &&
                            <div id="recapEvoChantierVille">
                                <p>{t("Récapitulatif des évolutions chantiers (lvl)", {ns: "ville"})}</p>
                                {carte.evoChantier.filter((evolution: UpChantierDTO) => !evolution.destroy).map((evolution: UpChantierDTO, index: number) => {
                                    return <div className="divZoneInfoVille" key={"evoChantier_" + index}>
                                        <span className="titreZoneInfoVille">
                                            <SvgIcone icone={evolution.chantier.icon}/>
                                            {t(evolution.chantier.nom, {ns: "chantiers"})} :
                                        </span>
                                        <span className="resultZoneInfoVille">{evolution.lvl_actuel} / {evolution.chantier.level_max}</span>
                                    </div>;
                                })}
                            </div>
                        }
                        <div className="separateurVertical"></div>
                        <div id={"recapEstimStatsVille"}>
                            {carte.constructionChantier.tdg && <div id="recapEstimationVille">
                                <p>{t("Récapitulatif des estimations", {ns: "ville"})}</p>
                                {carte.constructionChantier.tdg && <React.Fragment>
                                    <h4>{t("Tour de guet :", {ns: "ville"})} {carte.estimation.maxed_jour && <span className={"color-red"}>(max)</span>}</h4>
                                    <div className="divZoneInfoVille">
                                        <span className="titreZoneInfoVille">{t("min :", {ns: "ville"})}</span>
                                        <span className="resultZoneInfoVille">{carte.estimation.min_jour}</span>
                                    </div>
                                    <div className="divZoneInfoVille">
                                        <span className="titreZoneInfoVille">{t("max :", {ns: "ville"})}</span>
                                        <span className="resultZoneInfoVille">{carte.estimation.max_jour}</span>
                                    </div>
                                </React.Fragment>}
                                {carte.constructionChantier.planif && <React.Fragment>
                                    <h4>{t("Planificateur :", {ns: "ville"})} {carte.estimation.maxed_planif && <span className={"color-red"}>(max)</span>}</h4>
                                    <div className="divZoneInfoVille">
                                        <span className="titreZoneInfoVille">{t("min :", {ns: "ville"})}</span>
                                        <span className="resultZoneInfoVille">{carte.estimation.min_planif}</span>
                                    </div>
                                    <div className="divZoneInfoVille">
                                        <span className="titreZoneInfoVille">{t("max :", {ns: "ville"})}</span>
                                        <span className="resultZoneInfoVille">{carte.estimation.max_planif}</span>
                                    </div>
                                </React.Fragment>}
                            </div>}
                            <div id={"recapStatsVille"}>
                                <p>{t("Statistiques cases", {ns: "ville"})}</p>
                                <h4>
                                    <span>{t("Zone", {ns: "ville"})} :</span>
                                    <select value={statsZone} onChange={(event) => {
                                        setStatsZone(parseInt(event.target.value, 10));
                                    }}>
                                        <option value={99} key={"zonePa_" + 99}>{t("Toute la carte", {ns: "ville"})}</option>
                                        {Object.values(carte.listZonage).map((zonePa: number) => {
                                            return <option value={zonePa} key={"zonePa_" + zonePa}>{zonePa}</option>;
                                        })}
                                    </select>
                                    {statsZone !== 99 && <SvgIcone icone={"h_pa"}/>}
                                </h4>
                                <div>
                                    <div>
                                        <span>{t("Epuisées", {ns: "ville"})} : </span>
                                        <span>{Math.round(carte.pctMap.epuise[statsZone] / carte.pctMap.total[statsZone] * 10000) / 100}%</span>
                                    </div>
                                    <div>
                                        <span>{t("Découvertes", {ns: "ville"})} : </span>
                                        <span>{Math.round(carte.pctMap.decouverte[statsZone] / carte.pctMap.total[statsZone] * 10000) / 100}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {idxSelected === "citoyen" &&
                <div id="zoneInfoCitoyens">
                    <div className="bordureHaut">
                        <span id="bordureInfoCitoyens1"></span>
                        <span className="bordureInvi"></span>
                        <span id="bordureInfoCitoyens2"></span>
                    </div>
                    <div id="contenuZoneInfoCitoyens">
                        {carte.citoyensDehors.length !== 0 &&
                            <div className={"outCitoyens colCitoyen" + carte.nbColOut}>
                                <h3><SvgIcone
                                    icone={"h_exploration"}/> {t("Dehors", {ns: "ville"})} {carte.citoyensDehors.length}/{carte.citoyensDehors.length +
                                    carte.citoyensVille.length} <SvgIcone icone={"h_exploration"}/></h3>
                                <div>
                                    {Object.entries(carte.citoyensDehors).map(([index, citoyen]: [string, CitoyensDTO]) => {
                                        const [x, y] = citoyen.position_relatif.split("/");
                                        const position = <span>{x}<span style={{margin: "0 2px"}}>/</span>{y}</span>;
                                        
                                        return <span className={"citoyenListCarte" + ((citoyen.ban ? " citoyenBanni" : "")) +
                                            ((parseInt(index) < citoyensOutWithBorderCount) ? " citoyensBorder" : "") +
                                            ((selectedCarte.cit.includes(citoyen.citoyen.id)) ? " select_cit" : "")}
                                                     id={"c-" + citoyen.citoyen.id + "-" + citoyen.x + "-" + citoyen.y} key={"citoyen_" + citoyen.citoyen.id}
                                                     onClick={() => handleSelectionCarte(citoyen.citoyen.id, "cit")}>
                                        <span className="jobCitoyen"><SvgIcone icone={"h_" + citoyen.job.icon}/></span>
                                        <span className="nomCitoyen">{citoyen.citoyen.pseudo}</span>
                                        <span className="coordCitoyen">{position}</span>
                                        </span>;
                                    })}
                                </div>
                            </div>}
                        <div className="separateurVertical"></div>
                        {carte.citoyensVille.length !== 0 &&
                            <div className={"inCitoyens colCitoyen" + carte.nbColIn}>
                                <h3><SvgIcone icone={"h_door"}/>{t("En ville ou aux portes", {ns: "ville"})} {carte.citoyensVille.length}/{carte.citoyensDehors.length +
                                    carte.citoyensVille.length} <SvgIcone icone={"h_door"}/></h3>
                                <div>
                                    {Object.entries(carte.citoyensVille).map(([index, citoyen]: [string, CitoyensDTO]) => {
                                        return <span className={"lignCitInCarte" + ((parseInt(index) < citoyensInWithBorderCount) ? " citoyensBorder" : "")}
                                                     key={"citoyen_" + citoyen.citoyen.id}>
                                            <span className="jobCitoyen"><SvgIcone icone={"h_" + citoyen.job.icon}/></span>
                                            <span className={"nomCitoyen" +
                                                ((citoyen.ban) ? " citoyenBanni" : "")}>{citoyen.citoyen.pseudo}{citoyen.dehors &&
                                                <span className={"infoBulle"}><SvgIcone icone={"h_door"}/><span
                                                    className={"info"}>{t("Aux portes", {ns: "ville"})}</span></span>}</span>
                                        </span>;
                                    })}
                                </div>
                            </div>}
                    </div>
                    <div id="selectionBoutonCitoyenCarte">
                        <button className={"btn btn-xs btn-primary"} id="selectAllCitoyenCarte" onClick={() => {
                            const arrayCitoyens = [];
                            // On parcourt tous les citoyens
                            Object.entries(carte.citoyensDehors).map(([, citoyen]: [string, CitoyensDTO]) => {
                                arrayCitoyens.push(citoyen.citoyen.id);
                            });
                            const newSelectedCarte = {...selectedCarte, ["cit"]: arrayCitoyens};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Sélectionner tous les citoyens", {ns: "ville"})}</button>
                        <button className={"btn btn-xs btn-danger"} id="deSelectAllCitoyenCarte" onClick={() => {
                            const newSelectedCarte = {...selectedCarte, ["cit"]: []};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Désélectionner tous les citoyens", {ns: "ville"})}</button>
                        <button className={"btn btn-xs btn-warning"} id="invSelectCitoyenCarte" onClick={() => {
                            const newArrayCitoyens = [];
                            // On récupère l'ancien tableau
                            const oldArrayCitoyens = selectedCarte.cit;
                            Object.entries(carte.citoyensDehors).map(([, citoyen]: [string, CitoyensDTO]) => {
                                const id = citoyen.citoyen.id;
                                // si sur ce qu'on a cliqué est déjà présent, on retire, sinon on rajoute
                                if (!oldArrayCitoyens.includes(id)) {
                                    newArrayCitoyens.push(id);
                                }
                            });
                            const newSelectedCarte = {...selectedCarte, ["cit"]: newArrayCitoyens};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Inverser la sélection", {ns: "ville"})}</button>
                    </div>
                </div>
            }
            {idxSelected === "objet" &&
                <div id="zoneInfoObjet">
                    <div className="bordureHaut">
                        <span id="bordureInfoObjet1"></span>
                        <span className="bordureInvi"></span>
                        <span id="bordureInfoObjet2"></span>
                    </div>
                    <div id="menuListingObjetCarte">
                        <label htmlFor="rechercheObjetListingCarte">{t("Rechercher un objet par son nom :", {ns: "ville"})}</label>
                        <input
                            type="search"
                            name="rechercheObjetListingCarte"
                            id="rechercheObjetListingCarte"
                            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                const searchItem = event.currentTarget.value;
                                //const matchingCategories = props.carte.listCategorie.filter(category => Object.entries(category.objetSol).some(([, item]) =>
                                // item.nom.toLowerCase().includes(searchItem.toLowerCase())));
                                
                                //const matchingIds = matchingCategories.map(category => category.id);
                                setSearchItem(searchItem);
                            }}
                            onClick={() => {
                                const searchItem = "";
                                setSearchItem(searchItem);
                            }}
                        />
                        <button className={"btn btn-xs btn-primary"} id="selectAllObjet" onClick={() => {
                            const newArrayObjet = [];
                            // On balaye tous les objets
                            carte.listCategorie.map((category: CategoryObjetCarte) => {
                                Object.entries(category.objetSol).map(([, itemCount]: [string, ItemCount]) => {
                                    newArrayObjet.push("item_" + itemCount.id_objet);
                                });
                            });
                            const newSelectedCarte = {...selectedCarte, ["obj"]: newArrayObjet};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Sélectionner tous les objets", {ns: "ville"})}</button>
                        <button className={"btn btn-xs btn-danger"} id="deSelectAllObjet" onClick={() => {
                            const newSelectedCarte = {...selectedCarte, ["obj"]: []};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Désélectionner tous les objets", {ns: "ville"})}</button>
                        <button className={"btn btn-xs btn-warning"} id="invSelectObjet" onClick={() => {
                            // On initialise un nouveau tableau
                            const newArrayObjet = [];
                            // On récupère l'ancien tableau
                            const oldArrayObjet = selectedCarte.obj;
                            // On balaye tous les objets
                            carte.listCategorie.map((category: CategoryObjetCarte) => {
                                Object.entries(category.objetSol).map(([, itemCount]: [string, ItemCount]) => {
                                    const id = itemCount.id_objet;
                                    if (!oldArrayObjet.includes("item_" + id)) {
                                        newArrayObjet.push("item_" + id);
                                    }
                                });
                            });
                            const newSelectedCarte = {...selectedCarte, ["obj"]: newArrayObjet};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Inverser la sélection", {ns: "ville"})}</button>
                    </div>
                    <div id="contenuZoneInfoObjet">
                        {filterData().map((category: CategoryObjetCarte, index: number) => {
                            
                            let classBordure = null;
                            if (index !== 4) {
                                classBordure = "cat_bordureGauche";
                            }
                            if (index < 5) {
                                if (classBordure === null) {
                                    classBordure = "cat_bordureBas";
                                } else {
                                    classBordure += " cat_bordureBas";
                                }
                            }
                            return <div id={"catObjet_" + category.id} key={"category_" + index} className={classBordure}>
                                <div className="listObjetCat">
                                    <h3>{t(category.nom, {ns: "items"})}</h3>
                                    <div className="listObjetLigne">
                                        {Object.entries(category.objetSol).map(([, itemCount]: [string, ItemCount]) => {
                                            const item = itemCount;
                                            const itemBroken = carte.listItemsSolBroken[category.id][item.id_objet] ?? null;
                                            return <span id={"objId_" + item.id_objet}
                                                         className={"lignObjetCarte" + ((selectedCarte.obj.includes("item_" + item.id_objet)) ? " select_obj" : "")}
                                                         key={"objId_" + item.id_objet} onClick={() => handleSelectionCarte("item_" + item.id_objet, "obj")}>
                                                        <SvgIcone icone={item.icon}/>
                                                        <span className="ensembleNomNombreCassePasCasseLignObjet">
                                                            <span className="ensNomNbrObjet">
                                                                <span className="nomItemLigneObjet">{t(item.nom, {ns: "items"})}</span>
                                                                <span className="ensItemNonCasseLigneObjet">
                                                                    <span className="itemDehors">{item.nbr_item}</span>
                                                                    <span className="seperateurDehorsBankLigneObjet">/</span>
                                                                    <span className="itemBank">{item.nbr_item_bank}</span>
                                                                </span>
                                                            </span>
                                                            {itemBroken !== null && <span className="ensItemCasseLigneObjet">
                                                            <span className="textCasseLigneObjet">{(itemBroken.nbr_item > 1) ?
                                                                t("dont cassés", {ns: "ville"}) : t("dont cassé", {ns: "ville"})} </span>
                                                            <span className="objetCasseLignObjet">
                                                                <span className="itemDehors">{itemBroken.nbr_item}</span>
                                                                <span className="seperateurDehorsBankLigneObjet">/</span>
                                                                <span className="itemBank">{itemBroken.nbr_item_bank}</span>
                                                            </span>
                                                        </span>}
                                                        </span>
                                                    </span>;
                                        })
                                        }
                                    </div>
                                </div>
                            </div>;
                        })}
                        <div id={"filtre_objet"} key={"filtre_objet"} className={null}>
                            <div className="listObjetCat">
                                <h3>{t("Filtrer les objets", {ns: "ville"})}</h3>
                                <div className="listObjetLigne">
                                    <div id={"objet_filtre_zoneKm"}>
                                        <div id={"activation_filtre_zoneKm"}>
                                            <label htmlFor={"carte_filtre_objet"}>{t("Activer le filtre", {ns: "ville"})}</label>
                                            <Form.Check
                                                name={"carte_filtre_objet"}
                                                id={"carte_filtre_objet"}
                                                type="switch"
                                                checked={isFiltred}
                                                onChange={(event) => {
                                                    setIsFiltred(event.target.checked);
                                                    if (!event.target.checked) {
                                                        return props.onApplyFiltre(event.target.checked, typeFiltre, filtre_min, filtre_max);
                                                    }
                                                }}
                                            />
                                        </div>
                                        {isFiltred && <>
                                            <div>
                                                <label>{t("Type de filtre", {ns: "ville"})}</label>
                                                <select value={typeFiltre} onChange={(event) => setTypeFiltre(parseInt(event.target.value, 10))}>
                                                    <option value={0}>{t("Km", {ns: "ville"})}</option>
                                                    <option value={1}>{t("Zone", {ns: "ville"})}</option>
                                                </select>
                                            </div>
                                            <div id={"filtre_zone_carte_objet"}>
                                                <label>{(typeFiltre === 0) ? t("Entre les km", {ns: "ville"}) : t("Entre les zones", {ns: "ville"})}</label>
                                                <input type={"number"} min={0} value={filtre_min} onChange={(event) => {
                                                    let new_filtre_max = filtre_max;
                                                    const new_filtre_min = (parseInt(event.target.value, 10) < 0) ? 0 : parseInt(event.target.value, 10);
                                                    if (new_filtre_max < new_filtre_min) {
                                                        new_filtre_max = new_filtre_min + 1;
                                                    }
                                                    setFiltre_min(new_filtre_min);
                                                    setFiltre_max(new_filtre_max);
                                                }}/>
                                                <span>{t("et", {ns: "ville"})}</span>
                                                <input type={"number"} min={filtre_min + 1} value={filtre_max} onChange={(event) => {
                                                    const new_filtre_max = (parseInt(event.target.value, 10) < filtre_min) ? (filtre_min + 1) :
                                                        parseInt(event.target.value, 10);
                                                    setFiltre_max(new_filtre_max);
                                                }}/>
                                            </div>
                                            <div id={"button_filtre_zone_carte_objet"}>
                                                <button className={"btn btn-xs btn-success"} type={"button"} onClick={() => props.onApplyFiltre(isFiltred, typeFiltre, filtre_min,
                                                    filtre_max)}>{t("Filtrer", {ns: "ville"})}</button>
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {idxSelected === "batiment" &&
                <div id="zoneInfoBatiment">
                    <div className="bordureHaut">
                        <span id="bordureInfoBatiment1"></span>
                        <span className="bordureInvi"></span>
                        <span id="bordureInfoBatiment2"></span>
                    </div>
                    <div id="contenuZoneInfoBatiment">
                        <div id="zoneBatimentCamp">
                            <h3>
                                <span>{t("Bâtiments", {ns: "ville"})} ({nbrBatJauneTotal + nbrBatBleuTotal}/{carte.ville.height < 24 ? 7 : 20})</span>
                                <span className="infoBulle">
                                    <i className="fa-solid fa-circle-info"></i>
                                    <span className="info">
                                        <SvgIcone icone={"h_home"}/>{t("Bâtiment non vide", {ns: "ville"})}<br/>
                                        <SvgIcone icone={"h_ban"}/>{t("Bâtiment vide", {ns: "ville"})}<br/>
                                        <SvgIcone icone={"r_camp"}/>{t("Bâtiment pas encore campé", {ns: "ville"})}<br/>
                                        <SvgIcone icone={"r_cmplst"}/>{t("Bâtiment déjà campé", {ns: "ville"})}<br/>
                                    </span>
                                </span>
                            </h3>
                            <div id="regroupementBatiment">
                                <div id="batJaune">
                                    <h4>{t("Plans Jaunes", {ns: "ville"})} <SvgIcone
                                        icone={"item_bplan_u"}/> ({(carte.batCarte.nbCampedJaune > 1) ? t("campés", {ns: "ville"}) : t("campé", {ns: "ville"})} : {carte.batCarte.nbCampedJaune}/ {(carte.batCarte.batJaune.length - carte.batCarte.nbCampedJaune > 1) ? t("restants", {ns: "ville"}) : t("restant", {ns: "ville"})} : {nbrBatJauneTotal - carte.batCarte.nbCampedJaune})
                                    </h4>
                                    <div>
                                        {Object.values(carte.batCarte.batJaune).sort((bat_a, bat_b) => {
                                            const aBat = t(bat_a.bat.nom, {ns: "bats"});
                                            const bBat = t(bat_b.bat.nom, {ns: "bats"});
                                            return aBat.localeCompare(bBat);
                                        }).map((bat) => {
                                            return <span className={"lignBat" + ((selectedCarte.bat.includes(bat.x + "_" + bat.y)) ? " select_bat" : "")}
                                                         id={"b-" + bat.x + "_" + bat.y} key={"b-" + bat.x + "_" + bat.y}
                                                         onClick={() => handleSelectionCarte(bat.x + "_" + bat.y, "bat")}>
                                                    <LigneBat zone={bat} posX={carte.ville.pos_x} posY={carte.ville.pos_y} onShowInfoBat={(id) => {
                                                        setIdBatShow(id);
                                                        setShowModalBat(true);
                                                    }}/>
                                                </span>;
                                        })}
                                    </div>
                                </div>
                                <div id="batBleu">
                                    <h4>{t("Plans bleus", {ns: "ville"})} <SvgIcone
                                        icone={"item_bplan_r"}/> ({(carte.batCarte.nbCampedBleu > 1) ? t("campés", {ns: "ville"}) : t("campé", {ns: "ville"})} : {carte.batCarte.nbCampedBleu}/ {(carte.batCarte.batBleu.length - carte.batCarte.nbCampedBleu > 1) ? t("restants", {ns: "ville"}) : t("restant", {ns: "ville"})} : {nbrBatBleuTotal - carte.batCarte.nbCampedBleu})
                                    </h4>
                                    <div>
                                        {Object.values(carte.batCarte.batBleu).sort((bat_a, bat_b) => {
                                            const aBat = t(bat_a.bat.nom, {ns: "bats"});
                                            const bBat = t(bat_b.bat.nom, {ns: "bats"});
                                            return aBat.localeCompare(bBat);
                                        }).map((bat) => {
                                            return <span className={"lignBat" + ((selectedCarte.bat.includes(bat.x + "_" + bat.y)) ? " select_bat" : "")}
                                                         id={"b-" + bat.x + "_" + bat.y} key={"b-" + bat.x + "_" + bat.y}
                                                         onClick={() => handleSelectionCarte(bat.x + "_" + bat.y, "bat")}>
                                                    <LigneBat zone={bat} posX={carte.ville.pos_x} posY={carte.ville.pos_y} onShowInfoBat={(id) => {
                                                        setIdBatShow(id);
                                                        setShowModalBat(true);
                                                    }}/>
                                                </span>;
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {carte.batCarte.ruines.length > 0 &&
                            <div id="batRuine">
                                <h3>{t("Ruine", {ns: "ville"})} ({carte.batCarte.ruines.length}/{(carte.ville.height >= 24) ? ((carte.ville.hard) ? 2 : 1) : 0})</h3>
                                {Object.values(carte.batCarte.ruines).sort((bat_a, bat_b) => {
                                    const aBat = t(bat_a.bat.nom, {ns: "bats"});
                                    const bBat = t(bat_b.bat.nom, {ns: "bats"});
                                    return aBat.localeCompare(bBat);
                                }).map((bat) => {
                                    return <span className={"lignBat" + ((selectedCarte.bat.includes(bat.x + "_" + bat.y)) ? " select_bat" : "")}
                                                 id={"b-" + bat.x + "_" + bat.y} key={"b-" + bat.x + "_" + bat.y}
                                                 onClick={() => handleSelectionCarte(bat.x + "_" + bat.y, "bat")}>
                                            <LigneBat zone={bat} posX={carte.ville.pos_x} posY={carte.ville.pos_y} onShowInfoBat={(id) => {
                                                setIdBatShow(id);
                                                setShowModalBat(true);
                                            }}/>
                                        </span>;
                                })}
                            </div>}
                    </div>
                    <div id="selectionBoutonBatimentCarte">
                        <button className={"btn btn-xs btn-success"} id="selectAllBatCarte" onClick={() => {
                            // On initialise un nouveau tableau
                            const newArrayBatiment = [];
                            // On balaye tous les batiments jaune
                            Object.entries(carte.batCarte.batJaune).map(([, bat]) => {
                                newArrayBatiment.push(bat.x + "_" + bat.y);
                            });
                            // On balaye tous les batiments bleus
                            Object.entries(carte.batCarte.batBleu).map(([, bat]) => {
                                newArrayBatiment.push(bat.x + "_" + bat.y);
                            });
                            // On balaye ruines également
                            Object.entries(carte.batCarte.ruines).map(([, bat]) => {
                                newArrayBatiment.push(bat.x + "_" + bat.y);
                            });
                            const newSelectedCarte = {...selectedCarte, ["bat"]: newArrayBatiment};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Sélectionner tous les Bâtiments", {ns: "ville"})}</button>
                        <button className={"btn btn-xs btn-info"} id="selectAllBatACamperCarte" onClick={() => {
                            // On initialise un nouveau tableau
                            const newArrayBatiment = [];
                            // On balaye tous les batiments jaune et on ne garde que ceux qui ne sont pas campés
                            Object.entries(carte.batCarte.batJaune).map(([, bat]) => {
                                if (!bat.camped) {
                                    newArrayBatiment.push(bat.x + "_" + bat.y);
                                }
                            });
                            // On balaye tous les batiments bleus
                            Object.entries(carte.batCarte.batBleu).map(([, bat]) => {
                                if (!bat.camped) {
                                    newArrayBatiment.push(bat.x + "_" + bat.y);
                                }
                            });
                            const newSelectedCarte = {...selectedCarte, ["bat"]: newArrayBatiment};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Sélectionner tous les bâts restant à camper", {ns: "ville"})}</button>
                        <button className={"btn btn-xs btn-warning"} id="selectAllBatJauneCarte" onClick={() => {
                            // On initialise un nouveau tableau
                            const newArrayBatiment = [];
                            // On balaye tous les batiments jaune
                            Object.entries(carte.batCarte.batJaune).map(([, bat]) => {
                                newArrayBatiment.push(bat.x + "_" + bat.y);
                            });
                            const newSelectedCarte = {...selectedCarte, ["bat"]: newArrayBatiment};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Sélectionner Bâtiments à plan inhab", {ns: "ville"})}</button>
                        <button className={"btn btn-xs btn-primary"} id="selectAllBatBleuCarte" onClick={() => {
                            // On initialise un nouveau tableau
                            const newArrayBatiment = [];
                            // On balaye tous les batiments bleus
                            Object.entries(carte.batCarte.batBleu).map(([, bat]) => {
                                newArrayBatiment.push(bat.x + "_" + bat.y);
                            });
                            const newSelectedCarte = {...selectedCarte, ["bat"]: newArrayBatiment};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Sélectionner Bâtiments à plan rare", {ns: "ville"})}</button>
                        <button className={"btn btn-xs btn-danger"} id="deSelectAllBatCarte" onClick={() => {
                            const newSelectedCarte = {...selectedCarte, ["bat"]: []};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Désélectionner tous les Bâtiments", {ns: "ville"})}</button>
                        <button className={"btn btn-xs btn-secondary"} id="invSelectBatCarte" onClick={() => {
                            // On initialise un nouveau tableau
                            const newArrayBatiment = [];
                            // On récupère l'ancien tableau
                            const oldArrayBatiment = selectedCarte.bat;
                            Object.entries(carte.batCarte.batJaune).map(([, bat]) => {
                                const idZone = bat.x + "_" + bat.y;
                                // si sur ce qu'on a cliqué est déjà présent, on retire, sinon on rajoute
                                if (!oldArrayBatiment.includes(idZone)) {
                                    newArrayBatiment.push(idZone);
                                }
                            });
                            Object.entries(carte.batCarte.batBleu).map(([, bat]) => {
                                const idZone = bat.x + "_" + bat.y;
                                // si sur ce qu'on a cliqué est déjà présent, on retire, sinon on rajoute
                                if (!oldArrayBatiment.includes(idZone)) {
                                    newArrayBatiment.push(idZone);
                                }
                            });
                            Object.entries(carte.batCarte.ruines).map(([, bat]) => {
                                const idZone = bat.x + "_" + bat.y;
                                // si sur ce qu'on a cliqué est déjà présent, on retire, sinon on rajoute
                                if (!oldArrayBatiment.includes(idZone)) {
                                    newArrayBatiment.push(idZone);
                                }
                            });
                            const newSelectedCarte = {...selectedCarte, ["bat"]: newArrayBatiment};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}>{t("Inverser la sélection", {ns: "ville"})}</button>
                    </div>
                </div>
            }
            {idxSelected === "expe" &&
                <div id="zoneInfoExpédition">
                    <div className="bordureHaut">
                        <span id="bordureInfoExpedition1"></span>
                        <span className="bordureInvi"></span>
                        <span id="bordureInfoExpedition2"></span>
                    </div>
                    <ExpeditionModule
                        carte={carte}
                        arraySelect={selectedCarte.expe}
                        mode_expe={props.mode_expe}
                        expeManuel={props.expeManuel}
                        onUpdateModeExpe={(mode_expe: boolean) => props.onUpdateModeExpe(mode_expe)}
                        onUpdateSeletedExpe={(id: string) => handleSelectionCarte(id, "expe")}
                        onUpdateSelectedExpes={(arraySelect: string[]) => {
                            const newSelectedCarte = {...selectedCarte, ["expe"]: arraySelect};
                            setSelectedCarte(newSelectedCarte);
                            props.onUpdateSelectionCarte(newSelectedCarte);
                        }}
                        onUpdateExpedition={(expedition: Expedition, retracage: boolean) => props.onUpdateExpedition(expedition, retracage)}
                        onEditExpedition={(idExpe: string) => props.onEditExpedition(idExpe)}
                        onRefresh={props.onRefresh}
                        onSaveExpedition={(expedition: Expedition) => props.onSaveExpedition(expedition)}
                        onSuppExpedition={(idExpe: string) => props.onSuppExpedition(idExpe)}
                        outilsExpedition={carte.outilsExpe}
                    />
                </div>
            }
            {carte.outilsExpe && idxSelected === "outilsExpe" &&
                <div id="zoneInfoExpédition">
                    <div className="bordureHaut">
                        <span id="bordureInfoExpedition1"></span>
                        <span className="bordureInvi"></span>
                        <span id="bordureInfoExpedition2"></span>
                    </div>
                    <ModuleOutilsExpeditions onUpdateSeletedExpe={(id: string, forcage: boolean) => handleSelectionCarte(id, "expe", forcage)}/>
                </div>
            }
            {idxSelected === "param" &&
                <div id="zoneInfoParam">
                    <div className="bordureHaut">
                        <span id="bordureInfoParam1"></span>
                        <span className="bordureInvi"></span>
                        <span id="bordureInfoParam2"></span>
                    </div>
                    <div id="contenuZoneInfoParam">
                        <div className="lignOption">
                            <span className="lignLabelOption">{t("Afficher :", {ns: "ville"})}</span>
                            <div id="optionDiverseCarte">
                                <div><input type={"checkbox"} id={"param_danger"} name={"danger"} key={"param_danger"} checked={paramCarte.danger}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_danger"}>{t("Danger", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_distance"} name={"distance"} key={"param_distance"} checked={paramCarte.distance}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_distance"}>{t("Distance", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_distance_pa"} name={"distance_pa"} key={"param_distance_pa"} checked={paramCarte.distance_pa}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_distance_pa"}>{t("Distance en PA", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_scrut"} name={"scrutateur"} key={"param_scrut"} checked={paramCarte.scrutateur}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_scrut"}>{t("Scrutateur", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_zombie"} name={"zombie"} key={"param_zombie"} checked={paramCarte.zombie}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_zombie"}>{t("Zombie", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_epuise"} name={"epuise"} key={"param_epuise"} checked={paramCarte.epuise}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_epuise"}>{t("Epuisé", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_objetSol"} name={"objetSol"} key={"param_objetSol"} checked={paramCarte.objetSol}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_objetSol"}>{t("Objet sol", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_citoyensVille"} name={"citoyen"} key={"param_citoyensVille"} checked={paramCarte.citoyen}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_citoyensVille"}>{t("Citoyens", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_indicVisit"} name={"indicVisite"} key={"param_indicVisit"} checked={paramCarte.indicVisite}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_indicVisit"}>{t("Indicateur de visite", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_carteAlter"} name={"carteAlter"} key={"param_carteAlter"} checked={paramCarte.carteAlter}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_carteAlter"}>{t("Carte Alternative", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_carteScrut"} name={"carteScrut"} key={"param_carteScrut"} checked={paramCarte.carteScrut}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_carteScrut"}>{t("Carte Scrutateur", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_zonage"} name={"zonage"} key={"param_zonage"} checked={paramCarte.zonage}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_zonage"}>{t("Zone PA", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_estimZombie"} name={"estimZombie"} key={"param_estimZombie"} checked={paramCarte.estimZombie}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_estimZombie"}>{t("Estimation Zombie", {ns: "ville"})}</label></div>
                                <div><input type={"checkbox"} id={"param_balisage"} name={"balisage"} key={"param_balisage"} checked={paramCarte.balisage}
                                            onChange={handleParamChange}/><label
                                    htmlFor={"param_balisage"}>{t("Balisage", {ns: "ville"})}</label></div>
                            </div>
                        </div>
                        {paramCarte.distance && <div className="lignOption">
                            <span className="lignLabelOption">{t("Afficher les kms :", {ns: "ville"})}</span>
                            <div id="optionDiverseKm">
                                {checkboxesKm}
                            </div>
                        </div>}
                        {paramCarte.distance_pa && <div className="lignOption">
                            <span className="lignLabelOption">{t("Afficher les pa ", {ns: "ville"})}</span>
                            <div id="optionDiversePa">
                                {checkboxesPa}
                            </div>
                        </div>}
                        {paramCarte.zonage && <div className="lignOption">
                            <span className="lignLabelOption">{t("Afficher les zones :", {ns: "ville"})}</span>
                            <div id="optionDiverseZone">
                                {checkboxesZonage}
                            </div>
                        </div>}
                        {paramCarte.carteAlter && <div className="lignOption">
                            <span className="lignLabelOption">{t("Afficher les décharges", {ns: "ville"})}</span>
                            <div id="optionDiverseDecharge">
                                {checkboxesDecharge}
                            </div>
                        </div>}
                        {paramCarte.estimZombie && <div className="lignOption">
                            <span className="lignLabelOption">{t("Estimation de zombie pour le jour", {ns: "ville"})}</span>
                            <input id={"estimZombieDay"} type={"number"} value={carte.estim_day}
                                   onChange={(event) => props.onMajEstimDay(parseInt(event.target.value))}/>
                        </div>}
                    </div>
                </div>
            }
            {idxSelected === "outils" &&
                <div id="zoneInfoOutils">
                    <div className="bordureHaut">
                        <span id="bordureInfoParam1"></span>
                        <span className="bordureInvi"></span>
                        <span id="bordureInfoParam2"></span>
                    </div>
                    <div id="contenuZoneInfoParam">
                        <div>
                            {(carte.myVille || carte.isLead) &&
                                <button type="button" className="btn btn-primary btn-sm" id="carte_scrutatateur" disabled={isApiCallInProgress}
                                        onClick={props.onMajScrut}>{isApiCallInProgress ? t("Traitement en cours...", {ns: "ville"}) : t("Ajouter marqueurs de régénération selon la direction du scrutateur", {ns: "ville"})}</button>}
                        </div>
                        <div className={"recherche_coordonnee"}>
                            <span>{t("Rechercher une case :", {ns: "ville"})}</span>
                            <span className={"input_recherche_coord"}>
                                <label htmlFor="rechercheX">{t("X :", {ns: "ville"})}</label>
                                <InputNumber value={coordonnees.x} min={-carte.ville.pos_x} max={carte.ville.height - carte.ville.pos_x - 1} step={1} precision={0} onChange={(value) => handleChangeCoordonnees({x: value})} controls/>
                            </span>
                            <span className={"input_recherche_coord"}>
                                <label htmlFor="rechercheX">{t("Y :", {ns: "ville"})}</label>
                                <InputNumber value={coordonnees.y} min={carte.ville.pos_y - carte.ville.height + 1} max={carte.ville.pos_y} step={1} precision={0} onChange={(value) => handleChangeCoordonnees({y: value})} controls/>
                            </span>

                        </div>
                    </div>
                </div>
            }
        </div>
        <Modal show={showModalBat} onHide={() => setShowModalBat(false)} centered>
            {showModalBat && <InformationBatiment id={idBatShow} onClose={() => {
                setIdBatShow(null);
                setShowModalBat(false);
            }}/>}
        </Modal>
    </div>;
    
}

export function LigneBat(props: {
    zone: ZoneMapDTO,
    posX: number,
    posY: number,
    onShowInfoBat: (id: number) => void
}) {
    const {t} = useTranslation();
    const zone = props.zone;
    
    return <React.Fragment>
        <span className={"nomBatCarte"}>
            <span>{t(zone.bat.nom, {ns: "bats"})}</span>
            {zone.dig > 0 && <span>({zone.dig} <SvgIcone icone={"small_dig"}/>)</span>}
            {zone.bat_hypothese !== null && zone.dig > 0 && <span>
                <span>- </span>
                <span className="infoBulle">
                    <i className="fa-solid fa-house-circle-exclamation"/>
                    <span className={"info"}>{t("Bâtiment hypothèse : {batHypo}", {ns: "ville"}).replace("{batHypo}", t(zone.bat_hypothese.nom, {ns: "bats"}))}</span>
                </span>
            </span>}
            <span onClick={(event) => {
                event.stopPropagation();
                props.onShowInfoBat(zone.bat.id_bat);
            }}><SvgIcone icone={"item_bag"}/></span>
        </span>
        <span className="kmBatCarte">{useCalculKm(zone.x, zone.y, props.posX, props.posY)} km</span>
        <span className="coordBatCarte">({zone.x - props.posX}/{props.posY - zone.y})</span>
        {!zone.bat.explorable && <React.Fragment>
            <span className="etatBatCarte">{zone.dig === 0 && (<SvgIcone icone={(zone.empty) ? "h_ban" : "h_home"}/>)}</span>
            <span className="campBatCarte"><SvgIcone icone={(zone.camped) ? "r_cmplst" : "r_camp"}/></span>
        </React.Fragment>}
    </React.Fragment>;
}