import React      from "react";
import svg_bat    from "../../../../img/sprite_bat.svg";
import svg_bord   from "../../../../img/sprite_bord.svg";
import svg_divers from "../../../../img/sprite_divers.svg";
import svg_fleche from "../../../../img/sprite_fleche.svg";
import svg_icone  from "../../../../img/sprite.svg";


function SvgCase(props: {
    classPrincipale: string; typeCarte: string; svg: string; idSvg: string; classOption?: string; style?: React.CSSProperties;
}) {
    const classOption = props.classOption ?? "";
    const style = props.style ?? null;
    const classPrincipale = props.classPrincipale;
    const typeCarte = props.typeCarte ?? null;
    const idSvg = props.idSvg ?? null;
    
    const svgMap = {
        "bat"   : svg_bat,
        "bord"  : svg_bord,
        "fleche": svg_fleche,
        "icone" : svg_icone,
    };
    
    let svg = svgMap[props.svg] || svg_divers;
    
    if (style === null) {
        return <svg className={classPrincipale + " " + typeCarte + " " + classOption}>
            <use xlinkHref={svg + "#" + idSvg} />
        </svg>;
    } else {
        return <svg className={classPrincipale + " " + typeCarte + " " + classOption} style={style}>
            <use xlinkHref={svg + "#" + idSvg} />
        </svg>;
    }
}

export default SvgCase;