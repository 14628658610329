import { Fetch }                     from "../../components/generality/Fetch";
import { OutilsReparationPropsType } from "../../types/components/Outils/ReparationType";
import { GeneralType }               from "../../types/components/Generality/GeneralType";
import { OutilsReparationDTO }       from "../../types/models/outilsReparation.dto";

export class ReparationsApi {
    
    private fetch: Fetch;
    
    constructor(mapId: number) {
        this.fetch = new Fetch("outils/reparation", mapId ?? 0);
    }
    
    public sauvegarde(data: { outilsReparations: OutilsReparationDTO, mapId: number, userId: number, sauvegarde: boolean }): Promise<RetourSauvegarde> {
        return this.fetch.from("/save").request().post(data);
    }
    
    public main(): Promise<AffichageReparations> {
        return this.fetch.from("/").request().get();
    }
    
}

export interface RetourSauvegarde {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        outilsReparations: OutilsReparationDTO,
        textReparation: string,
    },
}

export interface AffichageReparations {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        reparation: OutilsReparationPropsType,
        general: GeneralType,
    },
}