import {createContext, useContext} from "react";
import {ExpeditionContextType}     from "./ExpeditionContextType";

export const ExpeditionContext = createContext<ExpeditionContextType>({
    expeditions        : [],
    setExpeditions     : () => {
    },
    ouvriers           : [],
    setOuvriers        : () => {
    },
    optionsSoif        : [],
    expedition         : {},
    setExpedition      : () => {
    },
    ongletActuel       : "",
    setOngletActuel    : () => {
    },
    loadData           : false,
    setLoadData        : () => {
    },
    refreshOutils      : () => {
    },
    citoyensUser       : [],
    onDuplicate        : false,
    setOnDuplicate     : () => {
    },
    calculStockRestant : (): number[] => {
        return [];
    },
    onUpdateSeletedExpe: () => {
    }
});

export function useExpeditionContext() {
    return useContext(ExpeditionContext);
}