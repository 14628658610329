import React              from "react";
import { JournalType }    from "../../../types/components/Hotel/JournalType";
import * as Icon          from "react-icons/bs";
import SvgIcone           from "../../../components/generality/SvgIcone";
import HTMLParser         from "html-react-parser";
import { ChantiersDTO }   from "../../../types/models/chantiers.dto";
import { JournalDTO }     from "../../../types/models/journal.dto";
import { useTranslation } from "react-i18next";

interface JournalProps {
    journal: JournalType;
}

export default function Journal({ journal }: JournalProps) {
    const { t } = useTranslation();
    const listJournal: JournalDTO[] = journal.listJournal;
    const level_regen: number[] = journal.level_regen;
    const level_evo = journal.level_up_chantier;
    const chantierList: [ChantiersDTO[]] = journal.chantiersList;
    const chantierListDest: [ChantiersDTO[]] = journal.chantiersDetruitList;
    
    const journalSorted = Object.values(listJournal).sort((a: JournalDTO, b: JournalDTO) => a.day < b.day ? 1 : -1);
    
    return <table id={"journalVilleTab"}>
        <thead>
        <tr>
            <th className="colJour">{t("Jour", { ns: "hotel" })}</th>
            <th>{t("Contenu de la gazette", { ns: "hotel" })}</th>
            <th className="colZ">{t("Zombies", { ns: "hotel" })}</th>
            <th className="colZ">{t("Défense", { ns: "hotel" })}</th>
            <th className="colDir">{t("Direction et regénération scrutateur", { ns: "hotel" })}</th>
            <th className="colDir">{t("Evolution voté la veille", { ns: "hotel" })}</th>
            <th className="colDir">{t("Chantiers construits", { ns: "hotel" })}</th>
        </tr>
        </thead>
        <tbody>
        {journalSorted.map((journal, index) => {
            const evolution = level_evo[journal.day];
            const chantiers: ChantiersDTO[] = Object.values(chantierList[journal.day] ?? [])
                .sort((a: ChantiersDTO, b: ChantiersDTO) => a.chantier.nom.localeCompare(b.chantier.nom));
            const chantiersDest: ChantiersDTO[] = Object.values(chantierListDest[journal.day] ?? [])
                .sort((a: ChantiersDTO, b: ChantiersDTO) => a.chantier.nom.localeCompare(b.chantier.nom));
            return <tr key={"journal_" + index}>
                <td className="colJour">{journal.day}</td>
                <td>{HTMLParser(journal.content)}</td>
                <td className="colZ">{journal.zombie}</td>
                <td className="colZ">{journal.def}</td>
                <td className={"colDir"}>{journal.day !== 1 && <ScrutateurLib journal={journal} level_regen={level_regen} size={25} />}</td>
                <td className={"colDir"}>
                    {evolution && <span>
                        <span className="infoBulle"><SvgIcone icone={evolution.icon} /><span className="info">{t(evolution.nom, { ns: "chantiers" })}</span>{evolution.destroy && <span className={"color-red"}><sup>*</sup></span>}</span>
                        <span>({evolution.lvl}/{evolution.lvl_max})</span>
                    </span>}
                </td>
                <td className={"colDir"}>
                    {chantiers && <div>
                        {chantiers.map((chantier) => (
                            <div key={"chantier_" + chantier.chantier.id_chantier}>
                                <SvgIcone icone={chantier.chantier.icon} />
                                <span>{t(chantier.chantier.nom, { ns: "chantiers" })}</span>
                            </div>
                        ))}
                    </div>}
                    {chantiersDest && <div>
                        {chantiersDest.map((chantier) => (
                            <div key={"chantier_" + chantier.chantier.id_chantier}>
                                <SvgIcone icone={chantier.chantier.icon} />
                                <span className={"color-red"}>{t(chantier.chantier.nom, { ns: "chantiers" })}</span>
                            </div>
                        ))}
                    </div>}
                </td>
            </tr>;
        })}
        </tbody>
    </table>;
}

export function ScrutateurLib(props: { journal: JournalDTO, level_regen: number[], size: number }) {
    const { t } = useTranslation();
    const journal = props.journal;
    const level_regen = props.level_regen;
    const size = props.size;
    
    const scrutInconnu = journal.regen_dir === null || journal.regen_dir === "Inconnue" || journal.regen_dir === "invalid direction";
    
    return <div className={"group-scrut"}>
		<span className={"infoBulle"}>
			{scrutInconnu && <Icon.BsQuestionCircleFill color={"red"} size={size + "px"} />}
            {!scrutInconnu && journal.direction_sans_article === "nord" && <Icon.BsFillArrowUpCircleFill size={size + "px"} />}
            {!scrutInconnu && journal.direction_sans_article === "est" && <Icon.BsFillArrowRightCircleFill size={size + "px"} />}
            {!scrutInconnu && journal.direction_sans_article === "sud" && <Icon.BsFillArrowDownCircleFill size={size + "px"} />}
            {!scrutInconnu && journal.direction_sans_article === "ouest" && <Icon.BsFillArrowLeftCircleFill size={size + "px"} />}
            {!scrutInconnu && journal.direction_sans_article === "nord-est" && <Icon.BsFillArrowUpRightCircleFill size={size + "px"} />}
            {!scrutInconnu && journal.direction_sans_article === "nord-ouest" && <Icon.BsFillArrowUpLeftCircleFill size={size + "px"} />}
            {!scrutInconnu && journal.direction_sans_article === "sud-est" && <Icon.BsFillArrowDownRightCircleFill size={size + "px"} />}
            {!scrutInconnu && journal.direction_sans_article === "sud-ouest" && <Icon.BsFillArrowDownLeftCircleFill size={size + "px"} />}
            {scrutInconnu && <span className={"info"}>{t("Direction inconnue", { ns: "hotel" })}</span>}
            {!scrutInconnu && <span className={"info"}>{t(journal.direction_translate, { ns: "hotel" })}</span>}
		</span>
        <span className={"regen-scrut"}><span className={"general_space"}><SvgIcone icone={"h_collec"} /></span><span>{level_regen[journal.day]}%</span></span>
    </div>;
    
}