import React, { useEffect, useState } from "react";
import { EstimationTdgDTO }           from "../../../types/models/estimationTdg.dto";
import { useTranslation }             from "react-i18next";

interface CalculAttaqueProps {
    jour: number,
    tdg: EstimationTdgDTO[],
    planif: EstimationTdgDTO[],
}

export default function CalculAttaque(props: CalculAttaqueProps) {
    const { t } = useTranslation();
    const [jour, setJour] = useState(props.jour);
    //const [tdg, setTdg] = useState(props.tdg);
    //const [planif, setPlanif] = useState(props.planif);
    
    useEffect(() => {
        setJour(props.jour);
    }, [props.jour]);
    
    /*useEffect(() => {
     setTdg(props.tdg);
     }, [props.tdg]);
     
     useEffect(() => {
     setPlanif(props.planif);
     }, [props.planif]);*/
    
    const calculAttaqueTheoriqueMin = (jour: number) => {
        return Math.round(((jour <= 3 ? 75 : 110) * Math.pow(Math.max(1, jour - 1) * 75 + 250, 3)) / 100000000);
    };
    const calculAttaqueTheoriqueMax = (jour: number) => {
        return Math.round(((jour <= 3 ? (jour <= 1 ? 50 : 75) : 110) * Math.pow(jour * 75 + 350, 3)) / 100000000);
    };
    
    return (
        <div id={"calcul_tdg_theorique"}>
            <fieldset>
                <legend>{t("Attaque théorique J{jour}", { ns: "hotel" }).replace("{jour}", jour.toString())}</legend>
                <span className={"min_max_theorique_tdg"}>
						<span>{t("Min :", { ns: "hotel" })}</span>
						<span>{calculAttaqueTheoriqueMin(jour)}</span>
					</span>
                <span className={"general_space"}>-</span>
                <span className={"min_max_theorique_tdg"}>
						<span>{t("Max :", { ns: "hotel" })}</span>
						<span>{calculAttaqueTheoriqueMax(jour)}</span>
					</span>
            </fieldset>
            <fieldset>
                <legend>{t("Attaque théorique J{jour}", { ns: "hotel" }).replace("{jour}", (jour + 1).toString())}</legend>
                <span className={"min_max_theorique_tdg"}>
						<span>{t("Min :", { ns: "hotel" })}</span>
						<span>{calculAttaqueTheoriqueMin(jour + 1)}</span>
					</span>
                <span className={"general_space"}>-</span>
                <span className={"min_max_theorique_tdg"}>
						<span>{t("Max :", { ns: "hotel" })}</span>
						<span>{calculAttaqueTheoriqueMax(jour + 1)}</span>
					</span>
            </fieldset>
        </div>
    );
}

