import React, { useState }        from "react";
import { ClassementAme }          from "../../types/components/Ame/ClassementAme";
import { ClassementPicto }        from "../../types/components/Ame/HistoriquePictos";
import SvgIcone                   from "../../components/generality/SvgIcone";
import Modal                      from "react-bootstrap/Modal";
import Button                     from "react-bootstrap/Button";
import { ClassementAmeApi }       from "../../services/api/ClassementAmeApi";
import { Link }                   from "react-router-dom";
import { useGeneralContext }      from "../../types/Context/GeneralContext";
import { Status_error, usePopUp } from "../../types/Context/PopUpContext";
import { PictoPrototypeDTO }      from "../../types/models/pictoPrototype.dto";
import { UserDTO }                from "../../types/models/user.dto";
import { useTranslation }         from "react-i18next";

interface ClassementAmeProps {
    classementAme: ClassementAme;
}

export default function ClassementAme({ classementAme }: ClassementAmeProps) {
    const { t } = useTranslation();
    const [typeAff, setTypeAff] = useState("general");
    const [classementPicto, setClassementPicto] = useState([]);
    const [pictoShow, setPictoShow] = useState(null);
    const [showPopPicto, setShowPopPicto] = useState(false);
    const { setStatus, setShowPop, setMessagePopUp } = usePopUp();
    const { general } = useGeneralContext();
    
    const inVille = general.inVille;
    const user = general.user;
    const listPicto = classementAme.listPicto;
    const classPicto = classementAme.classPicto;
    const classPictoVille = classementAme.classPictoVille;
    
    const handleGeneral = (picto: PictoPrototypeDTO) => {
        const classementApi = new ClassementAmeApi();
        
        classementApi.recup_picto_general(picto).then((response) => {
            if (response.data.classement !== undefined) {
                setShowPopPicto(true);
                setClassementPicto(response.data.classement);
                setPictoShow(response.data.picto);
            }
        }).catch((error) => {
            setShowPop(true);
            setMessagePopUp(error.message);
            setStatus(Status_error);
        });
    };
    const handleVille = (picto: PictoPrototypeDTO) => {
        const classementApi = new ClassementAmeApi();
        
        classementApi.recup_picto_ville(picto).then((response) => {
            
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0";
            
            setShowPopPicto(true);
            setClassementPicto(response.data.classement);
            setPictoShow(response.data.picto);
        }).catch((error) => {
            setShowPop(true);
            setMessagePopUp(error.data.error);
            setStatus(Status_error);
        });
    };
    const handleClosePicto = () => {
        setPictoShow(null);
        setShowPopPicto(false);
        setClassementPicto([]);
    };
    
    
    const genereBlocPicto = (picto: PictoPrototypeDTO, listClassement: ClassementPicto[], type_aff: string, user: UserDTO) => {
        return <div className={((picto.rare) ? "pictoRare" : "pictoCommun") + " tablePicto"} key={"bloc_picto_" + picto.id}>
            <div className="entete_classement_picto">
                <a onClick={(type_aff === "ville") ? () => handleVille(picto) : () => handleGeneral(picto)}><SvgIcone icone={picto.img} />{t(picto.name, { ns: "game" })} <span
                    className="infoBulle"><i className="fa-solid fa-circle-info"></i><span className="info">{t(picto.description, { ns: "game" })}</span></span></a>
            </div>
            <div>
                <table>
                    <tbody>
                    {Object.values(listClassement).map((classement, index) => {
                        return <tr className={(classement.user.id === user.id) ? "myLine" : ""} key={"class_" + index}>
                            <td className="tab_classement_picto_id">{index + 1}</td>
                            <td className="tab_classement_picto_pseudo"><Link to={"/ame/" + classement.user.id_my_hordes}>{classement.user.pseudo}</Link></td>
                            <td className="tab_classement_picto_nombre">{classement.nombre}</td>
                        </tr>;
                    })}
                    </tbody>
                </table>
            </div>
        </div>;
    };
    
    return <div id="corps_picto">
        {inVille && <div id="choix_listing">
            <label htmlFor="choix_classement_picto">{t("Choix d'affichage du classement :", { ns: "ame" })}</label>
            <select id="choix_classement_picto" name="choix_classement_picto" value={typeAff} onChange={(event) => {
                return setTypeAff(event.target.value);
            }}>
                <option value="general">{t("Général", { ns: "ame" })}</option>
                <option value="ville">{t("Ma ville", { ns: "ame" })}</option>
            </select>
        </div>}
        <div id="zoneListingPictosClassement">
            <div id="classementPicto_general">
                {typeAff === "general" && Object.values(listPicto).map((picto) => {
                    return genereBlocPicto(picto, classPicto[picto.id], typeAff, user);
                })}
                {typeAff === "ville" && Object.values(listPicto).map((picto) => {
                    return genereBlocPicto(picto, classPictoVille[picto.id], typeAff, user);
                })}
            </div>
        </div>
        <Modal show={showPopPicto} onHide={handleClosePicto} centered={true} scrollable={true}>
            <Modal.Header>
                <Modal.Title>{((typeAff === "ville") ? t("Classement de la ville pour :", { ns: "ame" }) : t("Classement pour :", { ns: "ame" })) + " "}{(pictoShow === null ? "" :
                    (<><SvgIcone icone={pictoShow.img} />{t(pictoShow.name, { ns: "game" })}</>))}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="classement_picto_part">
                    <table>
                        <thead>
                        <tr>
                            <th>{t("Pos", { ns: "ame" })}</th>
                            <th>{t("Pseudo", { ns: "ame" })}</th>
                            <th>{t("Nombre", { ns: "ame" })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.values(classementPicto).map((classement, index) => {
                            return <tr className={(classement.user.id === user.id) ? "myLine" : ""} key={"class_" + index}>
                                <td className="tab_classement_picto_id">{index + 1}</td>
                                <td className="tab_classement_picto_pseudo"><Link to={"/ame/" + classement.user.id_my_hordes}>{classement.user.pseudo}</Link></td>
                                <td className="tab_classement_picto_nombre">{classement.nombre}</td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePicto}>{t("Fermer", { ns: "app" })}</Button>
            </Modal.Footer>
        </Modal>
    </div>;
}

