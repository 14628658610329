import React                             from "react";
import { NumberField, useRecordContext } from "react-admin";

const ShiftNumberField = ({ source, adjustment }: { source: string, adjustment: number }) => {
    const record = useRecordContext();
    if (!record || record[source] === undefined) {
        return null;
    }
    
    const adjustedValue = record[source] - adjustment;
    
    return (
        <NumberField
            source={source}
            record={{ ...record, [source]: adjustedValue }}
        />
    );
};

export default ShiftNumberField;