import React, { useEffect, useState }                        from "react";
import Button                                                from "react-bootstrap/Button";
import Tabs                                                  from "react-bootstrap/Tabs";
import Tab                                                   from "react-bootstrap/Tab";
import Form                                                  from "react-bootstrap/Form";
import RangeSlider                                           from "react-bootstrap-range-slider";
import SvgIcone                                              from "../../components/generality/SvgIcone";
import ColorPicker                                           from "../../components/generality/ColorPickerGH";
import { OptionsPersoType }                                  from "../../types/components/OptionsPerso/OptionsPersoType";
import Carte                                                 from "../Ville/Carte/Carte";
import { OptionPersoApi }                                    from "../../services/api/OptionPersoApi";
import ColorMapVisu                                          from "./ColorMapVisu";
import Cookies                                               from "js-cookie";
import Select, { components, OptionProps, SingleValueProps } from "react-select";
import HTMLParser                                            from "html-react-parser";
import { formatInTimeZone }                                  from "date-fns-tz";
import SelectTimeZone                                        from "../../components/generality/ComposantGeneral/SelectTimeZone";
import GestionTheme                                          from "./GestionTheme";
import { Status_error, Status_success, usePopUp }            from "../../types/Context/PopUpContext";
import { useGeneralContext }                                 from "../../types/Context/GeneralContext";
import GestionDispo                                          from "./GestionDispo";
import { UserDTO }                                           from "../../types/models/user.dto";
import { ThemeUserDTO }                                      from "../../types/models/themeUser.dto";
import { useTranslation }                                    from "react-i18next";
import { HerosPrototypeDTO }                                 from "../../types/models/herosPrototype.dto";
import { CarteType }                                         from "../../types/components/ville/CarteType";
import { UserPersonnalisationDTO }                           from "../../types/models/userPersonnalisation.dto";
import AppercuCarteTaille                                    from "../../components/OptionsPerso/AppercuCarteTaille";

const { SingleValue, Option } = components;


export default function OptionsPerso(props: { options: OptionsPersoType }) {
    const { t } = useTranslation();
    const storedActiveTab = Cookies.get("activeTab_options_perso");
    
    const options = props.options.options;
    
    const [activeTab, setActiveTab] = useState(storedActiveTab || "perso");
    const [activeAlternative, setActiveAlternative] = useState(false);
    const [activeEstimation, setActiveEstimation] = useState(false);
    const [carte, setCarte] = useState<CarteType>(props.options.carte);
    const [exportColor, setExportColor] = useState("");
    const [isCopy, setIsCopy] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isErrorCopy, setIsErrorCopy] = useState(false);
    const [isErrorImport, setIsErrorImport] = useState(false);
    const [user, setUser] = useState<UserDTO>(props.options.user);
    const [theme, setTheme] = useState(user.theme);
    const [themeId, setThemeId] = useState(user.themes_user.find((theme: ThemeUserDTO) => theme.selected).id);
    const [showAttention, setShowAttention] = useState(false);
    const [showGestionTheme, setShowGestionTheme] = useState(false);
    const [showApercuTaille, setShowApercuTaille] = useState(false);
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const { general, setGeneral } = useGeneralContext();
    
    useEffect(() => {
        setCarte(props.options.carte);
        setUser(props.options.user);
    }, [props.options]);
    
    
    const IconSingleValue = (props: SingleValueProps<HerosPrototypeDTO>) => {
        return <SingleValue {...props}>
            <SvgIcone icone={props.data.icon} />
            <span>{t(props.data.nom, { ns: "game" })}</span>
        </SingleValue>;
    };
    const IconOption = (props: OptionProps<HerosPrototypeDTO>) => {
        return <Option {...props}>
            <SvgIcone icone={props.data.icon} />
            <span>{t(props.data.nom, { ns: "game" })}</span>
        </Option>;
    };
    const ColorSingleValue = (props: any) => {
        return <SingleValue {...props}>
            <ColorMapVisu colormapName={props.data.label} />
            <span>{props.data.label}</span>
        </SingleValue>;
    };
    const ColorOption = (props: any) => {
        return <Option {...props}>
            <ColorMapVisu colormapName={props.data.label} />
            <span>{props.data.label}</span>
        </Option>;
    };
    const customStyles = {
        option           : (provided) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        singleValue      : (provided) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        control          : (provided) => ({
            ...provided,
            minHeight: "24px",
            height   : "24px",
        }),
        input            : (provided) => ({
            ...provided,
            gridTemplateColumns: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "0",
        }),
        valueContainer   : (provided) => ({
            ...provided,
            padding: "0px 8px",
        }),
        menu             : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuList         : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuPortal       : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
    };
    
    const handleClose = () => {
        setMessagePopUp("");
        setShowPop(false);
        setStatus(Status_success);
    };
    
    const handleTabChange = (eventkey) => {
        setActiveTab(eventkey);
        Cookies.set("activeTab_options_perso", eventkey, { expires: 365 });
    };
    
    const handleUserChange = (updatedProperties: Partial<UserDTO>) => {
        // On met à jour les propriétés de l'utilisateur
        Object.assign(user, updatedProperties);
        setUser(user);
        
        const carteMod = { ...carte, ...{ user: user } };
        setCarte(carteMod);
        setShowAttention(true);
    };
    
    const handleUserPersoChange = (updatedProperties: Partial<UserPersonnalisationDTO>) => {
        // On met à jour les propriétés de l'utilisateur
        // on effectue une copie de l'objet user
        const userMod = { ...user };
        // on effectue une copie de l'objet user_personnalisation
        const userPersoMod = { ...user.user_personnalisation };
        // on met à jour les propriétés de l'objet user_personnalisation
        Object.assign(userPersoMod, updatedProperties);
        // on met à jour les propriétés de l'objet user
        userMod.user_personnalisation = userPersoMod;
        setUser(userMod);
        
        const carteMod = { ...carte, ...{ user: userMod } };
        setCarte(carteMod);
        setShowAttention(true);
    };
    
    const handleExportCouleur = async () => {
        const couleurExport = {
            "couleurScrut"      : user.user_personnalisation.couleur_scrut,
            "couleurKm"         : user.user_personnalisation.couleur_km,
            "couleurPos"        : user.user_personnalisation.couleur_pos,
            "couleurBat"        : user.user_personnalisation.couleur_bat,
            "couleurSelectObj"  : user.user_personnalisation.couleur_select_obj,
            "couleurSelectBat"  : user.user_personnalisation.couleur_select_bat,
            "couleurSelectCit"  : user.user_personnalisation.couleur_select_cit,
            "couleurVueAuj"     : user.user_personnalisation.couleur_vue_auj,
            "couleurVue24"      : user.user_personnalisation.couleur_vue24,
            "couleurVue48"      : user.user_personnalisation.couleur_vue48,
            "couleurPA"         : user.user_personnalisation.couleur_p_a,
            "couleurZone"       : user.user_personnalisation.couleur_zone,
            "colorSelExp"       : user.user_personnalisation.color_sel_exp,
            "couleurSelCaseMaj" : user.user_personnalisation.couleur_sel_case_maj,
            "couleurDanger0"    : user.user_personnalisation.couleur_danger0,
            "couleurDanger1"    : user.user_personnalisation.couleur_danger1,
            "couleurDanger2"    : user.user_personnalisation.couleur_danger2,
            "couleurDanger3"    : user.user_personnalisation.couleur_danger3,
            "couleurEpuise"     : user.user_personnalisation.couleur_epuise,
            "couleurCarte"      : user.user_personnalisation.couleur_carte,
            "couleurEstimZombie": user.user_personnalisation.color_zombie,
            "couleurFlag"       : user.user_personnalisation.color_flag,
            "couleurFlagFinish" : user.user_personnalisation.color_flag_finish,
            "couleurExpeInscrit": user.user_personnalisation.color_my_exp,
            "couleurTown"       : user.user_personnalisation.color_town,
            "couleurCity"       : user.user_personnalisation.color_city,
        };
        const textExport = JSON.stringify(couleurExport);
        setExportColor(textExport);
        try {
            await navigator.clipboard.writeText(textExport);
            setIsCopy(true);
            setTimeout(() => setIsCopy(false), 1500);
        } catch (error) {
            setIsErrorCopy(true);
        }
    };
    
    const handleImportCouleur = async () => {
        const regex = /^\{"couleurScrut":"#[0-9a-fA-F]{8}","couleurKm":"#[0-9a-fA-F]{8}","couleurPos":"#[0-9a-fA-F]{8}","couleurBat":"#[0-9a-fA-F]{8}","couleurSelectObj":"#[0-9a-fA-F]{8}","couleurSelectBat":"#[0-9a-fA-F]{8}","couleurSelectCit":"#[0-9a-fA-F]{8}","couleurVueAuj":"#[0-9a-fA-F]{8}","couleurVue24":"#[0-9a-fA-F]{8}","couleurVue48":"#[0-9a-fA-F]{8}","couleurPA":"#[0-9a-fA-F]{8}","couleurZone":"#[0-9a-fA-F]{8}","colorSelExp":"#[0-9a-fA-F]{8}","couleurSelCaseMaj":"#[0-9a-fA-F]{8}","couleurDanger0":"#[0-9a-fA-F]{8}","couleurDanger1":"#[0-9a-fA-F]{8}","couleurDanger2":"#[0-9a-fA-F]{8}","couleurDanger3":"#[0-9a-fA-F]{8}","couleurEpuise":"#[0-9a-fA-F]{8}","couleurCarte":"#[0-9a-fA-F]{8}","couleurEstimZombie":"#[0-9a-fA-F]{8}","couleurFlag":"#[0-9a-fA-F]{8}","couleurFlagFinish":"#[0-9a-fA-F]{8}","couleurExpeInscrit":"#[0-9a-fA-F]{8}","couleurTown":"#[0-9a-fA-F]{8}","couleurCity":"#[0-9a-fA-F]{8}"\}$/gm;
        
        if (regex.test(exportColor)) {
            const couleur = JSON.parse(exportColor);
            // Faire quelque chose avec l'objet JSON valide
            const userMod = {
                ...user,
                user_personnalisation: {
                    ...user.user_personnalisation,
                    ...{
                        couleur_scrut          : couleur.couleurScrut,
                        couleur_km             : couleur.couleurKm,
                        couleur_pos            : couleur.couleurPos,
                        couleur_bat            : couleur.couleurBat,
                        couleur_select_obj     : couleur.couleurSelectObj,
                        couleur_select_bat     : couleur.couleurSelectBat,
                        couleur_select_cit     : couleur.couleurSelectCit,
                        couleur_vue_auj        : couleur.couleurVueAuj,
                        couleur_vue24          : couleur.couleurVue24,
                        couleur_vue48          : couleur.couleurVue48,
                        couleur_p_a            : couleur.couleurPA,
                        couleur_zone           : couleur.couleurZone,
                        color_sel_exp          : couleur.colorSelExp,
                        couleur_sel_case_maj   : couleur.couleurSelCaseMaj,
                        couleur_danger0        : couleur.couleurDanger0,
                        couleur_danger1        : couleur.couleurDanger1,
                        couleur_danger2        : couleur.couleurDanger2,
                        couleur_danger3        : couleur.couleurDanger3,
                        couleur_epuise         : couleur.couleurEpuise,
                        couleur_carte          : couleur.couleurCarte,
                        color_estimation_zombie: couleur.couleurEstimZombie,
                        color_flag             : couleur.couleurFlag,
                        color_my_exp           : couleur.couleurExpeInscrit,
                        color_flag_finish      : couleur.couleurFlagFinish,
                        color_town             : couleur.couleurTown,
                        color_city             : couleur.couleurCity,
                    },
                },
            };
            setUser(userMod);
            const carteMod = { ...carte, ...{ user: userMod } };
            setCarte(carteMod);
            setShowAttention(true);
            
            setIsImport(true);
            window.setTimeout(function() {
                setIsImport(false);
            }, 1500);
            
        } else {
            setIsErrorImport(true);
            window.setTimeout(function() {
                setIsErrorImport(false);
            }, 5000);
        }
    };
    
    const choixDispo = (type: number, id_creneau: number) => {
        return <select name={"dispo_user_" + type + "_" + id_creneau} id={"dispo_user_" + type + "_" + id_creneau}
                       value={Object.values(user.dispo_types).find(d => {
                           return (type * 100 + id_creneau) === d.id;
                       })?.dispo?.id || 0}
                       onChange={(event) => {
                           const selectedDispoId = parseInt(event.target.value, 10);
                           const selectedDispo = options.dispoList.find((dispo) => dispo.id === selectedDispoId);
                           
                           if (selectedDispo) {
                               const updatedUser = { ...user };
                               
                               // Créez une copie de dispo_types pour mettre à jour l'élément spécifique
                               updatedUser.dispo_types = user.dispo_types;
                               
                               // Recherchez l'index correspondant dans le tableau dispo_types
                               const index = type * 100 + id_creneau;
                               
                               // Mettez à jour l'élément spécifique avec le nouvel objet disponible
                               updatedUser.dispo_types[index] = {
                                   ...updatedUser.dispo_types[index],
                                   dispo: selectedDispo,
                               };
                               
                               // Mettez à jour l'état du composant avec le nouvel objet user
                               setUser(updatedUser);
                               setShowAttention(true);
                           }
                       }}>
            {options.dispoList.map((dispo) => {
                return <option value={dispo.id} key={"dispo_" + type + "_" + id_creneau + "_" + dispo.id}>{t(dispo.nom, { ns: "jump" })}</option>;
            })}
        </select>;
    };
    
    useEffect(() => {
        document.documentElement.setAttribute("data-theme", theme);
    }, [theme]);
    const onSaveUser = () => {
        const optionPersoApi = new OptionPersoApi();
        optionPersoApi.majUser({ user: user, themeId: themeId }).then(response => {
            setUser(response.data.user);
            setStatus(Status_success);
            setShowPop(true);
            setMessagePopUp(response.data.libRetour);
            setShowAttention(false);
            setGeneral(response.data.general);
            setTimeout(() => {
                handleClose();
            }, 1000);
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error.data.error);
        });
    };
    
    const colormapObject = props.options.options.colormapList.map((option) => ({
        value: option,
        label: option,
    }));
    
    const customStylesFuseau = {
        option           : (provided, state) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: state.isDisabled ? "lightgrey" : "white",
            color          : state.isDisabled ? "grey" : "black",
            ":active"      : {
                ...provided[":active"],
                backgroundColor: !state.isDisabled && (state.isSelected ? "data.color" : "white"),
            },
        }),
        singleValue      : (provided) => ({
            ...provided,
            display        : "flex",
            flexDirection  : "row",
            alignItems     : "center",
            backgroundColor: "white",
            color          : "black",
        }),
        control          : (provided) => ({
            ...provided,
            minHeight: "24px",
            height   : "24px",
            width    : "400px",
        }),
        input            : (provided) => ({
            ...provided,
            gridTemplateColumns: "none",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: "0",
        }),
        valueContainer   : (provided) => ({
            ...provided,
            padding: "0px 8px",
        }),
        menu             : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
            width          : "400px",
        }),
        menuList         : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
        menuPortal       : (provided) => ({
            ...provided,
            backgroundColor: "white",
            color          : "black",
        }),
    };
    
    return <div className="espacePerso">
        {showAttention && <h4 style={{
            color         : "red",
            display       : "flex",
            alignContent  : "center",
            alignItems    : "center",
            gap           : "5px",
            justifyContent: "center",
            margin        : 0,
            textAlign     : "center",
            width         : "100%",
        }}>
            <i className="fa-solid fa-triangle-exclamation"></i>
            <span>{t("Attention, pensez bien à sauvegarder vos modifications !", { ns: "perso" })}</span>
            <i className="fa-solid fa-triangle-exclamation"></i>
        </h4>}
        <div id="info_gestion_perso">
            <div id="zone_option">
                <Tabs activeKey={activeTab} onSelect={handleTabChange}>
                    <Tab eventKey="perso" title={t("Perso", { ns: "perso" })}>
                        <div id="zone_perso">
                            <div id="zonePerso">
                                <fieldset>
                                    <legend><h2>{t("Gestion des informations personnelles", { ns: "perso" })}</h2></legend>
                                    <div id={"groupement_form_option_perso"}>
                                        <div className={"option_perso_form"}>
                                            <span>{t("Dernier pouvoir héros :", { ns: "perso" })}</span>
                                            <Select value={user.der_pouv} id={"option_der_pouv"} name={"option_der_pouv"} options={props.options.options.heroList}
                                                    components={{ SingleValue: IconSingleValue, Option: IconOption }} getOptionLabel={(hero) => hero.nom}
                                                    onChange={(optionSelected: HerosPrototypeDTO) => handleUserChange({ der_pouv: optionSelected })}
                                                    styles={customStyles} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_legend"}>{t("Légendaire :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_legend"} id={"option_legend"} type="switch" checked={user.legend}
                                                        onChange={(event) => handleUserChange({ legend: event.target.checked })} />
                                            <div>
                                                <span className="infoBulle">
                                                    <i className="fa fa-circle-info"></i>
                                                    <span className="infoHelpPerso">{t("Vous êtes légendaire quand vous possédez un réveil, c'est-à-dire que vous avez été dans une ville qui a finit première dans le classement RE et/ou Pandémonium", { ns: "perso" })}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_temArma"}>{t("Temoin Arma :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_temArma"} id={"option_temArma"} type="switch" checked={user.tem_arma}
                                                        onChange={(event) => handleUserChange({ tem_arma: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"} id="regroupement_charge_op">
                                            <label htmlFor={"option_nbChargeCamaraderie"}>{t("Nombre de charge de camaraderie disponible :", { ns: "perso" })}</label>
                                            <input type="number" id={"option_nbChargeCamaraderie"} name={"option_nbChargeCamaraderie"} min={0}
                                                   value={user.nb_charge_camaraderie} onChange={(event) => handleUserChange(
                                                { nb_charge_camaraderie: (parseInt(event.target.value, 10) < 0) ? 0 : parseInt(event.target.value, 10) })} />
                                            <div id="aide_charge_op">
                                                <span className="infoBulle">
                                                    <i className="fa fa-circle-info"></i>
                                                    <span
                                                        className="infoHelpPerso">{t("Le nombre de charge ne sera pas visible, seul le fait que vous possédez une charge de disponible le sera sur la page Citoyens. Par ailleurs, il se décrémentera à chaque fois que vous utiliserez une charge (en mettant à jour votre utilisation sur la page Citoyens).", { ns: "perso" })}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_show_histo"}>{t("Afficher votre historique de picto aux autres joueurs :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_show_histo"} id={"option_show_histo"} type="switch" checked={user.show_histo_picto}
                                                        onChange={(event) => handleUserChange({ show_histo_picto: event.target.checked })} />
                                            <div>
                                                <span className="infoBulle">
                                                    <i className="fa fa-circle-info"></i>
                                                    <span className="infoHelpPerso">{t("Option vous permettant d'afficher ou non à tous les autres joueurs vos gains de picto ville par ville lorsque des joueurs visitent votre âme.", { ns: "perso" })}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <span>{t("Couleur ville commune :", { ns: "perso" })}</span>
                                            <ColorPicker color={user.user_personnalisation.color_ville_commune} onChangeColor={(color) => handleUserPersoChange({ color_ville_commune: color })} />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Gestion des paramètres du site", { ns: "perso" })}</h2></legend>
                                    <div id={"groupement_theme_option_perso"}>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_maj_co"}>{t("Mettre à jour à la connexion (via l'annuaire MyHordes) :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_maj_co"} id={"option_maj_co"} type="switch" checked={user.user_personnalisation.maj_co}
                                                        onChange={(event) => handleUserPersoChange({ maj_co: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_keep_open"}>{t("Garder le menu du site ouvert tout le temps :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_keep_open"} id={"option_keep_open"} type="switch" checked={user.user_personnalisation.keep_menu_open}
                                                        onChange={(event) => handleUserPersoChange({ keep_menu_open: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_menu_hor"}>{t("Menu sous forme de bandeau horizontal en haut de page au lieu du mmenu \"Burger\" :", { ns: "perso" })}</label>
                                            <Form.Check
                                                name={"option_menu_hor"}
                                                id={"option_menu_hor"}
                                                type="switch"
                                                checked={user.user_personnalisation.menu_bandeau}
                                                onChange={(event) => handleUserPersoChange({ menu_bandeau: event.target.checked })}
                                            />
                                        </div>
                                    </div>
                                    <div id={"groupe_fuseau"}>
                                        <label>{t("Fuseau horaire préféré (modifie l'intégralité des heures sur GH) :", { ns: "perso" })}</label>
                                        <SelectTimeZone timeZone={user.fuseau} disabled={false} style={customStylesFuseau}
                                                        onUpdate={(timezone) => handleUserChange({ fuseau: timezone })} />
                                    </div>
                                    <fieldset>
                                        <legend>{t("Gestion des thèmes", { ns: "perso" })}</legend>
                                        <div id={"groupement_theme_gestion_option_perso"}>
                                            <div className={"option_perso_form"}>
                                                <label htmlFor={"options_theme"}>{t("Choix theme :", { ns: "perso" })}</label>
                                                <select id={"options_theme"} name={"options_theme"} value={user.themes_user.find((theme: ThemeUserDTO) => theme.selected).id}
                                                        onChange={(event) => {
                                                            const themeIdChoice = parseInt(event.target.value, 10);
                                                            setThemeId(themeIdChoice);
                                                            const themeChoice = user.themes_user.find((themeList: ThemeUserDTO) => themeList.id === themeIdChoice);
                                                            if (themeChoice) {
                                                                setTheme(themeChoice.base_theme);
                                                                // On désélectionne tous les thèmes pour ne sélectionner que celui choisi
                                                                const updatedUser = { ...user };
                                                                updatedUser.themes_user.forEach((themeList: ThemeUserDTO) => {
                                                                    themeList.selected = themeList.id === themeIdChoice;
                                                                });
                                                                updatedUser.theme = themeChoice.base_theme;
                                                                setUser(updatedUser);
                                                                // Il faut mettre à jour également les valeurs dans le general par rapport au theme choisi
                                                                const updatedGeneral = { ...general };
                                                                updatedGeneral.themeUser = themeChoice;
                                                                setGeneral(updatedGeneral);
                                                                setShowAttention(true);
                                                            }
                                                            
                                                        }}>
                                                    {Object.entries(user.themes_user).map(([index, theme]: [string, ThemeUserDTO]) => {
                                                        return <option value={theme.id} key={"theme_" + index}>{theme.nom}</option>;
                                                    })}
                                                </select>
                                            </div>
                                            <div className={"option_perso_form"}>
                                                <button type="button" id="gestion_theme" className="btn-sm btn btn-primary"
                                                        onClick={() => setShowGestionTheme(!showGestionTheme)}>{showGestionTheme ? t("Masquer la gestion des thèmes", { ns: "perso" }) : t("Afficher la gestion des thèmes", { ns: "perso" })}</button>
                                            </div>
                                        </div>
                                        {showGestionTheme && <GestionTheme user={user} baseThemeList={options.baseThemeList} themeName={options.themeList} onMajUser={setUser} />}
                                    </fieldset>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Paramètre de la page citoyens", { ns: "perso" })}</h2></legend>
                                    <div id={"groupement_citoyen_option_perso"}>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_modeCompact"}>{t("Afficher la page citoyens en mode compact :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_modeCompact"} id={"option_modeCompact"} type="switch" checked={user.user_personnalisation.citoyens_mode_compact}
                                                        onChange={(event) => handleUserPersoChange({ citoyens_mode_compact: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_blocMaj"}>{t("Bloquer la mise à jour de ses informations de la page Citoyens par les autres joueurs :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_blocMaj"} id={"option_blocMaj"} type="switch" checked={user.user_personnalisation.bloc_maj_citoyens}
                                                        onChange={(event) => handleUserPersoChange({ bloc_maj_citoyens: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_blocMaj_other"}>{t("Bloquer la mise à jour des informations des autres joueurs de la page Citoyens :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_blocMaj_other"} id={"option_blocMajOhter"} type="switch" checked={user.user_personnalisation.bloc_maj_citoyen}
                                                        onChange={(event) => handleUserPersoChange({ bloc_maj_citoyen: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_citoyen_on_top"}>{t("Se positionner en premier dans la page citoyen :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_citoyen_on_top"} id={"option_citoyen_on_top"} type="switch" checked={user.user_personnalisation.on_top_citoyen}
                                                        onChange={(event) => handleUserPersoChange({ on_top_citoyen: event.target.checked })} />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Gestion des options de la page Ruine", { ns: "perso" })}</h2></legend>
                                    <div id={"gestion_option_ruine"}>
                                        <div className={"choix_color_ruine_option"}>
                                            <span>{t("Couleur des escaliers montants", { ns: "perso" })}</span>
                                            <ColorPicker color={user.user_personnalisation.color_stair_up} onChangeColor={(color) => handleUserPersoChange({ color_stair_up: color })} />
                                        </div>
                                        <div className={"choix_color_ruine_option"}>
                                            <span>{t("Couleur des escaliers descendants", { ns: "perso" })}</span>
                                            <ColorPicker color={user.user_personnalisation.color_stair_down} onChangeColor={(color) => handleUserPersoChange({ color_stair_down: color })} />
                                        </div>
                                        <div className={"choix_color_ruine_option"}>
                                            <span>{t("Couleur de sélection des objets dans la ruine", { ns: "perso" })}</span>
                                            <ColorPicker color={user.user_personnalisation.color_select_objet_ruine}
                                                         onChangeColor={(color) => handleUserPersoChange({ color_select_objet_ruine: color })} />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="carte" title={t("Carte", { ns: "perso" })}>
                        <div id="zone_carte">
                            <div id="zoneCarte">
                                <fieldset id="gestionCouleurCarte">
                                    <legend><h2>{t("Gestion des différentes couleurs personnalisables sur la carte", { ns: "perso" })}</h2></legend>
                                    <p>{t("Modifiez les couleurs, elles changeront en même temps sur la mini carte à droite afin de prévisualiser directement les modifications.", { ns: "perso" })}</p>
                                    <div id="optionCouleurCarte">
                                        <div id="choixCouleurCarte">
                                            <div className={"gestion_option_couleur_carte"}>
                                                <span>{t("Couleur du scrut :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_scrut} onChangeColor={(color) => handleUserPersoChange({ couleur_scrut: color })} />
                                                <span>{t("Couleur KM :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_km} onChangeColor={(color) => handleUserPersoChange({ couleur_km: color })} />
                                                <span>{t("Couleur position :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_pos} onChangeColor={(color) => handleUserPersoChange({ couleur_pos: color })} />
                                                <span>{t("Couleur du bâtiment :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_bat} onChangeColor={(color) => handleUserPersoChange({ couleur_bat: color })} />
                                                <span>{t("Couleur de la ville (case) :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.color_town} onChangeColor={(color) => handleUserPersoChange({ color_town: color })} />
                                                <span>{t("Couleur de la ville (picto) :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.color_city} onChangeColor={(color) => handleUserPersoChange({ color_city: color })} />
                                                <span>{t("Couleur vue aujourd'hui :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_vue_auj} onChangeColor={(color) => handleUserPersoChange({ couleur_vue_auj: color })} />
                                                <span>{t("Couleur vue 24h :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_vue24} onChangeColor={(color) => handleUserPersoChange({ couleur_vue24: color })} />
                                                <span>{t("Couleur vue > 48h :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_vue48} onChangeColor={(color) => handleUserPersoChange({ couleur_vue48: color })} />
                                                <span>{t("Couleur PA :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_p_a} onChangeColor={(color) => handleUserPersoChange({ couleur_p_a: color })} />
                                                <span>{t("Couleur Zone PA :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_zone} onChangeColor={(color) => handleUserPersoChange({ couleur_zone: color })} />
                                                <span>{t("Couleur Zombie :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.color_zombie} onChangeColor={(color) => handleUserPersoChange({ color_zombie: color })} />
                                                <span>{t("Couleur épuisée :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_epuise} onChangeColor={(color) => handleUserPersoChange({ couleur_epuise: color })} />
                                                <span>{t("Couleur danger 0 :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_danger0} onChangeColor={(color) => handleUserPersoChange({ couleur_danger0: color })} />
                                                <span>{t("Couleur danger 1 :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_danger1} onChangeColor={(color) => handleUserPersoChange({ couleur_danger1: color })} />
                                                <span>{t("Couleur danger 2 :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_danger2} onChangeColor={(color) => handleUserPersoChange({ couleur_danger2: color })} />
                                                <span>{t("Couleur danger 3 :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_danger3} onChangeColor={(color) => handleUserPersoChange({ couleur_danger3: color })} />
                                                <span>{t("Couleur du drapeau des consignes :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.color_flag} onChangeColor={(color) => handleUserPersoChange({ color_flag: color })} />
                                                <span>{t("Couleur du drapeau des consignes finis :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.color_flag_finish} onChangeColor={(color) => handleUserPersoChange({ color_flag_finish: color })} />
                                                <label htmlFor={"option_carteTextured"}>{t("Ajouter de la texture à la carte :", { ns: "perso" })}</label>
                                                <Form.Check name={"option_carteTextured"} id={"option_carteTextured"} type="switch" checked={user.user_personnalisation.carte_textured}
                                                            onChange={(event) => handleUserPersoChange({ carte_textured: event.target.checked })} />
                                                <label htmlFor={"option_zombie_discret"}>{t("Zombie discret : ", { ns: "perso" })}</label>
                                                <Form.Check name={"option_zombie_discret"} id={"option_zombie_discret"} type="switch" checked={user.user_personnalisation.zombie_discret}
                                                            onChange={(event) => handleUserPersoChange({ zombie_discret: event.target.checked })} />
                                                <div id={"option_couleur_carte_choix"}>
                                                    <span className={"option_specifique"}>{t("Couleur de fond de carte :", { ns: "perso" })}</span>
                                                    <span className={"option_specifique infoBulle"}>
                                                        <i className="fa fa-circle-info"></i>
                                                        <span className="option_specifique infoHelpPerso">{t("Laissez transparent si vous voulez ne pas mettre de couleur supplémentaire", { ns: "perso" })}</span>
                                                    </span>
                                                </div>
                                                <ColorPicker color={user.user_personnalisation.couleur_carte} onChangeColor={(color) => handleUserPersoChange({ couleur_carte: color })} />
                                            
                                            </div>
                                            <hr style={{ width: "400px" }} />
                                            <p className="optionPerso_infoSelection">{t("Couleur concernant les différentes sélections sur la carte :", { ns: "perso" })}</p>
                                            <div className={"gestion_option_couleur_carte"}>
                                                <span>{t("Couleur sélection Objet :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_select_obj} onChangeColor={(color) => handleUserPersoChange({ couleur_select_obj: color })} />
                                                <span>{t("Couleur sélection Batiment :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_select_bat} onChangeColor={(color) => handleUserPersoChange({ couleur_select_bat: color })} />
                                                <span>{t("Couleur sélection Citoyen :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_select_cit} onChangeColor={(color) => handleUserPersoChange({ couleur_select_cit: color })} />
                                                <span>{t("Couleur des expés sélectionnés (dans le listing) :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.color_sel_exp} onChangeColor={(color) => handleUserPersoChange({ color_sel_exp: color })} />
                                                <span>{t("Couleur des expéditions où vous êtes inscrit :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.color_my_exp} onChangeColor={(color) => handleUserPersoChange({ color_my_exp: color })} />
                                                <span>{t("Couleur de bordure pour afficher le détail de la case :", { ns: "perso" })}</span>
                                                <ColorPicker color={user.user_personnalisation.couleur_sel_case_maj} onChangeColor={(color) => handleUserPersoChange({ couleur_sel_case_maj: color })} />
                                            
                                            </div>
                                        </div>
                                        <div id="zonePrevisu">
                                            <div id={"option_gestion_carte_alter"}>
                                                <div>
                                                    <label htmlFor={"option_active_alternative"}>{t("Activer la carte alternative", { ns: "perso" })}</label>
                                                    <Form.Check name={"option_active_alternative"} id={"option_active_alternative"} type="switch" checked={activeAlternative}
                                                                onChange={(event) => {
                                                                    const carteMod = { ...carte, ...{ carteOptionPerso_alter: event.target.checked } };
                                                                    setCarte(carteMod);
                                                                    setActiveAlternative(event.target.checked);
                                                                }} />
                                                </div>
                                                {activeAlternative && <>
                                                    <div className={"option_perso_gestion_couleur"} style={{ position: "relative", zIndex: 1000 }}>
                                                        <span>{t("Choix de la colormap :", { ns: "perso" })}</span>
                                                        <Select value={{ value: user.user_personnalisation.colormap, label: user.user_personnalisation.colormap }} id={"option_colormap"} name={"option_colormap"}
                                                                options={Object.values(colormapObject)} components={{ SingleValue: ColorSingleValue, Option: ColorOption }}
                                                                onChange={(optionSelected) => handleUserPersoChange({ colormap: optionSelected.label })}
                                                                styles={customStyles} />
                                                    </div>
                                                    <div>
                                                        <span>{t("Transparence de la colormap :", { ns: "perso" })}</span>
                                                        <RangeSlider value={user.user_personnalisation.alpha_colormap}
                                                                     onChange={e => handleUserPersoChange({ alpha_colormap: parseInt(e.target.value, 10) })} min={0} max={100}
                                                                     step={1} />
                                                    </div>
                                                </>}
                                            </div>
                                            <div id="miniCartePrevisu">
                                                <Carte carte={carte} />
                                            </div>
                                            <div id={"option_gestion_carte_estim"}>
                                                <div>
                                                    <label htmlFor={"option_active_estim"}>{t("Activer l'estimation de zombie", { ns: "perso" })}</label>
                                                    <Form.Check
                                                        name={"option_active_estim"}
                                                        id={"option_active_estim"}
                                                        type="switch"
                                                        checked={activeEstimation}
                                                        onChange={(event) => {
                                                            const carteMod = { ...carte, ...{ carteOptionPerso_estim: event.target.checked } };
                                                            setCarte(carteMod);
                                                            setActiveEstimation(event.target.checked);
                                                        }}
                                                    />
                                                </div>
                                                {activeEstimation && <div>
                                                    <span>{t("Couleur zombie estimée :", { ns: "perso" })}</span>
                                                    <ColorPicker color={user.user_personnalisation.color_estimation_zombie}
                                                                 onChangeColor={(color) => handleUserPersoChange({ color_estimation_zombie: color })} />
                                                </div>}
                                            </div>
                                            <div id={"option_gestion_resizable_carte"}>
                                                <div id={"option_resizable_carte"}>
                                                    <label htmlFor={"option_active_taille"}>{t("Activer la taille personnalisée", { ns: "perso" })}</label>
                                                    <Form.Check
                                                        name={"option_active_taille"}
                                                        id={"option_active_taille"}
                                                        type="switch"
                                                        checked={user.user_personnalisation.resizabled}
                                                        onChange={(event) => handleUserPersoChange({ resizabled: event.target.checked })}
                                                    />
                                                </div>
                                                {user.user_personnalisation.resizabled && <div id={"option_form_resizable_carte"}>
                                                    <fieldset id={"option_resizable_carte_range"}>
                                                        <legend>{t("Taille de la case (en px)", { ns: "perso" })}</legend>
                                                        <RangeSlider value={user.user_personnalisation.width_case} onChange={(e) => handleUserPersoChange({ width_case: parseInt(e.target.value, 10) })} min={10} max={50} step={1} />
                                                    </fieldset>
                                                    <button id={"option_show_apercu_carte_btn"} className={"btn btn-xs btn-primary"} onClick={() => setShowApercuTaille(!showApercuTaille)}>{(showApercuTaille) ? t("Masquer les dimensions de la carte", { ns: "perso" }) : t("Visualiser les dimensions de la carte", { ns: "perso" })}</button>
                                                    {showApercuTaille && <div>
                                                        <div id={"zoneApercuTaille"}>
                                                            <div>{t("Légende", { ns: "perso" })}</div>
                                                            <div>
                                                                <span style={{ width: "25px", height: "10px", display: "block", backgroundColor: "blue" }}></span>
                                                                <span>{t("Pour villes RE ou Pandémonium", { ns: "perso" })}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{ width: "25px", height: "10px", display: "block", backgroundColor: "red" }}></span>
                                                                <span>{t("Pour villes RNE", { ns: "perso" })}</span>
                                                            </div>
                                                        </div>
                                                        <AppercuCarteTaille tailleCase={user.user_personnalisation.width_case} />
                                                    </div>}
                                                </div>}
                                            </div>
                                            <div id="miniTabExpePrevisu">
                                                <table id="trace_carte">
                                                    <thead>
                                                    <tr>
                                                        <th className="nom_coul_tb_trace">{t("Nom expédition - Couleur", { ns: "perso" })}</th>
                                                        <th className="jour_tb_trace">{t("J", { ns: "ville" })}</th>
                                                        <th className="pa_tb_trace"><SvgIcone icone={"h_pa"} /></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {options.color.map((colExp, index) => {
                                                        return <tr className={"visualiser_expe" + ((index === 1) ? " selectedExpe" : ((index === 2) ? " myExpe" : ""))} key={"expe_" + index}>
                                                            <td className="nom_coul_tb_trace">
                                                                <div>{"Exp_" + index} <span className="list_couleur_trace" style={{ backgroundColor: `${colExp}` }}></span>
                                                                </div>
                                                            </td>
                                                            <td className="jour_tb_trace">2</td>
                                                            <td className="pa_tb_trace">19</td>
                                                        </tr>;
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="import_export_couleur">
                                        <div id="zone_bouton_export">
                                            <button type="button" id="export_couleur_perso" className="button btn-xs btn btn-success"
                                                    onClick={handleExportCouleur}>{(isErrorCopy) ? t("Appuyer sur \"Ctrl + C\" pour copier", { ns: "outils" }) : ((isCopy) ? t("Texte copié", { ns: "outils" }) : t("Exporter les couleurs et copier dans le presse-papier", { ns: "perso" }))}</button>
                                            <button type="button" id="import_couleur_perso" className="button btn-xs btn btn-warning"
                                                    onClick={handleImportCouleur}>{(isErrorImport) ? t("Le texte fournit n'est pas dans le bon format attendu.", { ns: "outils" }) : ((isImport) ? t("Couleurs correctement importées, vous pouvez sauvegarder", { ns: "perso" }) : t("Importer les couleurs", { ns: "perso" }))}</button>
                                        </div>
                                        <div id="zone_text_import_export">
                      <textarea name="export_import_couleur_option" id="export_import_couleur_option" cols={80} rows={5} value={exportColor}
                                onChange={(event) => setExportColor(event.target.value)}></textarea>
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Choix de l'affichage des informations sur la case", { ns: "perso" })}</h2></legend>
                                    <div id={"groupement_form_gestion_affichage"}>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_popUpClick"}>{t("Afficher la pop-up uniquement au clic :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_popUpClick"} id={"option_popUpClick"} type="switch" checked={user.user_personnalisation.pop_up_click}
                                                        onChange={(event) => handleUserPersoChange({ pop_up_click: event.target.checked })} />
                                        </div>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_affichage_citoyen"}>{t("Afficher le nombre de citoyens sur la case :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_affichage_citoyen"} id={"option_affichage_citoyen"} type="switch" checked={user.user_personnalisation.affichage_nb_citoyen}
                                                        onChange={(event) => handleUserPersoChange({ affichage_nb_citoyen: event.target.checked })} />
                                        </div>
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <legend><h2>{t("Choix de l'affichage des informations du menu", { ns: "perso" })}</h2></legend>
                                    <div id={"groupement_form_gestion_affichage"}>
                                        <div className={"option_perso_form"}>
                                            <label htmlFor={"option_menuFiger"}>{t("Figer le menu de la carte sur l'écran pour qu'il passe au dessus de la carte :", { ns: "perso" })}</label>
                                            <Form.Check name={"option_menuFiger"} id={"option_menuFiger"} type="switch" checked={user.user_personnalisation.fige_menu}
                                                        onChange={(event) => handleUserPersoChange({ fige_menu: event.target.checked })} />
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="jump" title={t("Jump", { ns: "perso" })}>
                        <div id="zone_opt_jump">
                            <div id="zoneoOptJump">
                                <div id="gestionDispoCreneau">
                                    <h2>{t("Gestion des dispos pour le préremplissage lors des inscriptions", { ns: "perso" })}</h2>
                                    <div className="intituleDispoOptionPerso">{t("Disponibilité type en semaine", { ns: "perso" })}</div>
                                    <div>
                                        <div className="ligneDispoOptionPerso">{Object.values(options.creneauList).map((creneau, index) => {
                                            return <div className="colonneDispoOptionPerso" key={"dispo_1_" + index}>
                                                <div><span>{HTMLParser(creneau.libelle)}</span></div>
                                                <div>{choixDispo(1, creneau.id)}</div>
                                            </div>;
                                        })}</div>
                                    </div>
                                    <div className="intituleDispoOptionPerso" id="titreDispoWeekendOptionPerso">{t("Disponibilité type en week-end", { ns: "perso" })}</div>
                                    <div>
                                        <div className="ligneDispoOptionPerso">{Object.values(options.creneauList).map((creneau, index) => {
                                            return <div className="colonneDispoOptionPerso" key={"dispo_2_" + index}>
                                                <div><span>{HTMLParser(creneau.libelle)}</span></div>
                                                <div>{choixDispo(2, creneau.id)}</div>
                                            </div>;
                                        })}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="expe" title={t("Expédition", { ns: "perso" })}>
                        <GestionDispo user={user}
                                      onUserChange={(userPartial: Partial<UserDTO>) => handleUserChange(userPartial)}
                                      onUserPersoChange={(userPersoPartial: Partial<UserPersonnalisationDTO>) => handleUserPersoChange(userPersoPartial)}
                                      dispo={options.creneauDispoExpe.dispo}
                                      creneau={options.creneauDispoExpe.creneau}
                                      onUser={(userMod) => setUser(userMod)} />
                    </Tab>
                </Tabs>
            </div>
        </div>
        <div className="zoneBoutonGestionOptionPerso">
            <div id={"option_bouton_gestion"}>
                <Button variant={"primary"} onClick={onSaveUser}>{t("Sauvegarder", { ns: "perso" })}</Button>
                <Button variant={"warning"} onClick={() => {
                    setUser(props.options.user);
                    setShowAttention(false);
                }}>{t("Annuler", { ns: "perso" })}</Button>
            </div>
            {user.date_maj !== null && <div id={"option_date_maj"}>
                <em>{t("Dernière mise à jour le {date}", { ns: "perso" }).replace("{date}",
                    formatInTimeZone(new Date(Date.parse(user.date_maj)), user.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" })))}</em>
            </div>}
        </div>
    </div>;
    
}