import React, {useEffect, useState} from "react";
import {CoalitionPropsType}         from "../../../types/components/Jump/CoalitionPropsType";
import SvgIcone                     from "../../../components/generality/SvgIcone";
import {InscriptionJumpApi}         from "../../../services/api/InscriptionJumpApi";
import {GestionJumpApi}             from "../../../services/api/GestionJumpApi";
import {BoutonCopy}                 from "../../../components/generality/ComposantGeneral";
import HTMLParser                   from "html-react-parser";
import {JobPrototypeDTO}            from "../../../types/models/jobPrototype.dto";
import {JumpDTO}                    from "../../../types/models/jump.dto";
import {InscriptionJumpDTO}         from "../../../types/models/inscriptionJump.dto";
import {useTranslation}             from "react-i18next";

export default function RepartitionCoalitions(props: {
    jump: JumpDTO,
    listInscription: InscriptionJumpDTO[],
    coalition: CoalitionPropsType,
    onRetour: (isError: boolean, showMod: boolean, messagePop: string) => void
    onChangeJump: (jump: JumpDTO) => void
    onChangeListInscrit: (listInscription: InscriptionJumpDTO[]) => void
}) {
    const {t} = useTranslation();
    const listJob = props.coalition.options.listJob;
    
    const [listInscription, setListInscription] = useState(props.listInscription);
    const [listJoueur, setListJoueur] = useState("");
    const [jump, setJump] = useState(props.jump);
    const [idInscUpdate, setIdInscUpdate] = useState(null);
    const [majFigeOk, setMajFigeOk] = useState(false);
    
    useEffect(() => {
        setListInscription(props.listInscription);
        setJump(props.jump);
    }, [props]);
    
    const handleChangeJob = (event, inscription: InscriptionJumpDTO) => {
        const selectedValue = parseInt(event.target.value, 10); // Conversion la valeur en nombre si nécessaire
        
        const inscriptionJumpApi = new InscriptionJumpApi();
        
        inscriptionJumpApi.majMetierInscription(
            {userId: props.coalition.user.id, idJump: props.coalition.jump.id, userIdMaj: inscription.user.id, idJob: selectedValue})
            .then((response) => {
                if (response.codeRetour === 0) {
                    setIdInscUpdate(inscription.user.id);
                    let job;
                    if (selectedValue === 0) {
                        job = null;
                    } else {
                        job = Object.values(listJob).find((jobItem) => jobItem.id === selectedValue);
                    }
                    
                    // Mise à jour de l'état outilsReparation en fonction de la sélection
                    const updatedInscription = Object.values(listInscription).map((inscriptionItem) => {
                        if (inscriptionItem.id === inscription.id) {
                            return {...inscriptionItem, metier_def: job};
                        } else {
                            return inscriptionItem;
                        }
                    });
                    
                    setListInscription(updatedInscription);
                    props.onChangeListInscrit(updatedInscription);
                    setTimeout(() => {
                        setIdInscUpdate(null);
                    }, 1000);
                } else {
                    props.onRetour(true, true, response.libRetour);
                }
            });
        
        
    };
    
    const handleChangeFige = (event) => {
        const gestionApi = new GestionJumpApi();
        const value = event.target.checked;
        
        gestionApi.majFigeMetier({userId: props.coalition.user.id, idJump: props.coalition.jump.id, fige: value})
            .then((response) => {
                if (response.codeRetour === 0) {
                    setJump(jump => ({...jump, job_fige: value}));
                    setMajFigeOk(true);
                    
                    setTimeout(() => {
                        setMajFigeOk(false);
                    }, 1000);
                    props.onChangeJump({...jump, job_fige: value});
                } else {
                    props.onRetour(true, true, response.libRetour);
                }
            });
    };
    
    const listSelectionMetierDef = (inscription: InscriptionJumpDTO) => {
        return <select name={"jobDef_" + inscription.user.id}
                       key={"jobDef_" + inscription.user.id}
                       disabled={!(props.coalition.options.isLeadOrOrga && !jump.job_fige)}
                       value={inscription.metier_def?.id ?? 0}
                       onChange={(event) => handleChangeJob(event, inscription)}
                       style={(idInscUpdate === inscription.user.id) ? {border: "5px solid green"} : {}}
        >
            <option value={0}>{t("Choix métier", {ns: "jumpEvent"})}</option>
            {Object.values(listJob).map((job) => {
                return <option value={job.id} key={"job_" + inscription.user.id + "_choice_" + job.id}>{t(job.alternatif, {ns: "game"})}</option>;
            })}
        </select>;
    };
    
    const handleGenereList = () => {
        // On va générer un tableau pour chaque métier et les joueurs retenus. On relayera ce tableau ensuite pour générer le texte avec
        // métier/joueurs associés
        const listJoueurJob = [];
        
        Object.values(listJob).forEach((job) => {
            const listJoueur = listInscription.filter((inscription) => inscription.metier_def?.id === job.id).map((inscription) => {
                return `@${inscription.user.pseudo.replace(/\s/g, "")}:${inscription.user.id_my_hordes}`;
            });
            listJoueurJob.push({job: job, listJoueur: listJoueur});
        });
        
        const listJoueur = listJoueurJob.map((jobLigne: { job: JobPrototypeDTO, listJoueur: string[] }) => {
            if (jobLigne.listJoueur.length !== 0) {
                return t(jobLigne.job.nom, {ns: "game"}) + " : " + jobLigne.listJoueur.join(", ") + "<br/>";
            }
        }).join("");
        setListJoueur(listJoueur);
    };
    
    return <div id="zone_repartition">
        <table id="coalition_compteur">
            <thead>
            <tr>
                <th className="coalition_title_number"></th>
                <th className="coalition_number">
                    <span className="infoBulle">J<span className="info">{t("Nombre de joueur dans le jump", {ns: "jumpEvent"})}</span></span>
                </th>
                {Object.values(listJob).map((job) => {
                    return <th className={"coatition_number"} key={"job_" + job.id}>
						<span className={"infoBulle"}>
							<SvgIcone icone={"h_" + job.icon}/>
							<span className={"info"}>{t(job.alternatif, {ns: "game"})}</span>
						</span>
                    </th>;
                })}
                <th className="coalition_number">
					<span className="infoBulle">
						<SvgIcone icone={"h_arma"}/>
						<span className="info">{t("Témoin de l'Arma", {ns: "jumpEvent"})}</span>
					</span>
                </th>
                <th className="coalition_number">
					<span className="infoBulle">
						<SvgIcone icone={"item_bplan_c"}/>
						<span className="info">{t("Architecte et plus", {ns: "jumpEvent"})}</span>
					</span>
                </th>
                <th className="coalition_number">
					<span className="infoBulle">
						<SvgIcone icone={"r_guard"}/>
						<span className="info">{t("Veilleur Pro", {ns: "jumpEvent"})}</span>
					</span>
                </th>
                <th className="coalition_number">
					<span className="infoBulle">
						<SvgIcone icone={"r_wintop"}/>
						<span className="info">{t("Légendaire", {ns: "jumpEvent"})}</span>
					</span>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th className="coalition_title_number">{t("Nombre", {ns: "jumpEvent"})}</th>
                <td className="coalition_number">{listInscription.length}</td>
                {Object.values(listJob).map((job) => {
                    return <td className="coalition_number" id={"nbJobCoa_" + job.id} key={"nbJobCoa_" + job.id}>{listInscription.filter(
                        (inscription) => inscription.metier_def?.id === job.id).length}</td>;
                })}
                <td className="coalition_number">{listInscription.filter((inscription) => inscription.user.tem_arma).length}</td>
                <td className="coalition_number">{listInscription.filter(
                    (inscription) => inscription.user.der_pouv.ordre_recup >= props.coalition.options.id_pouv_archi).length}</td>
                <td className="coalition_number">{listInscription.filter(
                    (inscription) => inscription.user.der_pouv.ordre_recup >= props.coalition.options.id_pouv_vp).length}</td>
                <td className="coalition_number">{listInscription.filter((inscription) => inscription.user.legend).length}</td>
            </tr>
            </tbody>
        
        </table>
        <div>
            <table id="repartitionMetier" className="fondWhite02">
                <thead>
                <tr>
                    <th className="repartition_job_pseudo">{t("Pseudo", {ns: "jumpEvent"})}</th>
                    <th className="repartition_job_leg"><SvgIcone icone={"item_reveil"}/></th>
                    <th className="repartition_job_arma"><SvgIcone icone={"h_arma"}/></th>
                    <th className="repartition_job_cama"><SvgIcone icone={"r_share"}/></th>
                    <th className="repartition_job_pouvoir">{t("Dernier Pouvoir", {ns: "jumpEvent"})}</th>
                    <th className="repartition_job_voeux">{t("Voeux n°1", {ns: "jumpEvent"})}</th>
                    <th className="repartition_job_voeux">{t("Voeux n°2", {ns: "jumpEvent"})}</th>
                    <th className="repartition_job_voeux">{t("Voeux n°3", {ns: "jumpEvent"})}</th>
                    <th className="repartition_job_choix">{t("Métier définitif", {ns: "jumpEvent"})}</th>
                </tr>
                </thead>
                <tbody>
                {listInscription.sort((a: InscriptionJumpDTO, b: InscriptionJumpDTO) => {
                    return a.user.pseudo.localeCompare(b.user.pseudo);
                }).map((inscription) => {
                    return <tr id={"metier_" + inscription.user.id} key={"metier_" + inscription.user.id}>
                        <td className="repartition_job_pseudo">
                            <a href={"/ame/" + inscription.user.id_my_hordes}>{inscription.user.pseudo}</a>
                            <a href={"https://myhordes.eu/jx/soul/" + inscription.user.id_my_hordes}><i className="fa-solid fa-arrow-up-right-from-square"></i></a>
                        </td>
                        <td className="repartition_job_leg">{inscription.user.legend && <i className="fas fa-check color-green"></i>}</td>
                        <td className="repartition_job_arma">{inscription.user.tem_arma && <i className="fas fa-check color-green"></i>}</td>
                        <td className="repartition_job_cama">{inscription.user.nb_charge_camaraderie > 0 && <i className="fas fa-check color-green"></i>}</td>
                        <td className="repartition_job_pouvoir"><SvgIcone icone={inscription.user.der_pouv.icon}/> {t(inscription.user.der_pouv.nom, {ns: "game"})}</td>
                        <td className="repartition_job_voeux"><SvgIcone icone={"h_" + inscription.voeux_metier1.icon}/> {t(inscription.voeux_metier1.alternatif, {ns: "jump"})}
                        </td>
                        <td className="repartition_job_voeux">{(inscription.voeux_metier2 === null) ? "" : (<><SvgIcone icone={"h_" + inscription.voeux_metier2.icon}/>{t(inscription.voeux_metier2.alternatif, {ns: "jump"})}</>)}</td>
                        <td className="repartition_job_voeux">{(inscription.voeux_metier3 === null) ? "" : (<><SvgIcone icone={"h_" + inscription.voeux_metier3.icon}/>{t(inscription.voeux_metier3.alternatif, {ns: "jump"})}</>)}</td>
                        <td className="repartition_job_choix">{listSelectionMetierDef(inscription)}</td>
                    </tr>;
                })}
                </tbody>
            </table>
            {props.coalition.options.isOrga && <div id="formRepartitionMetier">
                <input type="checkbox" name="figeMetier" id="figeMetier" checked={jump.job_fige} onChange={(event) => handleChangeFige(event)}/><label
                htmlFor="figeMetier" style={(majFigeOk) ? {color: "greenyellow"} : {}}>{t("Figer les métiers", {ns: "jumpEvent"})}</label>
            </div>}
            {props.coalition.options.isOrga && <fieldset>
                <legend>{t("Extraction des joueurs avec leur méter", {ns: "jumpEvent"})}</legend>
                <div id={"zone-btn-extraction-coalitions"}>
                    <button type={"button"} className={"btn btn-warning btn-sm"} onClick={handleGenereList}>{t("Générer le texte", {ns: "jumpEvent"})}</button>
                </div>
                <div id="contenuTextlistJoueurCoalitions">{HTMLParser(listJoueur)}</div>
                <BoutonCopy textAcopier={listJoueur} translate={{
                    boutonCopier: t("Texte copié", {ns: "hotel"}),
                    boutonNormal: t("Copier le texte", {ns: "hotel"}),
                    boutonKo    : t("Appuyer sur \"Ctrl + C\" pour copier", {ns: "hotel"}),
                }}/>
            </fieldset>}
        </div>
    </div>;
}

