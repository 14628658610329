import React, { useEffect, useState }       from "react";
import chargement                           from "../../../img/chargement.svg";
import { InscriptionJumpApi }               from "../../services/api/InscriptionJumpApi";
import { redirect, useNavigate, useParams } from "react-router-dom";
import JumpList                             from "../../containers/Jump/Inscription/JumpList";
import { useGeneralContext }                from "../../types/Context/GeneralContext";
import { useGHContext }                     from "../../types/Context/GHContext";
import { Status_error, usePopUp }           from "../../types/Context/PopUpContext";
import { useTranslation }                   from "react-i18next";


export function JumpListGeneral({ isArchMode = false }: { isArchMode?: boolean }) {
    const { general, setGeneral } = useGeneralContext();
    const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate, triggerRefresh } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [data, setData] = useState(null);
    const params = useParams();
    
    // Fonction pour recharger les données
    const reloadData = async () => {
        const inscriptionApi = new InscriptionJumpApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
        
        inscriptionApi.listJumpOnEvent({ idEvent: params.idEvent, isArchMode: isArchMode }).then((response) => {
            setIsOnRefresh(false);
            setIsOnUpdate(false);
            if (response.data.general !== undefined) {
                setGeneral(response.data.general);
                sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
            }
            if (response.data.event === undefined) {
                console.error("Erreur de chargement des données inscription jump list", response);
            } else {
                setData(response.data.event);
            }
        }).catch(error => {
            if (error.status === 409) {
                setStatus(Status_error);
                setMessagePopUp(error?.data?.error ?? error.message);
                setShowPop(true);
                setTimeout(() => {
                    setIsOnRefresh(false);
                    setIsOnUpdate(false);
                    setShowPop(false);
                    setMessagePopUp("");
                    navigate("/jump/inscription");
                    triggerRefresh();
                }, 2000);
            } else {
                setStatus(Status_error);
                setMessagePopUp(error?.data?.error ?? error.message);
                setShowPop(true);
                setTimeout(() => {
                    setIsOnRefresh(false);
                    setIsOnUpdate(false);
                    setShowPop(false);
                    setMessagePopUp("");
                    navigate("/");
                    triggerRefresh();
                }, 2000);
            }
        });
    };
    
    useEffect(() => {
        if (checkHabilitation("jump", "inscription")) {
            reloadData().then(r => r);
        } else {
            redirect("/news");
        }
    }, [refreshKey]);
    
    return (
        <>
            {data === null ? (
                <div className={"chargement_page"}>
                    <img src={chargement} alt="Drapeau" />
                    <span>{t("Chargement...", { ns: "app" })}</span>
                </div>
            ) : (
                <JumpList event={data} isArchMode={isArchMode} />
            )}
        
        </>
    );
}