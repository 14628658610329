import React, { useEffect, useState }                                                             from "react";
import type { Combinator, RuleGroupType, Translations }                                           from "react-querybuilder";
import { defaultRuleProcessorSQL, defaultValidator, QueryBuilder, ValueEditor, ValueEditorProps } from "react-querybuilder";
import { Field, Operator, ParameterizedNamedSQL, RuleType }                                       from "react-querybuilder/dist/cjs/react-querybuilder.cjs.development";
import { FiltreCitoyens, ListIdPouvoir }                                                          from "../../../types/components/Hotel/Citoyens";
import { QueryBuilderAntD }                                                                       from "@react-querybuilder/antd";
import CheckboxList                                                                               from "./CheckboxList";
import { HerosPrototypeDTO }                                                                      from "../../../types/models/herosPrototype.dto";
import { useTranslation }                                                                         from "react-i18next";

const initialQuery: RuleGroupType = { combinator: "and", rules: [] };
/*const customRuleProcessor: RuleProcessor = (rule, options) => {
 if (rule.operator === "poss") {
 return `${rule.field} =1`;
 }
 if (rule.operator === "notPoss") {
 return `${rule.field} =0`;
 }
 if (rule.operator === "done") {
 return `${rule.field} =1`;
 }
 if (rule.operator === "notDone") {
 return `${rule.field} =0`;
 }
 
 return defaultRuleProcessorSQL(rule, options);
 };
 */
const MultiSelectValidator = (r: RuleType) => {
    // Récupère les propriétés de la règle
    const { operator, value } = r;
    
    // Vérifie le nombre de valeurs sélectionnées pour certains opérateurs
    if (["<", "<=", ">", ">="].includes(operator) && Array.isArray(value) && value.length > 1) {
        return { valid: false };
    }
    // Vérifie le nombre de valeurs sélectionnées pour certains opérateurs
    if (["in", "notIn", "<", "<=", ">", ">="].includes(operator) && Array.isArray(value) && value.length === 0) {
        return { valid: false };
    }
};

const CustomValueEditor = (props: ValueEditorProps) => {
    if (props.fieldData.datatype === "multiCheckbox") {
        return (
            <div className={"citoyen_checkbox"}>
                <CheckboxList values={props.values} onChange={props.handleOnChange} valueChecked={props.value} />
            </div>
        );
    }
    return <ValueEditor {...props} />;
};

const CitoyensQueryBuilder = (props: {
          filtre: FiltreCitoyens, listIdPouvoir: ListIdPouvoir,
          onFiltre: (querySql: ParameterizedNamedSQL, query: RuleGroupType) => void, onReinit: (boolean) => void, onError: (error: string) => void,
          query_filtre: RuleGroupType | null
      }) => {
          const { t } = useTranslation();
          const [query, setQuery] = useState((props.query_filtre === null) ? initialQuery : props.query_filtre);
          const [validationErrors, setValidationErrors] = useState({});
          
          useEffect(() => {
              setQuery((props.query_filtre === null) ? initialQuery : props.query_filtre);
          }, [props.query_filtre]);
          
          const listIdPouvoir = props.listIdPouvoir;
          const filtre = props.filtre;
          const operators: Operator[] = [
              { name: "=", label: t("est égal à", { ns: "app" }) },
              { name: "!=", label: t("n'est pas égal à", { ns: "app" }) },
              { name: "in", label: t("est égal à", { ns: "app" }) },
              { name: "notIn", label: t("n'est pas égal à", { ns: "app" }) },
              { name: "<", label: t("est inférieur à", { ns: "app" }) },
              { name: ">", label: t("est supérieur à", { ns: "app" }) },
              { name: "<=", label: t("est inférieur ou égal à", { ns: "app" }) },
              { name: ">=", label: t("est supérieur ou égal à", { ns: "app" }) },
              { name: "poss", label: t("disponible", { ns: "app" }), arity: "unary" },
              { name: "notPoss", label: t("plus disponible", { ns: "app" }), arity: "unary" },
              { name: "done", label: t("faite", { ns: "app" }), arity: "unary" },
              { name: "notDone", label: t("non faite", { ns: "app" }), arity: "unary" },
              { name: "had", label: t("oui", { ns: "app" }), arity: "unary" },
              { name: "notHad", label: t("non", { ns: "app" }), arity: "unary" },
              { name: "camara1", label: t("disponible", { ns: "app" }), arity: "unary" },
              { name: "camaraUse", label: t("utilisée", { ns: "app" }), arity: "unary" },
              { name: "camara0", label: t("plus disponible", { ns: "app" }), arity: "unary" },
              { name: "camaraNot", label: t("non débloquée", { ns: "app" }), arity: "unary" },
          ];
          const combinators: Combinator[] = [
              { name: "and", label: t("Et", { ns: "app" }) },
              { name: "or", label: t("Ou", { ns: "app" }) },
          ];
          const translations: Partial<Translations> = {
              "removeRule"    : {
                  "label": "x",
                  "title": t("Supprimer la règle", { ns: "hotel" }),
              },
              "removeGroup"   : {
                  "label": "x",
                  "title": t("Supprimer groupe", { ns: "hotel" }),
              },
              "addRule"       : {
                  "label": t("Ajouter une règle", { ns: "hotel" }),
                  "title": t("Ajouter une règle", { ns: "hotel" }),
              },
              "addGroup"      : {
                  "label": t("Ajouter un groupe", { ns: "hotel" }),
                  "title": t("Ajouter un groupe", { ns: "hotel" }),
              },
              "notToggle"     : {
                  "label": t("Inverser", { ns: "hotel" }),
                  "title": t("Inverser se groupe", { ns: "hotel" }),
              },
              "cloneRule"     : {
                  "label": "⧉",
                  "title": t("Cloner la règle", { ns: "hotel" }),
              },
              "cloneRuleGroup": {
                  "label": "⧉",
                  "title": t("Cloner le groupe", { ns: "hotel" }),
              },
          };
          
          const tableNameLabel = {
              "c.job"          : t("Métier", { ns: "hotel" }),
              "c.chargeApag"   : t("APAG", { ns: "hotel" }),
              "c.lvlRuine"     : t("Lvl Ruine", { ns: "hotel" }),
              "c.nbCamping"    : t("Nombre de camping", { ns: "hotel" }),
              "c.lvlMaison"    : t("Habitation", { ns: "game" }),
              "c.lvlCoinSieste": t("Coin Sieste", { ns: "game" }),
              "c.lvlCuisine"   : t("Cuisine", { ns: "game" }),
              "c.lvlLabo"      : t("Laboratoire", { ns: "game" }),
              "c.lvlRenfort"   : t("Renfort", { ns: "game" }),
              "c.lvlRangement" : t("Rangement", { ns: "game" }),
              "c.nbCarton"     : t("Cartons", { ns: "game" }),
              "c.nbBarricade"  : t("Barricade", { ns: "game" }),
          };
          
          const fields: Field[] = [
              {
                  name     : "c.job",
                  label    : t("Métier", { ns: "hotel" }),
                  datatype : "multiCheckbox",
                  values   : filtre.listJob.map((job) => ({ name: (job.id).toString(), label: job.nom, icone: (job.icon === "") ? null : "h_" + job.icon })),
                  operators: [
                      ...operators.filter(op => ["in", "notIn"].includes(op.name)),
                  ],
                  validator: MultiSelectValidator,
                  
              },
              {
                  name           : "c.immuniser",
                  label          : t("Immunisé", { ns: "hotel" }),
                  operators      : operators.filter((op) => op.name === "="),
                  valueEditorType: "radio",
                  values         : [
                      { name: "0", label: t("Non", { ns: "app" }) },
                      { name: "1", label: t("Oui", { ns: "app" }) },
                  ],
              },
              {
                  name           : "c.dehors",
                  label          : t("Position du joueur", { ns: "hotel" }),
                  operators      : operators.filter((op) => op.name === "="),
                  valueEditorType: "radio",
                  values         : [
                      { name: "0", label: t("En ville ou aux portes", { ns: "app" }) },
                      { name: "1", label: t("Dehors", { ns: "app" }) },
                  ],
              },
              {
                  name     : `h.ordre_recup >= ${listIdPouvoir.poss_apag} AND c.chargeApag`,
                  label    : t("APAG", { ns: "hotel" }),
                  operators: [
                      ...operators.filter(op => ["in", "notIn", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  datatype : "multiCheckbox",
                  values   : [
                      { name: "0", label: t("0 charge", { ns: "hotel" }) },
                      { name: "1", label: t("1 charge", { ns: "hotel" }) },
                      { name: "2", label: t("2 charges", { ns: "hotel" }) },
                      { name: "3", label: t("3 charges", { ns: "hotel" }) },
                  ],
                  validator: MultiSelectValidator,
              },
              {
                  name     : "c.lvlRuine",
                  label    : t("Lvl Ruine", { ns: "hotel" }),
                  operators: [
                      ...operators.filter(op => ["in", "notIn", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  datatype : "multiCheckbox",
                  values   : [
                      { name: "0", label: "0" },
                      { name: "1", label: "1" },
                      { name: "2", label: "2" },
                      { name: "3", label: "3" },
                      { name: "4", label: "4" },
                  ],
                  validator: MultiSelectValidator,
              },
              {
                  name     : "c.nbCamping",
                  label    : t("Nombre de camping", { ns: "hotel" }),
                  operators: [
                      ...operators.filter(op => ["in", "notIn", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  datatype : "multiCheckbox",
                  values   : [
                      { name: "0", label: "0" },
                      { name: "1", label: "1" },
                      { name: "2", label: "2" },
                      { name: "3", label: "3" },
                      { name: "4", label: "4" },
                      { name: "5", label: "5" },
                      { name: "6", label: "6" },
                      { name: "7", label: "7" },
                      { name: "8", label: "8" },
                      { name: "9", label: "9" },
                      { name: "10", label: "10" },
                  ],
                  validator: MultiSelectValidator,
              },
              {
                  name           : "c.ban",
                  label          : t("Citoyen banni", { ns: "hotel" }),
                  operators      : operators.filter((op) => op.name === "="),
                  valueEditorType: "radio",
                  values         : [
                      { name: "0", label: t("Non", { ns: "app" }) },
                      { name: "1", label: t("Oui", { ns: "app" }) },
                  ],
              },
              {
                  name           : "u.legend",
                  label          : t("Légendaire", { ns: "game" }),
                  operators      : operators.filter((op) => op.name === "="),
                  valueEditorType: "radio",
                  values         : [
                      { name: "0", label: t("Non", { ns: "app" }) },
                      { name: "1", label: t("Oui", { ns: "app" }) },
                  ],
              },
              {
                  name     : "u.derPouv",
                  label    : t("Pouvoir Héros", { ns: "game" }),
                  datatype : "multiCheckbox",
                  values   : Object.values(filtre.listPouvoir).sort((heros_a: HerosPrototypeDTO, heros_b: HerosPrototypeDTO) => {
                      return (heros_a.ordre_recup > heros_b.ordre_recup) ? 1 : -1;
                  }).map((heros) => ({ name: heros.id.toString(), label: heros.nom, icone: heros.icon })),
                  operators: [
                      ...operators.filter(op => ["in", "notIn"].includes(op.name)),
                  ],
              },
              {
                  name     : "c.sauvetage",
                  label    : t("Sauvetage", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["poss", "notPoss"].includes(op.name)),
                  ],
              },
              {
                  name     : "c.rdh",
                  label    : t("RDH", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["poss", "notPoss"].includes(op.name)),
                  ],
              },
              {
                  name     : "c.uppercut",
                  label    : t("Uppercut", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["poss", "notPoss"].includes(op.name)),
                  ],
              },
              {
                  name     : "u.nbChargeCamaraderie",
                  label    : t("Camaraderie", { ns: "hotel" }),
                  operators: [
                      ...operators.filter(op => ["camara1", "camara0", "camaraNot"].includes(op.name)),
                  ],
              },
              {
                  name     : "c.chargeCamaraderie",
                  label    : t("Camaraderie reçue", { ns: "hotel" }),
                  operators: [
                      ...operators.filter(op => ["had", "notHad"].includes(op.name)),
                  ],
              },
              {
                  name     : `h.ordre_recup < ${listIdPouvoir.trouvaille_ame} AND c.trouvaille`,
                  label    : t("Trouvaille", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["poss", "notPoss"].includes(op.name)),
                  ],
              },
              {
                  name     : `h.ordre_recup >= ${listIdPouvoir.corps_sain} AND c.corpsSain`,
                  label    : t("Corps Sain", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["poss", "notPoss"].includes(op.name)),
                  ],
              },
              {
                  name     : `h.ordre_recup >= ${listIdPouvoir.second_souffle} AND c.secondSouffle`,
                  label    : t("Second Souffle", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["poss", "notPoss"].includes(op.name)),
                  ],
              },
              {
                  name     : `h.ordre_recup >= ${listIdPouvoir.trouvaille_ame} AND c.trouvaille`,
                  label    : t("Jolies trouvailles", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["poss", "notPoss"].includes(op.name)),
                  ],
              },
              {
                  name     : `h.ordre_recup >= ${listIdPouvoir.vlm} AND c.vlm`,
                  label    : t("Vaincre la Mort", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["poss", "notPoss"].includes(op.name)),
                  ],
              },
              {
                  name     : "u.temArma = 1 AND c.pef",
                  label    : t("Passage en Force", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["poss", "notPoss"].includes(op.name)),
                  ],
              },
              {
                  name     : "c.lvlMaison",
                  label    : t("Habitation", { ns: "game" }),
                  datatype : "multiCheckbox",
                  values   : Object.values(filtre.listMaison).map((hab) => ({ name: (hab.id).toString(), label: hab.nom, icone: "home_" + hab.icon })),
                  operators: [
                      ...operators.filter(op => ["in", "notIn"].includes(op.name)),
                  ],
              },
              {
                  name     : "c.lvlCoinSieste",
                  label    : t("Coin Sieste", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["in", "notIn", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  datatype : "multiCheckbox",
                  values   : Array.from({ length: Object.keys(filtre.listAmelio[0].levels).length + 1 }, (_, i) => i)
                      .map((value) => ({ name: (value).toString(), label: (value).toString() })),
                  validator: MultiSelectValidator,
              },
              {
                  name     : "c.lvlCuisine",
                  label    : t("Cuisine", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["in", "notIn", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  datatype : "multiCheckbox",
                  values   : Array.from({ length: Object.keys(filtre.listAmelio[1].levels).length + 1 }, (_, i) => i)
                      .map((value) => ({ name: (value).toString(), label: (value).toString() })),
                  validator: MultiSelectValidator,
              },
              {
                  name     : "c.lvlLabo",
                  label    : t("Laboratoire", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["in", "notIn", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  datatype : "multiCheckbox",
                  values   : Array.from({ length: Object.keys(filtre.listAmelio[2].levels).length + 1 }, (_, i) => i)
                      .map((value) => ({ name: (value).toString(), label: (value).toString() })),
                  validator: MultiSelectValidator,
              },
              {
                  name     : "c.lvlRenfort",
                  label    : t("Renfort", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["in", "notIn", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  datatype : "multiCheckbox",
                  values   : Array.from({ length: Object.keys(filtre.listAmelio[3].levels).length + 1 }, (_, i) => i)
                      .map((value) => ({ name: (value).toString(), label: (value).toString() })),
                  validator: MultiSelectValidator,
              },
              {
                  name     : "c.lvlRangement",
                  label    : t("Rangement", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["in", "notIn", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  datatype : "multiCheckbox",
                  values   : Array.from({ length: Object.keys(filtre.listAmelio[4].levels).length + 1 }, (_, i) => i)
                      .map((value) => ({ name: (value).toString(), label: (value).toString() })),
                  validator: MultiSelectValidator,
              },
              {
                  name           : "c.cloture",
                  label          : t("Clôture", { ns: "game" }),
                  valueEditorType: "checkbox",
                  operators      : [
                      ...operators.filter(op => ["done", "notDone"].includes(op.name)),
                  ],
              },
              {
                  name     : "c.nbCarton",
                  label    : t("Cartons", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["=", "!=", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  inputType: "number",
              },
              {
                  name     : "c.nbBarricade",
                  label    : t("Barricade", { ns: "game" }),
                  operators: [
                      ...operators.filter(op => ["=", "!=", "<", "<=", ">", ">="].includes(op.name)),
                  ],
                  inputType: "number",
              },
          
          ];
          
          const handleQueryChange = (newQuery) => {
              
              setQuery(newQuery);
              
              // Effectue la validation lors du changement de la requête
              const errors = {};
              const validateRule = (rule) => {
                  if (rule.rules) {
                      if (rule.rules.length === 0) {
                          errors[rule.id] = [t("Vous devez au moins avoir une règle dans le groupe", { ns: "hotel" })];
                      } else {
                          rule.rules.forEach(validateRule);
                      }
                  } else {
                      if (!rule.field || rule.field === "~") {
                          errors[rule.id] = [t("Vous devez au moins avoir une règle", { ns: "hotel" })];
                      }
                      if (["<", "<=", ">", ">="].includes(rule.operator) && Array.isArray(rule.value) && rule.value.length > 1) {
                          errors[rule.id] = [t("Ne sélectionnez qu'une seule valeur pour {field}", { ns: "hotel" }).replace("{field}", tableNameLabel[rule.field])];
                      }
                      // Vérifie le nombre de valeurs sélectionnées pour certains opérateurs
                      if (["in", "notIn", "<", "<=", ">", ">="].includes(rule.operator) && Array.isArray(rule.value) && rule.value.length === 0) {
                          errors[rule.id] = [t("Vous devez sélectionner au moins une valeur pour {field}", { ns: "hotel" }).replace("{field}", tableNameLabel[rule.field])];
                      }
                  }
              };
              
              if (newQuery.rules.length !== 0) {
                  newQuery.rules.forEach(validateRule);
              }
              
              setValidationErrors(errors);
          };
          
          const formatQueryPerso = (query, options) => {
              const parameters = {};
              const customOperators = {
                  camara1  : (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 0;
                      return `(${field} > :param_${Object.keys(parameters).length} AND c.donJh = 1)`;
                  },
                  camaraUse: (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 0;
                      return `(${field} >= :param_${Object.keys(parameters).length} AND c.donJh = 0 AND h.ordre_recup >= ${listIdPouvoir.camaraderie})`;
                  },
                  camara0  : (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 0;
                      return `((${field} >= :param_${Object.keys(parameters).length} AND c.donJh = 0) OR (${field} = :param_${Object.keys(
                          parameters).length} AND c.donJh = 1) AND h.ordre_recup >= ${listIdPouvoir.camaraderie})`;
                  },
                  camaraNot: (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 0;
                      return `(${field} >= :param_${Object.keys(parameters).length}  AND h.ordre_recup < ${listIdPouvoir.camaraderie})`;
                  },
                  poss     : (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 1;
                      return `(${field} = :param_${Object.keys(parameters).length})`;
                  },
                  notPoss  : (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 0;
                      return `(${field} = :param_${Object.keys(parameters).length})`;
                  },
                  done     : (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 1;
                      return `(${field} = :param_${Object.keys(parameters).length})`;
                  },
                  notDone  : (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 0;
                      return `(${field} = :param_${Object.keys(parameters).length})`;
                  },
                  had      : (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 1;
                      return `(${field} = :param_${Object.keys(parameters).length})`;
                  },
                  notHad   : (field) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = 0;
                      return `(${field} = :param_${Object.keys(parameters).length})`;
                  },
                  "="      : (field, value) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = (value.length === 1) ? parseInt(value[0], 10) : "";
                      return `(${field} = :param_${Object.keys(parameters).length})`;
                  },
                  "!="     : (field, value) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = (value.length === 1) ? parseInt(value[0], 10) : "";
                      return `(${field} <> :param_${Object.keys(parameters).length})`;
                  },
                  "<"      : (field, value) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = (value.length === 1) ? parseInt(value[0], 10) : "";
                      return `(${field} < :param_${Object.keys(parameters).length})`;
                  },
                  "<="     : (field, value) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = (value.length === 1) ? parseInt(value[0], 10) : "";
                      return `(${field} <= :param_${Object.keys(parameters).length})`;
                  },
                  ">"      : (field, value) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = (value.length === 1) ? parseInt(value[0], 10) : "";
                      return `(${field} > :param_${Object.keys(parameters).length})`;
                  },
                  ">="     : (field, value) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = (value.length === 1) ? parseInt(value[0], 10) : "";
                      return `(${field} >= :param_${Object.keys(parameters).length})`;
                  },
                  in       : (field, values) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = (Array.isArray(values) && values.length > 0) ? values.map(value => {
                          return parseInt(value, 10);
                      }) : [];
                      return `(${field} IN (:param_${Object.keys(parameters).length}))`;
                  },
                  notIn    : (field, values) => {
                      parameters[`param_${Object.keys(parameters).length + 1}`] = (Array.isArray(values) && values.length > 0) ? values.map(value => {
                          return parseInt(value, 10);
                      }) : [];
                      return `(${field} NOT IN (:param_${Object.keys(parameters).length}))`;
                  },
              };
              
              
              const formatRule = (rule) => {
                  const operator = customOperators[rule.operator];
                  
                  if (operator) {
                      // Utilisez la logique spécifique de l'opérateur pour générer la partie de requête et stocker le paramètre nommé correspondant
                      return operator(rule.field, rule.value);
                  }
                  
                  // Utilisez le processeur de règles par défaut pour les opérateurs standard
                  return defaultRuleProcessorSQL(rule, options);
              };
              
              const formatGroup = (group) => {
                  const formattedRules = group.rules.map(formatRule).join(" " + group.combinator + " ");
                  return `(${formattedRules})`;
              };
              
              const formatRules = (rules) => {
                  return rules.map(rule => {
                      if (rule.rules) {
                          return formatGroup(rule); // Formater les groupes de règles
                      } else {
                          return formatRule(rule); // Formater les règles individuelles
                      }
                  }).join(" " + query.combinator + " ");
              };
              
              const formattedQuery = formatRules(query.rules);
              
              return {
                  sql   : formattedQuery,
                  params: parameters,
              };
          };
          
          const querySqlPerso: ParameterizedNamedSQL = formatQueryPerso(query, { format: "parameterized_named", parseNumbers: true });
          
          return (
              <QueryBuilderAntD>
                  <QueryBuilder
                      fields={fields}
                      query={query}
                      operators={operators}
                      combinators={combinators}
                      onQueryChange={handleQueryChange}
                      autoSelectField={true}
                      autoSelectOperator={true}
                      addRuleToNewGroups={true}
                      resetOnFieldChange={true}
                      resetOnOperatorChange={true}
                      showCloneButtons
                      showNotToggle
                      listsAsArrays
                      validator={defaultValidator}
                      translations={translations}
                      controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
                      controlElements={{ valueEditor: CustomValueEditor }}
                  />
                  {Object.keys(validationErrors).map((ruleId) => (
                      <div key={ruleId}>
                          <span>{validationErrors[ruleId]}</span>
                      </div>
                  ))}
                  <div className="btn-group">
                      <button className="btn btn-warning reset" id="btn-reset" onClick={() => {
                          setQuery(initialQuery);
                          props.onReinit(true);
                      }}>{t("Reinitialiser", { ns: "hotel" })}</button>
                      <button className="btn btn-primary parse-json" id="btn-get" onClick={() => {
                          if (Object.keys(validationErrors).length === 0 && query.rules.length !== 0) {
                              props.onFiltre(querySqlPerso, query);
                          } else {
                              // Afficher un message d'erreur ou prendre une autre action
                              props.onError(t("Votre filtre n'est pas correct, il n'est pas possible de filtrer !", { ns: "hotel" }));
                          }
                      }}>{t("Filtrer", { ns: "hotel" })}</button>
                  </div>
              </QueryBuilderAntD>
          
          );
      }
;
export default CitoyensQueryBuilder;
