import React                        from "react";
import { OutilsChantiersTypeProps } from "../../../../types/components/Outils/OutilsChantiers";
import SvgIcone                     from "../../../../components/generality/SvgIcone";
import { components }               from "react-select";
import UpHabitation                 from "./UpHabitation";
import UpAmelioration               from "./UpAmelioration";
import { useOutilsChantiers }       from "../TabsOutilsChantiers";
import { CitoyensDTO }              from "../../../../types/models/citoyens.dto";
import { useTranslation }           from "react-i18next";

const { SingleValue, Option, GroupHeading } = components;

export default function UpHabitationAmelio(props: { outils: OutilsChantiersTypeProps }) {
    const { t } = useTranslation();
    const { outilsChantiers, setOutilsChantiers } = useOutilsChantiers();
    const outils: OutilsChantiersTypeProps = props.outils;
    
    return <div id={"upAmeliorationHabitation"}>
        <div id={"bloc_recapCitoyen"}>
            <table className={"tab_recapCitoyen"}>
                <thead>
                <tr className={"fondBlack02"}>
                    <th className={"pseudo"}>{t("Citoyen", { ns: "outils" })}</th>
                    <th className={"lvl"}>
						<span className="infoBulle">
							<SvgIcone icone={"r_hbuild"} /><span className="info">{t("Habitation", { ns: "outils" })}</span>
						</span>
                    </th>
                    <th className={"lvl"}>
						<span className="infoBulle">
							<SvgIcone icone={"status_tired"} /><span className="info">{t(outils.option.list_ame["cs"].label, { ns: "game" })}</span>
						</span>
                    </th>
                    <th className={"lvl"}>
						<span className="infoBulle">
							<SvgIcone icone={"item_machine_2"} /><span className="info">{t(outils.option.list_ame["cuisine"].label, { ns: "game" })}</span>
						</span>
                    </th>
                    <th className={"lvl"}>
						<span className="infoBulle">
							<SvgIcone icone={"item_drug"} /><span className="info">{t(outils.option.list_ame["labo"].label, { ns: "game" })}</span>
						</span>
                    </th>
                    <th className={"lvl"}>
						<span className="infoBulle">
							<SvgIcone icone={"item_bag"} /><span className="info">{t(outils.option.list_ame["rangement"].label, { ns: "game" })}</span>
						</span>
                    </th>
                    <th className={"lvl"}>
						<span className="infoBulle">
							<SvgIcone icone={"item_fence"} /><span className="info">{t(outils.option.list_ame["renfort"].label, { ns: "game" })}</span>
						</span>
                    </th>
                    <th className={"lvl"}>
						<span className="infoBulle">
							<SvgIcone icone={"item_wood_plate"} /><span className="info">{t(outils.option.list_ame["cloture"].label, { ns: "game" })}</span>
						</span>
                    </th>
                    <th className={"lvl"}>
						<span className="infoBulle">
							<SvgIcone icone={"item_home_box"} /><span className="info">{t(outils.option.list_ame["carton"].label, { ns: "game" })}</span>
						</span>
                    </th>
                    <th className={"lvl"}>
						<span className="infoBulle">
							<SvgIcone icone={"item_home_def"} /><span className="info">{t(outils.option.list_ame["barricade"].label, { ns: "game" })}</span>
						</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                {Object.values(outils.listCitoyens).filter((citoyen: CitoyensDTO) => {
                    return !citoyen.mort;
                }).sort((a: CitoyensDTO, b: CitoyensDTO) => a.citoyen.pseudo.localeCompare(b.citoyen.pseudo)).map((citoyen) => {
                    return <tr key={citoyen.citoyen.id}>
                        <td className={"pseudo"}><span><SvgIcone icone={"h_" + citoyen.job.icon} />{citoyen.citoyen.pseudo}</span></td>
                        <td className={"lvl"}>
							<span className="infoBulle habitation_icone">
								<SvgIcone icone={"home_" + citoyen.lvl_maison.icon} />
								<span className="info">{citoyen.lvl_maison.nom}</span>
							</span>
                        </td>
                        <td className={"lvl"}>
                            {(citoyen.lvl_coin_sieste === 0) ? "-" : citoyen.lvl_coin_sieste}
                        </td>
                        <td className={"lvl"}>
                            {(citoyen.lvl_cuisine === 0) ? "-" : citoyen.lvl_cuisine}
                        </td>
                        <td className={"lvl"}>
                            {(citoyen.lvl_labo === 0) ? "-" : citoyen.lvl_labo}
                        </td>
                        <td className={"lvl"}>
                            {(citoyen.lvl_rangement === 0) ? "-" : citoyen.lvl_rangement}
                        </td>
                        <td className={"lvl"}>
                            {(citoyen.lvl_renfort === 0) ? "-" : citoyen.lvl_renfort}
                        </td>
                        <td className={"lvl"}>
                            {citoyen.cloture ? (<i className="fa-solid fa-check color-green icone_citoyen_centre"></i>) : (<i className="fa-solid fa-xmark color-red icone_citoyen_centre fa-lg"></i>)}
                        </td>
                        <td className={"lvl"}>
                            {(citoyen.nb_carton === 0) ? "-" : citoyen.nb_carton}
                        </td>
                        <td className={"lvl"}>
                            {(citoyen.nb_barricade === 0) ? "-" : citoyen.nb_barricade}
                        </td>
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
        <div id={"option_upAme"}>
            <h2 className={"titre_up"}>{t("Gestion habitation", { ns: "outils" })}</h2>
            <UpHabitation outils={outils} />
            <hr style={{ width: "80%" }} />
            <h2 className={"titre_up"}>{t("Gestion amelioration", { ns: "outils" })}</h2>
            <UpAmelioration outils={outils} />
        </div>
    </div>;
}

