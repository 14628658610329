import React, { useEffect }     from "react";
import SvgIcone                 from "../../../components/generality/SvgIcone";
import HTMLParser               from "html-react-parser";
import { useExpeditionContext } from "../../../types/Context/Outils/Expedition/ExpeditionContext";
import { useCarteContext }      from "../../../types/Context/CarteContext";
import { ExpeditionDTO }        from "../../../types/models/expedition.dto";
import { OuvriersDTO }          from "../../../types/models/ouvriers.dto";
import { CitoyensDTO }          from "../../../types/models/citoyens.dto";
import { JobPrototypeDTO }      from "../../../types/models/jobPrototype.dto";
import { useTranslation }       from "react-i18next";

const calculMetierBase = (job: JobPrototypeDTO[], citoyens: CitoyensDTO[]): number[] => {
    const countCitoyen = [];
    job.map((job) => {
        countCitoyen[job.id] = citoyens.filter((citoyen) => citoyen.job.id === job.id).length;
    });
    return countCitoyen;
};

const calculMetierChoix = (job: JobPrototypeDTO[], listExpe: ExpeditionDTO[], listOuvriers: OuvriersDTO[]): number[] => {
    const countCitoyen = [];
    job.map((job) => {
        countCitoyen[job.id] = 0;
        listExpe.map((expedition) => {
            countCitoyen[job.id] += expedition.expedition_parts[0].expeditionnaires.filter((expeditionnaire) => expeditionnaire.job_fige && expeditionnaire.job?.id === job.id).length;
        });
        listOuvriers.map((ouvrier) => {
            countCitoyen[job.id] += ouvrier.job_fige && ouvrier.job?.id === job.id ? 1 : 0;
        });
    });
    
    // Pour les places sans métier figé, on utilisera l'index 0 ;
    countCitoyen[0] = 0;
    listExpe.map((expedition) => {
        countCitoyen[0] += expedition.expedition_parts[0].expeditionnaires.filter((expeditionnaire) => !expeditionnaire.job_fige).length;
    });
    listOuvriers.map((ouvrier) => {
        countCitoyen[0] += ouvrier.job_fige ? 0 : 1;
    });
    
    return countCitoyen;
};

export default function CompteursMetiers() {
    const { carte } = useCarteContext();
    const { t } = useTranslation();
    const { citoyensUser, expeditions, ouvriers } = useExpeditionContext();
    const [countCitoyen, setCountCitoyen] = React.useState<number[]>([]);
    const [countJob, setCountJob] = React.useState<number[]>([]);
    const listJob = carte.outilsExpe.liste.job;
    
    useEffect(() => {
        setCountCitoyen(calculMetierBase(listJob, citoyensUser));
    }, [citoyensUser]);
    
    useEffect(() => {
        setCountJob(calculMetierChoix(listJob, expeditions, ouvriers));
    }, [expeditions, ouvriers]);
    
    return <div>
        <table className={"compteur_metier_expedition"}>
            <thead>
            <tr>
                <th className={"cols_compteur"}>{t("Compteurs métiers", { ns: "ville" })}</th>
                <th className={"cols_compteur"}>{t("Non-définis", { ns: "ville" })}</th>
                {listJob.map((job: JobPrototypeDTO) => {
                    if (countCitoyen[job.id] !== 0) {
                        return <th className={"cols_compteur"} key={job.id}>
                            <div>{t(job.nom, { ns: "game" })}<SvgIcone icone={"h_" + job.icon} /></div>
                        </th>;
                    }
                })}
            </tr>
            </thead>
            <tbody>
            <tr>
                <th className={"cols_compteur"}>
                    <div className={"help_compteurs_zone"}>
                        <div>{t("Nombres", { ns: "ville" })}</div>
                        <div>
                                <span className={"infoBulle"}>
                                    <i className="fa-solid fa-circle-info"></i>
                                    <span className={"info"}>
                                        <ul className={"help_compteurs"}>
                                            <li>{HTMLParser(t("En <span className='text-primary'>bleu</span> : limite non atteinte.", { ns: "ville" }))}</li>
                                            <li>{HTMLParser(t("En <span className='text-success'>vert</span> : limite atteinte.", { ns: "ville" }))}</li>
                                            <li>{HTMLParser(t("En <span className='text-danger'>rouge</span> : limite dépassée.", { ns: "ville" }))}</li>
                                        </ul>
                                    </span>
                                </span>
                        </div>
                    </div>
                </th>
                <td className={"cols_compteur"}>{countJob[0]}</td>
                {listJob.map((job: JobPrototypeDTO, index: number) => {
                    if (countCitoyen[job.id] !== 0) {
                        return <td className={"cols_compteur"} key={index}>
                            <div className={"font-weight-bold " + ((countJob[job.id] < countCitoyen[job.id]) ? "bg-primary" : ((countJob[job.id] > countCitoyen[job.id]) ? "bg-danger" : "bg-success"))}>{countJob[job.id]}</div>
                        </td>;
                    }
                })}
            </tr>
            </tbody>
        </table>
    </div>;
    
}

