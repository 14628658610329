import { FieldTitle, useInput, Validator }     from "ra-core";
import React, { useState }                     from "react";
import { ChromePicker }                        from "react-color";
import reactCSS                                from "reactcss";
import { hexToRgba }                           from "../../../../components/generality/ConversionCouleur";
import { ColorPicker, ColorService, useColor } from "react-color-palette";
import "react-color-palette/css";

interface ColorInputProps {
    name?: string;
    resource?: string;
    label?: string;
    options?: {
        disableAlpha: false
    };
    disabled?: boolean;
    source: string;
    helperText?: string;
    className?: string;
    isRequired?: boolean;
    validate?: Validator | Validator[] | undefined;
}

export const ColorInput = (props: ColorInputProps) => {
    const [show, setShow] = useState(false);
    
    const { label, source, options, resource, validate, disabled = false } = props;
    const { field, isRequired } = useInput({ ...props, validate });
    const { color, setColor } = useColor(field.value);
    
    const styles = reactCSS({
        "default": {
            color  : {
                width       : "36px",
                height      : "14px",
                borderRadius: "2px",
                background  : `${field.value}`,
            },
            swatch : {
                padding     : "5px",
                background  : "#fff",
                borderRadius: "1px",
                boxShadow   : "0 0 0 1px rgba(0,0,0,.1)",
                display     : "inline-block",
                cursor      : "pointer",
            },
            popover: {
                position: "absolute" as "absolute",
                zIndex  : "2",
            },
            cover  : {
                position: "fixed" as "fixed",
                top     : "0px",
                right   : "0px",
                bottom  : "0px",
                left    : "0px",
            },
            label  : {
                display       : "flex" as "flex",
                alignItems    : "center" as "center",
                justifyContent: "space-arround" as "space-arround",
                gap           : "10px",
                marginBottom  : "5px",
            },
            display: {
                padding     : "5px",
                background  : "#fff",
                borderRadius: "1px",
                boxShadow   : "0 0 0 1px rgba(0,0,0,.1)",
                display     : "inline-block",
            },
        },
    });
    
    const handleClose = () => setShow(false);
    const handleClick = () => {
        if (!disabled) {
            setShow(!show);
        }
    };
    const handleChange = (color) => {
        setColor(color);
        field.onChange(ColorService.convert("hex", color));
    };
    
    
    return (
        <div style={styles.label}>
            <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
            <div style={(disabled) ? styles.display : styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
            </div>
            {show ? (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <ChromePicker {...options} color={hexToRgba(field.value)} onChange={handleChange} />
                    <ColorPicker hideInput={["rgb", "hsv"]} color={color} onChange={handleChange} />
                </div>
            ) : null}
        </div>
    );
};