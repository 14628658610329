import React, { useEffect, useState } from "react";
import { CreationJumpType }           from "../../../types/components/Jump/CreationJumpType";
import { GestionJumpApi }             from "../../../services/api/GestionJumpApi";
import FormEvent                      from "../FormEvent";
import { useNavigate }                from "react-router-dom";
import { EventDTO }                   from "../../../types/models/event.dto";
import { useTranslation }             from "react-i18next";

export default function CreationEvent(props: {
    creation: CreationJumpType, onRetour: (isError: boolean, showMod: boolean, messagePop: string) => void
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    
    const [eventJump, setEventJump] = useState(props.creation.event);
    const [activeEvent, setActiveEvent] = useState(false);
    
    useEffect(() => {
        setEventJump(props.creation.event);
    }, [props.creation.event]);
    
    const handleChangeEvent = (eventMod: EventDTO) => {
        setEventJump(eventMod);
    };
    
    const handleSubmitEvent = () => {
        // On fait des contrôles pour vérifier les dates si elles sont cohérentes entre elle → date de fin > date de début, date de jump > date de fin
        const dateDebut = new Date(eventJump.deb_inscription_date_at);
        const dateFin = new Date(eventJump.fin_inscription_date_at);
        const dateJump = new Date(eventJump.event_begin_at);
        
        if (dateDebut > dateFin) {
            props.onRetour(true, true, t("La date de début d'inscription doit être inférieur à la date de fin d'inscription.", { ns: "jumpEvent" }));
            return;
        }
        
        if (dateFin > dateJump) {
            props.onRetour(true, true, t("La date de fin d'inscription doit être inférieur à la date du début de l'évènement.", { ns: "jumpEvent" }));
            return;
        }
        
        const gestionApi = new GestionJumpApi();
        
        
        gestionApi.creation_event({ event: eventJump }).then((response) => {
            if (response.codeRetour === 0) {
                props.onRetour(false, true, response.libRetour);
                setTimeout(() => {
                    props.onRetour(false, false, "");
                    navigate("/event/" + response.zoneRetour.event);
                }, 2000);
            } else {
                props.onRetour(true, true, response.libRetour);
            }
        });
    };
    
    useEffect(() => {
        setActiveEvent(
            eventJump.nom !== null && eventJump.community !== null && eventJump.type_ville !== null && eventJump.list_jump.length > 0 &&
            eventJump.list_jump.every((jump) => jump.nom !== undefined && jump.nom !== null && jump.nom !== "") &&
            eventJump.deb_inscription_date_at !== eventJump.fin_inscription_date_at && eventJump.event_begin_at !== eventJump.deb_inscription_date_at &&
            eventJump.fin_inscription_date_at !== eventJump.event_begin_at);
    }, [eventJump]);
    
    return <div id="zone_event">
        <div id="formEvent" className="fondWhite02">
            <FormEvent formEventProps={{
                event  : eventJump,
                user   : props.creation.user,
                options: props.creation.options,
            }} onRetour={props.onRetour} onChange={handleChangeEvent} />
            <div className="simpleFormButton">
                <button type={"button"} className={"btn btn-primary"} disabled={!activeEvent}
                        onClick={handleSubmitEvent}>{t("Créer event", { ns: "jumpEvent" })}</button>
            </div>
        </div>
    </div>;
    
    
}