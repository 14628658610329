import React, { useEffect }                                                                                                                                                                                            from "react";
import { InscriptionExpeditionSauvegardeType }                                                                                                                                                                         from "../../../types/components/Hotel/InscriptionExpedition";
import SvgIcone                                                                                                                                                                                                        from "../../../components/generality/SvgIcone";
import { SoifType }                                                                                                                                                                                                    from "../../../types/components/Outils/OutilsExpeditionType";
import Select                                                                                                                                                                                                          from "react-select";
import { useGeneralContext }                                                                                                                                                                                           from "../../../types/Context/GeneralContext";
import { camaraderie, corps_sain, jolie_trouvaille, pef, rdh, sauvetage, ss, trouvaille, uppercut, vlm }                                                                                                               from "../../../types/components/Generality/Heros";
import { AffNbrIcon }                                                                                                                                                                                                  from "../../../components/generality/ComposantGeneral";
import { InscriptionExpeditionApi }                                                                                                                                                                                    from "../../../services/api/InscriptionExpeditionApi";
import { Status_error, Status_warning, usePopUp }                                                                                                                                                                      from "../../../types/Context/PopUpContext";
import ConfirmationDialog                                                                                                                                                                                              from "../../../components/generality/ComposantGeneral/ConfirmationDialog";
import { useGHContext }                                                                                                                                                                                                from "../../../types/Context/GHContext";
import { calculColorDispo, customStyle, customStyleDispo, DispoOption, DispoSingleValue, InscriptionExpeditionProps, PseudoOption, PseudoSingleValue, SoifOption, SoifSingleValue, styleDispo, styleJob, stylePseudo } from "./InscriptionExpedition";
import { DirectionScrutateurArrow }                                                                                                                                                                                    from "../../../components/generality/ComposantGeneral/DirectionScrutateurArrow";
import HTMLParser                                                                                                                                                                                                      from "html-react-parser";
import { useIEContext }                                                                                                                                                                                                from "../../../types/Context/Hotel/Expedition/InscriptionExpeditionContext";
import { OuvriersDTO }                                                                                                                                                                                                 from "../../../types/models/ouvriers.dto";
import { CitoyensDTO }                                                                                                                                                                                                 from "../../../types/models/citoyens.dto";
import { JobPrototypeDTO }                                                                                                                                                                                             from "../../../types/models/jobPrototype.dto";
import { HerosPrototypeDTO }                                                                                                                                                                                           from "../../../types/models/herosPrototype.dto";
import { TypeDispoDTO }                                                                                                                                                                                                from "../../../types/models/typeDispo.dto";
import { DispoExpeditionDTO }                                                                                                                                                                                          from "../../../types/models/dispoExpedition.dto";
import { useTranslation }                                                                                                                                                                                              from "react-i18next";

export default function ListeOuvrier({ inscription }: InscriptionExpeditionProps) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const { triggerRefresh } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const { ouvriers, setOuvriers, optionsSoif, recuperationCitoyensPreinscritOuvrier, recuperationCitoyensPreinscrit, expeditions, faoDirection, jourActuel } = useIEContext();
    const inscriptionApi = new InscriptionExpeditionApi(general.ville.map_id ?? 0);
    const [citoyenExpe, setCitoyenExpe] = React.useState<CitoyensDTO | null>(null);
    const [soifOuvrier, setSoifOuvrier] = React.useState<number | null>(null);
    const [dispoOuvrier, setDispoOuvrier] = React.useState<{ indexOuvrier: number, creneauId: number } | null>(null);
    const [selectionPseudoOuvrier, setSelectionPseudoOuvrier] = React.useState<{ ouvrier: OuvriersDTO, index_ouvrier: number, optionSelected: CitoyensDTO, placeVide: boolean }>(null);
    const [confirmationOuvrierShow, setConfirmationOuvrierShow] = React.useState(false);
    const [dispoRapideOuvrier, setDispoRapideOuvrier] = React.useState<boolean>(false);
    const [listCitoyen, setListCitoyen] = React.useState<CitoyensDTO[]>(inscription.citoyens.sort((citoyen_a, citoyen_b) => {
        // Tri pour mettre l'utilisateur en premier
        if (citoyen_a.citoyen.id === general.user.id) {
            return -1;
        }
        if (citoyen_b.citoyen.id === general.user.id) {
            return 1;
        }
        // Tri pour mettre par ordre alpha les pseudos
        return citoyen_a.citoyen.pseudo.localeCompare(citoyen_b.citoyen.pseudo);
    }));
    
    useEffect(() => {
        
        const citoyenTrier = listCitoyen.sort((citoyen_a, citoyen_b) => {
            // Tri pour mettre l'utilisateur en premier
            if (citoyen_a.citoyen.id === general.user.id) {
                return -1;
            }
            if (citoyen_b.citoyen.id === general.user.id) {
                return 1;
            }
            // Tri pour mettre par ordre alpha les pseudos
            return citoyen_a.citoyen.pseudo.localeCompare(citoyen_b.citoyen.pseudo);
        });
        
        setListCitoyen(citoyenTrier);
    }, [inscription.citoyens]);
    
    // Gestion des changements de d'information sur les expéditions et les ouvriers
    const handleChangeOuvrier = (index_ouvrier: number, updatedProperties: Partial<OuvriersDTO>) => {
        const newOuvriers = [...ouvriers];
        newOuvriers[index_ouvrier] = {
            ...newOuvriers[index_ouvrier],
            ...updatedProperties,
        };
        setOuvriers(newOuvriers);
    };
    
    // Gestion des changements de disponibilité des expéditionnaires
    const handleChangeOuvriersDispo = (indexOuvrier: number, indexCreneau: number, updatedProperties: TypeDispoDTO) => {
        const newOuvriers = [...ouvriers];
        // On balaye toutes les parties de l'expedition pour metre à jour l'expediteur
        newOuvriers[indexOuvrier].dispo[indexCreneau] = {
            ...newOuvriers[indexOuvrier].dispo[indexCreneau],
            dispo: updatedProperties,
        };
        setOuvriers(newOuvriers);
    };
    
    // Gestion du changement de citoyen dans une expédition - controle sur les préinscriptions, et s'il est déjà inscrit ailleurs
    const handleChangeOuvrierInscription = (ouvrier: OuvriersDTO, index_ouvrier: number, optionSelected: CitoyensDTO) => {
        const placeVide = ouvriers[index_ouvrier].citoyen === null;
        setSelectionPseudoOuvrier({ ouvrier, index_ouvrier, optionSelected, placeVide });
        
        
        // Contrôle si le citoyen ne fait pas l'objet d'une préinscription si et seulement la sélection ne vaut pas null
        if (optionSelected === null) {
            gestionInscriptionPseudoOuvrier(ouvrier, index_ouvrier, optionSelected, false);
        } else {
            let preinscrit = false;
            recuperationCitoyensPreinscrit().map((citoyen) => {
                if (citoyen.citoyen.id === optionSelected.citoyen.id) {
                    preinscrit = true;
                }
            });
            recuperationCitoyensPreinscritOuvrier().map((citoyen) => {
                if (citoyen.citoyen.id === optionSelected.citoyen.id) {
                    preinscrit = true;
                }
            });
            
            if (preinscrit) {
                setStatus(Status_error);
                setMessagePopUp(t("Attention, vous êtes préinscrit ailleurs !", { ns: "hotel" }));
                setShowPop(true);
                return;
            } else {
                // vérification si le joueur n'est pas déjà inscrit ailleurs : on parcourt toutes les expéditions
                let dejaInscrit = false;
                expeditions.forEach((expedition) => {
                    expedition.expedition_parts[0].expeditionnaires.forEach((exped) => {
                        if (exped.citoyen !== null && exped.citoyen.citoyen.id === optionSelected.citoyen.id) {
                            dejaInscrit = true;
                        }
                    });
                });
                // on vérifie également s'il n'est pas inscrit dans les ouvriers
                ouvriers.forEach((ouvrier) => {
                    if (ouvrier.citoyen !== null && ouvrier.citoyen.citoyen.id === optionSelected.citoyen.id) {
                        dejaInscrit = true;
                    }
                });
                if (dejaInscrit) {
                    setConfirmationOuvrierShow(true);
                } else {
                    gestionInscriptionPseudoOuvrier(ouvrier, index_ouvrier, optionSelected, placeVide);
                }
                
            }
            
        }
        
    };
    
    const handleConfirmationOuvrier = () => {
        if (selectionPseudoOuvrier !== null) {
            gestionInscriptionPseudoOuvrier(selectionPseudoOuvrier.ouvrier, selectionPseudoOuvrier.index_ouvrier, selectionPseudoOuvrier.optionSelected, selectionPseudoOuvrier.placeVide);
            setSelectionPseudoOuvrier(null);
        }
        setConfirmationOuvrierShow(false);
    };
    
    const handleAnnulationOuvrier = () => {
        setSelectionPseudoOuvrier(null);
        setConfirmationOuvrierShow(false);
    };
    
    const gestionInscriptionPseudoOuvrier = (ouvrier: OuvriersDTO, index_ouvrier: number, optionSelected: CitoyensDTO, placeVide: boolean) => {
        // On met à jour le pseudo du citoyen
        if (ouvrier.job_fige) {
            handleChangeOuvrier(index_ouvrier, { citoyen: optionSelected });
        } else {
            handleChangeOuvrier(index_ouvrier, { citoyen: optionSelected, job: optionSelected?.job });
        }
        handleMajPseudoOuvrier(index_ouvrier, optionSelected, placeVide);
    };
    
    const filtreCitoyen = (citoyen: CitoyensDTO, action_heroic: HerosPrototypeDTO, job: JobPrototypeDTO | null) => {
        // On stocke si l'action héroïque a été utilisé ou non pour le citoyen
        let action_heroic_utilise = true;
        if (action_heroic !== null) {
            switch (action_heroic.id) {
                case sauvetage.id:
                    action_heroic_utilise = citoyen.sauvetage;
                    break;
                case rdh.id:
                    action_heroic_utilise = citoyen.rdh;
                    break;
                case uppercut.id:
                    action_heroic_utilise = citoyen.uppercut;
                    break;
                case trouvaille.id:
                    action_heroic_utilise = citoyen.trouvaille && citoyen.citoyen.der_pouv.ordre_recup <= trouvaille.recup;
                    break;
                case pef.id:
                    action_heroic_utilise = citoyen.pef && citoyen.citoyen.tem_arma;
                    break;
                case camaraderie.id:
                    action_heroic_utilise = citoyen.don_jh && citoyen.citoyen.nb_charge_camaraderie > 0;
                    break;
                case corps_sain.id:
                    action_heroic_utilise = citoyen.corps_sain && citoyen.citoyen.der_pouv.ordre_recup >= corps_sain.recup;
                    break;
                case jolie_trouvaille.id:
                    action_heroic_utilise = citoyen.trouvaille && citoyen.citoyen.der_pouv.ordre_recup >= jolie_trouvaille.recup;
                    break;
                case ss.id:
                    action_heroic_utilise = citoyen.second_souffle && citoyen.citoyen.der_pouv.ordre_recup >= ss.recup;
                    break;
                case vlm.id:
                    action_heroic_utilise = citoyen.vlm && citoyen.citoyen.der_pouv.ordre_recup >= vlm.recup;
                    break;
                default:
                    action_heroic_utilise = true;
                    break;
            }
        }
        
        // Vérifier si le métier du citoyen correspond à celui spécifié dans le filtre
        const conditionJobFige = job === null || citoyen.job.id === job.id;
        // Retourner true si le citoyen passe tous les critères de filtrage
        return action_heroic_utilise && conditionJobFige;
    };
    
    const handleMajPseudoOuvrier = (indexOuvrier: number, citoyen: CitoyensDTO, placeVide) => {
        const ouvrierId = ouvriers[indexOuvrier].id;
        const expedition: InscriptionExpeditionSauvegardeType = {
            ouvrier     : {
                ouvrier_id: ouvrierId,
            },
            modification: {
                citoyen   : citoyen,
                place_vide: placeVide,
            },
        };
        inscriptionApi.sauvegarderInscriptionExpedition(expedition).then(() => {
            setCitoyenExpe(citoyen);
            if (citoyen === null) {
                // on vient remettre à blanc les dispos
                const newOuvriers = [...ouvriers];
                newOuvriers[indexOuvrier].citoyen = null;
                newOuvriers[indexOuvrier].dispo_rapide = null;
                // On balaye toutes les parties de l'expedition pour mettre à jour l'expediteur
                newOuvriers[indexOuvrier].dispo.map((dispo) => {
                    dispo.dispo = null;
                });
                setOuvriers(newOuvriers);
            }
            setTimeout(() => {
                setCitoyenExpe(null);
            }, 2000);
        }).catch((error) => {
            if (error.status === 409) {
                setStatus(Status_warning);
                setMessagePopUp(error?.data?.error ?? error.message);
                setShowPop(true);
                setTimeout(() => {
                    setShowPop(false);
                    setMessagePopUp("");
                    triggerRefresh();
                }, 2000);
            } else {
                setStatus(Status_error);
                setMessagePopUp(error?.data?.error ?? error.message);
                setShowPop(true);
            }
        });
    };
    
    const handleMajSoifOuvrier = (indexOuvrier: number, soif: boolean) => {
        const ouvrierId = ouvriers[indexOuvrier].id;
        const expedition: InscriptionExpeditionSauvegardeType = {
            ouvrier     : {
                ouvrier_id: ouvrierId,
            },
            modification: {
                soif: soif,
            },
        };
        inscriptionApi.sauvegarderInscriptionExpedition(expedition).then(() => {
            setSoifOuvrier(indexOuvrier);
            setTimeout(() => {
                setSoifOuvrier(null);
            }, 2000);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error.message);
            setShowPop(true);
        });
    };
    
    const handleMajDispoOuvrier = (indexOuvrier: number, dispo: DispoExpeditionDTO) => {
        const ouvrierId = ouvriers[indexOuvrier].id;
        const expedition: InscriptionExpeditionSauvegardeType = {
            ouvrier     : {
                ouvrier_id: ouvrierId,
            },
            modification: {
                dispo: dispo,
            },
        };
        inscriptionApi.sauvegarderInscriptionExpedition(expedition).then(() => {
            setDispoOuvrier({
                indexOuvrier,
                creneauId: dispo.creneau.id,
            });
            setTimeout(() => {
                setDispoOuvrier(null);
            }, 2000);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error.message);
            setShowPop(true);
        });
    };
    
    const handleChangeDispo = (ouvrier: OuvriersDTO, dispo_value: string) => {
        // On va créer une copie de l'expedition pour la mettre à jour
        const newOuvriers = [...ouvriers];
        // On recherche l'expédition
        const expeditionIndex = newOuvriers.findIndex((ouvrierList) => ouvrierList.id === ouvrier.id);
        const ouvrierMod = newOuvriers[expeditionIndex];
        
        const dispo_rapide = inscription.userOption.dispo_user_type_expeditions.find((dispo) => dispo.id === parseInt(dispo_value));
        
        const expeditionData: InscriptionExpeditionSauvegardeType = {
            ouvrier     : {
                ouvrier_id: ouvrier.id,
            },
            modification: {
                dispos: dispo_rapide,
            },
        };
        
        inscriptionApi.sauvegarderInscriptionExpedition(expeditionData).then(() => {
            // On va balayer toutes les dispos de l'ouvrier mettre à jour les dispos
            ouvrierMod.dispo.map((dispo) => {
                if (dispo_rapide === null) {
                    dispo.dispo = null;
                } else {
                    dispo.dispo = dispo_rapide.creneau.find((creneau) => creneau.creneau.id === dispo.creneau.id).dispo;
                }
            });
            ouvrierMod.dispo_rapide = dispo_rapide?.id ?? null;
            // On va mettre à jour l'expedition
            setOuvriers(newOuvriers);
            setDispoRapideOuvrier(true);
            setTimeout(() => {
                setDispoRapideOuvrier(false);
            }, 2000);
        }).catch((error) => {
            setStatus(Status_error);
            setMessagePopUp(error?.data?.error ?? error.message);
            setShowPop(true);
        });
    };
    
    const lectureSeul = jourActuel < general.ville.jour;
    
    const getListSelectDispo = () => {
        if (inscription.userOption.dispo_user_type_expeditions.length === 0) {
            return null;
        }
        
        const index_ouvrier = ouvriers.findIndex((ouvrier) => ouvrier.citoyen !== null && ouvrier.citoyen.citoyen.id === general.user.id);
        const ouvrier = ouvriers[index_ouvrier];
        
        return <select value={ouvrier.dispo_rapide ?? 0} onChange={(event) => {
            handleChangeOuvrier(index_ouvrier, { dispo_rapide: parseInt(event.target.value) });
            handleChangeDispo(ouvrier, event.target.value);
        }}>
            <option value={0}>{t("Utiliser une dispo rapide", { ns: "hotel" })}</option>
            {inscription.userOption.dispo_user_type_expeditions.sort((dispo_a, dispo_b) => dispo_a.priorite < dispo_b.priorite ? -1 : 1).map((dispo) => {
                return <option key={dispo.id} value={dispo.id}>{dispo.nom}</option>;
            })}
        </select>;
    };
    
    const iAmOuvrier = ouvriers.find((ouvrier) => ouvrier.citoyen !== null && ouvrier.citoyen.citoyen.id === general.user.id) !== undefined;
    return <div id={"liste_ouvriers_inscription"}>
        <h1><span>{t("Ouvriers", { ns: "app" })}</span>{inscription.direction_fao && <span id={"zone_fao_span"}>
            <span>{" - "}</span>
            <span>{t("Fao direction :", { ns: "app" })}</span>
            <span>{inscription.liste.direction.find((direction) => direction.id === faoDirection)?.nom}</span>
            <span>{DirectionScrutateurArrow(faoDirection)}</span>
        </span>}
        </h1>
        <div id={"zone_tab_ouvrier"}>
            <table className={"inscription_ouvrier"}>
                <thead>
                <tr className={"ligne_inscription_ouvrier"}>
                    <th className={"cols40_ouvrier"} rowSpan={2}></th>
                    <th className={"pseudo_ouvrier"} rowSpan={2}>{t("Pseudo", { ns: "hotel" })}</th>
                    <th className={"cols40_ouvrier"} rowSpan={2}><SvgIcone icone={"h_ban"} /></th>
                    <th className={"cols40_ouvrier"} rowSpan={2}><SvgIcone icone={"r_dwater"} /></th>
                    <th className={"big_cols"} rowSpan={2}>{t("Sac", { ns: "hotel" })}</th>
                    <th className={"big_cols"} rowSpan={2}>{t("Consigne", { ns: "hotel" })}</th>
                    <th className={"big_cols"} colSpan={inscription.liste.creneau.length}>
                        <span className={"dispo_rapide_inscription"}>
                            <span>{t("Disponibilités", { ns: "hotel" })}</span>
                            {iAmOuvrier && !lectureSeul && <span>{getListSelectDispo()}</span>}
                            {iAmOuvrier && dispoRapideOuvrier && <span className={"color-green"}><i className="fa-solid fa-calendar-check"></i></span>}
                        </span>
                    </th>
                </tr>
                <tr className={"ligne_inscription_ouvrier"}>
                    {inscription.liste.creneau.map((creneau) => {
                        return <th key={creneau.id} className={"cols40_expedition"}>{t(creneau.libelle, { ns: "jump" })}</th>;
                    })}
                </tr>
                </thead>
                <tbody>
                {ouvriers.map((ouvrier, index_ouvrier) => {
                    return <tr className={"ligne_inscription_ouvrier"} key={"ouvrier_" + index_ouvrier}>
                        <td className={"cols40_ouvrier"}><SvgIcone icone={"h_" + ouvrier?.citoyen?.job?.icon ?? ""} /></td>
                        <td className={"pseudo_ouvrier"}>
                            <div className={"zone_td_display"}>{(ouvrier.preinscrit || lectureSeul) ? ouvrier.citoyen?.citoyen.pseudo : (
                                <Select value={ouvrier.citoyen ? listCitoyen.find((citoyen) => citoyen.citoyen.id === ouvrier.citoyen?.citoyen?.id) : null}
                                        id={"pseudo_ouvrier_" + index_ouvrier}
                                        name={"pseudo_ouvrier_" + index_ouvrier}
                                        options={listCitoyen.filter((citoyen) => {
                                            if (ouvrier.job_fige) {
                                                return filtreCitoyen(citoyen, null, ouvrier.job_fige ? ouvrier.job : null);
                                            }
                                            return true;
                                        })}
                                        isClearable={true}
                                        components={{ SingleValue: PseudoSingleValue, Option: PseudoOption }}
                                        getOptionLabel={(citoyen: CitoyensDTO) => citoyen.citoyen.pseudo}
                                        getOptionValue={(citoyen: CitoyensDTO) => citoyen.citoyen.id.toString()}
                                        onChange={(optionSelected: CitoyensDTO) => {
                                            handleChangeOuvrierInscription(ouvrier, index_ouvrier, optionSelected);
                                        }}
                                        styles={customStyle(general.themeUser, stylePseudo, (citoyenExpe !== null && citoyenExpe?.citoyen !== null && citoyenExpe?.citoyen?.id === ouvrier.citoyen?.citoyen.id))} />
                            )}</div>
                        </td>
                        <td className={"cols40_ouvrier"}>
                            <div className={"zone_td_display"}>
                                {ouvrier.for_banni ? <SvgIcone icone={"h_ban"} /> : <i className="fa-solid fa-xmark color-red"></i>}
                            </div>
                        </td>
                        <td className={"cols40_ouvrier"}>
                            <div className={"zone_td_display"}>{(ouvrier.soif_fige || lectureSeul) ? (ouvrier.soif ? <SvgIcone icone={"r_dwater"} /> : <SvgIcone icone={"status_clean"} />) : (
                                <Select value={optionsSoif.find((soif) => soif.value === (ouvrier.soif ? 1 : (ouvrier.soif ? null : 0)))}
                                        id={"soif_ouvrier_" + index_ouvrier}
                                        name={"soif_ouvrier_" + index_ouvrier}
                                        options={optionsSoif}
                                        isClearable={false}
                                        components={{ SingleValue: SoifSingleValue, Option: SoifOption }}
                                        getOptionLabel={(soif: SoifType) => soif.label}
                                        getOptionValue={(soif: SoifType) => soif.value.toString()}
                                        onChange={(optionSelected: SoifType) => {
                                            handleChangeOuvrier(index_ouvrier, { soif: optionSelected.value === 1 });
                                            handleMajSoifOuvrier(index_ouvrier, optionSelected.value === 1);
                                        }}
                                        styles={customStyle(general.themeUser, styleJob, soifOuvrier !== null && soifOuvrier === index_ouvrier)} />
                            )}</div>
                        </td>
                        <td className={"td_sac_ouvrier"}>
                            <div className={"zone_sac_ouvrier"}>
                                <div className={"sac_ouvrier"}>{ouvrier.sac.map((sacItems) => {
                                    return <div key={`obj_${sacItems.item.id_objet}_${sacItems.broken ? 1 : 0}`}>
                                        <AffNbrIcon
                                            item={sacItems.item}
                                            broken={sacItems.broken}
                                            nbr={sacItems.nbr}
                                            classSpecifique={"itemSacOutilsExpedition"}
                                        />
                                    </div>;
                                })}</div>
                            </div>
                        </td>
                        <td className={"big_cols"}>
                            <div className={"consigne_ouvrier_inscription"}>{HTMLParser(ouvrier.consigne ?? "")}</div>
                        </td>
                        {
                            inscription.liste.creneau.map((creneau, indexCreneau) => {
                                const dispoBlock = inscription.userOption.user_personnalisation.fix_inscription_expe && ouvrier?.citoyen?.citoyen?.id !== general.user.id;
                                
                                return <td key={creneau.id} className={"cols40_ouvrier"}>
                                    <div className={"zone_td_display"}>
                                        <Select value={ouvrier.dispo[indexCreneau].dispo ? inscription.liste.dispo.find(dispo => dispo.id === ouvrier.dispo[indexCreneau].dispo.id) : null}
                                                id={"dispo_ouvrier_" + indexCreneau + "_" + index_ouvrier}
                                                name={"dispo_ouvrier_" + indexCreneau + "_" + index_ouvrier}
                                                options={inscription.liste.dispo.sort((a, b) => a.nom.localeCompare(b.nom))}
                                                isClearable={true}
                                                isDisabled={lectureSeul || dispoBlock}
                                                components={{ SingleValue: DispoSingleValue, Option: DispoOption }}
                                                getOptionLabel={(dispo) => dispo.nom}
                                                getOptionValue={(dispo) => dispo.id.toString()}
                                                onChange={(optionSelected: TypeDispoDTO) => {
                                                    handleChangeOuvriersDispo(index_ouvrier, indexCreneau, optionSelected);
                                                    handleMajDispoOuvrier(index_ouvrier, { id: ouvrier.dispo[indexCreneau].id, creneau, dispo: optionSelected });
                                                }}
                                                styles={customStyleDispo(general.themeUser,
                                                    styleDispo,
                                                    dispoOuvrier !== null && dispoOuvrier.indexOuvrier === index_ouvrier && dispoOuvrier.creneauId === creneau.id,
                                                    calculColorDispo(ouvrier.dispo[indexCreneau].dispo, inscription.userOption), inscription.userOption)} />
                                    </div>
                                </td>;
                            })
                        }
                    </tr>;
                })}
                </tbody>
            </table>
        </div>
        <ConfirmationDialog
            message={t("Joueur déjà inscrit ailleurs, êtes vous sûr ?", { ns: "hotel" })}
            titre={t("Attention !", { ns: "hotel" })}
            btnAnnuler={t("Non", { ns: "hotel" })}
            btnConfirmation={t("Oui", { ns: "hotel" })}
            show={confirmationOuvrierShow}
            onConfirm={handleConfirmationOuvrier}
            onCancel={handleAnnulationOuvrier}
        />
    </div>;
}



