import React, {useState}           from "react";
import {EncyclopedieChantiersType} from "../../types/components/Encyclopedie/EncyclopedieChantiersType";
import Cookies                     from "js-cookie";
import Tab                         from "react-bootstrap/Tab";
import Tabs                        from "react-bootstrap/Tabs";
import ListChantiers               from "./Chantiers/ListChantiers";
import ListEvolutions              from "./Chantiers/ListEvolutions";
import ListPlansRuines             from "./Chantiers/ListPlansRuines";
import {useTranslation}            from "react-i18next";

interface EncyclopedieChantierProps {
    chantiers: EncyclopedieChantiersType;
}

const EncyclopedieChantier = (props: EncyclopedieChantierProps) => {
    const {t} = useTranslation();
    const storedActiveTab = Cookies.get("activeTab_ency_chantiers");
    
    
    // The useState hook is used for state management in functional components
    const [activeTab, setActiveTab] = useState<string>(storedActiveTab || "chantiers");
    
    const handleTabChange = (eventKey: string) => {
        setActiveTab(eventKey);
        Cookies.set("activeTab_ency_chantiers", eventKey, {expires: 365});
    };
    
    
    return (
        <div id="ency_chantier">
            <Tabs activeKey={activeTab} onSelect={handleTabChange}>
                <Tab eventKey="chantiers" title={t("Liste des chantiers", {ns: "ency"})}>
                    <div id="listChantier">
                        <ListChantiers listChantier={props.chantiers.listChantier}/>
                    </div>
                </Tab>
                <Tab eventKey="plans" title={t("Liste des plans en ruine", {ns: "ency"})}>
                    <div id="listChantierPlansRuine">
                        <ListPlansRuines listPlans={props.chantiers.listPlans} listRuines={props.chantiers.listRuines}/>
                    </div>
                </Tab>
                <Tab eventKey="evos" title={t("Liste des évolutions", {ns: "ency"})}>
                    <div id="listEvoChantier">
                        <ListEvolutions evoChantier={props.chantiers.listEvo} translation={props.chantiers.translate}/>
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
};

export default EncyclopedieChantier;