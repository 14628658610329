import * as React                                                                                                                                                                              from "react";
import { useEffect, useState }                                                                                                                                                                 from "react";
import { BooleanInput, Datagrid, DateField, DateTimeInput, Edit, EditButton, List, NumberField, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextField, TextInput, useRecordContext } from "react-admin";
import { ColorInput }                                                                                                                                                                          from "./InputFieds/Inputs/Colors";
import axios                                                                                                                                                                                   from "axios";
import Button                                                                                                                                                                                  from "@mui/material/Button";
import { Link }                                                                                                                                                                                from "react-router-dom";
import { HerosPrototypeDTO }                                                                                                                                                                   from "../../types/models/herosPrototype.dto";

const HerosButton = () => {
    return (
        <Button
            component={Link}
            to={"/admin/user/heros"}
            color="primary"
        >
            Pouvoir Héros
        </Button>
    );
};

export const UserList = (props) => {
    const postFilters = [
        <TextInput source="q" label="Search" alwaysOn key={"search_user"} />,
        <ReferenceInput source="id" label="User" reference="user" key={"search_user_id"} />,
    ];
    
    return <>
        <HerosButton />
        <List {...props} filters={postFilters}>
            <Datagrid>
                <NumberField source="id" />
                <TextField source="pseudo" />
                <DateField source="date_maj" showTime={true} />
                <NumberField source="map_id" />
                <EditButton />
            </Datagrid>
        </List>
    </>;
};

export const UserEdit = (props) => {
    const [heros, setHeros] = useState([]);
    const [load, setLoad] = useState(false);
    
    useEffect(() => {
        fetchHeros().then(r => r);
        setLoad(true);
    }, []);
    
    
    const fetchHeros = async () => {
        try {
            const response = await axios.get("/rest/v1/admin/heros");
            const herosList = response.data.filter((hero: HerosPrototypeDTO) => hero.jour > 0).sort((a: HerosPrototypeDTO, b: HerosPrototypeDTO) => a.ordre_recup < b.ordre_recup);
            setHeros(Object.values(herosList));
        } catch (error) {
            console.error("Error fetching heros:", error);
        }
    };
    
    const role = [{ name: "ROLE_USER" }, { name: "ROLE_BETA" }, { name: "ROLE_MODO" }, { name: "ROLE_ADMIN" }];
    const theme = [{ name: "light" }, { name: "dark" }, { name: "vintage" }, { name: "hordes" }];
    
    const colors = [
        { name: "jet" },
        { name: "hsv" },
        { name: "hot" },
        { name: "cool" },
        { name: "spring" },
        { name: "summer" },
        { name: "autumn" },
        { name: "winter" },
        { name: "bone" },
        { name: "copper" },
        { name: "greys" },
        { name: "YIGnBu" },
        { name: "greens" },
        { name: "YIOrRd" },
        { name: "bluered" },
        { name: "RdBu" },
        { name: "picnic" },
        { name: "rainbow" },
        { name: "portland" },
        { name: "blackbody" },
        { name: "earth" },
        { name: "electric" },
        { name: "viridis" },
        { name: "inferno" },
        { name: "magma" },
        { name: "plasma" },
        { name: "warm" },
        { name: "rainbow-soft" },
        { name: "bathymetry" },
        { name: "cdom" },
        { name: "chlorophyll" },
        { name: "density" },
        { name: "freesurface-blue" },
        { name: "freesurface-red" },
        { name: "oxygen" },
        { name: "par" },
        { name: "phase" },
        { name: "salinity" },
        { name: "temperature" },
        { name: "turbidity" },
        { name: "velocity-blue" },
        { name: "velocity-green" },
    ];
    
    
    const PostTitle = () => {
        const record = useRecordContext();
        return <span>Edition de {record ? `"${record.pseudo}"` : ""}</span>;
    };
    
    
    return (load && <Edit {...props} title={<PostTitle />}>
        <SimpleForm>
            <div className={"user-switch"}>
                <NumberInput source="map_id" label="Identifiant ville en cours" />
                {heros.length > 0 && <SelectInput
                    source="der_pouv.id"
                    label="Dernier pouvoir héros"
                    choices={heros}
                    optionText="nom"
                    optionValue="id"
                />}
                <SelectInput
                    source="roles"
                    label="role"
                    choices={role}
                    optionText="name"
                    optionValue="name"
                />
                <SelectInput
                    source="theme"
                    label="Theme"
                    choices={theme}
                    optionText="name"
                    optionValue="name"
                />
                <SelectInput
                    source="colormap"
                    label="Colormap"
                    choices={colors}
                    optionText="name"
                    optionValue="name"
                />
                <BooleanInput source="heros" label="Héros" />
                <DateTimeInput source="date_maj" label="Date de mise à jour" />
                <TextInput source="periode_rappel" label="Période de rappel" />
                <BooleanInput source="poss_apag" label="Possède Apag" />
                <BooleanInput source="tem_arma" label="Tem arma" />
                <BooleanInput source="force_maj" label="Force de mise à jour" />
                <BooleanInput source="legend" label="Légende" />
                <BooleanInput source="annonce_news" label="Annonce les news" />
                <BooleanInput source="pop_up_click" label="Afficher la pop-up uniquement au clic" />
                <BooleanInput source="fige_menu" label="Figer le menu carte" />
                <BooleanInput source="bloc_maj_citoyens" label="Bloquer la mise à jour de ses informations de la page Citoyens par les autres joueurs" />
                <BooleanInput source="citoyens_mode_compact" label="Afficher la page citoyens en mode compact" />
                <BooleanInput source="carte_textured" label="Ajouter une texture à la carte" />
            </div>
            <div className={"user-color"}>
                <ColorInput source="couleur_scrut" label="Couleur Scrut" />
                <ColorInput source="couleur_km" label="Couleur Km" />
                <ColorInput source="couleur_pos" label="Couleur position" />
                <ColorInput source="couleur_bat" label="Couleur du bâtiment" />
                <ColorInput source="couleur_select_obj" label="Couleur sélection Objet" />
                <ColorInput source="couleur_select_bat" label="Couleur sélection Batiment" />
                <ColorInput source="couleur_select_cit" label="Couleur sélection Citoyen" />
                <ColorInput source="couleur_vue_auj" label="Couleur vue aujourd'hui" />
                <ColorInput source="couleur_vue24" label="Couleur vue 24h" />
                <ColorInput source="couleur_vue48" label="Couleur vue > 48h" />
                <ColorInput source="couleur_p_a" label="Couleur PA" />
                <ColorInput source="couleur_zone" label="Couleur Zone PA" />
                <ColorInput source="color_zombie" label="Couleur Zombie" />
                <ColorInput source="couleur_danger0" label="Couleur danger 0" />
                <ColorInput source="couleur_danger1" label="Couleur danger 1" />
                <ColorInput source="couleur_danger2" label="Couleur danger 2" />
                <ColorInput source="couleur_danger3" label="Couleur danger 3" />
                <ColorInput source="couleur_epuise" label="Couleur épuisée" />
                <ColorInput source="color_sel_exp" label="Couleur des expés sélectionnés (dans le listing)" />
                <ColorInput source="couleur_sel_case_maj" label="Couleur de bordure pour afficher le détail de la case" />
                <ColorInput source="couleur_carte" label="Couleur de carte" />
                <ColorInput source="color_estimation_zombie" label="Couleur estimation zombie" />
                <NumberInput source="alpha_colormap" label="Transparence color map" />
            </div>
            
            
            {/* Ajoutez d'autres champs à éditer ici */}
        </SimpleForm>
    </Edit>);
};