import React, { useContext, useEffect, useState }       from "react";
import { Nav, Navbar, NavDropdown, useAccordionButton } from "react-bootstrap";
import { Avatar }                                       from "../../components/generality/ComposantGeneral";
import HTMLParser                                       from "html-react-parser";
import Button                                           from "react-bootstrap/Button";
import Accordion                                        from "react-bootstrap/Accordion";
import ListGroup                                        from "react-bootstrap/ListGroup";
import Card                                             from "react-bootstrap/Card";
import AccordionContext                                 from "react-bootstrap/AccordionContext";
import { Link }                                         from "react-router-dom";
import { GeneralType }                                  from "../../types/components/Generality/GeneralType";
import { LanguageSelector }                             from "./LangueSelector";
import { BarLoader, ClipLoader }                        from "react-spinners";
import { formatInTimeZone }                             from "date-fns-tz";
import Cookies                                          from "js-cookie";
import { useGHContext }                                 from "../../types/Context/GHContext";
import { useGeneralContext }                            from "../../types/Context/GeneralContext";
import { useTranslation }                               from "react-i18next";
import SvgIcone                                         from "../../components/generality/SvgIcone";

export interface HeaderProps {
    onActualisation: () => void;
    onUpdate: () => void;
    onReset: () => void;
    isOnUpdate: boolean;
    isOnRefresh: boolean;
}

export interface NavigateurPropsProps {
    general: GeneralType;
    toggleMenu?: () => void;
}

function ContextAwareToggle({ children, eventKey, callback }: { children: any, eventKey: string, callback: () => void }) {
    const { activeEventKey } = useContext(AccordionContext);
    
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(),
    );
    
    const isCurrentEventKey = activeEventKey === eventKey;
    
    return (
        <div
            style={{
                cursor: "pointer", // Add this to show the element is clickable
            }}
            onClick={decoratedOnClick}
        >
            <ListGroup>
                <ListGroup.Item className="custom-menu-button">
                    <div className={"groupNameChevron"}>
                        <div className={"card-name-header-menu"}>{children}</div>
                        <i className={(isCurrentEventKey) ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </div>
    );
}

function Navigation({ general, toggleMenu }: NavigateurPropsProps) {
    const { checkHabilitation } = useGHContext();
    const mapIdVille = general.ville?.map_id ?? null;
    const myVille = general.myVille;
    const isCo = general.isCo;
    const visitVille = mapIdVille !== null;
    const { t } = useTranslation();
    
    const keepMenuOpen = general.user?.user_personnalisation.keep_menu_open ?? false;
    
    const closeMenuOnClick = () => {
        // Vérifiez si l'option keepMenuOpen est à false pour fermer le menu
        if (!keepMenuOpen) {
            toggleMenu();
        }
        // Si keepMenuOpen est true, ne faites rien (le menu restera ouvert)
    };
    
    return (
        <Accordion>
            {/* Sous-menu dépliable Ville */}
            {visitVille && <Card>
                <Card.Header>
                    <ContextAwareToggle eventKey="subMenu1" callback={() => {
                    }}>{t("Ville", { ns: "app" })}</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="subMenu1">
                    <ListGroup>
                        {checkHabilitation("ville", "carte") &&
                            <Link to={"/carte/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Carte", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("ville", "banque") &&
                            <Link to={"/banque/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Banque", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("ville", "ruine") &&
                            <Link to={"/ruine/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Ruine", { ns: "app" })}</ListGroup.Item></Link>}
                    </ListGroup>
                </Accordion.Collapse>
            </Card>}
            {/* Sous-menu dépliable Hotel de ville */}
            {visitVille && <Card>
                <Card.Header>
                    <ContextAwareToggle eventKey="subMenu2" callback={() => {
                    }}>{t("Hôtel de ville", { ns: "app" })}</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="subMenu2">
                    <ListGroup>
                        {checkHabilitation("hotel", "citoyens") &&
                            <Link to={"/citoyens/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Citoyens", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("hotel", "journal") &&
                            <Link to={"/journal/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Journal", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("hotel", "plans") && <Link to={"/plansDeChantier/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }}
                                                                      onClick={closeMenuOnClick}><ListGroup.Item
                            className="custom-menu-button">{t("Plans de chantier", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("hotel", "stats") &&
                            <Link to={"/stats/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Statistiques", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("hotel", "tour") && myVille &&
                            <Link to={"/tourDeGuet/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Tour de guet", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("hotel", "inscription") && myVille &&
                            <Link to={"/inscription_expe/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }}
                                  onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Inscriptions Expéditions", { ns: "app" })}</ListGroup.Item></Link>}
                    </ListGroup>
                </Accordion.Collapse>
            </Card>}
            
            {/* Sous-menu dépliable Outils */}
            {isCo && myVille && <Card>
                <Card.Header>
                    <ContextAwareToggle eventKey="subMenu3" callback={() => {
                    }}>{t("Outils", { ns: "app" })}</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="subMenu3">
                    <ListGroup>
                        {checkHabilitation("outils", "chantiers") &&
                            <Link to={"/chantiers/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Chantiers", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("outils", "cm") &&
                            <Link to={"/cm/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Camping Massif", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("outils", "decharge") &&
                            <Link to={"/decharges/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Décharges", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("outils", "expe") &&
                            <Link to={"/expeditions/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Expéditions", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("outils", "reparation") &&
                            <Link to={"/reparations/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Réparations", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("outils", "veilles") &&
                            <Link to={"/veilles/" + mapIdVille} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Veilles", { ns: "app" })}</ListGroup.Item></Link>}
                    </ListGroup>
                </Accordion.Collapse>
            </Card>}
            
            {/* Sous-menu dépliable Jump/Event */}
            {isCo && <Card>
                <Card.Header>
                    <ContextAwareToggle eventKey="subMenu4" callback={() => {
                    }}>{t("Jump/Event", { ns: "app" })}</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="subMenu4">
                    <ListGroup>
                        {checkHabilitation("jump", "inscription") &&
                            <Link to={"jump/inscription"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Inscriptions", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("jump", "coalitions") &&
                            <Link to={"coalition"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Coalitions", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("jump", "creations") &&
                            <Link to={"jump/creation"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Créations", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("jump", "gestions") &&
                            <Link to={"jump/gestion"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Gestions", { ns: "app" })}</ListGroup.Item></Link>}
                        {/*<ListGroup.Item className="custom-menu-button">Event</ListGroup.Item>*/}
                    </ListGroup>
                </Accordion.Collapse>
            </Card>}
            
            {/* Sous-menu direct Villes */}
            <Card>
                <ListGroup>
                    {checkHabilitation("villes") &&
                        <Link to={"villes"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                            className="custom-menu-button">{t("Villes", { ns: "app" })}</ListGroup.Item></Link>}
                </ListGroup>
            </Card>
            
            {/* Sous-menu dépliable Encyclopédie */}
            <Card>
                <Card.Header>
                    <ContextAwareToggle eventKey="subMenu5" callback={() => {
                    }}>
                        {t("Encyclopédie", { ns: "app" })}
                    </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="subMenu5">
                    <ListGroup>
                        {checkHabilitation("encyclopedie", "batiments") &&
                            <Link to={"encyclopedie/batiments"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Bâtiments", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("encyclopedie", "chantiers") &&
                            <Link to={"encyclopedie/chantiers"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Chantiers", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("encyclopedie", "citoyens") &&
                            <Link to={"encyclopedie/citoyens"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Citoyens", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("encyclopedie", "objets") &&
                            <Link to={"encyclopedie/objets"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Objets", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("encyclopedie", "villes") &&
                            <Link to={"encyclopedie/villes"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Villes", { ns: "app" })}</ListGroup.Item></Link>}
                    </ListGroup>
                </Accordion.Collapse>
            </Card>
            
            {/* Sous-menu dépliable Âme */}
            {isCo && <Card>
                <Card.Header>
                    <ContextAwareToggle eventKey="subMenu6" callback={() => {
                    }}>
                        {t("Âme", { ns: "app" })}
                    </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="subMenu6">
                    <ListGroup>
                        {checkHabilitation("ame", "moname") &&
                            <Link to={"ame/" + general.user.id_my_hordes} style={{ textDecoration: "none", color: "inherit" }}
                                  onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Mon âme", { ns: "app" })}</ListGroup.Item></Link>}
                        {checkHabilitation("ame", "classement") &&
                            <Link to={"ame/classement"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                                className="custom-menu-button">{t("Classement", { ns: "app" })}</ListGroup.Item></Link>}
                    </ListGroup>
                </Accordion.Collapse>
            </Card>}
            <Card>
                <ListGroup>
                    <Link to={"news"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                        className="custom-menu-button">{t("News", { ns: "app" })}</ListGroup.Item></Link>
                </ListGroup>
            </Card>
            {checkHabilitation("statistiques") && <Card>
                <ListGroup>
                    <Link to={"statistiques"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                        className="custom-menu-button">{t("Statistiques", { ns: "app" })}</ListGroup.Item></Link>
                </ListGroup>
            </Card>}
            {isCo && checkHabilitation("contact") && <Card>
                <ListGroup>
                    <Link to={"contact"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                        className="custom-menu-button">{t("Contact", { ns: "app" })}</ListGroup.Item></Link>
                </ListGroup>
            </Card>}
            {isCo && checkHabilitation("ruineGame") && <Card>
                <ListGroup>
                    <Link to={"ruineGame"} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                        className="custom-menu-button">{t("Ruine (Jeu)", { ns: "app" })}</ListGroup.Item></Link>
                </ListGroup>
            </Card>}
            {isCo && general.miseEnAvant.map((eventEnAvant, index) => {
                return <Card key={"menu_" + index}>
                    <ListGroup>
                        <Link to={"event/" + eventEnAvant.id} style={{ textDecoration: "none", color: "inherit" }} onClick={closeMenuOnClick}><ListGroup.Item
                            className="custom-menu-button-event"><span className={"d-flex gap-1 justify-content-center align-items-center"}><SvgIcone icone={eventEnAvant?.icone??"r_eventpart"} />{eventEnAvant.nom}</span></ListGroup.Item></Link>
                    </ListGroup>
                </Card>;
            })}
        </Accordion>
    );
}

function NavigationBandeau({ general }: NavigateurPropsProps) {
    const { checkHabilitation } = useGHContext();
    const mapIdVille = general.ville?.map_id ?? null;
    const myVille = general.myVille;
    const isCo = general.isCo;
    const visitVille = mapIdVille !== null;
    const { t } = useTranslation();
    
    
    return (
        <Navbar expand={true} id={"menuSite-hor"}>
            <Nav className="me-auto d-flex w-100">
                {visitVille && <NavDropdown title={<span className={"m-2"}>{t("Ville", { ns: "app" })}</span>} className={"custom-dropdown-item"}>
                    {checkHabilitation("ville", "carte") && <NavDropdown.Item to={"/carte/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Carte", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("ville", "banque") && <NavDropdown.Item to={"/banque/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Banque", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("ville", "ruine") && <NavDropdown.Item to={"/ruine/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Ruine", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                </NavDropdown>}
                {visitVille && <NavDropdown title={<span className={"m-2"}>{t("Hôtel de ville", { ns: "app" })}</span>}>
                    {checkHabilitation("hotel", "citoyens") && <NavDropdown.Item to={"/citoyens/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Citoyens", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("hotel", "journal") && <NavDropdown.Item to={"/journal/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Journal", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("hotel", "plans") && <NavDropdown.Item to={"/plansDeChantier/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Plans de chantier", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("hotel", "stats") && <NavDropdown.Item to={"/stats/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Statistiques", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("hotel", "tour") && <NavDropdown.Item to={"/stats/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Tour de guet", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("hotel", "inscription") && <NavDropdown.Item to={"/inscription_expe/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Inscriptions Expéditions", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                </NavDropdown>}
                {isCo && myVille && <NavDropdown title={<span className={"m-2"}>{t("Outils", { ns: "app" })}</span>}>
                    {checkHabilitation("outils", "chantiers") && <NavDropdown.Item to={"/chantiers/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Chantiers", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("outils", "cm") && <NavDropdown.Item to={"/cm/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Camping Massif", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("outils", "decharge") && <NavDropdown.Item to={"/decharges/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Décharges", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("outils", "expe") && <NavDropdown.Item to={"/expeditions/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Expéditions", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("outils", "reparation") && <NavDropdown.Item to={"/reparations/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Réparations", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("outils", "veilles") && <NavDropdown.Item to={"/veilles/" + mapIdVille} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Veilles", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                </NavDropdown>}
                {isCo && <NavDropdown title={<span className={"m-2"}>{t("Jump/Event", { ns: "app" })}</span>}>
                    {checkHabilitation("jump", "inscription") && <NavDropdown.Item to={"jump/inscription"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Inscriptions", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("jump", "coalitions") && <NavDropdown.Item to={"coalition"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Coalitions", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("jump", "creations") && <NavDropdown.Item to={"jump/creation"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Créations", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("jump", "gestions") && <NavDropdown.Item to={"jump/gestion"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Gestions", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {/*checkHabilitation("jump", "gestions") && <NavDropdown.Item to={"jump/gestion"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                     <ListGroup.Item className="custom-menu-button">{t("Event", { ns: "app" })}</ListGroup.Item>
                     </NavDropdown.Item>*/}
                </NavDropdown>}
                {checkHabilitation("villes") && <Nav.Link to={"villes"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                    <ListGroup.Item className="custom-menu-button">{<span className={"m-2"}>{t("Villes", { ns: "app" })}</span>}</ListGroup.Item>
                </Nav.Link>}
                {<NavDropdown title={<span className={"m-2"}>{t("Encyclopédie", { ns: "app" })}</span>}>
                    {checkHabilitation("encyclopedie", "batiments") && <NavDropdown.Item to={"encyclopedie/batiments"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Bâtiments", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("encyclopedie", "chantiers") && <NavDropdown.Item to={"encyclopedie/chantiers"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Chantiers", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("encyclopedie", "citoyens") && <NavDropdown.Item to={"encyclopedie/chantiers"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Citoyens", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("encyclopedie", "objets") && <NavDropdown.Item to={"encyclopedie/objets"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Objets", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("encyclopedie", "villes") && <NavDropdown.Item to={"encyclopedie/villes"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Villes", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                </NavDropdown>}
                {isCo && <NavDropdown title={<span className={"m-2"}>{t("Âme", { ns: "app" })}</span>}>
                    {checkHabilitation("ame", "moname") && <NavDropdown.Item to={"ame/" + general.user.id_my_hordes} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Mon âme", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                    {checkHabilitation("ame", "classement") && <NavDropdown.Item to={"ame/classement"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                        <ListGroup.Item className="custom-menu-button">{t("Classement", { ns: "app" })}</ListGroup.Item>
                    </NavDropdown.Item>}
                </NavDropdown>}
                <Nav.Link to={"news"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                    <ListGroup.Item className="custom-menu-button">{<span className={"m-2"}>{t("News", { ns: "app" })}</span>}</ListGroup.Item>
                </Nav.Link>
                {checkHabilitation("statistiques") && <Nav.Link to={"statistiques"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                    <ListGroup.Item className="custom-menu-button">{<span className={"m-2"}>{t("Statistiques", { ns: "app" })}</span>}</ListGroup.Item>
                </Nav.Link>}
                {isCo && checkHabilitation("contact") && <Nav.Link to={"contact"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                    <ListGroup.Item className="custom-menu-button">{<span className={"m-2"}>{t("Contact", { ns: "app" })}</span>}</ListGroup.Item>
                </Nav.Link>}
                {isCo && checkHabilitation("ruineGame") && <Nav.Link to={"ruineGame"} as={Link} style={{ textDecoration: "none", color: "inherit" }}>
                    <ListGroup.Item className="custom-menu-button">{<span className={"m-2"}>{t("Ruine (Jeu)", { ns: "app" })}</span>}</ListGroup.Item>
                </Nav.Link>}
                {isCo && general.miseEnAvant.map((eventEnAvant, index) => {
                    return <Nav.Link to={"event/" + eventEnAvant.id} as={Link} style={{ textDecoration: "none", color: "inherit" }} key={"menu_" + index}>
                        <ListGroup.Item className="custom-menu-button-event"><span className={"d-flex gap-1 justify-content-center align-items-center m-2"}><SvgIcone icone={"r_eventpart"} />{eventEnAvant.nom}</span></ListGroup.Item>
                    </Nav.Link>;
                })}
            </Nav>
        </Navbar>
    );
}

export function Header({ onActualisation, onUpdate, onReset, isOnUpdate, isOnRefresh }: HeaderProps) {
    const { checkHabilitation } = useGHContext();
    const { general, setGeneral } = useGeneralContext();
    const menuCookie = Cookies.get("menu");
    const [menuOpen, setMenuOpen] = useState(menuCookie ? JSON.parse(menuCookie) : false);
    const [localGeneral, setLocalGeneral] = useState(general);
    const { t } = useTranslation();
    
    useEffect(() => {
        setLocalGeneral(general);
    }, [general]);
    
    const toggleMenu = () => {
        if (general.user !== null && general.user.user_personnalisation.keep_menu_open) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 365);
            Cookies.set("menu", JSON.stringify(!menuOpen), { expires: expirationDate, path: "/", secure: true });
        }
        setMenuOpen(!menuOpen);
    };
    
    const ville = localGeneral.ville;
    const user = localGeneral.user;
    const isCo = localGeneral.isCo;
    const ptsSaison = localGeneral.ptsSaison.toString();
    const jourVille = ville ? ville.jour.toString() : "";
    
    let couleurChargement = "#ffffff";
    
    if (user?.theme === "light" || user?.theme === "vintage") {
        couleurChargement = "#000000";
    }
    
    const mapIdVille = ville?.map_id ?? null;
    const mapIdJoueur = user?.map_id ?? null;
    
    const buttonConnexion = () => {
        const isLoggedIn = !isCo;
        const isUserMapIdMatchesCity = user?.map_id === ville?.map_id || mapIdJoueur === mapIdVille || (mapIdJoueur !== null && ville === null);
        const isUserMapIdNotNull = mapIdJoueur !== null;
        
        if (isLoggedIn) {
            return <Button href={"/loginMH"} variant={"primary"} size={"sm"}>{t("Connexion", { ns: "app" })}</Button>;
        } else {
            const buttonOptions = [
                { check: isUserMapIdMatchesCity, variant: "success", onClick: onUpdate, text: isOnUpdate ? <BarLoader color={couleurChargement} height={12} width={80} /> : t("Mise à jour", { ns: "app" }) },
                { check: isUserMapIdNotNull, variant: "primary", onClick: onReset, text: t("Ma Ville", { ns: "app" }) },
                { check: true, variant: "primary", onClick: onReset, text: t("Quitter la ville", { ns: "app" }) },
            ];
            
            for (const option of buttonOptions) {
                if (option.check) {
                    return (
                        <Button variant={option.variant} size={"sm"} onClick={option.onClick} className={"bouton_chargement_maj"}>
                            {option.text}
                        </Button>
                    );
                }
            }
        }
    };
    
    return (
        <header id={"general"}>
            <div id={"entete"}>
                <div id="titre_principale">
                    <div id="groupImage">
                        <div id={"groupMenuMaj"} className={"gap-4"}>
                            {!user.user_personnalisation.menu_bandeau && <div className="header-content">
                                <div className="burger-icon" onClick={toggleMenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                {!user.user_personnalisation.menu_bandeau && menuOpen && <nav id="menuSite" className={(menuOpen ? "menu-open " : "") + "flex-column"}><Navigation general={general} toggleMenu={toggleMenu} /></nav>}
                            </div>}
                            <div className={(user.user_personnalisation.menu_bandeau) ? "ms-4" : null}>
                                {buttonConnexion()}
                            </div>
                            <div>
                                <Button variant={"warning"} size={"sm"} onClick={onActualisation} id={"#zoneRefresh"}
                                        className={"bouton_chargement_maj"}>{isOnRefresh ? (<ClipLoader color={couleurChargement} size={10} />) :
                                    (<i className="fa-solid fa-rotate-right"></i>)}</Button>
                            </div>
                        </div>
                        <div id={"entete_link"}>
                            <div id={"groupImgText"}>
                                <Link to={mapIdVille === null ? "/news" : ("/carte/" + mapIdVille)} style={{ textDecoration: "none", color: "inherit" }}>
                                    <div id="imageBanniere"></div>
                                </Link>
                                <Link to={mapIdVille === null ? "/news" : ("/carte/" + mapIdVille)} style={{ textDecoration: "none", color: "inherit" }}>
                                    <div id="textCentrale">
                                        <p id="entente_devise"> {t("Le site pour gérer ta ville MyHordes !", { ns: "app" })} </p>
                                        {ville !== null && (<>{mapIdVille === mapIdJoueur ? (
                                                <p id="entete_nomV">{HTMLParser(t("Vous êtes actuellement dans la ville {ville} jour <span id=\"jourVille\">{jour}</span>", { ns: "app" }).replace("{ville}", ville.nom).replace("{jour}", jourVille))} - {t("Points de saison : {point}", { ns: "app" }).replace("{point}", ptsSaison)}</p>) :
                                            (<p id="entete_nomV">{HTMLParser(t("Vous visitez actuellement la ville {ville} jour <span id=\"jourVille\">{jour}</span>", { ns: "app" }).replace("{ville}", ville.nom).replace("{jour}", jourVille))} - {t("Points de saison : {point}", { ns: "app" }).replace("{point}", ptsSaison)}</p>)}</>)}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div id="textDerMaj">
                        {ville !== null && <p id="entete_majV">{t("Dernière mise à jour le {date} par {user}", { ns: "app" }).replace("{date}",
                            (formatInTimeZone(ville.date_time, general.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" })))).replace("{user}",
                            ((ville.update_by === null) ? t("inconnu", { ns: "app" }) : ville.update_by.pseudo))}</p>}
                    </div>
                </div>
                <div id="Espace_perso">
                    <div id="avatar_pseudo">
                        <div id="pseuCo">
                            {user !== null && <p>{user?.pseudo}<br />
                                {user?.map_id !== null && <span>{t("Ville n°", { ns: "app" })}<span id="idMapPerso">{user.map_id}</span></span>}
                                {user?.map_id === null && <span>{t("Pas de ville", { ns: "app" })}</span>}
                            </p>}
                        </div>
                        <div id="avatar"><Avatar url={user?.avatar ?? null} classAvatar={"avatar_banniere"} /></div>
                    </div>
                    <div id="perso_admin">
                        <LanguageSelector />
                        {isCo && <div><Link to={"espace_perso"} style={{ textDecoration: "none", color: "inherit" }}>
                            <div id="menuEspacePerso" className="imgPanel"><i className="fa fa-2x fa-cogs"></i></div>
                        </Link></div>}
                        {checkHabilitation("admin") && <div><Link to={"admin"} style={{ textDecoration: "none", color: "inherit" }}>
                            <div id="admin" className="imgPanel"><i className="fa-solid fa-2x fa-screwdriver-wrench"></i></div>
                        </Link></div>}
                        {isCo && <div className={"deconnexion"}><Button href={"/logout"} variant={"danger"} size={"sm"} onClick={() => {
                            setGeneral(null);
                            sessionStorage.setItem("mapId", null);
                        }}>{t("Déconnexion", { ns: "app" })}</Button></div>}
                    </div>
                </div>
            </div>
            {user.user_personnalisation.menu_bandeau && <div className="header-content-horizontal"><NavigationBandeau general={general} /></div>}
        </header>
    );
    
}
