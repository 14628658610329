import Modal      from "react-bootstrap/Modal";
import Button     from "react-bootstrap/Button";
import React      from "react";
import HTMLParser from "html-react-parser";

const ConfirmationDialog = (props: { onConfirm, onCancel, show, message, titre, btnAnnuler, btnConfirmation }) => {
    const { onConfirm, onCancel, show, message, titre, btnAnnuler, btnConfirmation } = props;
    return (
        <Modal show={show} onHide={onCancel} centered={true}>
            {titre !== "" && <Modal.Header>
                <Modal.Title>{titre}</Modal.Title>
            </Modal.Header>}
            <Modal.Body>{HTMLParser(message)}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>{btnAnnuler}</Button>
                <Button variant="danger" onClick={onConfirm}>{btnConfirmation}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationDialog;