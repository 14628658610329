import {GeneralType}                                                               from "../../../types/components/Generality/GeneralType";
import {GardienId, HabitantId}                                                     from "../../../types/components/Generality/Job";
import {armoire, corps_sain}                                                       from "../../../types/components/Generality/Heros";
import {idItemCaddie, idItemCeinture, idItemSac, idItemSacSuper, itemExtensionSac} from "../../../types/components/Generality/Item";
import {ExpeditionDTO}                                                             from "../../../types/models/expedition.dto";
import {SacExpeditionnaireDTO}                                                     from "../../../types/models/sacExpeditionnaire.dto";
import {ExpeditionPartDTO}                                                         from "../../../types/models/expeditionPart.dto";
import {ItemPrototypeDTO}                                                          from "../../../types/models/itemPrototype.dto";
import {CitoyensDTO}                                                               from "../../../types/models/citoyens.dto";


export const ID_RAMASSAGE = 6;

export const calculPdc = (exped: CitoyensDTO, expedition_part: ExpeditionPartDTO, general: GeneralType) => {
    if (exped === null) {
        return 0;
    } else {
        let pdc = 0;
        if (general.ville.guide !== null && general.ville.guide.id === exped.citoyen.id) {
            pdc += expedition_part.expeditionnaires.length;
        }
        if (exped.job.id === GardienId) {
            pdc += 4;
        } else {
            pdc += 2;
        }
        
        if (exped.job.id !== HabitantId) {
            if (exped.citoyen.der_pouv.ordre_recup >= corps_sain.recup && exped.corps_sain) {
                pdc += 1;
            }
            if (exped.citoyen.der_pouv.ordre_recup >= armoire.recup) {
                pdc += 1;
            }
        }
        return pdc;
    }
};

export const calculPdcExpe = (expedition: ExpeditionDTO, general: GeneralType) => {
    let pdc = 0;
    expedition.expedition_parts[0].expeditionnaires.map((exped) => {
        pdc += calculPdc(exped?.citoyen ?? null, expedition.expedition_parts[0], general);
    });
    return pdc;
};

export const recuperationCitoyensPreinscritExpedition = (expedition: ExpeditionDTO) => {
    // On balaye les expeditions pour récupérer les citoyens préinscrits
    return expedition.expedition_parts[0].expeditionnaires.filter((exped) => exped.preinscrit).sort((expedi_a, expedi_b) => {
        return expedi_a.citoyen.citoyen.pseudo.localeCompare(expedi_b.citoyen.citoyen.pseudo);
    }).map((exped) => {
        if (exped.citoyen !== null) {
            return exped.citoyen;
        }
    });
};

export const recuperationCitoyensInscritExpedition = (expedition: ExpeditionDTO) => {
    // On balaye les expeditions pour récupérer les citoyens préinscrits
    return expedition.expedition_parts[0].expeditionnaires.filter((exped) => exped.citoyen !== null).sort((expedi_a, expedi_b) => {
        return expedi_a.citoyen.citoyen.pseudo.localeCompare(expedi_b.citoyen.citoyen.pseudo);
    }).map((exped) => {
        return exped.citoyen;
    });
};

export const controleCapaciteSac = (listItemsSac: SacExpeditionnaireDTO[], newItem: ItemPrototypeDTO, sensAjout: boolean): number => {
    let nbSacSup = 0;
    let nbSacSupPratique = 0;
    let nbCaddie = 0;
    let nbCeinture = 0;
    let nbEncombrant = 0;
    let nbExpeditionItem = 0;
    // Calcul de la capacité du sac
    let capacite = 6;
    let nbrItems = 0;
    // On balaye les items pour regarder s'il y a des extensions de sac
    listItemsSac.map((sac) => {
        if (sac.item.expedition) {
            nbExpeditionItem += sac.nbr;
            return;
        }
        nbrItems += sac.nbr;
        sac.item.caracteristiques.map((carac) => {
            if (carac.type_carac.id === itemExtensionSac) {
                capacite += (carac.value + 1);
                switch (sac.item.id_objet) {
                    case idItemCaddie:
                        nbCaddie += sac.nbr;
                        break;
                    case idItemCeinture:
                        nbCeinture += sac.nbr;
                        break;
                    case idItemSac:
                        nbSacSup += sac.nbr;
                        break;
                    case idItemSacSuper:
                        nbSacSupPratique += sac.nbr;
                        break;
                }
            }
        });
        if (sac.item.encombrant) {
            nbEncombrant += sac.nbr;
        }
    });
    
    if (sensAjout) {
        
        // Controle du nombre d'items expeditions max (1 seul et ne compte pas dans la capacité)
        if (newItem.expedition && nbExpeditionItem > 0) {
            return 5;
        }
        
        // Controle des items incompatibles avec les extensions de sac
        if (nbCaddie > 0 && (newItem.id_objet === idItemSac || newItem.id_objet === idItemSacSuper)) {
            return 1;
        }
        if (nbSacSup > 0 && (newItem.id_objet === idItemCaddie || newItem.id_objet === idItemSacSuper)) {
            return 1;
        }
        if (nbSacSupPratique > 0 && (newItem.id_objet === idItemCaddie || newItem.id_objet === idItemSac)) {
            return 1;
        }
        
        // Controle du nombre d'extensions de sac (1 seule extension de sac possible)
        if (nbCeinture > 0 && newItem.id_objet === idItemCeinture) {
            return 2;
        }
        if (nbCaddie > 0 && newItem.id_objet === idItemCaddie) {
            return 2;
        }
        if (nbSacSup > 0 && newItem.id_objet === idItemSac) {
            return 2;
        }
        if (nbSacSupPratique > 0 && newItem.id_objet === idItemSacSuper) {
            return 2;
        }
        
        // Controle du nombre d'items encombrants (1 seul item encombrant possible)
        if (nbEncombrant > 0 && newItem.encombrant) {
            return 3;
        }
        
        // Si le nouvel item est une extention de sac, on augmente la capacité
        newItem.caracteristiques.map((carac) => {
            if (carac.type_carac.id === itemExtensionSac) {
                capacite += (carac.value + 1);
            }
        });
        
        // On ajoute 1 au nombre d'items
        nbrItems += 1;
    } else {
        // Si le nouvel item est une extention de sac, on augmente la capacité
        newItem.caracteristiques.map((carac) => {
            if (carac.type_carac.id === itemExtensionSac) {
                capacite -= (carac.value + 1);
            }
        });
        
        // On ajoute 1 au nombre d'items
        nbrItems -= 1;
    }
    
    // Si le nombre d'items dépasse la capacité, on renvoie une erreur
    if (nbrItems > capacite) {
        return 4;
    }
    
    return 0;
    
};