import { Fetch }                   from "../../components/generality/Fetch";
import { OutilsDechargePropsType } from "../../types/components/Outils/DechargeType";
import { GeneralType }             from "../../types/components/Generality/GeneralType";
import { OutilsDechargeDTO }       from "../../types/models/outilsDecharge.dto";
import { DechargesDTO }            from "../../types/models/decharges.dto";

export class DechargesApi {
    
    private fetch: Fetch;
    
    constructor(mapId: number) {
        this.fetch = new Fetch("outils/decharge", mapId ?? 0);
    }
    
    public sauvegarde(data: { decharge: DechargesDTO[], mapId: number, userId: number }): Promise<RetourSauvegarde> {
        return this.fetch.from("/save").request().post(data);
    }
    
    public main(): Promise<AffichageDecharges> {
        return this.fetch.from("/").request().get();
    }
    
}

export interface RetourSauvegarde {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        outilsDecharge: OutilsDechargeDTO,
    },
}

export interface AffichageDecharges {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        outilsDecharge: OutilsDechargePropsType,
        general: GeneralType,
    },
}