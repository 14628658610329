import { createContext, useContext } from "react";
import { PopUpContextType }          from "./PopUpContextType";

export const Status_success = "succes";
export const Status_error = "error";
export const Status_info = "info";
export const Status_warning = "warning";

export const PopUpContext = createContext<PopUpContextType>({
    status              : Status_success,
    setStatus           : () => {
    },
    isWithButtonClose   : false,
    setIsWithButtonClose: () => {
    },
    showPop             : false,
    setShowPop          : () => {
    },
    messagePopUp        : "",
    setMessagePopUp     : () => {
    },
    titlePopUp          : "",
    setTitlePopUp       : () => {
    },
    handleClose         : () => {
    },
});

export function usePopUp() {
    return useContext(PopUpContext);
}