import React, { useEffect, useState } from "react";
import { Form }                       from "react-bootstrap";

interface CustomSwitchProps {
    leftLabel: string;
    rightLabel: string;
    initialChecked: boolean;
    onToggle?: (isChecked: boolean) => void;
}

function CustomSwitch({ leftLabel, rightLabel, initialChecked, onToggle }: CustomSwitchProps) {
    const [isChecked, setIsChecked] = useState(initialChecked);
    
    useEffect(() => {
        setIsChecked(initialChecked);
    }, [initialChecked]);
    
    const handleSwitch = () => {
        const newCheckedValue = !isChecked;
        setIsChecked(newCheckedValue);
        if (onToggle) {
            onToggle(newCheckedValue);
        }
    };
    
    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span>{leftLabel}</span>
            <Form.Check
                type="switch"
                id="custom-switch"
                checked={isChecked}
                onChange={handleSwitch}
            />
            <span>{rightLabel}</span>
        </div>
    );
}

export default CustomSwitch;
