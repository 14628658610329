import React                from "react";
import { useRecordContext } from "react-admin";
import SvgRuine             from "../../../../../components/generality/SvgRuine";

export const TypeCaseField = () => {
    const record = useRecordContext();
    return (record ? (<>
                <span>
                    <SvgRuine icone={"ruineCarte_" + record.id} classRuine={"ruineCarteAdmin"} />
                </span>
                <span>{record.name}</span>
            </>
        ) : null
    );
};