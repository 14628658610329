import React                    from "react";
import {useTranslation}         from "react-i18next";
import Badge                    from "react-bootstrap/Badge";
import {Stack}                  from "react-bootstrap";
import SvgIcone                 from "../generality/SvgIcone";
import {TypeCaracteristiqueDTO} from "../../types/models/typeCaracteristique.dto";
import {FontAwesomeIcon}        from "@fortawesome/react-fontawesome";
import {faXmark}                from "@fortawesome/free-solid-svg-icons";


export default function BadgeCaracteristiqueItemFiltre({caracteristique, onSupp, withSupp}: { caracteristique: TypeCaracteristiqueDTO, withSupp: boolean, onSupp?: (carac: TypeCaracteristiqueDTO) => void }) {
    const {t} = useTranslation();
    
    const listColorVariant = ["primary", "secondary", "success", "warning", "danger"];
    
    // Fonction pour calculer l'index basé sur l'ID du type de caractéristique
    const getColorVariant = (id: number | undefined) => {
        if (id === undefined) {
            return "primary";
        } // Valeur par défaut si l'ID est indéfini
        return listColorVariant[id % listColorVariant.length];
    };
    
    return <Badge pill bg={getColorVariant(caracteristique?.id)} onClick={withSupp ? () => onSupp(caracteristique) : null} style={(withSupp ? {cursor: "pointer"} : null)}>
        <Stack gap={2} direction={"horizontal"}>
            <span><SvgIcone icone={caracteristique?.icon ?? ""}/></span>
            <span>{t(caracteristique?.nom ?? "", {ns: "items"})}</span>
            {withSupp && <span><FontAwesomeIcon icon={faXmark}/></span>}
        </Stack>
    </Badge>;
}
