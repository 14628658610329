import { createContext, useContext } from "react";
import { EncyChantierContextType }   from "./EncyChantierContextType";

export const EncyChantierContext = createContext<EncyChantierContextType>({
    listChantierChoisi   : [],
    setListChantierChoisi: () => {
    },
});

export function useECContext() {
    return useContext(EncyChantierContext);
}