import React                 from "react";
import { RuinesListingType } from "../../../types/components/ville/RuinesType";
import HTMLParser            from "html-react-parser";
import SvgBatiment           from "../../../components/generality/SvgBatiment";
import { formatInTimeZone }  from "date-fns-tz";
import { Link }              from "react-router-dom";
import { useGeneralContext } from "../../../types/Context/GeneralContext";
import { useTranslation }    from "react-i18next";


export default function RuineListing(props: { ruine: RuinesListingType }) {
    const { t } = useTranslation();
    const { general } = useGeneralContext();
    const listRuines = props.ruine.listRuines;
    
    return <>
        <div id="listRuines">
            {listRuines.length === 0 && <h2>{t("Pas de ruine decouverte !", { ns: "ville" })}</h2>}
            {Object.values(listRuines).map((ruine) => {
                return <div className="blocRuine fondWhite02" key={"ruine_" + ruine.id}>
                    <div className="iconRuine"><SvgBatiment icone={ruine.bat.icon} /></div>
                    <div className="caracRuine">
                        <div className="nomCoordRuine">
                            <span>{ruine.bat.nom}</span>
                            <span>({ruine.x - ruine.ville.pos_x}/{ruine.ville.pos_y - ruine.y})</span>
                        </div>
                        <div className="infoPlansRuine">
                            {ruine.plans.length !== 0 && <>
                                <span>{HTMLParser(t("Crée par <strong>{createur}</strong> le <em>{date}</em> ", { ns: "ville" }).replace("{createur}", ruine.plans[0].create_by.pseudo).replace("{date}", formatInTimeZone(new Date(Date.parse(ruine.plans[0].created_at)), general.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" }))))}</span>
                                {(ruine.plans[0].modify_by?.pseudo ?? null) !== null &&
                                    <span>{HTMLParser(t("Modifiée par <strong>{modificateur}</strong> le <em>{date}</em> ", { ns: "ville" }).replace("{modificateur}", ruine.plans[0].modify_by.pseudo).replace("{date}", formatInTimeZone(new Date(Date.parse(ruine.plans[0].modify_at)), general.fuseau, t("dd/MM/yyyy à H:mm", { ns: "app" }))))}</span>}
                            </>}
                        </div>
                    </div>
                    <div className="boutonRuine">
                        <Link to={`/ruine/${ruine.id}/${ruine.ville.map_id}`} style={{ textDecoration: "none", color: "inherit" }}>
                            <button>
                                {ruine.plans.length === 0 ? t("Créer le plan", { ns: "ville" }) : t("Voir le plan", { ns: "ville" })}
                            </button>
                        </Link>
                    </div>
                </div>;
            })}
        </div>
    </>;
    
}