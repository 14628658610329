import { Fetch }               from "../../components/generality/Fetch";
import { TransitionTypeProps } from "../../types/components/Generality/TransitionTypeProps";
import { GeneralType }         from "../../types/components/Generality/GeneralType";

export class OutilsVeillesApi {
    
    private fetch: Fetch;
    
    constructor(mapId: number) {
        this.fetch = new Fetch("outils/veilles", mapId ?? 0);
    }
    
    public main(): Promise<AffichageVeilles> {
        return this.fetch.from("/").request().get();
    }
    
}

export interface AffichageVeilles {
    codeRetour?: number,
    libRetour?: string,
    zoneRetour: {
        outilsVeilles?: [],
        outils?: TransitionTypeProps,
        general: GeneralType,
    },
}