import React                        from "react";
import { OutilsChantiersTypeProps } from "../../../../types/components/Outils/OutilsChantiers";

export default function PaOuvrier(props: { outils: OutilsChantiersTypeProps }) {
    // const {outilsChantiers, setOutilsChantiers } = useOutilsChantiers();
    //const outils: OutilsChantiersTypeProps = props.outils;
    //const translate: OutilsChantiersTranslate = outils.translation;
    return <div id={"bloc_pa_ouvrier"}>
    
    </div>;
}

