import React                                                             from "react";
import SvgRuine                                                          from "../../../../components/generality/SvgRuine";
import { CaseRuine, DIRECTION_E, DIRECTION_N, DIRECTION_O, DIRECTION_S } from "../../../../types/components/ville/RuinesType";


export default function RuineZoomAffichage({ plan, x, y, z, calculDirection, calculClasseAffichage, calculCase = null }: {
    plan: CaseRuine[][][],
    x: number,
    y: number,
    z: number,
    calculDirection: (direction: string) => void,
    calculClasseAffichage: (typeAffichage: string, direction: string, classString: string, typeCase?: number) => string,
    calculCase?: (type: string, direction: string) => void
    isInPiece?: boolean
}) {
    
    const caseRuine = plan[z][y][x];
    
    return <>
        <div className="blocCentraleImg">
            <SvgRuine icone={"ruine" + (caseRuine.type_case) + ((caseRuine.type_porte === null) ? "" : ("_" + caseRuine.type_porte)) + ((caseRuine.type_escalier === null) ? "" : ("_esc_" + caseRuine.type_escalier))} />
            <SvgRuine icone={"perso" + (caseRuine.nbr_zombie ?? 0) + "Z"} classRuine={"ruineZoomPerso"} />
        </div>
        <div className="blocDirectionPlusMinus">
            <div id="blocHautDir">
                <div>
                    <div id="direction_ruine_haut">
                        <div className={calculClasseAffichage("direction", "haut", "ruine_haut", DIRECTION_N)} onClick={calculCase ? () => calculCase("supp", "haut") : undefined}><i className="fas fa-minus-circle fa-2x"></i></div>
                        <div className={calculClasseAffichage("direction", "haut", "ruine_haut")} onClick={() => calculDirection("haut")}><i className="fas fa-angle-up fa-2x"></i></div>
                    </div>
                    <div id="plus_ruine_haut">
                        <div className={calculClasseAffichage("plus", "haut", "ruine_haut")} onClick={calculCase ? () => calculCase("add", "haut") : undefined}><i className="fas fa-plus-circle fa-2x"></i></div>
                        <div className={calculClasseAffichage("plus", "haut", "ruine_haut")}></div>
                    </div>
                </div>
            </div>
            <div id="blocGlobalDir">
                <div id="blocGaucheDir">
                    <div id="direction_ruine_gauche">
                        <div className={calculClasseAffichage("direction", "gauche", "ruine_gauche", DIRECTION_O)} onClick={calculCase ? () => calculCase("supp", "gauche") : undefined}><i className="fas fa-minus-circle fa-2x"></i></div>
                        <div className={calculClasseAffichage("direction", "gauche", "ruine_gauche")} onClick={() => calculDirection("gauche")}><i className="fas fa-angle-left fa-2x"></i></div>
                    </div>
                    <div id="plus_ruine_gauche">
                        <div className={calculClasseAffichage("plus", "gauche", "ruine_gauche")} onClick={calculCase ? () => calculCase("add", "gauche") : undefined}><i className="fas fa-plus-circle fa-2x"></i></div>
                        <div className={calculClasseAffichage("plus", "gauche", "ruine_gauche")}></div>
                    </div>
                </div>
                <div id="blocDroiteDir">
                    <div id="direction_ruine_droite">
                        <div className={calculClasseAffichage("direction", "droite", "ruine_droite")} onClick={() => calculDirection("droite")}><i className="fas fa-angle-right fa-2x"></i></div>
                        <div className={calculClasseAffichage("direction", "droite", "ruine_droite", DIRECTION_E)} onClick={calculCase ? () => calculCase("supp", "droite") : undefined}><i className="fas fa-minus-circle fa-2x"></i></div>
                    </div>
                    <div id="plus_ruine_droite">
                        <div className={calculClasseAffichage("plus", "droite", "ruine_droite")}></div>
                        <div className={calculClasseAffichage("plus", "droite", "ruine_droite")} onClick={calculCase ? () => calculCase("add", "droite") : undefined}><i className="fas fa-plus-circle fa-2x"></i></div>
                    </div>
                </div>
            </div>
            <div id="blocBasDir">
                <div>
                    <div id="direction_ruine_bas">
                        <div className={calculClasseAffichage("direction", "bas", "ruine_bas")} onClick={() => calculDirection("bas")}><i className="fas fa-angle-down fa-2x"></i></div>
                        <div className={caseRuine.type_case === 16 ? "ruineCache ruine_bas" : calculClasseAffichage("direction", "bas", "ruine_bas", DIRECTION_S)} onClick={calculCase ? () => calculCase("supp", "bas") : undefined}><i className="fas fa-minus-circle fa-2x"></i></div>
                    </div>
                    <div id="plus_ruine_bas">
                        <div className={calculClasseAffichage("plus", "bas", "ruine_bas")}></div>
                        <div className={calculClasseAffichage("plus", "bas", "ruine_bas")} onClick={calculCase ? () => calculCase("add", "bas") : undefined}><i className="fas fa-plus-circle fa-2x"></i></div>
                    </div>
                </div>
            </div>
        </div>
    </>;
    
}