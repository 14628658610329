import React, { useEffect, useRef } from "react";
import { useRuineGameContext }      from "./RuineGame";
import { fromZonedTime }            from "date-fns-tz";

interface JaugeOxygeneProps {
    endGame: boolean;
    oxygeneStart: number;
    gameBegin: string;
    oxygen_lost: number;
}

export function JaugeOxygene({ endGame, oxygeneStart, gameBegin, oxygen_lost = 0 }: JaugeOxygeneProps) {
    const { oxygene, setOxygene } = useRuineGameContext();
    const intervalIdRef = useRef(null); // Référence mutable pour stocker l'ID d'intervalle
    
    useEffect(() => {
        if (endGame) {
            clearInterval(intervalIdRef.current); // Arrêter l'intervalle s'il est en cours
            return; // Sortir de l'effet
        }
        intervalIdRef.current = setInterval(() => {
            const diffTime = Math.abs(
                fromZonedTime(new Date().getTime(), "Europe/Paris").getTime() - fromZonedTime(new Date(gameBegin), "Europe/Paris").getTime());
            const oxygeneSupp = Math.round(diffTime / 3000);
            const newOxygene = Math.max(oxygeneStart - oxygeneSupp - oxygen_lost, 0);
            if (newOxygene === 0) {
                clearInterval(intervalIdRef.current); // Arrêter la décrémentation si le nombre d'unités d'oxygène atteint 0
            }
            setOxygene(newOxygene);
        }, 3000);
        
        return () => clearInterval(intervalIdRef.current);
    }, [oxygene, setOxygene, endGame]);
    
    return <div id={"zone_jauge_oxygene"}>
        <div className="ruine_progress-bar">
            <div className="progress-fill-oxygene" style={{ height: `${(oxygene / oxygeneStart * 100)}%` }}>{oxygene}</div>
        </div>
    </div>;
}