import React                from "react";
import { useRecordContext } from "react-admin";
import SvgIcone             from "../../../../../components/generality/SvgIcone";

export const TypePortesField = () => {
    const record = useRecordContext();
    return (record ? (<>
                <span>
                    <SvgIcone icone={record.icon.toString()} />
                </span>
                <span>{record.name}</span>
            </>
        ) : null
    );
};