import React, { useEffect, useState } from "react";
import chargement                     from "../../../img/chargement.svg";
import { EncyclopedieApi }            from "../../services/api/EncyclopedieApi";
import { useNavigate }                from "react-router-dom";
import EncyVille                      from "../../containers/Encyclopedie/EncyVille";
import { useGeneralContext }          from "../../types/Context/GeneralContext";
import { useGHContext }               from "../../types/Context/GHContext";
import { Status_error, usePopUp }     from "../../types/Context/PopUpContext";
import { useTranslation }             from "react-i18next";

export function EncyVillesGeneral() {
    const { general, setGeneral } = useGeneralContext();
    const { checkHabilitation, refreshKey, setIsOnRefresh, setIsOnUpdate } = useGHContext();
    const { setStatus, setMessagePopUp, setShowPop } = usePopUp();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const [encyVilleData, setEncyVilleData] = useState(null);
    
    // Fonction pour recharger les données
    const reloadData = async () => {
        const encyclopedieApi = new EncyclopedieApi(parseInt(sessionStorage.getItem("mapId") ?? "0", 10));
        
        encyclopedieApi.main_villes().then((response) => {
            setIsOnRefresh(false);
            setIsOnUpdate(false);
            if (response.data.general !== undefined) {
                setGeneral(response.data.general);
                sessionStorage.setItem("mapId", response.data.general.ville ? response.data.general.ville.map_id.toString() : null);
            }
            if (response.data.villes === undefined) {
                console.error("Erreur de chargement des données villes", response);
            } else {
                setEncyVilleData(response.data.villes);
            }
        }).catch((error) => {
            setStatus(Status_error);
            setShowPop(true);
            setMessagePopUp(error?.data?.error);
            navigate("/news");
        });
    };
    
    useEffect(() => {
        if (checkHabilitation("encyclopedie", "villes")) {
            reloadData().then(r => r);
        } else {
            navigate("/news");
        }
    }, [refreshKey]);
    
    return (
        <>
            {encyVilleData === null ? (
                <div className={"chargement_page"}>
                    <img src={chargement} alt="Drapeau" />
                    <span>{t("Chargement...", { ns: "app" })}</span>
                </div>
            ) : (
                <EncyVille villes={encyVilleData} />
            )}
        
        </>
    );
}