import React     from "react";
import * as Icon from "react-icons/bs";

export const DirectionScrutateurArrow = (idDirection: number) => {
    return <span>
    {idDirection === 1 && <Icon.BsFillArrowUpCircleFill size={"20px"} />}
        {idDirection === 2 && <Icon.BsFillArrowRightCircleFill size={"20px"} />}
        {idDirection === 4 && <Icon.BsFillArrowDownCircleFill size={"20px"} />}
        {idDirection === 8 && <Icon.BsFillArrowLeftCircleFill size={"20px"} />}
        {idDirection === 3 && <Icon.BsFillArrowUpRightCircleFill size={"20px"} />}
        {idDirection === 9 && <Icon.BsFillArrowUpLeftCircleFill size={"20px"} />}
        {idDirection === 6 && <Icon.BsFillArrowDownRightCircleFill size={"20px"} />}
        {idDirection === 12 && <Icon.BsFillArrowDownLeftCircleFill size={"20px"} />}
  </span>;
};